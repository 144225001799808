import { IApiResponseWithPagination, ITravelData, ITravelList, ITravelDTO } from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { TravelSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<ITravelList> | ApiError> => {
  try {
    const response = await Api().get('/travels', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, TravelSchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<ITravelData | ApiError> => {
  try {
    const response = await Api().get(`/travels/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, TravelSchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: ITravelDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/travels', payload, { headers });
    return response.data.travel_id;
  } catch (error: any) {
    return makeApiError(error, TravelSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: ITravelDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/travels/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, TravelSchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/travels/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, TravelSchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/travels/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, TravelSchemaApiError);
  }
};

export const TravelService = { getAll, getById, create, updateById, deleteById, deleteIds };
