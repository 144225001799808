import { AccessTime } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Div, H1, P } from '../../../shared/components';

// Show reset token is invalid
export const ResetPasswordInvalidToken: React.FC = () => {
  const theme = useTheme();

  return (
    <Div display="flex" flexDirection="column" alignItems="center">
      <AccessTime
        sx={{ color: theme.palette.text.secondary, fontSize: 48, mb: theme.spacing(2) }}
      />
      <H1 color={theme.palette.text.secondary} sx={{ marginX: theme.spacing(2) }}>
        Este link expirou
      </H1>
      <P color={theme.palette.text.secondary} align="center" sx={{ m: theme.spacing(2) }}>
        O Link que você acesso não está mais disponível, tente refazer o procedimento...
      </P>
    </Div>
  );
};
