/* eslint-disable react/jsx-props-no-spreading */
import { InputAdornment } from '@mui/material';
import { IInputNumberProps, InputNumber } from './InputNumber';

type IInputCurrencyProps = IInputNumberProps;

export const InputCurrency: React.FC<IInputCurrencyProps> = ({ name, ...rest }) => {
  return (
    <InputNumber
      name={name}
      mask="currency"
      InputProps={{
        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
      }}
      {...rest}
    />
  );
};
