/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

type ISelectPersonTypeProps = FieldProps;

const options: ISelectOptions[] = [
  { label: 'Física', value: 'FISICA' },
  { label: 'Jurídica', value: 'JURIDICA' },
];

export const SelectPersonType: React.FC<ISelectPersonTypeProps> = ({ ...rest }) => {
  return <Select label="Pessoa" defaultOption="FISICA" dataOptions={options} {...rest} />;
};
