import { AxiosResponse } from 'axios';
import { disableLoader } from '../../../../contexts/LoaderContext';

// Response interceptor
export const responseInterceptor = (response: AxiosResponse) => {
  // Disable loader
  disableLoader();

  // Return response
  return response;
};
