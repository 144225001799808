/* eslint-disable react/jsx-props-no-spreading */
import { Info } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { FieldProps } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth, useDeviceIsMobile, useUser } from '../../hooks';
import { Div } from '../display-data';
import { UserRoleDialog } from '../user-role-dialog/UserRoleDialog';
import { Button } from './Button';
import { IconButton } from './IconButton';
import { ISelectOptions, Select } from './Select';

type ISelectUserRoleProps = FieldProps;

export const SelectUserRole: React.FC<ISelectUserRoleProps> = ({ ...rest }) => {
  const [currentRole, setCurrentRole] = useState(rest.field.value);

  const [isOpenRoleModal, setIsOpenRoleModal] = useState(false);

  const [options, setOptions] = useState<ISelectOptions[]>([]);

  const { user } = useAuth();

  const { rowsUserRole, getAllUserRole } = useUser();

  const deviceIsMobile = useDeviceIsMobile();

  const handleToggleIsOpenRoleModal = () => setIsOpenRoleModal((prevState) => !prevState);

  const handleChangeCurrentRole = (value: any) => setCurrentRole(+value);

  useEffect(() => {
    if (!user.token) return;
    getAllUserRole();
  }, [user]);

  useEffect(
    () => setOptions(rowsUserRole.map((role) => ({ label: role.name, value: role.id }))),
    [rowsUserRole, setOptions],
  );

  return options && options.length > 0 ? (
    <Div display="flex" alignItems="center">
      <UserRoleDialog
        isOpen={isOpenRoleModal}
        onClose={handleToggleIsOpenRoleModal}
        role={currentRole}
      />
      <Div flexGrow={1}>
        <Select
          label="Permissão"
          defaultOption={options[0].value}
          dataOptions={options}
          onSelect={handleChangeCurrentRole}
          {...rest}
        />
        {deviceIsMobile && (
          <Button
            variant="text"
            color="info"
            startIcon={<Info />}
            label="Exibir permissões"
            size="small"
            fullWidth
            onClick={handleToggleIsOpenRoleModal}
          />
        )}
      </Div>
      {!deviceIsMobile && (
        <IconButton
          color="info"
          icon="info"
          tooltip="Exibir permissões"
          sx={{ mt: 1, mr: -1 }}
          onClick={handleToggleIsOpenRoleModal}
        />
      )}
    </Div>
  ) : (
    <Skeleton variant="text" animation="wave" height="100%" />
  );
};
