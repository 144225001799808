import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSnackbarDispatch, useUserIsAuthenticate } from '../shared/hooks';

// Define interface
interface IPrivateRoute {
  component: React.ComponentType;
}

// Check if user contains token access
export const PrivateRoute: React.FC<IPrivateRoute> = ({ component: RouteComponent }) => {
  // Check token in local storage
  const isAuthenticated = useUserIsAuthenticate();

  // Use hook for snackbar
  const { snackbarError } = useSnackbarDispatch();

  // Send message for required authenticate
  useEffect(() => {
    if (isAuthenticated) return;
    snackbarError('Autenticação necessária');
  }, []);

  return isAuthenticated ? <RouteComponent /> : <Navigate to="/login" />;
};
