import { daysOfWeek } from '../constants';
import { toDate, toDateTime, toNumberOrZero } from './transformData';

export const formatterCurrency = (value: any, showSymbol?: boolean) => {
  const formatted = Number(toNumberOrZero(value));
  const options = showSymbol
    ? {
        style: 'currency',
        currency: 'BRL',
      }
    : {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
  return formatted.toLocaleString('pt-br', options);
};

export const formatterQuantity = (value: any, decimals?: number) => {
  const formatted = Number(toNumberOrZero(value));
  const options = {
    minimumFractionDigits: decimals || 0,
    maximumFractionDigits: decimals || 0,
  };
  return formatted.toLocaleString('pt-br', options);
};

export const formatterDayWeek = (value: any, output?: 'longName' | 'shortName' | 'name') => {
  const formatted = toNumberOrZero(value);
  const dayWeek = daysOfWeek.find((item) => item.index === formatted);
  if (!dayWeek) return String(value);
  return dayWeek[output || 'name'];
};

// Convert date to pr-bt (dd/mm/yyyy)
export const formatterDateBR = (value: any) => {
  const date = value instanceof Date ? value : toDate(value);
  if (!date) return '';
  return date.toLocaleDateString('pt-br');
};

// Convert date time to pr-bt (dd/mm/yyyy hh:mm:ss)
export const formatterDateTimeBR = (value: any) => {
  const dateTime = value instanceof Date ? value : toDateTime(value);
  if (!dateTime) return '';
  return dateTime.toLocaleString('pt-br');
};
