/* eslint-disable react/jsx-props-no-spreading */
import { BoxProps, useTheme } from '@mui/material';
import { Div } from '../display-data';

interface IUploadSingleFileContainerProps extends BoxProps {
  bgColor?: any;
  border?: any;
  enabled?: boolean;
}

export const UploadSingleFileContainer: React.FC<IUploadSingleFileContainerProps> = ({
  bgColor,
  border,
  enabled,
  children,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Div
      isFlexRow
      alignItems="center"
      style={{
        backgroundColor: bgColor || theme.palette.background.paper,
        border:
          border || (theme.palette.mode === 'light' ? '1px dashed #C4C4C4' : '1px dashed #666666'),
        borderRadius: '4px',
        padding: theme.spacing(2),
        cursor: enabled ? 'pointer' : 'default',
      }}
      {...rest}
    >
      {children}
    </Div>
  );
};
