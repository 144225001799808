import { Field } from 'formik';
import { FilterDialog, DataPicker, SelectBoolean } from '../../../shared/components';
import { useVehicleSupply } from '../../../shared/hooks';
import { EmployeeInputSelectBase } from '../../employee/components';
import { VehicleInputSelectBase } from '../../vehicle/components/VehicleInputSelectBase';

// Create modal filter for VehicleSupply
export const VehicleSupplyFilterModal: React.FC = () => {
  // Use context VehicleSupply
  const {
    isOpenFilterModalVehicleSupply,
    toggleIsOpenFilterModalVehicleSupply,
    handleFilterVehicleSupply,
    filterVehicleSupply,
    defaultFilterVehicleSupply,
  } = useVehicleSupply();

  return (
    <FilterDialog
      isOpen={isOpenFilterModalVehicleSupply}
      onClose={toggleIsOpenFilterModalVehicleSupply}
      setFilter={handleFilterVehicleSupply}
      filter={filterVehicleSupply}
      initialValues={defaultFilterVehicleSupply}
    >
      <Field name="start_date" component={DataPicker} label="Início" />

      <Field name="end_date" component={DataPicker} label="Fim" />

      <EmployeeInputSelectBase baseName="driver" label="Motorista" />

      <VehicleInputSelectBase refId="vehicle_id" refName="vehicle_detail" />

      <Field name="audited" component={SelectBoolean} label="Conferido" />
    </FilterDialog>
  );
};
