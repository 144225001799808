import { AttachFile } from '@mui/icons-material';
import { Avatar, Paper, useTheme } from '@mui/material';
import { Div, P } from '../display-data';
import { IconButton } from '../inputs';
import { UploadSingleFileContainer } from './UploadSingleFileContainer';

interface IUploadSingleFileSelectedProps {
  file: any;
  onDelete: (file: string) => void;
}

export const UploadSingleFileSelected: React.FC<IUploadSingleFileSelectedProps> = ({
  file,
  onDelete,
}) => {
  const theme = useTheme();

  return (
    <UploadSingleFileContainer color={theme.palette.text.secondary} sx={{ mb: theme.spacing(1) }}>
      <Avatar
        src={URL.createObjectURL(file)}
        sx={{
          width: 48,
          height: 48,
          bgcolor: theme.palette.primary.main,
          mr: theme.spacing(1),
        }}
        variant="rounded"
        component={Paper}
        elevation={2}
      >
        <AttachFile fontSize="large" />
      </Avatar>

      <Div flexGrow={1} style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '15rem' }}>
        <P noWrap>{file.name}</P>
      </Div>

      <IconButton
        color="default"
        icon="cancel"
        onClick={() => onDelete('')}
        tooltip="Remover arquivo selecionado"
        sx={{ ml: theme.spacing(1) }}
      />
    </UploadSingleFileContainer>
  );
};
