import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, CustomerService } from '../services';
import {
  ICustomerData,
  ICustomerFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  ICustomerDTO,
  IDataGridSelection,
  ICustomerList,
} from '../types';
import { InfoStatusRecord } from '../components/feedback/InfoStatusRecord';
import { getFilterParams, toObjectWithoutNull } from '../utils';
import { getSortParams } from '../utils/getSortParams';

// Define interface for context
interface ICustomerContext {
  // Data
  columnsCustomer: IDataGridColumns;
  rowsCustomer: ICustomerList[];
  defaultDataCustomer: ICustomerData;
  dataCustomer: ICustomerData | undefined;
  handleDataCustomer: (data?: ICustomerData) => void;
  // Pagination
  rowCountCustomer: number;
  pageSizeCustomer: number;
  pageCustomer: number;
  handlePageSizeCustomer: (size: number) => void;
  handlePageCustomer: (page: number) => void;
  // Sort
  sortCustomer: IDataGridSortOptions;
  handleSortCustomer: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionCustomer: IDataGridSelection;
  handleSelectionCustomer: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterCustomer: ICustomerFilter;
  filterCustomer: ICustomerFilter;
  isOpenFilterModalCustomer: boolean;
  toggleIsOpenFilterModalCustomer: () => void;
  handleFilterCustomer: (filter: ICustomerFilter) => void;
  // Api
  getAllCustomer: () => void;
  getByIdCustomer: (id: number) => void;
  createCustomer: (payload: ICustomerDTO) => void;
  updateByIdCustomer: (id: number, payload: ICustomerDTO) => void;
  updateContractByIdCustomer: (customer: number, contract: number) => void;
  deleteByIdCustomer: (id: number) => void;
  deleteIdsCustomer: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaCustomer: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código do cliente',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
  {
    field: 'name',
    headerName: 'Nome',
    description: 'Razão social / Nome',
    hideable: false,
    flex: 2,
  },
  {
    field: 'nickname',
    headerName: 'Apelido',
    description: 'Nome fantasia / Apelido',
  },
  {
    field: 'business_type',
    headerName: 'Ramo de Atividade',
    description: 'Ramo de atividade do cliente',
  },
  {
    field: 'contract',
    headerName: 'Contrato',
    description: 'Contrato atual do cliente',
    flex: 1,
  },
  {
    field: 'phone',
    headerName: 'Telefone',
    description: 'Telefone padrão do cliente',
    flex: 1,
  },
  {
    field: 'document',
    headerName: 'Documento',
    description: 'CNPJ / CPF do cliente',
    flex: 1,
  },
  {
    field: 'neighborhood',
    headerName: 'Bairro',
    description: 'Bairro do endereço padrão do cliente',
  },
  {
    field: 'city',
    headerName: 'Cidade',
    description: 'Cidade do endereço padrão do cliente',
  },
  {
    field: 'state',
    headerName: 'UF',
    description: 'UF do endereço padrão do cliente',
  },
  {
    field: 'is_active',
    headerName: 'Status',
    description: 'Indica se o registro esta ativo ou não',
    renderCell: (params) => <InfoStatusRecord status={params.row.is_active} />,
    headerAlign: 'center',
    align: 'center',
  },
];

// Initial values for filter
const defaultFilterCustomer: ICustomerFilter = {
  id: 0,
  name: '',
  cnpj: '',
  cpf: '',
};

// initial values for data
const defaultDataCustomer: ICustomerData = {
  id: 0,
  cnpj: '',
  ie: '',
  cpf: '',
  rg: '',
  name: '',
  nickname: '',
  business_type_id: 0,
  business_type_name: '',
  contract_id: 0,
  contract_name: '',
  tags: '',
  is_active: true,
  contacts: [],
  addresses: [],
};

// Create context
export const CustomerContext = createContext<ICustomerContext>({} as ICustomerContext);

// Create context provider
export const CustomerContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsCustomer] = useState<IDataGridColumns>(columnsSchemaCustomer);

  // Rows state
  const [rowsCustomer, setRowsCustomer] = useState<ICustomerList[]>([]);

  // Data state
  const [dataCustomer, setDataCustomer] = useState<ICustomerData | undefined>();

  // Sort state
  const [sortCustomer, setSortCustomer] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterCustomer, setFilterCustomer] = useState<ICustomerFilter>(defaultFilterCustomer);

  // Modal filters
  const [isOpenFilterModalCustomer, setIsOpenCustomerFilterModal] = useState(false);

  // Page state
  const [pageCustomer, setPageCustomer] = useState(0);

  // Page size state
  const [pageSizeCustomer, setPageSizeCustomer] = useState(10);

  // Record count state
  const [rowCountCustomer, setRowCountCustomer] = useState(0);

  // Selection state
  const [selectionCustomer, setSelectionCustomer] = useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataCustomer = useCallback(
    (data?: ICustomerData) => {
      if (!data) return setDataCustomer(data);
      const dataWithoutNull = toObjectWithoutNull<ICustomerData>(data, defaultDataCustomer);
      dataWithoutNull.person_type = data.cnpj ? 'JURIDICA' : 'FISICA';
      return setDataCustomer(dataWithoutNull);
    },
    [dataCustomer],
  );

  // Change page status
  const handlePageCustomer = useCallback((page: number) => setPageCustomer(page), [pageCustomer]);

  // Change page size status
  const handlePageSizeCustomer = useCallback(
    (size: number) => {
      setPageSizeCustomer(size);
      setPageCustomer(0);
    },
    [pageSizeCustomer],
  );

  // Change sort status
  const handleSortCustomer = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortCustomer(sort);
      setPageCustomer(0);
    },
    [sortCustomer],
  );

  // Change filters status
  const handleFilterCustomer = useCallback(
    (filter: ICustomerFilter) => {
      setFilterCustomer(filter);
      setPageCustomer(0);
    },
    [filterCustomer],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalCustomer = useCallback(
    () => setIsOpenCustomerFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionCustomer = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionCustomer(selection);
    },
    [selectionCustomer],
  );

  // Clear data rows
  const clearStorageCustomer = useCallback(() => {
    setRowsCustomer([]);
    setRowCountCustomer(0);
  }, [setRowsCustomer]);

  // Load data from request
  const loadStorageCustomer = useCallback(
    (response: IApiResponseWithPagination<ICustomerList>) => {
      setRowsCustomer(response.data);
      setRowCountCustomer(response.records);
    },
    [setRowsCustomer],
  );

  // API - Get all records
  const getAllCustomer = useCallback(() => {
    // Reset selection
    handleSelectionCustomer([]);
    // Add filters in params
    const params: any = getFilterParams(filterCustomer);
    // Add sort in params
    const sort = getSortParams(sortCustomer);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeCustomer;
    params.offset = pageCustomer * pageSizeCustomer;
    // Get data
    CustomerService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        clearStorageCustomer();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageCustomer(0);
        return;
      }
      loadStorageCustomer(response);
    });
  }, [headers, filterCustomer, sortCustomer, pageCustomer, pageSizeCustomer, dataCustomer]);

  // API - Get record by id
  const getByIdCustomer = useCallback(
    (id: number) => {
      // Get details
      CustomerService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataCustomer(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createCustomer = useCallback(
    (payload: ICustomerDTO) => {
      // Get details
      CustomerService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        // Update data
        const updatedData: ICustomerData = { ...payload, id: response };
        handleDataCustomer(updatedData);
        //
        snackbarSuccess('Registro adicionado!');
      });
    },
    [getAllCustomer],
  );

  // API - Update record by id
  const updateByIdCustomer = useCallback(
    (id: number, payload: ICustomerDTO) => {
      // Get details
      CustomerService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        // Update form data
        const updatedData: ICustomerData = { ...payload, id };
        handleDataCustomer(updatedData);
        //
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllCustomer],
  );

  // API - Update contract by ID
  const updateContractByIdCustomer = useCallback(
    (customer: number, contract: number) => {
      // Update contract
      CustomerService.updateContractById(headers, customer, contract).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        snackbarSuccess('Contrato atualizado!');
      });
    },
    [getAllCustomer],
  );

  // API - Delete record by id
  const deleteByIdCustomer = useCallback(
    (id: number) => {
      CustomerService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataCustomer();
        getAllCustomer();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllCustomer],
  );

  // API - Delete record by ids
  const deleteIdsCustomer = useCallback(
    (ids: number[]) => {
      CustomerService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllCustomer();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllCustomer],
  );

  return (
    <CustomerContext.Provider
      value={{
        // Data
        columnsCustomer,
        rowsCustomer,
        dataCustomer,
        defaultDataCustomer,
        handleDataCustomer,
        // Pagination
        rowCountCustomer,
        pageSizeCustomer,
        pageCustomer,
        handlePageSizeCustomer,
        handlePageCustomer,
        // Sort
        sortCustomer,
        handleSortCustomer,
        // Selection
        selectionCustomer,
        handleSelectionCustomer,
        // Filter
        defaultFilterCustomer,
        filterCustomer,
        isOpenFilterModalCustomer,
        toggleIsOpenFilterModalCustomer,
        handleFilterCustomer,
        // Api
        getAllCustomer,
        getByIdCustomer,
        createCustomer,
        updateByIdCustomer,
        updateContractByIdCustomer,
        deleteByIdCustomer,
        deleteIdsCustomer,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
