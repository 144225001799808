/* eslint-disable @typescript-eslint/naming-convention */
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import { FilterDialog, DataPicker, Input, SelectPeopleType } from '../../../shared/components';
import { useVehicleMaintenance } from '../../../shared/hooks';
import { IPeopleType } from '../../../shared/types';
import { EmployeeInputSelectBase } from '../../employee/components';
import { SupplierInputSelectBase } from '../../supplier/components';
import { VehicleInputSelectBase } from '../../vehicle/components/VehicleInputSelectBase';

// Create modal filter for VehicleMaintenance
export const VehicleMaintenanceFilterModal: React.FC = () => {
  // State people type
  const [peopleType, setPeopleType] = useState<IPeopleType>('FORNECEDOR');

  // Use context VehicleMaintenance
  const {
    isOpenFilterModalVehicleMaintenance,
    toggleIsOpenFilterModalVehicleMaintenance,
    handleFilterVehicleMaintenance,
    filterVehicleMaintenance,
    defaultFilterVehicleMaintenance,
  } = useVehicleMaintenance();

  // On change people type
  const handleChangePeopleType = (type: IPeopleType) => setPeopleType(type);

  // Load people type
  useEffect(() => {
    if (filterVehicleMaintenance === undefined) return;
    const { people_type } = filterVehicleMaintenance;
    handleChangePeopleType(people_type || 'FORNECEDOR');
  }, [filterVehicleMaintenance]);

  return (
    <FilterDialog
      isOpen={isOpenFilterModalVehicleMaintenance}
      onClose={toggleIsOpenFilterModalVehicleMaintenance}
      setFilter={handleFilterVehicleMaintenance}
      filter={filterVehicleMaintenance}
      initialValues={defaultFilterVehicleMaintenance}
    >
      <Field name="start_date" component={DataPicker} label="Início" />

      <Field name="end_date" component={DataPicker} label="Fim" />

      <VehicleInputSelectBase refId="vehicle_id" refName="vehicle_detail" />

      <Field
        name="people_type"
        component={SelectPeopleType}
        label="Prestador"
        value={peopleType}
        onSelect={handleChangePeopleType}
        disableCustomer
      />

      {peopleType === 'COLABORADOR' && (
        <EmployeeInputSelectBase baseName="people" label="Prestador responsável (Colaborador)" />
      )}
      {peopleType === 'FORNECEDOR' && (
        <SupplierInputSelectBase baseName="people" label="Prestador responsável (Fornecedor)" />
      )}

      <Input label="Mecânico" name="mechanical" />
    </FilterDialog>
  );
};
