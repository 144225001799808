interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabActive: number;
  forceRender?: boolean;
}

export const TabPanel: React.FC<ITabPanelProps> = ({ children, index, tabActive, forceRender }) => {
  return (
    <div role="tabpanel" hidden={tabActive !== index}>
      {forceRender ? children : tabActive === index && children}
    </div>
  );
};
