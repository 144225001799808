/* eslint-disable react/jsx-props-no-spreading */

import { Tab, Tabs, ITabsProps } from '../../../shared/components';

interface IVehicleModalTabs extends ITabsProps {
  isEditMode?: boolean;
}

export const VehicleModalTabs: React.FC<IVehicleModalTabs> = ({ isEditMode, ...rest }) => {
  return (
    <Tabs {...rest}>
      <Tab label="Geral" />
      <Tab label="Seguros" disabled={!isEditMode} />
    </Tabs>
  );
};
