import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useFinancialCategory, useDialog, useDeviceIsMobile } from '../../../shared/hooks';
import {
  IFinancialCategoryFilter,
  IDataGridRowParams,
  IDataGridColumnsHidden,
} from '../../../shared/types';
import { FinancialCategoryDataGrid } from './FinancialCategoryDataGrid';
import { FinancialCategoryFilterModal } from './FinancialCategoryFilterModal';
import { FinancialCategoryModal } from './FinancialCategoryModal';

interface IFinancialCategoryMainProps {
  embedded?: boolean;
  initialFilter?: Partial<IFinancialCategoryFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {};

export const FinancialCategoryMain: React.FC<IFinancialCategoryMainProps> = ({
  embedded,
  initialFilter,
}) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState<boolean>(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsFinancialCategory,
    rowsFinancialCategory,
    defaultDataFinancialCategory,
    handleDataFinancialCategory,
    selectionFinancialCategory,
    handleSelectionFinancialCategory,
    filterFinancialCategory,
    handleFilterFinancialCategory,
    getByIdFinancialCategory,
    deleteIdsFinancialCategory,
  } = useFinancialCategory();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterFinancialCategory(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsFinancialCategory]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterFinancialCategory({});
      return;
    }
    handleFilterFinancialCategory({ ...filterFinancialCategory, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataFinancialCategory(defaultDataFinancialCategory);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdFinancialCategory(+id);
  };

  const handleDeleteData = () => {
    if (!selectionFinancialCategory || selectionFinancialCategory.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsFinancialCategory(selectionFinancialCategory as number[]);
      },
    );
  };

  return (
    <>
      <FinancialCategoryFilterModal />
      <FinancialCategoryModal />

      {!embedded && (
        <DataGridFilters
          columns={columnsFinancialCategory}
          filters={filterFinancialCategory}
          onRemoveFilter={handleRemoveFilter}
        />
      )}

      <FinancialCategoryDataGrid
        selectionModel={selectionFinancialCategory}
        onSelectionModelChange={handleSelectionFinancialCategory}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionFinancialCategory}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionFinancialCategory?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
