import { Container, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Div, H1, P, Button, Img } from '../../shared/components';
import NotFoundSVG from '../../assets/images/forbidden-page.svg';

// Page 403
export const Page403: React.FC = () => {
  // use theme
  const theme = useTheme();

  // use navigate
  const navigate = useNavigate();

  // Go back
  const handleToHome = () => navigate('/pagina-inicial');

  // Go to login
  const handleToLogin = () => navigate('/login');

  return (
    <Container sx={{ p: theme.spacing(8) }}>
      <Div isFlexColumn>
        <Img src={NotFoundSVG} width="300px" />
        <H1 variant="h4" sx={{ mt: theme.spacing(6) }}>
          Acesso negado!
        </H1>
        <P variant="h6">Ops, parece que você não tem permissões para executar esta rotina...</P>

        <Button
          variant="outlined"
          label="Trocar de usuário"
          onClick={handleToLogin}
          sx={{ mt: theme.spacing(6) }}
        />

        <Button
          variant="text"
          label="Página inicial"
          onClick={handleToHome}
          sx={{ mt: theme.spacing(2) }}
        />
      </Div>
    </Container>
  );
};
