import { createContext, useCallback, useState } from 'react';
import { Lock, VerifiedUser } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, UserService } from '../services';
import {
  IUserData,
  IUserFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  IUserDTO,
  IDataGridSelection,
  IUserList,
  IUserRoleList,
  IUserStatusDTO,
  IUserRolePermissionList,
  IUserChangePasswordDTO,
  IUserChangeTwoStepAuthDTO,
  IUserPinList,
} from '../types';
import { InfoStatusRecord } from '../components/feedback/InfoStatusRecord';
import { getFilterParams } from '../utils';
import { getSortParams } from '../utils/getSortParams';

// Define interface for context
interface IUserContext {
  // Data
  columnsUser: IDataGridColumns;
  rowsUser: IUserList[];
  defaultDataUser: IUserData;
  dataUser: IUserData | undefined;
  handleDataUser: (data?: IUserData) => void;
  handleClearStorageUser: () => void;
  // Pagination
  rowCountUser: number;
  pageSizeUser: number;
  pageUser: number;
  handlePageSizeUser: (size: number) => void;
  handlePageUser: (page: number) => void;
  // Sort
  sortUser: IDataGridSortOptions;
  handleSortUser: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionUser: IDataGridSelection;
  handleSelectionUser: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterUser: IUserFilter;
  hideFilterUser: string[];
  filterUser: IUserFilter;
  isOpenFilterModalUser: boolean;
  toggleIsOpenFilterModalUser: () => void;
  handleFilterUser: (filter: IUserFilter) => void;
  // Api
  getAllUser: () => void;
  getByIdUser: (id: number) => void;
  createUser: (payload: IUserDTO) => void;
  updateByIdUser: (id: number, payload: IUserDTO) => void;
  updateStatusByIdUser: (id: number, status: IUserStatusDTO) => void;
  updatePasswordCurrentUser: (payload: IUserChangePasswordDTO) => void;
  deleteByIdUser: (id: number) => void;
  deleteIdsUser: (ids: number[]) => void;
  // PIN
  rowsUserPin: IUserPinList[];
  updateTwoStepAuthCurrentUser: (status: IUserChangeTwoStepAuthDTO) => void;
  getAllUserPins: () => void;
  // Role
  rowsUserRole: IUserRoleList[];
  getAllUserRole: () => void;
  deleteUserPin: (pin: string) => void;
  // Permissions
  rowsPermissionsRole: IUserRolePermissionList[];
  getPermissionsByRoleId: (role: number) => void;
}

// Define columns schema
const columnsSchemaUser: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código do usuário',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'people_id',
    headerName: 'Pessoa',
    description: 'Código da pessoa',
    hideable: false,
    hide: true,
  },
  {
    field: 'people_name',
    headerName: 'Nome',
    description: 'Nome do responsável',
    flex: 3,
  },
  {
    field: 'username',
    headerName: 'E-mail',
    description: 'E-mail de acesso',
    flex: 2,
  },
  {
    field: 'role_name',
    headerName: 'Permissão',
    description: 'Nível de acesso do usuário',
    flex: 2,
  },
  {
    field: 'administrative_platform',
    headerName: 'ADM',
    description: 'Indica se o usuário tem acesso a plataforma administrativa',
    renderCell: (params) => (
      <InfoStatusRecord
        status={params.row.administrative_platform}
        rederOnTrue={<VerifiedUser color="success" />}
        rederOnFalse={<Lock color="disabled" />}
      />
    ),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'driver_platform',
    headerName: 'APP',
    description: 'Indica se o usuário tem acesso a plataforma de motorista',
    renderCell: (params) => (
      <InfoStatusRecord
        status={params.row.driver_platform}
        rederOnTrue={<VerifiedUser color="success" />}
        rederOnFalse={<Lock color="disabled" />}
      />
    ),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'customer_platform',
    headerName: 'CLI',
    description: 'Indica se o usuário tem acesso a plataforma de clientes',
    renderCell: (params) => (
      <InfoStatusRecord
        status={params.row.customer_platform}
        rederOnTrue={<VerifiedUser color="success" />}
        rederOnFalse={<Lock color="disabled" />}
      />
    ),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'is_active',
    headerName: 'Status',
    description: 'Indica se o registro esta ativo ou não',
    renderCell: (params) => <InfoStatusRecord status={params.row.is_active} />,
    headerAlign: 'center',
    align: 'center',
  },
];

// Initial values for filter
const defaultFilterUser: IUserFilter = {
  username: '',
  people_type: 'COLABORADOR',
  people_id: 0,
  people_name: '',
};

// Omit filter on list
const defaultHideFilterUser = ['people_type', 'people_name'];

// initial values for data
const defaultDataUser: IUserData = {
  id: 0,
  type: 'COLABORADOR',
  people_id: 0,
  people_name: '',
  username: '',
  role_id: 1,
  role_name: '',
  administrative_platform: false,
  driver_platform: false,
  customer_platform: false,
  two_step_authentication: false,
  is_active: true,
};

// Create context
export const UserContext = createContext<IUserContext>({} as IUserContext);

// Create context provider
export const UserContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsUser] = useState<IDataGridColumns>(columnsSchemaUser);

  // Rows state
  const [rowsUser, setRowsUser] = useState<IUserList[]>([]);

  // Data state
  const [dataUser, setDataUser] = useState<IUserData | undefined>();

  // Sort state
  const [sortUser, setSortUser] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterUser, setFilterUser] = useState<IUserFilter>(defaultFilterUser);

  // Hide Filter state
  const [hideFilterUser] = useState(defaultHideFilterUser);

  // Modal filters
  const [isOpenFilterModalUser, setIsOpenUserFilterModal] = useState(false);

  // Page state
  const [pageUser, setPageUser] = useState(0);

  // Page size state
  const [pageSizeUser, setPageSizeUser] = useState(10);

  // Record count state
  const [rowCountUser, setRowCountUser] = useState(0);

  // Selection state
  const [selectionUser, setSelectionUser] = useState<IDataGridSelection>();

  // Rows state for user pin
  const [rowsUserPin, setRowsUserPin] = useState<IUserPinList[]>([]);

  // Rows state for user role
  const [rowsUserRole, setRowsUserRole] = useState<IUserRoleList[]>([]);

  // Rows state for permissions role
  const [rowsPermissionsRole, setRowsPermissionsRole] = useState<IUserRolePermissionList[]>([]);

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // use navigate
  const navigate = useNavigate();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataUser = useCallback((data?: IUserData) => setDataUser(data), [dataUser]);

  // Change page status
  const handlePageUser = useCallback((page: number) => setPageUser(page), [pageUser]);

  // Change page size status
  const handlePageSizeUser = useCallback(
    (size: number) => {
      setPageSizeUser(size);
      setPageUser(0);
    },
    [pageSizeUser],
  );

  // Change sort status
  const handleSortUser = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortUser(sort);
      setPageUser(0);
    },
    [sortUser],
  );

  // Change filters status
  const handleFilterUser = useCallback(
    (filter: IUserFilter) => {
      setFilterUser(filter);
      setPageUser(0);
    },
    [filterUser],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalUser = useCallback(
    () => setIsOpenUserFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionUser = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionUser(selection);
    },
    [selectionUser],
  );

  // Clear data rows
  const handleClearStorageUser = useCallback(() => {
    setRowsUser([]);
    setRowCountUser(0);
    handleDataUser();
  }, [setRowsUser]);

  // Load data from request
  const handleLoadStorageUser = useCallback(
    (response: IApiResponseWithPagination<IUserList>) => {
      setRowsUser(response.data);
      setRowCountUser(response.records);
    },
    [setRowsUser],
  );

  // API - Get all records
  const getAllUser = useCallback(() => {
    // Reset selection
    handleSelectionUser([]);
    // Add filters in params
    const params: any = getFilterParams(filterUser);
    // Add sort in params
    const sort = getSortParams(sortUser);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeUser;
    params.offset = pageUser * pageSizeUser;
    // Get data
    UserService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        handleClearStorageUser();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageUser(0);
        return;
      }
      handleLoadStorageUser(response);
    });
  }, [headers, filterUser, sortUser, pageUser, pageSizeUser, dataUser]);

  // API - Get record by id
  const getByIdUser = useCallback(
    (id: number) => {
      // Get details
      UserService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        setDataUser(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createUser = useCallback(
    (payload: IUserDTO) => {
      // Get details
      UserService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataUser();
        snackbarSuccess('Registro adicionado!');
        if (pageUser > 0) {
          handlePageUser(0);
          return;
        }
        getAllUser();
      });
    },
    [getAllUser],
  );

  // API - Update record by id
  const updateByIdUser = useCallback(
    (id: number, payload: IUserDTO) => {
      // Get details
      UserService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataUser();
        getAllUser();
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllUser],
  );

  // API - Update status by id
  const updateStatusByIdUser = useCallback(
    (id: number, status: IUserStatusDTO) => {
      // Get details
      UserService.updateStatusById(headers, id, status).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getByIdUser(id);
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllUser],
  );

  // API - Change password (current user)
  const updatePasswordCurrentUser = useCallback(
    (payload: IUserChangePasswordDTO) => {
      // Get details
      UserService.updatePasswordCurrentUser(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        snackbarSuccess('Senha alterada');
        navigate('/login');
      });
    },
    [headers],
  );

  // API - Change two step authentication (current user)
  const updateTwoStepAuthCurrentUser = useCallback(
    (status: IUserChangeTwoStepAuthDTO) => {
      // Get details
      UserService.updateTwoStepAuthCurrentUser(headers, status).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        snackbarSuccess('Autenticação em duas etapas alterada');
        navigate('/login');
      });
    },
    [headers],
  );

  // API - Get all PIN (current user)
  const getAllUserPins = useCallback(() => {
    // Get data
    UserService.getAllPins(headers).then((response) => {
      if (response instanceof ApiError) {
        setRowsUserPin([]);
        snackbarError(response.message);
        return;
      }
      setRowsUserPin(response);
    });
  }, [headers]);

  // API - Delete PIN (current user)
  const deleteUserPin = useCallback(
    (pin: string) => {
      UserService.deletePin(headers, pin).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllUserPins();
        snackbarSuccess('Dispositivo desativado');
      });
    },
    [getAllUser],
  );

  // API - Delete record by id
  const deleteByIdUser = useCallback(
    (id: number) => {
      UserService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataUser();
        getAllUser();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllUser],
  );

  // API - Delete record by ids
  const deleteIdsUser = useCallback(
    (ids: number[]) => {
      UserService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllUser();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllUser],
  );

  // API - Get all user role records
  const getAllUserRole = useCallback(() => {
    // Get data
    UserService.getAllRoles(headers).then((response) => {
      if (response instanceof ApiError) {
        setRowsUserRole([]);
        snackbarError(response.message);
        return;
      }
      setRowsUserRole(response);
    });
  }, [headers]);

  // API - Get all permissions by role id
  const getPermissionsByRoleId = useCallback(
    (role: number) => {
      // Get data
      UserService.getPermissionsByRoleId(headers, role).then((response) => {
        if (response instanceof ApiError) {
          setRowsPermissionsRole([]);
          snackbarError(response.message);
          return;
        }
        setRowsPermissionsRole(response);
      });
    },
    [headers],
  );

  return (
    <UserContext.Provider
      value={{
        // Data
        columnsUser,
        rowsUser,
        dataUser,
        defaultDataUser,
        handleDataUser,
        handleClearStorageUser,
        // Pagination
        rowCountUser,
        pageSizeUser,
        pageUser,
        handlePageSizeUser,
        handlePageUser,
        // Sort
        sortUser,
        handleSortUser,
        // Selection
        selectionUser,
        handleSelectionUser,
        // Filter
        defaultFilterUser,
        hideFilterUser,
        filterUser,
        isOpenFilterModalUser,
        toggleIsOpenFilterModalUser,
        handleFilterUser,
        // Api
        getAllUser,
        getByIdUser,
        createUser,
        updateByIdUser,
        updateStatusByIdUser,
        updatePasswordCurrentUser,
        deleteByIdUser,
        deleteIdsUser,
        // Pins
        rowsUserPin,
        updateTwoStepAuthCurrentUser,
        getAllUserPins,
        // Roles
        rowsUserRole,
        getAllUserRole,
        deleteUserPin,
        // Permissions
        rowsPermissionsRole,
        getPermissionsByRoleId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
