import { Container, Grid, Paper, Toolbar, useTheme } from '@mui/material';
import { Div, Img, P } from '../../../shared/components';
import logo from '../../../assets/images/logo-login.png';

// Wrapper reset password page
export const ResetPasswordWrapper: React.FC = ({ children }) => {
  const theme = useTheme();

  return (
    <Div sx={{ backgroundColor: theme.palette.grey[100], height: '100vh' }}>
      <Toolbar />

      <Grid container justifyContent="center">
        <Div display="flex" flexDirection="column" alignItems="center">
          <Img src={logo} width="150px" alt="Logo da empresa" />
          <Container
            component={Paper}
            elevation={2}
            maxWidth="sm"
            sx={{
              m: theme.spacing(2),
              p: theme.spacing(2),
              bgcolor: theme.palette.mode === 'light' ? null : theme.palette.grey[900],
            }}
          >
            {children}
          </Container>
          <Div display="flex" flexDirection="column" alignItems="center">
            <P color={theme.palette.text.secondary} sx={{ mb: theme.spacing(2) }}>
              Plataforma LogMoto
            </P>
          </Div>
        </Div>
      </Grid>
    </Div>
  );
};
