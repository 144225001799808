import { useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useBusinessType, useDialog } from '../../../shared/hooks';
import { IBusinessTypeFilter, IDataGridRowParams } from '../../../shared/types';
import { BusinessTypeDataGrid } from './BusinessTypeDataGrid';
import { BusinessTypeFilterModal } from './BusinessTypeFilterModal';
import { BusinessTypeModal } from './BusinessTypeModal';

interface IBusinessTypeMainProps {
  embedded?: boolean;
  initialFilter?: Partial<IBusinessTypeFilter>;
}

export const BusinessTypeMain: React.FC<IBusinessTypeMainProps> = ({ embedded, initialFilter }) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState<boolean>(false);

  const {
    columnsBusinessType,
    rowsBusinessType,
    defaultDataBusinessType,
    handleDataBusinessType,
    selectionBusinessType,
    handleSelectionBusinessType,
    filterBusinessType,
    handleFilterBusinessType,
    getByIdBusinessType,
    deleteIdsBusinessType,
  } = useBusinessType();

  const { dialogConfirmDanger } = useDialog();

  useEffect(() => {
    if (initialFilter) handleFilterBusinessType(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsBusinessType]);

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterBusinessType({});
      return;
    }
    handleFilterBusinessType({ ...filterBusinessType, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataBusinessType(defaultDataBusinessType);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdBusinessType(+id);
  };

  const handleDeleteData = () => {
    if (!selectionBusinessType || selectionBusinessType.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsBusinessType(selectionBusinessType as number[]);
      },
    );
  };

  return (
    <>
      <BusinessTypeFilterModal />
      <BusinessTypeModal />

      {!embedded && (
        <DataGridFilters
          columns={columnsBusinessType}
          filters={filterBusinessType}
          onRemoveFilter={handleRemoveFilter}
        />
      )}

      <BusinessTypeDataGrid
        selectionModel={selectionBusinessType}
        onSelectionModelChange={handleSelectionBusinessType}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionBusinessType}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionBusinessType?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
