/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useTravel } from '../../../shared/hooks';

type ITravelDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const TravelDataGrid: React.FC<ITravelDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsTravel,
    rowsTravel,
    rowCountTravel,
    pageTravel,
    handlePageTravel,
    pageSizeTravel,
    handlePageSizeTravel,
    sortTravel,
    filterTravel,
    handleFilterTravel,
    handleSortTravel,
    getAllTravel,
  } = useTravel();

  useEffect(() => handleFilterTravel({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllTravel();
  }, [canSearch, sortTravel, filterTravel, pageTravel, pageSizeTravel]);

  return (
    <DataGrid
      rows={rowsTravel}
      rowCount={rowCountTravel}
      columns={columnsTravel}
      onSortModelChange={handleSortTravel}
      sortModel={sortTravel}
      pageSize={pageSizeTravel}
      onPageSizeChange={handlePageSizeTravel}
      page={pageTravel}
      onPageChange={handlePageTravel}
      {...rest}
    />
  );
};
