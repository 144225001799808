import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isExpired } from 'react-jwt';
import { ResetPasswordWrapper } from './components/ResetPasswordWrapper';
import { ResetPasswordForm } from './components/ResetPasswordForm';
import { P } from '../../shared/components';
import { ResetPasswordInvalidToken } from './components/ResetPasswordInvalidToken';
import { ResetPasswordUpdated } from './components/ResetPasswordUpdated';

// Page Reset Password
export const ResetPasswordPage: React.FC = () => {
  // User Search Params (RRD)
  const [searchParams, setSearchParams] = useSearchParams();

  // State for TOKEN
  const [token, setToken] = useState<string>('loading');

  // On initialize module
  useEffect(() => {
    // Get token from url search params
    const tokenFromUrl = searchParams.get('token') || '';

    // Set token valid
    setToken(tokenFromUrl);

    // If token is updated return
    if (tokenFromUrl === 'updated') return;

    // Validate token
    if (!tokenFromUrl || isExpired(tokenFromUrl)) {
      setToken('invalid');
    }
  }, [searchParams]);

  // Set token as updated
  const handleUpdateUrl = () => setSearchParams({ token: 'updated' });

  // Conditional render component
  const renderResetPassword = () => {
    switch (token) {
      case 'loading':
        return <P>Aguarde...</P>;
      case 'invalid':
        return <ResetPasswordInvalidToken />;
      case 'updated':
        return <ResetPasswordUpdated />;
      default:
        return <ResetPasswordForm token={token} onSubmit={handleUpdateUrl} />;
    }
  };

  return <ResetPasswordWrapper>{renderResetPassword()}</ResetPasswordWrapper>;
};
