/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, useTheme } from '@mui/material';
import { Field } from 'formik';
import * as Yup from 'yup';
import { Input, DataDialog, Checkbox, DataPicker } from '../../../shared/components';
import { useMedicalLeave, useDialog } from '../../../shared/hooks';
import { IPeopleType, IMedicalLeaveDTO } from '../../../shared/types';
import { schemaValidation } from '../../../shared/utils';
import { EmployeeInputSelectBase } from '../../employee/components/EmployeeInputSelectBase';

// Define interface for modal
interface IMedicalLeaveModalProps {
  fixedPeopleType?: IPeopleType;
  fixedPeopleId?: number;
}

// Define validation fields form
const schemaPayloadMedicalLeave = Yup.object({
  employee_name: schemaValidation.people.name,
  reason: schemaValidation.commons.string(true),
  start_leave: schemaValidation.dateTime.date(true),
  end_leave: schemaValidation.dateTime
    .date(true)
    .min(Yup.ref('start_leave'), 'Data final não pode ser inferior a inicial'),
});

// Create modal data for MedicalLeave
export const MedicalLeaveModal: React.FC<IMedicalLeaveModalProps> = ({
  fixedPeopleId,
  fixedPeopleType,
}) => {
  // Use context MedicalLeave
  const {
    dataMedicalLeave,
    defaultDataMedicalLeave,
    handleDataMedicalLeave,
    createMedicalLeave,
    updateByIdMedicalLeave,
    deleteByIdMedicalLeave,
  } = useMedicalLeave();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Use theme
  const theme = useTheme();

  // Reset data and close modal
  const handleClose = () => handleDataMedicalLeave();

  // Save actions
  const handleSave = (payload: any) => {
    // Normalize payload
    const normalize = { ...payload };

    const id = dataMedicalLeave?.id || 0;
    const data = normalize as IMedicalLeaveDTO;
    if (id > 0) {
      updateByIdMedicalLeave(id, data);
      return;
    }
    createMedicalLeave(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, reason } = payload;
    dialogConfirmDanger(
      'Excluir atestado?',
      `Deseja realmente excluir o atestado médico: ${id} - ${reason} `,
      () => deleteByIdMedicalLeave(id),
    );
  };

  const fixedPeople = fixedPeopleId && fixedPeopleType;

  return (
    <DataDialog
      referenceId={dataMedicalLeave?.id}
      referenceDisplayName="atestado"
      initialValues={dataMedicalLeave || defaultDataMedicalLeave}
      validationSchema={schemaPayloadMedicalLeave}
      open={dataMedicalLeave !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      <input type="hidden" name="is_active" />
      <Grid container columnSpacing={2}>
        {fixedPeople ? (
          <>
            <input type="hidden" name="employee_id" />
            <input type="hidden" name="employee_name" />
          </>
        ) : (
          <Grid item xs={12} md={12}>
            <EmployeeInputSelectBase label="Colaborador *" />
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          <Input label="Motivo *" name="reason" />
        </Grid>

        <Grid item xs={6} md={4}>
          <Field
            name="start_leave"
            component={DataPicker}
            label="Início *"
            tooltip="Selecione o Início do afastamento"
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <Field
            name="end_leave"
            component={DataPicker}
            label="Fim *"
            tooltip="Selecione o fim do afastamento"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ pt: theme.spacing(3) }}>
          <Checkbox label="Remunerado?" name="paid" />
        </Grid>
      </Grid>
    </DataDialog>
  );
};
