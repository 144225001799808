import { Input, FilterDialog, InputMask } from '../../../shared/components';
import { useSupplier } from '../../../shared/hooks';

// Create modal filter for Supplier
export const SupplierFilterModal: React.FC = () => {
  // Use context Supplier
  const {
    isOpenFilterModalSupplier,
    toggleIsOpenFilterModalSupplier,
    handleFilterSupplier,
    filterSupplier,
    defaultFilterSupplier,
  } = useSupplier();

  return (
    <FilterDialog
      isOpen={isOpenFilterModalSupplier}
      onClose={toggleIsOpenFilterModalSupplier}
      setFilter={handleFilterSupplier}
      filter={filterSupplier}
      initialValues={defaultFilterSupplier}
    >
      <Input label="Código" name="id" type="number" placeholder="Código do fornecedor" />
      <Input autoFocus label="Nome" name="name" />
      <InputMask label="CNPJ" name="cnpj" mask="cnpj" maxLength={18} inputMode="numeric" />
      <InputMask label="CPF" name="cpf" mask="cpf" maxLength={14} inputMode="numeric" />
    </FilterDialog>
  );
};
