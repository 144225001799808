import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useInsurancePlan, useDialog, useDeviceIsMobile } from '../../../shared/hooks';
import {
  IInsurancePlanFilter,
  IDataGridRowParams,
  IDataGridColumnsHidden,
} from '../../../shared/types';
import { InsurancePlanDataGrid } from './InsurancePlanDataGrid';
import { InsurancePlanFilterModal } from './InsurancePlanFilterModal';
import { InsurancePlanModal } from './InsurancePlanModal';

interface IInsurancePlanMainProps {
  embedded?: boolean;
  initialFilter?: Partial<IInsurancePlanFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {
  coverage_id: false,
  broker_name: false,
  type: false,
};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {
  coverage_id: false,
  insurance_name: false,
  broker_name: false,
  end_coverage: false,
  type: false,
};

export const InsurancePlanMain: React.FC<IInsurancePlanMainProps> = ({
  embedded,
  initialFilter,
}) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState<boolean>(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsInsurancePlan,
    rowsInsurancePlan,
    defaultDataInsurancePlan,
    handleDataInsurancePlan,
    selectionInsurancePlan,
    handleSelectionInsurancePlan,
    filterInsurancePlan,
    hideFilterInsurancePlan,
    handleFilterInsurancePlan,
    getByIdInsurancePlan,
    deleteIdsInsurancePlan,
  } = useInsurancePlan();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterInsurancePlan(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsInsurancePlan]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterInsurancePlan({});
      return;
    }
    handleFilterInsurancePlan({ ...filterInsurancePlan, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataInsurancePlan(defaultDataInsurancePlan);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdInsurancePlan(+id);
  };

  const handleDeleteData = () => {
    if (!selectionInsurancePlan || selectionInsurancePlan.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsInsurancePlan(selectionInsurancePlan as number[]);
      },
    );
  };

  return (
    <>
      <InsurancePlanFilterModal />

      {!embedded && (
        <>
          <InsurancePlanModal />
          <DataGridFilters
            columns={columnsInsurancePlan}
            filters={filterInsurancePlan}
            hideFilters={hideFilterInsurancePlan}
            onRemoveFilter={handleRemoveFilter}
          />
        </>
      )}

      <InsurancePlanDataGrid
        selectionModel={selectionInsurancePlan}
        onSelectionModelChange={handleSelectionInsurancePlan}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionInsurancePlan}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionInsurancePlan?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
