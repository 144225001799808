import {
  IApiResponseWithPagination,
  IVehicleSupplyData,
  IVehicleSupplyList,
  IVehicleSupplyDTO,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { VehicleSupplySchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<IVehicleSupplyList> | ApiError> => {
  try {
    const response = await Api().get('/vehicles/supplies', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, VehicleSupplySchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<IVehicleSupplyData | ApiError> => {
  try {
    const response = await Api().get(`/vehicles/supplies/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, VehicleSupplySchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: IVehicleSupplyDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/vehicles/supplies', payload, { headers });
    return response.data.VehicleSupply_id;
  } catch (error: any) {
    return makeApiError(error, VehicleSupplySchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: IVehicleSupplyDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/vehicles/supplies/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, VehicleSupplySchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/vehicles/supplies/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, VehicleSupplySchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/vehicles/supplies/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, VehicleSupplySchemaApiError);
  }
};

export const VehicleSupplyService = { getAll, getById, create, updateById, deleteById, deleteIds };
