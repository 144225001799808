/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

interface ISelectBooleanProps extends FieldProps {
  label?: string;
  disableEveryone?: boolean;
}

const options: ISelectOptions[] = [
  { label: 'Todos', value: '' },
  { label: 'Sim', value: 'SIM' },
  { label: 'Não', value: 'NAO' },
];

export const SelectBoolean: React.FC<ISelectBooleanProps> = ({
  label = 'Tipo',
  disableEveryone,
  ...rest
}) => {
  if (disableEveryone) {
    options.splice(0, 1);
  }
  return <Select label={label} defaultOption={options[0].value} dataOptions={options} {...rest} />;
};
