/* eslint-disable react/jsx-props-no-spreading */
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { InputSelect, SelectDialog, IInputProps } from '../../../shared/components';
import { useBusinessType } from '../../../shared/hooks';
import { IDataGridColumnsHidden, IDataGridRowParams } from '../../../shared/types';
import { BusinessTypeDataGrid } from './BusinessTypeDataGrid';
import { BusinessTypeFilterModal } from './BusinessTypeFilterModal';
import { BusinessTypeModal } from './BusinessTypeModal';

interface IBusinessTypeInputSelectProps extends Omit<IInputProps, 'name' | 'onSelect'> {
  label?: string;
  title?: string;
  baseName?: string;
}

const defaultBaseName = 'business_type';

const defaultTitle = 'Ramo de atividade';

const hiddenColumnsBusinessType: IDataGridColumnsHidden = {
  is_active: false,
};

export const BusinessTypeInputSelect: React.FC<IBusinessTypeInputSelectProps> = ({
  label = defaultTitle,
  title = defaultTitle,
  baseName = defaultBaseName,
  disabled,
  ...rest
}) => {
  // Define name
  const [fieldId] = useState(`${baseName}_id`);
  const [fieldName] = useState(`${baseName}_name`);

  // State for control opened search dialog
  const [isOpen, setIsOpen] = useState(false);

  // Use hook formik context
  const { setFieldValue } = useFormikContext();

  // Use context
  const {
    toggleIsOpenFilterModalBusinessType,
    handleFilterBusinessType,
    defaultDataBusinessType,
    handleDataBusinessType,
  } = useBusinessType();

  // Open search dialog
  const handleOpen = () => {
    if (disabled) return;
    setIsOpen(true);
  };

  // Close search modal
  const handleClose = () => setIsOpen(false);

  // On click in add button
  const handleAdd = () => handleDataBusinessType(defaultDataBusinessType);

  // On click in filter button
  const handleResetFilter = () => handleFilterBusinessType({});

  // Apply filters
  const handleApplyFilterName = (filter: any) => handleFilterBusinessType({ name: filter });

  // On select record
  const handleSelect = (data: IDataGridRowParams) => {
    setFieldValue(fieldId, data.row.id);
    setFieldValue(fieldName, data.row.name);
    handleClose();
  };

  return (
    <>
      <BusinessTypeModal />
      <BusinessTypeFilterModal />

      <SelectDialog
        title={title}
        open={isOpen}
        onClose={handleClose}
        applyFilter={handleApplyFilterName}
        showFilter={toggleIsOpenFilterModalBusinessType}
        resetFilter={handleResetFilter}
        add={handleAdd}
      >
        <BusinessTypeDataGrid
          simpleMode
          onRowClick={handleSelect}
          columnVisibilityModel={hiddenColumnsBusinessType}
        />
      </SelectDialog>

      <InputSelect
        label={label}
        fieldId={fieldId}
        fieldName={fieldName}
        onClick={handleOpen}
        disabled={disabled}
        {...rest}
      />
    </>
  );
};
