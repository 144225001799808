import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useCustomer, useDeviceIsMobile, useDialog } from '../../../shared/hooks';
import { ICustomerFilter, IDataGridColumnsHidden, IDataGridRowParams } from '../../../shared/types';
import { CustomerDataGrid } from './CustomerDataGrid';
import { CustomerFilterModal } from './CustomerFilterModal';
import { CustomerModal } from './CustomerModal';

interface ICustomerMainProps {
  embedded?: boolean;
  initialFilter?: Partial<ICustomerFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {
  nickname: false,
  business_type: false,
  contact: false,
  neighborhood: false,
  state: false,
};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {
  nickname: false,
  business_type: false,
  contract: false,
  phone: false,
  contact: false,
  document: false,
  neighborhood: false,
  city: false,
  state: false,
};

export const CustomerMain: React.FC<ICustomerMainProps> = ({ embedded, initialFilter }) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsCustomer,
    rowsCustomer,
    defaultDataCustomer,
    handleDataCustomer,
    selectionCustomer,
    handleSelectionCustomer,
    filterCustomer,
    handleFilterCustomer,
    getByIdCustomer,
    deleteIdsCustomer,
  } = useCustomer();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterCustomer(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsCustomer]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterCustomer({});
      return;
    }
    handleFilterCustomer({ ...filterCustomer, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataCustomer(defaultDataCustomer);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdCustomer(+id);
  };

  const handleDeleteData = () => {
    if (!selectionCustomer || selectionCustomer.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsCustomer(selectionCustomer as number[]);
      },
    );
  };

  return (
    <>
      <CustomerFilterModal />
      <CustomerModal />

      {!embedded && (
        <DataGridFilters
          columns={columnsCustomer}
          filters={filterCustomer}
          onRemoveFilter={handleRemoveFilter}
        />
      )}

      <CustomerDataGrid
        selectionModel={selectionCustomer}
        onSelectionModelChange={handleSelectionCustomer}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionCustomer}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionCustomer?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
