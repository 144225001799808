/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useUser } from '../../../shared/hooks';

type IUserDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const UserDataGrid: React.FC<IUserDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsUser,
    rowsUser,
    rowCountUser,
    pageUser,
    handlePageUser,
    pageSizeUser,
    handlePageSizeUser,
    sortUser,
    filterUser,
    handleFilterUser,
    handleSortUser,
    getAllUser,
  } = useUser();

  useEffect(() => handleFilterUser({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllUser();
  }, [canSearch, sortUser, filterUser, pageUser, pageSizeUser]);

  return (
    <DataGrid
      rows={rowsUser}
      rowCount={rowCountUser}
      columns={columnsUser}
      onSortModelChange={handleSortUser}
      sortModel={sortUser}
      pageSize={pageSizeUser}
      onPageSizeChange={handlePageSizeUser}
      page={pageUser}
      onPageChange={handlePageUser}
      {...rest}
    />
  );
};
