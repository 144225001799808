/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

interface ISelectPeopleTypeProps extends FieldProps {
  label?: string;
  disableEmployee?: boolean;
  disableCustomer?: boolean;
  disableSupplier?: boolean;
}

const options: ISelectOptions[] = [
  { label: 'Colaborador', value: 'COLABORADOR' },
  { label: 'Cliente', value: 'CLIENTE' },
  { label: 'Fornecedor', value: 'FORNECEDOR' },
];

export const SelectPeopleType: React.FC<ISelectPeopleTypeProps> = ({
  label = 'Tipo',
  disableEmployee,
  disableCustomer,
  disableSupplier,
  ...rest
}) => {
  if (disableEmployee) {
    const indexEmployee = options.findIndex((option) => option.value === 'COLABORADOR');
    if (indexEmployee > -1) options.splice(indexEmployee, 1);
  }
  if (disableCustomer) {
    const indexCustomer = options.findIndex((option) => option.value === 'CLIENTE');
    if (indexCustomer > -1) options.splice(indexCustomer, 1);
  }
  if (disableSupplier) {
    const indexSupplier = options.findIndex((option) => option.value === 'FORNECEDOR');
    if (indexSupplier > -1) options.splice(indexSupplier, 1);
  }
  return <Select label={label} defaultOption="COLABORADOR" dataOptions={options} {...rest} />;
};
