import { createContext, useCallback, useState } from 'react';
import { DialogContent, DialogActions, DialogContentText, Divider } from '@mui/material';
import { Dialog } from '../components/feedback/Dialog';
import { DialogActionButton } from '../components/inputs/DialogActionButton';
import { StyleClassType } from '../types';
import { updatePartialStateObject } from '../utils';

// Define action required
type DialogProviderActionType = 'confirm' | 'ok' | 'none';

// Define interface for dialog params
interface IDialogParamsData {
  title: string;
  message: string;
  classType?: StyleClassType;
  actionType?: DialogProviderActionType;
  submit?: () => void;
}

// Define interface for context
interface IDialogContext {
  dialogConfirm: (title: string, message: string, submit: () => void) => void;
  dialogConfirmGrant: (title: string, message: string, submit: () => void) => void;
  dialogConfirmDanger: (title: string, message: string, submit: () => void) => void;
  dialogOk: (title: string, message: string, submit: () => void) => void;
  dialogInfo: (title: string, message: string) => void;
}

// Create context
export const DialogContext = createContext({} as IDialogContext);

// Create snackbar dispatch context
export const DialogContextProvider: React.FC = ({ children }) => {
  // Create state for dialog builder
  const [dialogParams, setDialogParams] = useState<IDialogParamsData>({} as IDialogParamsData);

  // Build dialog
  const dialogBuilder = useCallback(
    (
      title: string,
      message: string,
      submit: () => void,
      actionType: DialogProviderActionType = 'confirm',
      classType: StyleClassType = 'default',
    ) => {
      // Default state
      const defaultActionType: DialogProviderActionType = 'confirm';
      const defaultClassType: StyleClassType = 'default';
      // Update state
      setDialogParams({
        title,
        message,
        submit,
        classType: classType || defaultClassType,
        actionType: actionType || defaultActionType,
      });
    },
    [],
  );

  // Update partial dialog params
  const updateDialogParams = (value?: Partial<IDialogParamsData>) =>
    updatePartialStateObject<IDialogParamsData>(dialogParams, setDialogParams, value);

  // Close dialog
  const handleClose = () => setDialogParams(updateDialogParams({ submit: undefined }));

  // Builder confirm default dialog
  const dialogConfirm = useCallback(
    (title: string, message: string, submit: () => void) => dialogBuilder(title, message, submit),
    [],
  );

  // Builder confirm dialog with class success
  const dialogConfirmGrant = useCallback(
    (title: string, message: string, submit: () => void) =>
      dialogBuilder(title, message, submit, 'confirm', 'success'),
    [],
  );

  // Builder confirm dialog with class error
  const dialogConfirmDanger = useCallback(
    (title: string, message: string, submit: () => void) =>
      dialogBuilder(title, message, submit, 'confirm', 'error'),
    [],
  );

  // Builder OK dialog
  const dialogOk = useCallback(
    (title: string, message: string, submit: () => void) =>
      dialogBuilder(title, message, submit, 'ok'),
    [],
  );

  // Builder info dialog
  const dialogInfo = useCallback(
    (title: string, message: string) => dialogBuilder(title, message, () => console.log(), 'none'),
    [],
  );

  // Create dialog builder
  return (
    <DialogContext.Provider
      value={{ dialogConfirm, dialogConfirmGrant, dialogConfirmDanger, dialogInfo, dialogOk }}
    >
      <Dialog
        title={dialogParams.title}
        classType={dialogParams.classType}
        open={Boolean(dialogParams.submit)}
        close={handleClose}
      >
        <Divider />
        <DialogContent>
          <DialogContentText> {dialogParams.message} </DialogContentText>
        </DialogContent>

        {dialogParams.actionType !== 'none' ? (
          <>
            <Divider />
            <DialogActions>
              {dialogParams.actionType === 'confirm' ? (
                <DialogActionButton onClick={handleClose}>Cancelar</DialogActionButton>
              ) : null}
              <DialogActionButton
                primaryAction
                autoFocus
                classType={dialogParams.classType}
                onClick={() => {
                  if (dialogParams.submit) {
                    dialogParams.submit();
                  }
                  handleClose();
                }}
              >
                {dialogParams.actionType === 'confirm' ? 'Confirmar' : 'OK'}
              </DialogActionButton>
            </DialogActions>
          </>
        ) : null}
      </Dialog>
      {children}
    </DialogContext.Provider>
  );
};
