/* eslint-disable react/jsx-props-no-spreading */
import { Drawer, DrawerProps, Toolbar, useTheme } from '@mui/material';
import { useDeviceIsMobile, useMenu } from '../../hooks';
import { SidebarMenuBuilder } from './SidebarMenuBuilder';

// Define interface
type ISidebarProps = DrawerProps;

// Create sidebar component
export const SidebarMenu: React.FC<ISidebarProps> = () => {
  // Use theme mui
  const theme = useTheme();

  // Use hook for check device type
  const isMobile = useDeviceIsMobile();

  // Use Navbar store
  const { isOpenSidebarMenu, openSidebarMenu, closeSidebarMenu } = useMenu();

  // Define sidebar width
  const drawerWidth = 300;

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={isOpenSidebarMenu}
      onClose={closeSidebarMenu}
      PaperProps={{
        onMouseEnter: openSidebarMenu,
        onMouseLeave: closeSidebarMenu,
      }}
      sx={{
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          position: 'relative',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          ...(!isOpenSidebarMenu &&
            !isMobile && {
              overflowX: 'hidden',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              width: theme.spacing(9),
            }),
        },
      }}
    >
      <Toolbar />

      <SidebarMenuBuilder />
    </Drawer>
  );
};
