import { createContext, useCallback, useState } from 'react';
import { AccessTimeOutlined, TaskAlt } from '@mui/icons-material';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, VehicleSupplyService } from '../services';
import {
  IVehicleSupplyData,
  IVehicleSupplyFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  IVehicleSupplyDTO,
  IDataGridSelection,
  IVehicleSupplyList,
} from '../types';
import {
  formatterCurrency,
  formatterDateBR,
  getFilterParams,
  toDate,
  toDateApi,
  toObjectWithoutNull,
} from '../utils';
import { getSortParams } from '../utils/getSortParams';
import { InfoStatusRecord } from '../components/feedback';

// Define interface for context
interface IVehicleSupplyContext {
  // Data
  columnsVehicleSupply: IDataGridColumns;
  rowsVehicleSupply: IVehicleSupplyList[];
  defaultDataVehicleSupply: IVehicleSupplyData;
  dataVehicleSupply: IVehicleSupplyData | undefined;
  handleDataVehicleSupply: (data?: IVehicleSupplyData) => void;
  // Pagination
  rowCountVehicleSupply: number;
  pageSizeVehicleSupply: number;
  pageVehicleSupply: number;
  handlePageSizeVehicleSupply: (size: number) => void;
  handlePageVehicleSupply: (page: number) => void;
  // Sort
  sortVehicleSupply: IDataGridSortOptions;
  handleSortVehicleSupply: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionVehicleSupply: IDataGridSelection;
  handleSelectionVehicleSupply: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterVehicleSupply: IVehicleSupplyFilter;
  hideFilterVehicleSupply: string[];
  filterVehicleSupply: IVehicleSupplyFilter;
  isOpenFilterModalVehicleSupply: boolean;
  toggleIsOpenFilterModalVehicleSupply: () => void;
  handleFilterVehicleSupply: (filter: IVehicleSupplyFilter) => void;
  // Api
  getAllVehicleSupply: () => void;
  getByIdVehicleSupply: (id: number) => void;
  createVehicleSupply: (payload: IVehicleSupplyDTO) => void;
  updateByIdVehicleSupply: (id: number, payload: IVehicleSupplyDTO) => void;
  deleteByIdVehicleSupply: (id: number) => void;
  deleteIdsVehicleSupply: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaVehicleSupply: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código do abastecimento',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
  {
    field: 'fueled_at',
    headerName: 'Data',
    description: 'Data do abastecimento',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => formatterDateBR(params.row.fueled_at),
  },
  {
    field: 'type',
    headerName: 'Combustível',
    description: 'Combustível abastecido',
  },
  {
    field: 'start_date',
    headerName: 'Início',
    hideable: false,
  },
  {
    field: 'end_date',
    headerName: 'Fim',
    hideable: false,
  },
  {
    field: 'driver_id',
    headerName: 'Motorista',
    hideable: false,
  },
  {
    field: 'driver_name',
    headerName: 'Motorista',
    description: 'Motorista que realizou o abastecimento',
    flex: 1,
  },
  {
    field: 'vehicle_id',
    headerName: 'Veículo',
    hideable: false,
  },
  {
    field: 'vehicle_detail',
    headerName: 'Veículo',
    description: 'Veículo abastecido',
  },
  {
    field: 'total',
    headerName: 'Total',
    description: 'Valor total do abastecimento',
    headerAlign: 'right',
    align: 'right',
    valueFormatter: ({ value }) => formatterCurrency(value, true),
  },
  {
    field: 'audited',
    headerName: 'Conferido',
    description: 'Indica se o registro foi conferido',
    renderCell: (params) => (
      <InfoStatusRecord
        status={params.row.audited}
        rederOnTrue={<TaskAlt color="success" />}
        rederOnFalse={<AccessTimeOutlined color="disabled" />}
      />
    ),
    headerAlign: 'center',
    align: 'center',
  },
];

// Initial values for filter
const defaultFilterVehicleSupply: IVehicleSupplyFilter = {
  start_date: null,
  end_date: null,
  driver_id: 0,
  driver_name: '',
  vehicle_id: 0,
  vehicle_detail: '',
  audited: '',
};

// Omit filter on list
const defaultHideFilterVehicleSupply = ['driver_name', 'vehicle_detail'];

// initial values for data
const defaultDataVehicleSupply: IVehicleSupplyData = {
  id: 0,
  driver_id: 0,
  driver_name: '',
  vehicle_id: 0,
  vehicle_detail: '',
  type: 'AVULSO',
  fueled_at: new Date(),
  gas_station: '',
  fuel_type: 'GASOLINA',
  liters: 0,
  total: 0,
  audited: false,
  audited_at: null,
  audited_value: 0,
};

// Create context
export const VehicleSupplyContext = createContext<IVehicleSupplyContext>(
  {} as IVehicleSupplyContext,
);

// Create context provider
export const VehicleSupplyContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsVehicleSupply] = useState<IDataGridColumns>(columnsSchemaVehicleSupply);

  // Rows state
  const [rowsVehicleSupply, setRowsVehicleSupply] = useState<IVehicleSupplyList[]>([]);

  // Data state
  const [dataVehicleSupply, setDataVehicleSupply] = useState<IVehicleSupplyData | undefined>();

  // Sort state
  const [sortVehicleSupply, setSortVehicleSupply] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterVehicleSupply, setFilterVehicleSupply] = useState<IVehicleSupplyFilter>(
    defaultFilterVehicleSupply,
  );

  // Hide Filter state
  const [hideFilterVehicleSupply] = useState(defaultHideFilterVehicleSupply);

  // Modal filters
  const [isOpenFilterModalVehicleSupply, setIsOpenVehicleSupplyFilterModal] = useState(false);

  // Page state
  const [pageVehicleSupply, setPageVehicleSupply] = useState(0);

  // Page size state
  const [pageSizeVehicleSupply, setPageSizeVehicleSupply] = useState(10);

  // Record count state
  const [rowCountVehicleSupply, setRowCountVehicleSupply] = useState(0);

  // Selection state
  const [selectionVehicleSupply, setSelectionVehicleSupply] = useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataVehicleSupply = useCallback(
    (data?: IVehicleSupplyData) => {
      if (!data) return setDataVehicleSupply(data);
      const dataWithoutNull = toObjectWithoutNull<IVehicleSupplyData>(
        data,
        defaultDataVehicleSupply,
      );
      dataWithoutNull.fueled_at = toDate(dataWithoutNull.fueled_at);
      return setDataVehicleSupply(dataWithoutNull);
    },
    [dataVehicleSupply],
  );

  // Change page status
  const handlePageVehicleSupply = useCallback(
    (page: number) => setPageVehicleSupply(page),
    [pageVehicleSupply],
  );

  // Change page size status
  const handlePageSizeVehicleSupply = useCallback(
    (size: number) => {
      setPageSizeVehicleSupply(size);
      setPageVehicleSupply(0);
    },
    [pageSizeVehicleSupply],
  );

  // Change sort status
  const handleSortVehicleSupply = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortVehicleSupply(sort);
      setPageVehicleSupply(0);
    },
    [sortVehicleSupply],
  );

  // Change filters status
  const handleFilterVehicleSupply = useCallback(
    (filter: IVehicleSupplyFilter) => {
      setFilterVehicleSupply(filter);
      setPageVehicleSupply(0);
    },
    [filterVehicleSupply],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalVehicleSupply = useCallback(
    () => setIsOpenVehicleSupplyFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionVehicleSupply = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionVehicleSupply(selection);
    },
    [selectionVehicleSupply],
  );

  // Clear data rows
  const clearStorageVehicleSupply = useCallback(() => {
    setRowsVehicleSupply([]);
    setRowCountVehicleSupply(0);
  }, [setRowsVehicleSupply]);

  // Load data from request
  const loadStorageVehicleSupply = useCallback(
    (response: IApiResponseWithPagination<IVehicleSupplyList>) => {
      setRowsVehicleSupply(response.data);
      setRowCountVehicleSupply(response.records);
    },
    [setRowsVehicleSupply],
  );

  // API - Get all records
  const getAllVehicleSupply = useCallback(() => {
    // Reset selection
    handleSelectionVehicleSupply([]);
    // Add filters in params
    const params: any = getFilterParams(filterVehicleSupply);
    // Normalize data
    if (params.start_date) params.start_date = toDateApi(params.start_date);
    if (params.end_date) params.end_date = toDateApi(params.end_date);
    if (params.audited) {
      params.audited = params.audited === 'SIM';
    }
    // Add sort in params
    const sort = getSortParams(sortVehicleSupply);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeVehicleSupply;
    params.offset = pageVehicleSupply * pageSizeVehicleSupply;
    // Get data
    VehicleSupplyService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        clearStorageVehicleSupply();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageVehicleSupply(0);
        return;
      }
      loadStorageVehicleSupply(response);
    });
  }, [
    headers,
    filterVehicleSupply,
    sortVehicleSupply,
    pageVehicleSupply,
    pageSizeVehicleSupply,
    dataVehicleSupply,
  ]);

  // API - Get record by id
  const getByIdVehicleSupply = useCallback(
    (id: number) => {
      // Get details
      VehicleSupplyService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicleSupply(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createVehicleSupply = useCallback(
    (payload: IVehicleSupplyDTO) => {
      // Get details
      VehicleSupplyService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicleSupply();
        snackbarSuccess('Registro adicionado!');
        if (pageVehicleSupply > 0) {
          handlePageVehicleSupply(0);
          return;
        }
        getAllVehicleSupply();
      });
    },
    [getAllVehicleSupply],
  );

  // API - Update record by id
  const updateByIdVehicleSupply = useCallback(
    (id: number, payload: IVehicleSupplyDTO) => {
      // Get details
      VehicleSupplyService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicleSupply();
        getAllVehicleSupply();
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllVehicleSupply],
  );

  // API - Delete record by id
  const deleteByIdVehicleSupply = useCallback(
    (id: number) => {
      VehicleSupplyService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicleSupply();
        getAllVehicleSupply();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllVehicleSupply],
  );

  // API - Delete record by ids
  const deleteIdsVehicleSupply = useCallback(
    (ids: number[]) => {
      VehicleSupplyService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllVehicleSupply();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllVehicleSupply],
  );

  return (
    <VehicleSupplyContext.Provider
      value={{
        // Data
        columnsVehicleSupply,
        rowsVehicleSupply,
        dataVehicleSupply,
        defaultDataVehicleSupply,
        handleDataVehicleSupply,
        // Pagination
        rowCountVehicleSupply,
        pageSizeVehicleSupply,
        pageVehicleSupply,
        handlePageSizeVehicleSupply,
        handlePageVehicleSupply,
        // Sort
        sortVehicleSupply,
        handleSortVehicleSupply,
        // Selection
        selectionVehicleSupply,
        handleSelectionVehicleSupply,
        // Filter
        defaultFilterVehicleSupply,
        hideFilterVehicleSupply,
        filterVehicleSupply,
        isOpenFilterModalVehicleSupply,
        toggleIsOpenFilterModalVehicleSupply,
        handleFilterVehicleSupply,
        // Api
        getAllVehicleSupply,
        getByIdVehicleSupply,
        createVehicleSupply,
        updateByIdVehicleSupply,
        deleteByIdVehicleSupply,
        deleteIdsVehicleSupply,
      }}
    >
      {children}
    </VehicleSupplyContext.Provider>
  );
};
