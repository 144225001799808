import {
  IApiResponseWithPagination,
  IVehicleMaintenanceData,
  IVehicleMaintenanceList,
  IVehicleMaintenanceDTO,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { VehicleMaintenanceSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<IVehicleMaintenanceList> | ApiError> => {
  try {
    const response = await Api().get('/vehicles/maintenances', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, VehicleMaintenanceSchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<IVehicleMaintenanceData | ApiError> => {
  try {
    const response = await Api().get(`/vehicles/maintenances/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, VehicleMaintenanceSchemaApiError);
  }
};

// Create record
const create = async (
  headers: any,
  payload: IVehicleMaintenanceDTO,
): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/vehicles/maintenances', payload, { headers });
    return response.data.vehicle_maintenance_id;
  } catch (error: any) {
    return makeApiError(error, VehicleMaintenanceSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: IVehicleMaintenanceDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/vehicles/maintenances/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, VehicleMaintenanceSchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/vehicles/maintenances/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, VehicleMaintenanceSchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/vehicles/maintenances/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, VehicleMaintenanceSchemaApiError);
  }
};

export const VehicleMaintenanceService = {
  getAll,
  getById,
  create,
  updateById,
  deleteById,
  deleteIds,
};
