import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useSupplier, useDeviceIsMobile, useDialog } from '../../../shared/hooks';
import { ISupplierFilter, IDataGridColumnsHidden, IDataGridRowParams } from '../../../shared/types';
import { SupplierDataGrid } from './SupplierDataGrid';
import { SupplierFilterModal } from './SupplierFilterModal';
import { SupplierModal } from './SupplierModal';

interface ISupplierMainProps {
  embedded?: boolean;
  initialFilter?: Partial<ISupplierFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {
  nickname: false,
  business_type: false,
  contact: false,
  neighborhood: false,
  state: false,
};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {
  nickname: false,
  business_type: false,
  phone: false,
  contact: false,
  document: false,
  neighborhood: false,
  city: false,
  state: false,
};

export const SupplierMain: React.FC<ISupplierMainProps> = ({ embedded, initialFilter }) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsSupplier,
    rowsSupplier,
    defaultDataSupplier,
    handleDataSupplier,
    selectionSupplier,
    handleSelectionSupplier,
    filterSupplier,
    handleFilterSupplier,
    getByIdSupplier,
    deleteIdsSupplier,
  } = useSupplier();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterSupplier(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsSupplier]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterSupplier({});
      return;
    }
    handleFilterSupplier({ ...filterSupplier, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataSupplier(defaultDataSupplier);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdSupplier(+id);
  };

  const handleDeleteData = () => {
    if (!selectionSupplier || selectionSupplier.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsSupplier(selectionSupplier as number[]);
      },
    );
  };

  return (
    <>
      <SupplierFilterModal />
      <SupplierModal />

      {!embedded && (
        <DataGridFilters
          columns={columnsSupplier}
          filters={filterSupplier}
          onRemoveFilter={handleRemoveFilter}
        />
      )}

      <SupplierDataGrid
        selectionModel={selectionSupplier}
        onSelectionModelChange={handleSelectionSupplier}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionSupplier}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionSupplier?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
