import { useTheme } from '@mui/material';
import { P } from '../display-data';
import { UploadSingleFileIcon } from './UploadSingleFileIcon';
import { UploadSingleFileContainer } from './UploadSingleFileContainer';

export const UploadSingleFileReject: React.FC = () => {
  const theme = useTheme();

  return (
    <UploadSingleFileContainer
      bgColor={theme.palette.error.light}
      color={theme.palette.error.contrastText}
    >
      <UploadSingleFileIcon icon="block" />
      <P>Arquivo não suportado</P>
    </UploadSingleFileContainer>
  );
};
