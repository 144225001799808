import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, VehicleMaintenanceService } from '../services';
import {
  IVehicleMaintenanceData,
  IVehicleMaintenanceFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  IVehicleMaintenanceDTO,
  IDataGridSelection,
  IVehicleMaintenanceList,
} from '../types';
import {
  formatterCurrency,
  formatterDateBR,
  getFilterParams,
  toDate,
  toDateApi,
  toObjectWithoutNull,
} from '../utils';
import { getSortParams } from '../utils/getSortParams';

// Define interface for context
interface IVehicleMaintenanceContext {
  // Data
  columnsVehicleMaintenance: IDataGridColumns;
  rowsVehicleMaintenance: IVehicleMaintenanceList[];
  defaultDataVehicleMaintenance: IVehicleMaintenanceData;
  dataVehicleMaintenance: IVehicleMaintenanceData | undefined;
  handleDataVehicleMaintenance: (data?: IVehicleMaintenanceData) => void;
  // Pagination
  rowCountVehicleMaintenance: number;
  pageSizeVehicleMaintenance: number;
  pageVehicleMaintenance: number;
  handlePageSizeVehicleMaintenance: (size: number) => void;
  handlePageVehicleMaintenance: (page: number) => void;
  // Sort
  sortVehicleMaintenance: IDataGridSortOptions;
  handleSortVehicleMaintenance: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionVehicleMaintenance: IDataGridSelection;
  handleSelectionVehicleMaintenance: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterVehicleMaintenance: IVehicleMaintenanceFilter;
  hideFilterVehicleMaintenance: string[];
  filterVehicleMaintenance: IVehicleMaintenanceFilter;
  isOpenFilterModalVehicleMaintenance: boolean;
  toggleIsOpenFilterModalVehicleMaintenance: () => void;
  handleFilterVehicleMaintenance: (filter: IVehicleMaintenanceFilter) => void;
  // Api
  getAllVehicleMaintenance: () => void;
  getByIdVehicleMaintenance: (id: number) => void;
  createVehicleMaintenance: (payload: IVehicleMaintenanceDTO) => void;
  updateByIdVehicleMaintenance: (id: number, payload: IVehicleMaintenanceDTO) => void;
  deleteByIdVehicleMaintenance: (id: number) => void;
  deleteIdsVehicleMaintenance: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaVehicleMaintenance: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código da manutenção',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
  {
    field: 'maintenance_date',
    headerName: 'Data',
    description: 'Data da manutenção',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => formatterDateBR(params.row.maintenance_date),
  },
  {
    field: 'people_id',
    headerName: 'Prestador',
    hideable: false,
  },
  {
    field: 'people_name',
    headerName: 'Prestador',
    description: 'Nome do responsável',
    flex: 1,
  },
  {
    field: 'mechanical',
    headerName: 'Mecânico',
    hideable: false,
  },
  {
    field: 'service_description',
    headerName: 'Serviço',
    description: 'Detalhes do serviço',
    flex: 1,
  },
  {
    field: 'vehicle_id',
    headerName: 'Veículo',
    hideable: false,
  },
  {
    field: 'vehicle_detail',
    headerName: 'Veículo',
    description: 'Veículo utilizado',
    flex: 1,
  },
  {
    field: 'start_date',
    headerName: 'Início',
    hideable: false,
  },
  {
    field: 'end_date',
    headerName: 'Fim',
    hideable: false,
  },
  {
    field: 'total',
    headerName: 'Total',
    description: 'Valor total da manutenção',
    headerAlign: 'right',
    align: 'right',
    valueFormatter: ({ value }) => formatterCurrency(value, true),
  },
];

// Initial values for filter
const defaultFilterVehicleMaintenance: IVehicleMaintenanceFilter = {
  people_id: 0,
  people_name: '',
  people_type: 'FORNECEDOR',
  start_date: null,
  end_date: null,
  vehicle_id: 0,
  vehicle_detail: '',
  mechanical: '',
};

// Omit filter on list
const defaultHideFilterVehicleMaintenance = ['vehicle_detail', 'people_name', 'people_type'];

// initial values for data
const defaultDataVehicleMaintenance: IVehicleMaintenanceData = {
  id: 0,
  maintenance_date: new Date(),
  vehicle_id: 0,
  vehicle_detail: '',
  people_id: 0,
  people_name: '',
  people_type: 'FORNECEDOR',
  mechanical: '',
  service_description: '',
  service_price: 0,
  parts_description: '',
  parts_price: 0,
  total: 0,
  attachments: [],
};

// Create context
export const VehicleMaintenanceContext = createContext<IVehicleMaintenanceContext>(
  {} as IVehicleMaintenanceContext,
);

// Create context provider
export const VehicleMaintenanceContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsVehicleMaintenance] = useState<IDataGridColumns>(columnsSchemaVehicleMaintenance);

  // Rows state
  const [rowsVehicleMaintenance, setRowsVehicleMaintenance] = useState<IVehicleMaintenanceList[]>(
    [],
  );

  // Data state
  const [dataVehicleMaintenance, setDataVehicleMaintenance] = useState<
    IVehicleMaintenanceData | undefined
  >();

  // Sort state
  const [sortVehicleMaintenance, setSortVehicleMaintenance] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterVehicleMaintenance, setFilterVehicleMaintenance] =
    useState<IVehicleMaintenanceFilter>(defaultFilterVehicleMaintenance);

  // Hide Filter state
  const [hideFilterVehicleMaintenance] = useState(defaultHideFilterVehicleMaintenance);

  // Modal filters
  const [isOpenFilterModalVehicleMaintenance, setIsOpenVehicleMaintenanceFilterModal] =
    useState(false);

  // Page state
  const [pageVehicleMaintenance, setPageVehicleMaintenance] = useState(0);

  // Page size state
  const [pageSizeVehicleMaintenance, setPageSizeVehicleMaintenance] = useState(10);

  // Record count state
  const [rowCountVehicleMaintenance, setRowCountVehicleMaintenance] = useState(0);

  // Selection state
  const [selectionVehicleMaintenance, setSelectionVehicleMaintenance] =
    useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataVehicleMaintenance = useCallback(
    (data?: IVehicleMaintenanceData) => {
      if (!data) return setDataVehicleMaintenance(data);
      const dataWithoutNull = toObjectWithoutNull<IVehicleMaintenanceData>(
        data,
        defaultDataVehicleMaintenance,
      );
      dataWithoutNull.maintenance_date = toDate(dataWithoutNull.maintenance_date);
      return setDataVehicleMaintenance(dataWithoutNull);
    },
    [dataVehicleMaintenance],
  );

  // Change page status
  const handlePageVehicleMaintenance = useCallback(
    (page: number) => setPageVehicleMaintenance(page),
    [pageVehicleMaintenance],
  );

  // Change page size status
  const handlePageSizeVehicleMaintenance = useCallback(
    (size: number) => {
      setPageSizeVehicleMaintenance(size);
      setPageVehicleMaintenance(0);
    },
    [pageSizeVehicleMaintenance],
  );

  // Change sort status
  const handleSortVehicleMaintenance = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortVehicleMaintenance(sort);
      setPageVehicleMaintenance(0);
    },
    [sortVehicleMaintenance],
  );

  // Change filters status
  const handleFilterVehicleMaintenance = useCallback(
    (filter: IVehicleMaintenanceFilter) => {
      setFilterVehicleMaintenance(filter);
      setPageVehicleMaintenance(0);
    },
    [filterVehicleMaintenance],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalVehicleMaintenance = useCallback(
    () => setIsOpenVehicleMaintenanceFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionVehicleMaintenance = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionVehicleMaintenance(selection);
    },
    [selectionVehicleMaintenance],
  );

  // Clear data rows
  const clearStorageVehicleMaintenance = useCallback(() => {
    setRowsVehicleMaintenance([]);
    setRowCountVehicleMaintenance(0);
  }, [setRowsVehicleMaintenance]);

  // Load data from request
  const loadStorageVehicleMaintenance = useCallback(
    (response: IApiResponseWithPagination<IVehicleMaintenanceList>) => {
      setRowsVehicleMaintenance(response.data);
      setRowCountVehicleMaintenance(response.records);
    },
    [setRowsVehicleMaintenance],
  );

  // API - Get all records
  const getAllVehicleMaintenance = useCallback(() => {
    // Reset selection
    handleSelectionVehicleMaintenance([]);
    // Add filters in params
    const params: any = getFilterParams(filterVehicleMaintenance);
    // Normalize date
    if (params.start_date) params.start_date = toDateApi(params.start_date);
    if (params.end_date) params.end_date = toDateApi(params.end_date);
    // Add sort in params
    const sort = getSortParams(sortVehicleMaintenance);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeVehicleMaintenance;
    params.offset = pageVehicleMaintenance * pageSizeVehicleMaintenance;
    // Get data
    VehicleMaintenanceService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        clearStorageVehicleMaintenance();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageVehicleMaintenance(0);
        return;
      }
      loadStorageVehicleMaintenance(response);
    });
  }, [
    headers,
    filterVehicleMaintenance,
    sortVehicleMaintenance,
    pageVehicleMaintenance,
    pageSizeVehicleMaintenance,
    dataVehicleMaintenance,
  ]);

  // API - Get record by id
  const getByIdVehicleMaintenance = useCallback(
    (id: number) => {
      // Get details
      VehicleMaintenanceService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicleMaintenance(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createVehicleMaintenance = useCallback(
    (payload: IVehicleMaintenanceDTO) => {
      // Get details
      VehicleMaintenanceService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicleMaintenance();
        snackbarSuccess('Registro adicionado!');
        if (pageVehicleMaintenance > 0) {
          handlePageVehicleMaintenance(0);
          return;
        }
        getAllVehicleMaintenance();
      });
    },
    [getAllVehicleMaintenance],
  );

  // API - Update record by id
  const updateByIdVehicleMaintenance = useCallback(
    (id: number, payload: IVehicleMaintenanceDTO) => {
      // Get details
      VehicleMaintenanceService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicleMaintenance();
        getAllVehicleMaintenance();
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllVehicleMaintenance],
  );

  // API - Delete record by id
  const deleteByIdVehicleMaintenance = useCallback(
    (id: number) => {
      VehicleMaintenanceService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicleMaintenance();
        getAllVehicleMaintenance();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllVehicleMaintenance],
  );

  // API - Delete record by ids
  const deleteIdsVehicleMaintenance = useCallback(
    (ids: number[]) => {
      VehicleMaintenanceService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllVehicleMaintenance();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllVehicleMaintenance],
  );

  return (
    <VehicleMaintenanceContext.Provider
      value={{
        // Data
        columnsVehicleMaintenance,
        rowsVehicleMaintenance,
        dataVehicleMaintenance,
        defaultDataVehicleMaintenance,
        handleDataVehicleMaintenance,
        // Pagination
        rowCountVehicleMaintenance,
        pageSizeVehicleMaintenance,
        pageVehicleMaintenance,
        handlePageSizeVehicleMaintenance,
        handlePageVehicleMaintenance,
        // Sort
        sortVehicleMaintenance,
        handleSortVehicleMaintenance,
        // Selection
        selectionVehicleMaintenance,
        handleSelectionVehicleMaintenance,
        // Filter
        defaultFilterVehicleMaintenance,
        hideFilterVehicleMaintenance,
        filterVehicleMaintenance,
        isOpenFilterModalVehicleMaintenance,
        toggleIsOpenFilterModalVehicleMaintenance,
        handleFilterVehicleMaintenance,
        // Api
        getAllVehicleMaintenance,
        getByIdVehicleMaintenance,
        createVehicleMaintenance,
        updateByIdVehicleMaintenance,
        deleteByIdVehicleMaintenance,
        deleteIdsVehicleMaintenance,
      }}
    >
      {children}
    </VehicleMaintenanceContext.Provider>
  );
};
