import { FilterList } from '@mui/icons-material';
import { IconButton, useTheme } from '@mui/material';
import { useDeviceIsMobile } from '../../hooks';
import { Div } from '../display-data';
import { Button, IButtonProps } from './Button';

// Define props
interface IFilterActionButton extends IButtonProps {
  onClick: () => void;
}

// Create filter action button component
export const FilterActionButton: React.FC<IFilterActionButton> = ({ onClick }) => {
  // Use theme
  const theme = useTheme();

  // Use hook device type
  const deviceIsMobile = useDeviceIsMobile();

  return (
    <Div onClick={onClick}>
      {deviceIsMobile ? (
        <IconButton size="large">
          <FilterList />
        </IconButton>
      ) : (
        <Button
          sx={{ mt: theme.spacing(1) }}
          startIcon={<FilterList />}
          variant="outlined"
          label="Filtrar"
          tooltip="Mostrar filtros disponíveis"
          disableFocusRipple
        />
      )}
    </Div>
  );
};
