/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useContract } from '../../../shared/hooks';

type IContractDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const ContractDataGrid: React.FC<IContractDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsContract,
    rowsContract,
    rowCountContract,
    pageContract,
    handlePageContract,
    pageSizeContract,
    handlePageSizeContract,
    sortContract,
    filterContract,
    handleFilterContract,
    handleSortContract,
    getAllContract,
  } = useContract();

  useEffect(() => handleFilterContract({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllContract();
  }, [canSearch, sortContract, filterContract, pageContract, pageSizeContract]);

  return (
    <DataGrid
      rows={rowsContract}
      rowCount={rowCountContract}
      columns={columnsContract}
      onSortModelChange={handleSortContract}
      sortModel={sortContract}
      pageSize={pageSizeContract}
      onPageSizeChange={handlePageSizeContract}
      page={pageContract}
      onPageChange={handlePageContract}
      {...rest}
    />
  );
};
