import axios from 'axios';
import axiosRetry from 'axios-retry';
import { ENV } from '../../../environment';
import { retryRequestCondition, retryRequestDelay } from './ApiUtils';
import { errorInterceptor, requestInterceptor, responseInterceptor } from './interceptors';

export interface IApiCustomConfig {
  timeout?: number;
}

export const Api = (customConfig?: IApiCustomConfig) => {
  const params = axios.create({
    baseURL: ENV.API.BASE_URL[0],
    headers: {
      'Content-type': 'application/json',
    },
    timeout: customConfig?.timeout || ENV.API.REQUEST_TIMEOUT,
  });

  axiosRetry(params, {
    retries: ENV.API.BASE_URL.length,
    shouldResetTimeout: true,
    retryCondition: retryRequestCondition,
    retryDelay: retryRequestDelay,
  });

  params.interceptors.request.use(
    (request) => requestInterceptor(request),
    (error) => errorInterceptor(error),
  );

  params.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error),
  );

  return params;
};
