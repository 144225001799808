import { useTheme } from '@mui/material';
import { P } from '../display-data';
import { UploadSingleFileIcon } from './UploadSingleFileIcon';
import { UploadSingleFileContainer } from './UploadSingleFileContainer';

export const UploadSingleFileAccept: React.FC = () => {
  const theme = useTheme();

  return (
    <UploadSingleFileContainer
      bgColor={theme.palette.success.light}
      color={theme.palette.success.contrastText}
    >
      <UploadSingleFileIcon icon="library_add" />
      <P>Solte o arquivo para iniciar o carregamento</P>
    </UploadSingleFileContainer>
  );
};
