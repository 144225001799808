import { useMedicalLeave } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { MedicalLeaveMain } from './components/MedicalLeaveMain';

export const MedicalLeavePage: React.FC = () => {
  const { toggleIsOpenFilterModalMedicalLeave } = useMedicalLeave();

  return (
    <PageContainer>
      <PageTitle
        title="Atestados"
        subtitle="Gerencie os atestados médico"
        filterAction={toggleIsOpenFilterModalMedicalLeave}
      />

      <PageMain>
        <MedicalLeaveMain />
      </PageMain>
    </PageContainer>
  );
};
