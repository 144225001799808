import * as Yup from 'yup';
import { Input, DataDialog } from '../../../shared/components';
import { useBusinessType, useDialog } from '../../../shared/hooks';
import { IBusinessTypeDTO } from '../../../shared/types';

// Define validation fields form
const schemaPayloadBusinessType = Yup.object({
  name: Yup.string()
    .trim()
    .required('O nome é obrigatório')
    .min(3, 'Deve conter ao menos 3 caracteres')
    .max(120, 'Deve conter no máximo 120 caracteres'),
});

// Create modal data for business type
export const BusinessTypeModal: React.FC = () => {
  // Use context business type
  const {
    dataBusinessType,
    defaultDataBusinessType,
    handleDataBusinessType,
    createBusinessType,
    updateByIdBusinessType,
    deleteByIdBusinessType,
  } = useBusinessType();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Reset data and close modal
  const handleClose = () => handleDataBusinessType();

  // Save actions
  const handleSave = (payload: object) => {
    const id = dataBusinessType?.id || 0;
    const data = payload as IBusinessTypeDTO;
    if (id > 0) {
      updateByIdBusinessType(id, data);
      return;
    }
    createBusinessType(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, name } = payload;
    dialogConfirmDanger(
      'Excluir ramo de atividade?',
      `Deseja realmente excluir o ramo de atividade: ${id} - ${name} `,
      () => deleteByIdBusinessType(id),
    );
  };

  return (
    <DataDialog
      referenceId={dataBusinessType?.id}
      referenceDisplayName="ramo de atividade"
      initialValues={dataBusinessType || defaultDataBusinessType}
      validationSchema={schemaPayloadBusinessType}
      open={dataBusinessType !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      <input type="hidden" name="is_active" />
      <Input
        autoFocus
        label="Nome *"
        name="name"
        type="text"
        placeholder="Nome do ramo de atividade"
      />
    </DataDialog>
  );
};
