import { CheckCircle } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Div, H1, P } from '../../../shared/components';

// Show password updated with success
export const ResetPasswordUpdated: React.FC = () => {
  const theme = useTheme();

  return (
    <Div display="flex" flexDirection="column" alignItems="center">
      <CheckCircle sx={{ color: theme.palette.success.main, fontSize: 48, mb: theme.spacing(2) }} />
      <H1 color={theme.palette.success.main} sx={{ marginX: theme.spacing(2) }}>
        Senha alterada
      </H1>
      <P color={theme.palette.text.secondary} align="center" sx={{ m: theme.spacing(2) }}>
        Sua senha foi alterada com sucesso, agora basta realizar o login utilizando a nova senha.
      </P>
    </Div>
  );
};
