import { ENV } from '../../../environment';
import { IAttachmentList, IAttachmentDTO, IAttachmentResource } from '../../../types';
import { Api, ApiError, IApiCustomConfig, makeApiError } from '../config';
import { AttachmentSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  resource: IAttachmentResource,
  resourceId: number,
): Promise<IAttachmentList[] | ApiError> => {
  try {
    const url = `${resource}/${resourceId}/attachments`;

    const response = await Api().get(url, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, AttachmentSchemaApiError);
  }
};

// Create record
const create = async (
  headers: any,
  resource: IAttachmentResource,
  resourceId: number,
  payload: IAttachmentDTO,
): Promise<number | ApiError> => {
  try {
    const url = `${resource}/${resourceId}/attachments`;

    const customConfig: IApiCustomConfig = {
      timeout: ENV.API.REQUEST_TIMEOUT * 10,
    };

    const response = await Api(customConfig).post(url, payload, { headers });
    return response.data.attachment_id;
  } catch (error: any) {
    return makeApiError(error, AttachmentSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  resource: IAttachmentResource,
  resourceId: number,
  attachmentId: number,
  payload: IAttachmentDTO,
): Promise<boolean | ApiError> => {
  try {
    const url = `${resource}/${resourceId}/attachments/${attachmentId}`;

    await Api().put(url, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, AttachmentSchemaApiError);
  }
};

// delete record by id
const deleteById = async (
  headers: any,
  resource: IAttachmentResource,
  resourceId: number,
  attachmentId: number,
): Promise<boolean | ApiError> => {
  try {
    const url = `${resource}/${resourceId}/attachments/${attachmentId}`;

    await Api().delete(url, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, AttachmentSchemaApiError);
  }
};

export const AttachmentService = { getAll, create, updateById, deleteById };
