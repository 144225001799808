import { Icon, useTheme } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useCallback } from 'react';
import { Span, Div, P } from '../display-data';
import { useAuth, useMenu } from '../../hooks';
import { useCompaniesAvailableModalStore } from '../../store';
import { CompaniesAvailableModal } from '../companies-available-modal/CompaniesAvailableModal';
import { ENV } from '../../environment';

export const SidebarMenuFooter: React.FC = () => {
  // Use theme mui
  const theme = useTheme();

  // Use Navbar store
  const { isOpenSidebarMenu } = useMenu();

  // Use auth
  const { user } = useAuth();

  // Use store
  const { openCompaniesAvailableModal } = useCompaniesAvailableModalStore();

  // Companies available
  const handleCompaniesAvailableClick = useCallback(() => openCompaniesAvailableModal(), []);

  return (
    <>
      <Div sx={{ flexGrow: 1 }} />

      {isOpenSidebarMenu && (
        <Div sx={{ px: 1, py: 2 }} onClick={handleCompaniesAvailableClick}>
          <Div
            isFlexRow
            sx={{
              alignItems: 'center',
              backgroundColor:
                theme.palette.mode === 'light'
                  ? theme.palette.primary.light
                  : theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              cursor: 'pointer',
              px: 2,
              py: '15px',
              borderRadius: '4px',
            }}
          >
            <Icon sx={{ mr: 1 }}>business</Icon>

            <Div sx={{ flexGrow: 1 }}>
              <P variant="subtitle1">{user.payload?.companies.name}</P>
              <Span variant="body2">{`${user.payload?.companies.address.city} / ${user.payload?.companies.address.state}`}</Span>
            </Div>
            <UnfoldMoreIcon />
          </Div>
        </Div>
      )}

      <Div isFlexColumn>
        <Span>
          {isOpenSidebarMenu && 'Versão:'} {ENV.APP.VERSION}{' '}
          {isOpenSidebarMenu && `(${ENV.APP.ENV})`}
        </Span>
      </Div>

      <CompaniesAvailableModal />
    </>
  );
};
