/* eslint-disable react/jsx-props-no-spreading */
import { Box, BoxProps } from '@mui/material';

// Extend props MUI Typography H1
interface IBoxProps extends BoxProps {
  isFlexRow?: boolean;
  isFlexColumn?: boolean;
  fillInFlex?: boolean;
}

// Create component Div Custom
export const Div: React.FC<IBoxProps> = ({
  isFlexRow,
  isFlexColumn,
  fillInFlex,
  children,
  ...rest
}) => {
  const props: Partial<IBoxProps> = {};

  if (isFlexRow) {
    props.display = 'flex';
    props.flexDirection = 'row';
  }

  if (isFlexColumn) {
    props.display = 'flex';
    props.flexDirection = 'column';
    props.alignItems = 'center';
  }

  if (fillInFlex) {
    props.flexGrow = 1;
  }

  return (
    <Box component="div" {...props} {...rest}>
      {children}
    </Box>
  );
};
