import { Formik } from 'formik';
import { Form } from './Form';

interface IFormSuiteProps {
  formId: string;
  initialValues: object;
  validationSchema: any;
  submit: (data: object) => void;
}

export const FormSuite: React.FC<IFormSuiteProps> = ({
  formId,
  validationSchema,
  initialValues,
  submit,
  children,
}) => {
  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        submit(values);
      }}
    >
      {({ handleSubmit, handleReset }) => (
        <Form
          id={formId}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          onReset={handleReset}
        >
          {children}
        </Form>
      )}
    </Formik>
  );
};
