import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, ContractService } from '../services';
import {
  IContractFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  IContractDTO,
  IDataGridSelection,
  IContractList,
  IContractData,
  IContractParamsDaily,
  IContractParamsDailyData,
} from '../types';
import { InfoStatusRecord } from '../components/feedback/InfoStatusRecord';
import { getFilterParams } from '../utils';
import { getSortParams } from '../utils/getSortParams';

// Define interface for context
interface IContractContext {
  // Data
  columnsContract: IDataGridColumns;
  rowsContract: IContractList[];
  defaultDataContract: IContractData;
  dataContract: IContractData | undefined;
  handleDataContract: (data?: IContractData) => void;
  // Params daily
  dataParamsDailyContract: IContractParamsDaily[];
  defaultDataParamsDailyContract: IContractParamsDailyData;
  handleDataParamsDailyContract: (data: IContractParamsDaily[]) => void;
  // Pagination
  rowCountContract: number;
  pageSizeContract: number;
  pageContract: number;
  handlePageSizeContract: (size: number) => void;
  handlePageContract: (page: number) => void;
  // Sort
  sortContract: IDataGridSortOptions;
  handleSortContract: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionContract: IDataGridSelection;
  handleSelectionContract: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterContract: IContractFilter;
  filterContract: IContractFilter;
  isOpenFilterModalContract: boolean;
  toggleIsOpenFilterModalContract: () => void;
  handleFilterContract: (filter: IContractFilter) => void;
  // Api
  getAllContract: () => void;
  getByIdContract: (id: number) => void;
  createContract: (payload: IContractDTO) => void;
  updateByIdContract: (id: number, payload: IContractDTO) => void;
  deleteByIdContract: (id: number) => void;
  deleteIdsContract: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaContract: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código do contrato',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'name',
    headerName: 'Nome',
    description: 'Nome do contrato',
    flex: 1,
  },
  {
    field: 'is_active',
    headerName: 'Status',
    description: 'Indica se o registro esta ativo ou não',
    renderCell: (params) => <InfoStatusRecord status={params.row.is_active} />,
    headerAlign: 'center',
    align: 'center',
  },
];

// Initial values for filter
const defaultFilterContract: IContractFilter = {
  id: 0,
  name: '',
};

// initial values for data
const defaultDataContract: IContractData = {
  id: 0,
  name: '',
  description: '',
  is_active: true,
  type: 'MENSAL',
  paramsMonthly: {
    price: 0,
    delivery_limit: 0,
  },
};

// initial values for data
const defaultDataParamsDailyContract: IContractParamsDailyData = {
  week_day_list: [],
  delivery_price: 0,
  km_price: 0,
  fixed_price: 0,
};

// Create context
export const ContractContext = createContext<IContractContext>({} as IContractContext);

// Create context provider
export const ContractContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsContract] = useState<IDataGridColumns>(columnsSchemaContract);

  // Rows state
  const [rowsContract, setRowsContract] = useState<IContractList[]>([]);

  // Data state
  const [dataContract, setDataContract] = useState<IContractData | undefined>();

  // Data state for params daily
  const [dataParamsDailyContract, setDataParamsDailyContract] = useState<IContractParamsDaily[]>(
    [],
  );

  // Sort state
  const [sortContract, setSortContract] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterContract, setFilterContract] = useState<IContractFilter>(defaultFilterContract);

  // Modal filters
  const [isOpenFilterModalContract, setIsOpenContractFilterModal] = useState(false);

  // Page state
  const [pageContract, setPageContract] = useState(0);

  // Page size state
  const [pageSizeContract, setPageSizeContract] = useState(10);

  // Record count state
  const [rowCountContract, setRowCountContract] = useState(0);

  // Selection state
  const [selectionContract, setSelectionContract] = useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataContract = useCallback(
    (data?: IContractData) => {
      setDataContract(data);
      if (!data) setDataParamsDailyContract([]);
    },
    [setDataContract],
  );

  // Set data params daily
  const handleDataParamsDailyContract = useCallback(
    (data: IContractParamsDaily[]) => setDataParamsDailyContract(data),
    [setDataParamsDailyContract],
  );

  // Change page status
  const handlePageContract = useCallback((page: number) => setPageContract(page), [pageContract]);

  // Change page size status
  const handlePageSizeContract = useCallback(
    (size: number) => {
      setPageSizeContract(size);
      setPageContract(0);
    },
    [pageSizeContract],
  );

  // Change sort status
  const handleSortContract = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortContract(sort);
      setPageContract(0);
    },
    [sortContract],
  );

  // Change filters status
  const handleFilterContract = useCallback(
    (filter: IContractFilter) => {
      setFilterContract(filter);
      setPageContract(0);
    },
    [filterContract],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalContract = useCallback(
    () => setIsOpenContractFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionContract = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionContract(selection);
    },
    [selectionContract],
  );

  // Clear data rows
  const clearStorageContract = useCallback(() => {
    setRowsContract([]);
    setRowCountContract(0);
  }, [setRowsContract]);

  // Load data from request
  const loadStorageContract = useCallback(
    (response: IApiResponseWithPagination<IContractList>) => {
      setRowsContract(response.data);
      setRowCountContract(response.records);
    },
    [setRowsContract],
  );

  // API - Get all records
  const getAllContract = useCallback(() => {
    // Reset selection
    handleSelectionContract([]);
    // Add filters in params
    const params: any = getFilterParams(filterContract);
    // Add sort in params
    const sort = getSortParams(sortContract);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeContract;
    params.offset = pageContract * pageSizeContract;
    // Get data
    ContractService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        clearStorageContract();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageContract(0);
        return;
      }
      loadStorageContract(response);
    });
  }, [headers, filterContract, sortContract, pageContract, pageSizeContract, dataContract]);

  // API - Get record by id
  const getByIdContract = useCallback(
    (id: number) => {
      // Get details
      ContractService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }

        // Convert response to data interface
        const data: IContractData = {
          // Add default values
          ...defaultDataContract,
          // Add values from response
          ...response,
        };

        // Apply params for type contract
        if (response.type === 'DIARIO' && Array.isArray(response.params)) {
          handleDataParamsDailyContract(response.params as IContractParamsDaily[]);
        } else if ('price' in response.params) {
          data.paramsMonthly = { ...response.params };
        }

        // Set data
        handleDataContract(data);
      });
    },
    [headers, setDataContract],
  );

  // API - Create record
  const createContract = useCallback(
    (payload: IContractDTO) => {
      // Get details
      ContractService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataContract();
        snackbarSuccess('Registro adicionado!');
        if (pageContract > 0) {
          handlePageContract(0);
          return;
        }
        getAllContract();
      });
    },
    [getAllContract],
  );

  // API - Update record by id
  const updateByIdContract = useCallback(
    (id: number, payload: IContractDTO) => {
      // Get details
      ContractService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataContract();
        getAllContract();
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllContract],
  );

  // API - Delete record by id
  const deleteByIdContract = useCallback(
    (id: number) => {
      ContractService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataContract();
        getAllContract();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllContract],
  );

  // API - Delete record by ids
  const deleteIdsContract = useCallback(
    (ids: number[]) => {
      ContractService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllContract();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllContract],
  );

  return (
    <ContractContext.Provider
      value={{
        // Data
        columnsContract,
        rowsContract,
        dataContract,
        defaultDataContract,
        handleDataContract,
        // Params daily
        dataParamsDailyContract,
        defaultDataParamsDailyContract,
        handleDataParamsDailyContract,
        // Pagination
        rowCountContract,
        pageSizeContract,
        pageContract,
        handlePageSizeContract,
        handlePageContract,
        // Sort
        sortContract,
        handleSortContract,
        // Selection
        selectionContract,
        handleSelectionContract,
        // Filter
        defaultFilterContract,
        filterContract,
        isOpenFilterModalContract,
        toggleIsOpenFilterModalContract,
        handleFilterContract,
        // Api
        getAllContract,
        getByIdContract,
        createContract,
        updateByIdContract,
        deleteByIdContract,
        deleteIdsContract,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};
