import { IApiError, ISchemaApiError } from '../../../types';

export class ApiError extends Error {
  public readonly statusCode: number;

  public readonly message: string;

  constructor(error: IApiError) {
    super();
    this.statusCode = error.statusCode || 500;
    this.message = error.message || 'Erro desconhecido';
  }
}

export const createApiError = (error: IApiError) => new ApiError(error);

// Maps api error schemas
export const makeApiError = (error: any, schema?: ISchemaApiError[]): ApiError => {
  // If the error is mapped by the interceptor
  if (error instanceof ApiError) return error;

  const response = {
    statusCode: error.response.status || error.status || 500,
    message: error.response.data.error || error.message || 'Unknown error',
  };

  if (!schema) return createApiError(response);

  // Locate status code in schema
  const schemaStatus = schema.find((item) => item.statusCode === response.statusCode);

  if (schemaStatus) {
    // If locate status and message is define for default
    if (schemaStatus.message)
      return createApiError({ statusCode: schemaStatus.statusCode, message: schemaStatus.message });

    // If no locate message, find in errors list
    const schemaError = schemaStatus.errors?.find((item) => {
      if (item.isPartial) {
        return String(response.message).includes(item.error);
      }
      return item.error === response.message;
    });

    // If locate item
    if (schemaError?.message)
      return createApiError({
        statusCode: schemaStatus.statusCode,
        message: schemaError.message,
      });
  }

  // Error not mapped for schema
  console.log(response);
  return createApiError({
    statusCode: 500,
    message: 'Erro desconhecido',
  });
};
