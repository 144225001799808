/* eslint-disable react/jsx-props-no-spreading */

import { Tab, Tabs, ITabsProps } from '../../../shared/components';

interface IEmployeeModalTabs extends ITabsProps {
  isEditMode?: boolean;
}

export const EmployeeModalTabs: React.FC<IEmployeeModalTabs> = ({ isEditMode, ...rest }) => {
  return (
    <Tabs {...rest} variant="scrollable" scrollButtons="auto">
      <Tab label="Geral" />
      <Tab label="Contatos" disabled={!isEditMode} />
      <Tab label="Endereços" disabled={!isEditMode} />
      <Tab label="Contas" disabled={!isEditMode} />
      <Tab label="Anexos" disabled={!isEditMode} />
      <Tab label="Veículos" disabled={!isEditMode} />
      <Tab label="Seguros" disabled={!isEditMode} />
      <Tab label="Atestados" disabled={!isEditMode} />
      <Tab label="Usuários" disabled={!isEditMode} />
    </Tabs>
  );
};
