/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { daysOfWeek } from '../../constants';
import { MultipleSelect } from './MultipleSelect';
import { ISelectOptions, Select } from './Select';

interface ISelectWeekDayProps extends FieldProps {
  label?: string;
  multiple?: boolean;
}

const options: ISelectOptions[] = daysOfWeek.map((day) => {
  return { value: day.index, label: day.name };
});

export const SelectWeekDay: React.FC<ISelectWeekDayProps> = ({
  label = 'Dia da semana',
  multiple,
  ...rest
}) => {
  return multiple ? (
    <MultipleSelect label={label} defaultOption={[]} dataOptions={options} {...rest} />
  ) : (
    <Select label={label} defaultOption={0} dataOptions={options} {...rest} />
  );
};
