import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useVehicleMaintenance, useDialog, useDeviceIsMobile } from '../../../shared/hooks';
import {
  IVehicleMaintenanceFilter,
  IDataGridRowParams,
  IDataGridColumnsHidden,
} from '../../../shared/types';
import { VehicleMaintenanceDataGrid } from './VehicleMaintenanceDataGrid';
import { VehicleMaintenanceFilterModal } from './VehicleMaintenanceFilterModal';
import { VehicleMaintenanceModal } from './VehicleMaintenanceModal';

interface IVehicleMaintenanceMainProps {
  embedded?: boolean;
  initialFilter?: Partial<IVehicleMaintenanceFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {
  start_date: false,
  end_date: false,
  vehicle_id: false,
  mechanical: false,
  people_id: false,
};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {
  start_date: false,
  end_date: false,
  vehicle_id: false,
  vehicle_detail: false,
  mechanical: false,
  maintenance_date: false,
  people_id: false,
  people_name: false,
};

export const VehicleMaintenanceMain: React.FC<IVehicleMaintenanceMainProps> = ({
  embedded,
  initialFilter,
}) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState<boolean>(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsVehicleMaintenance,
    rowsVehicleMaintenance,
    defaultDataVehicleMaintenance,
    handleDataVehicleMaintenance,
    selectionVehicleMaintenance,
    handleSelectionVehicleMaintenance,
    filterVehicleMaintenance,
    hideFilterVehicleMaintenance,
    handleFilterVehicleMaintenance,
    getByIdVehicleMaintenance,
    deleteIdsVehicleMaintenance,
  } = useVehicleMaintenance();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterVehicleMaintenance(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsVehicleMaintenance]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterVehicleMaintenance({});
      return;
    }
    handleFilterVehicleMaintenance({ ...filterVehicleMaintenance, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataVehicleMaintenance(defaultDataVehicleMaintenance);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdVehicleMaintenance(+id);
  };

  const handleDeleteData = () => {
    if (!selectionVehicleMaintenance || selectionVehicleMaintenance.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsVehicleMaintenance(selectionVehicleMaintenance as number[]);
      },
    );
  };

  return (
    <>
      <VehicleMaintenanceFilterModal />
      <VehicleMaintenanceModal />

      {!embedded && (
        <DataGridFilters
          columns={columnsVehicleMaintenance}
          filters={filterVehicleMaintenance}
          hideFilters={hideFilterVehicleMaintenance}
          onRemoveFilter={handleRemoveFilter}
        />
      )}

      <VehicleMaintenanceDataGrid
        selectionModel={selectionVehicleMaintenance}
        onSelectionModelChange={handleSelectionVehicleMaintenance}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionVehicleMaintenance}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionVehicleMaintenance?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
