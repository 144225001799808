/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useVehicleMaintenance } from '../../../shared/hooks';

type IVehicleMaintenanceDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const VehicleMaintenanceDataGrid: React.FC<IVehicleMaintenanceDataGridProps> = ({
  ...rest
}) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsVehicleMaintenance,
    rowsVehicleMaintenance,
    rowCountVehicleMaintenance,
    pageVehicleMaintenance,
    handlePageVehicleMaintenance,
    pageSizeVehicleMaintenance,
    handlePageSizeVehicleMaintenance,
    sortVehicleMaintenance,
    filterVehicleMaintenance,
    handleFilterVehicleMaintenance,
    handleSortVehicleMaintenance,
    getAllVehicleMaintenance,
  } = useVehicleMaintenance();

  useEffect(() => handleFilterVehicleMaintenance({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllVehicleMaintenance();
  }, [
    canSearch,
    sortVehicleMaintenance,
    filterVehicleMaintenance,
    pageVehicleMaintenance,
    pageSizeVehicleMaintenance,
  ]);

  return (
    <DataGrid
      rows={rowsVehicleMaintenance}
      rowCount={rowCountVehicleMaintenance}
      columns={columnsVehicleMaintenance}
      onSortModelChange={handleSortVehicleMaintenance}
      sortModel={sortVehicleMaintenance}
      pageSize={pageSizeVehicleMaintenance}
      onPageSizeChange={handlePageSizeVehicleMaintenance}
      page={pageVehicleMaintenance}
      onPageChange={handlePageVehicleMaintenance}
      {...rest}
    />
  );
};
