import { Alert, DialogContent, Divider, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { Block, CheckCircle, ErrorOutline } from '@mui/icons-material';
import { useAuth, useUser } from '../../hooks';
import { Dialog } from '../feedback/Dialog';
import { Div } from '../display-data';
import { IUserRolePermissions } from '../../types';
import { Chip } from '../feedback';

interface IUserRoleDialogProps {
  isOpen: boolean;
  onClose: () => void;
  role: number;
}

export const UserRoleDialog: React.FC<IUserRoleDialogProps> = ({ isOpen, role, onClose }) => {
  const { rowsPermissionsRole, getPermissionsByRoleId } = useUser();

  const { user } = useAuth();

  const theme = useTheme();

  useEffect(() => {
    if (!isOpen) return;
    if (!user.token) return;
    getPermissionsByRoleId(role);
  }, [user, role, isOpen]);

  const getPermissions = (permissions: IUserRolePermissions) => {
    const { get, put, post, patch } = permissions;
    const drop = permissions.delete;

    if (get && post && put && drop)
      return (
        <Chip
          label="Acesso completo"
          tooltip="O usuário terá total acesso a este recurso"
          icon={<CheckCircle />}
          color="success"
          size="small"
        />
      );
    if (!get && !post && !put && !drop)
      return (
        <Chip
          label="Acesso bloqueado"
          tooltip="O usuário está sem acesso a este recurso"
          icon={<Block />}
          color="error"
          size="small"
        />
      );

    return (
      <Chip
        label={`Apenas
               ${get ? 'consulta, ' : ''}
               ${post ? 'inclusão, ' : ''}
               ${put || patch ? 'alteração, ' : ''}
               ${drop ? 'exclusão' : ''}`}
        tooltip="O usuário tem acesso parcial a este recurso"
        icon={<ErrorOutline />}
        color="warning"
        size="small"
      />
    );
  };

  return (
    <Dialog title="Permissões" open={isOpen} close={onClose}>
      <DialogContent dividers>
        {rowsPermissionsRole.length === 0 ? (
          <Alert severity="info" variant="outlined">
            Este nível de usuário ainda não possuí permissões configuradas
          </Alert>
        ) : (
          rowsPermissionsRole.map((platform) => (
            <Div key={platform.platform_id} display="flex" flexDirection="column">
              <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
                {platform.platform_name}
              </Typography>

              {platform.permissions.map((permission) => (
                <Div key={permission.resource}>
                  <Typography>{permission.resource}</Typography>

                  <Typography
                    variant="body2"
                    style={{
                      margin: 0,
                      fontWeight: 400,
                      fontSize: '0.875rem',
                      lineHeight: '1.43',
                      letterSpacing: '0.01071em',
                    }}
                    sx={{ color: theme.palette.text.secondary, pb: 1 }}
                  >
                    {permission.description}
                  </Typography>

                  {getPermissions(permission)}

                  <Divider sx={{ my: 1 }} />
                </Div>
              ))}
            </Div>
          ))
        )}
      </DialogContent>
    </Dialog>
  );
};
