import { Alert, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { LockOpen } from '@mui/icons-material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IAuthPinActivatePayload } from '../../../shared/types';
import { useAuth } from '../../../shared/hooks/useAuth';
import { usePinModalStore } from '../../../shared/store';
import { Button, Dialog, DialogActionButton, Form, InputMask } from '../../../shared/components';

// Modal for activation pin
export const LoginPinModal: React.FC = () => {
  // Use PIN Store
  const { isOpen, resendBlocked, resent, closePinModal } = usePinModalStore();

  // Use Auth Context
  const { singIn } = useAuth();

  // Define default values
  const defaultPinPayload: IAuthPinActivatePayload = { pin: '' };

  // Define schema validation
  const schemaPinPayload = Yup.object({
    pin: Yup.string().required('Informe o PIN').length(7, 'O PIN deve conter 6 dígitos'),
  });

  // Send PIN Activation
  const handleActivePIN = ({ pin }: IAuthPinActivatePayload) => singIn({ pin });

  // Resend PIN
  const handleResentPIN = () => singIn({ pin: '' });

  return (
    <Dialog title="Autorização por PIN" open={isOpen} close={closePinModal}>
      <DialogContent dividers>
        <DialogContentText>
          Um código de ativação foi enviado para seu e-mail, digite-o no campo abaixo para
          continuar.
        </DialogContentText>

        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={defaultPinPayload}
          validationSchema={schemaPinPayload}
          onSubmit={(values) => {
            handleActivePIN(values);
          }}
        >
          {({ handleSubmit }) => (
            <Form id="form-pin" onSubmit={handleSubmit}>
              <InputMask
                mask="pin"
                label="PIN"
                name="pin"
                placeholder="Digite o PIN"
                autoFocus
                size="medium"
                maxLength={7}
                inputMode="numeric"
                inputProps={{
                  style: {
                    textAlign: 'center',
                    fontWeight: 'bolder',
                    fontSize: '20px',
                  },
                }}
              />
              {resendBlocked ? (
                // if resent PIN is block for excessive try
                <Alert severity="error">
                  Detectamos várias tentativas de acesso, caso não tenha recebido o PIN em seu
                  e-mail, aguarde para solicitar um novo!
                </Alert>
              ) : null}
              {resent && !resendBlocked ? (
                // if resend PIN
                <Alert severity="success">
                  Reenviamos o PIN para seu e-mail, verifique sua caixa de entrada/spam.
                </Alert>
              ) : null}
              {!resendBlocked ? (
                <Button
                  label="Não recebeu o e-mail?"
                  fullWidth
                  variant="text"
                  onClick={handleResentPIN}
                />
              ) : null}
            </Form>
          )}
        </Formik>
      </DialogContent>

      <DialogActions>
        <DialogActionButton
          label="Autorizar este dispositivo"
          type="submit"
          primaryAction
          form="form-pin"
          startIcon={<LockOpen />}
        />
      </DialogActions>
    </Dialog>
  );
};
