import { useBusinessType } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { BusinessTypeMain } from './components/BusinessTypeMain';

export const BusinessTypePage: React.FC = () => {
  const { toggleIsOpenFilterModalBusinessType } = useBusinessType();

  return (
    <PageContainer>
      <PageTitle
        title="Ramo de atividade"
        subtitle="Gerencie o cadastro de ramos de atividade"
        filterAction={toggleIsOpenFilterModalBusinessType}
      />

      <PageMain>
        <BusinessTypeMain />
      </PageMain>
    </PageContainer>
  );
};
