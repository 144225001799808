import { AxiosError } from 'axios';
import { disableLoader } from '../../../../contexts/LoaderContext';
import { createApiError } from '../ApiError';

export const errorInterceptor = (error: AxiosError) => {
  disableLoader();

  // Network error
  const networkErrors = ['timeout', 'Network Error'];
  if (networkErrors.some((item) => String(error.message).includes(item))) {
    return Promise.reject(createApiError({ statusCode: 408, message: 'Erro de conexão' }));
  }

  // Credential error
  if (error.response?.status === 401 && !error.config.url?.includes('auth/reset-password')) {
    window.location.href = `/#/login`;
    return Promise.reject(
      createApiError({ statusCode: 401, message: 'Credenciais de acesso inválido' }),
    );
  }

  // Authorized error
  if (error.response?.status === 403) {
    window.location.href = `/#/403-nao-autorizado`;
    return Promise.reject(createApiError({ statusCode: 403, message: 'Não autorizado' }));
  }

  // Unmapped error
  return Promise.reject(error);
};
