import logo from '../../../assets/images/logo-login.png';
import { H1, H2, Img, P } from '../../../shared/components';

// Information data content in login page
export const LoginIntro: React.FC = () => {
  return (
    <>
      <Img src={logo} width="150px" alt="Logo da empresa" />
      <H1>LogMoto Express</H1>
      <H2>Painel administrativo</H2>
      <P sx={{ mt: 3, mb: 0 }}>Bem vindo ao painel administrativo LogMoto</P>
    </>
  );
};
