/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import { useTheme } from '@mui/material';
import { DataGrid as MuiDataGrid, DataGridProps, ptBR } from '@mui/x-data-grid';
import { useDeviceIsMobile, useLongPress } from '../../hooks';
import { DataGridWrapper } from './DataGridWrapper';

// Define props component
export interface IDataGridProps extends DataGridProps {
  onCheckboxSelectionModeChange?: (state?: boolean) => void;
  simpleMode?: boolean;
}

// Create component
export const DataGrid: React.FC<IDataGridProps> = ({
  rows,
  columns,
  pageSize,
  onRowClick,
  checkboxSelection,
  onCheckboxSelectionModeChange,
  simpleMode,
  ...rest
}) => {
  // Use theme
  const theme = useTheme();

  // Log click in row
  const handleLongClick = () => onCheckboxSelectionModeChange?.(true);

  // Use long press hook
  const longPress = useLongPress(handleLongClick, 300);

  const deviceIsMobile = useDeviceIsMobile();

  return (
    <DataGridWrapper
      isEmpty={rows.length === 0}
      elevation={simpleMode ? 0 : 2}
      sx={{ borderRadius: simpleMode ? 0 : '4px' }}
    >
      <MuiDataGrid
        checkboxSelection={checkboxSelection}
        hideFooterSelectedRowCount
        disableSelectionOnClick={!checkboxSelection}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        disableColumnFilter
        filterMode="server"
        sortingMode="server"
        density="standard"
        paginationMode="server"
        pagination
        rowHeight={deviceIsMobile ? 52 : 45}
        autoHeight
        columns={columns}
        rows={rows}
        pageSize={pageSize || 10}
        rowsPerPageOptions={[10, 25, 50]}
        editMode="row"
        componentsProps={{
          row: { ...longPress },
        }}
        sx={{
          borderRadius: simpleMode ? 0 : '4px',
          '& .MuiDataGrid-columnHeaders': {
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
          },
          '& .MuiCheckbox-root': {
            py: '20px',
          },
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
          '& .MuiDataGrid-row:nth-of-type(even)': {
            backgroundColor:
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          },
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            display: simpleMode ? 'none' : '',
          },
          '& .MuiDataGrid-sortIcon': {
            color: theme.palette.primary.contrastText,
          },
          '& .MuiDataGrid-menuIconButton': {
            color: theme.palette.primary.contrastText,
          },
          '& 	.MuiDataGrid-iconSeparator': {
            color: theme.palette.primary.main,
          },
          '& .MuiCheckbox-root svg': {
            width: 18,
            height: 18,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'}`,
            borderRadius: '2px',
          },
          '& .MuiCheckbox-root svg path': {
            display: 'none',
          },
          '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
            backgroundColor: theme.palette.secondary.light,
            borderColor: theme.palette.secondary.contrastText,
          },
          '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
            position: 'absolute',
            display: 'table',
            border: '2px solid #fff',
            borderTop: 0,
            borderLeft: 0,
            transform: 'rotate(45deg) translate(-50%,-50%)',
            opacity: 1,
            transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
            content: '""',
            top: '50%',
            left: '39%',
            width: 5.71428571,
            height: 9.14285714,
          },
          '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
            width: 8,
            height: 8,
            backgroundColor: '#1890ff',
            transform: 'none',
            top: '39%',
            border: 0,
          },
          '& .MuiDataGrid-virtualScroller': {
            marginTop: simpleMode ? '0!important' : null,
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        }}
        {...rest}
        onRowClick={checkboxSelection ? undefined : onRowClick}
      />
    </DataGridWrapper>
  );
};
