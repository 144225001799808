// Extract only filters fill
export function getFilterParams(value: any): any {
  const partial: any = {};
  Object.keys(value).forEach((key: string) => {
    if (value[key]) {
      if (typeof value[key] === 'number' && value[key] === 0) return;
      if (typeof value[key] === 'string' && value[key] === '') return;
      partial[key] = value[key];
    }
  });
  return partial;
}
