import { Grid } from '@mui/material';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Input, DataDialog } from '../../../shared/components';
import { useContract, useDialog, useSnackbarDispatch } from '../../../shared/hooks';
import { IContractDTO, IContractType } from '../../../shared/types';
import { schemaValidation, toNumber, toNumberOrZero } from '../../../shared/utils';
import { ContractInputSelectType } from './ContractInputSelectType';
import { ContractParamsDaily } from './ContractParamsDaily';
import { ContractParamsMonthly } from './ContractParamsMonthly';

// Validation submit form data
const schemaPayloadContract = Yup.object({
  name: schemaValidation.commons.name,
  description: schemaValidation.commons.description,
  type: schemaValidation.commons.string(true).oneOf(['MENSAL', 'DIARIO']),
  paramsMonthly: Yup.object({
    price: schemaValidation.number.float,
    delivery_limit: schemaValidation.number.integer,
  }).notRequired(),
});

// Create component contact modal
export const ContractModal: React.FC = () => {
  // State for contract type
  const [typeContract, setTypeContract] = useState<IContractType | undefined>();

  // Use contract
  const {
    dataContract,
    defaultDataContract,
    dataParamsDailyContract,
    handleDataContract,
    createContract,
    updateByIdContract,
    deleteByIdContract,
  } = useContract();

  // Use dialog
  const { dialogConfirmDanger } = useDialog();

  // Use Snack dispatch
  const { snackbarError } = useSnackbarDispatch();

  // Change state contract type
  const handleSelectTypeContract = (value: any) => {
    const type = String(value).toUpperCase();
    if (type !== 'MENSAL' && type !== 'DIARIO') return setTypeContract(undefined);
    return setTypeContract(type as IContractType);
  };

  // Set contract type on load data
  useEffect(() => handleSelectTypeContract(dataContract?.type), [dataContract]);

  // Close modal
  const handleClose = () => handleDataContract();

  // Save data contract
  const handleSave = (payload: any) => {
    // Get ID current contract
    const id = dataContract?.id || 0;

    // Normalize payload data
    const normalizePayload = {
      ...payload,
      params:
        payload.type === 'MENSAL'
          ? {
              price: toNumberOrZero(payload.paramsMonthly.price),
              delivery_limit: toNumber(payload.paramsMonthly.delivery_limit),
            }
          : [...dataParamsDailyContract],
    };

    // Remove child not utilized in request
    delete normalizePayload.paramsMonthly;

    // Convert payload to interface DTO
    const dataPayload: IContractDTO = { ...normalizePayload };

    // Validate
    if (
      dataPayload.type === 'DIARIO' &&
      (!Array.isArray(dataPayload.params) || dataPayload.params.length === 0)
    ) {
      snackbarError('Informe ao menos um dia da semana');
      return;
    }

    // Update contract
    if (id > 0) {
      updateByIdContract(id, dataPayload);
      return;
    }
    // Create contract
    createContract(dataPayload);
  };

  // Delete contract
  const handleDelete = (payload: any) => {
    const { id, name } = payload;
    dialogConfirmDanger(
      'Excluir Contrato?',
      `Deseja realmente excluir o contrato: ${id} - ${name} `,
      () => deleteByIdContract(id),
    );
  };

  return (
    <DataDialog
      referenceId={dataContract?.id}
      referenceDisplayName="contrato"
      initialValues={dataContract || defaultDataContract}
      validationSchema={schemaPayloadContract}
      open={dataContract !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      <input type="hidden" name="is_active" />

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={9}>
          <Input autoFocus label="Nome *" name="name" placeholder="Nome do Contratos" />
        </Grid>

        <Grid item xs={12} md={3}>
          <Field
            name="type"
            component={ContractInputSelectType}
            onSelect={handleSelectTypeContract}
          />
        </Grid>

        <Grid item xs={12} md={12} xl={12}>
          <Input label="Descrição" name="description" placeholder="Descrição do contrato" />
        </Grid>

        {typeContract === 'DIARIO' && <ContractParamsDaily />}

        {typeContract === 'MENSAL' && <ContractParamsMonthly />}
      </Grid>
    </DataDialog>
  );
};
