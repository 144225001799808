/* eslint-disable react/jsx-props-no-spreading */
import { Box, BoxProps } from '@mui/material';

// Extend props MUI Box FORM
type IFormProps = BoxProps<'form'>;

// Create component Form Custom
export const Form: React.FC<IFormProps> = ({ children, ...rest }) => {
  return (
    <Box component="form" {...rest}>
      {children}
    </Box>
  );
};
