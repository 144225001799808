/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from '../../../shared/components';

interface IContractInputSelectTypeProps extends FieldProps {
  onSelect: (value: any) => void;
}

const options: ISelectOptions[] = [
  { label: 'Diário', value: 'DIARIO' },
  { label: 'Mensal', value: 'MENSAL' },
];

export const ContractInputSelectType: React.FC<IContractInputSelectTypeProps> = ({
  onSelect,
  ...rest
}) => {
  return (
    <Select
      label="Tipo"
      defaultOption="MENSAL"
      dataOptions={options}
      onSelect={onSelect}
      {...rest}
    />
  );
};
