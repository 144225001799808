import { ISchemaApiError } from '../../../types';

export const UserSchemaApiError: ISchemaApiError[] = [
  {
    statusCode: 404,
    errors: [
      {
        error: 'customer not found',
        message: 'Cliente não localizado',
      },
      {
        error: 'employee not found',
        message: 'Colaborador não localizado',
      },
    ],
  },
  {
    statusCode: 400,
    errors: [
      {
        error: 'Username already registered for the user',
        message: 'E-mail já cadastrado',
        isPartial: true,
      },
    ],
  },
];
