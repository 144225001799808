/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

interface ISelectVehicleOwnerTypeProps extends FieldProps {
  defaultOption?: string;
  enableAll?: boolean;
  label?: string;
}

const options: ISelectOptions[] = [
  { label: 'Todos', value: '' },
  { label: 'Próprio', value: 'PROPRIO' },
  { label: 'Terceiro', value: 'TERCEIRO' },
];

export const SelectVehicleOwnerType: React.FC<ISelectVehicleOwnerTypeProps> = ({
  label = 'Tipo',
  enableAll = false,
  defaultOption = 'PROPRIO',
  ...rest
}) => {
  if (!enableAll) {
    const indexAll = options.findIndex((option) => option.label === 'Todos');
    if (indexAll > -1) options.splice(indexAll, 1);
  }
  return <Select label={label} defaultOption={defaultOption} dataOptions={options} {...rest} />;
};
