import { useVehicleSupply } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { VehicleSupplyMain } from './components/VehicleSupplyMain';

export const VehicleSupplyPage: React.FC = () => {
  const { toggleIsOpenFilterModalVehicleSupply } = useVehicleSupply();

  return (
    <PageContainer>
      <PageTitle
        title="Abastecimento"
        subtitle="Gerencie os abastecimentos"
        filterAction={toggleIsOpenFilterModalVehicleSupply}
      />

      <PageMain>
        <VehicleSupplyMain />
      </PageMain>
    </PageContainer>
  );
};
