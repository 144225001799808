/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useCustomer } from '../../../shared/hooks';

type ICustomerDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const CustomerDataGrid: React.FC<ICustomerDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsCustomer,
    rowsCustomer,
    rowCountCustomer,
    pageCustomer,
    handlePageCustomer,
    pageSizeCustomer,
    handlePageSizeCustomer,
    sortCustomer,
    filterCustomer,
    handleFilterCustomer,
    handleSortCustomer,
    getAllCustomer,
  } = useCustomer();

  useEffect(() => handleFilterCustomer({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllCustomer();
  }, [canSearch, sortCustomer, filterCustomer, pageCustomer, pageSizeCustomer]);

  return (
    <DataGrid
      rows={rowsCustomer}
      rowCount={rowCountCustomer}
      columns={columnsCustomer}
      onSortModelChange={handleSortCustomer}
      sortModel={sortCustomer}
      pageSize={pageSizeCustomer}
      onPageSizeChange={handlePageSizeCustomer}
      page={pageCustomer}
      onPageChange={handlePageCustomer}
      {...rest}
    />
  );
};
