import { FilterDialog, Input } from '../../../shared/components';
import { useFinancialCategory } from '../../../shared/hooks';

// Create modal filter for FinancialCategory
export const FinancialCategoryFilterModal: React.FC = () => {
  // Use context FinancialCategory
  const {
    isOpenFilterModalFinancialCategory,
    toggleIsOpenFilterModalFinancialCategory,
    handleFilterFinancialCategory,
    filterFinancialCategory,
    defaultFilterFinancialCategory,
  } = useFinancialCategory();

  return (
    <FilterDialog
      isOpen={isOpenFilterModalFinancialCategory}
      onClose={toggleIsOpenFilterModalFinancialCategory}
      setFilter={handleFilterFinancialCategory}
      filter={filterFinancialCategory}
      initialValues={defaultFilterFinancialCategory}
    >
      <Input autoFocus label="Nome" name="name" />
    </FilterDialog>
  );
};
