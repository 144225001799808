import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useDelivery, useDialog, useDeviceIsMobile } from '../../../shared/hooks';
import { IDeliveryFilter, IDataGridRowParams, IDataGridColumnsHidden } from '../../../shared/types';
import { DeliveryDataGrid } from './DeliveryDataGrid';
import { DeliveryFilterModal } from './DeliveryFilterModal';
import { DeliveryModal } from './DeliveryModal';

interface IDeliveryMainProps {
  embedded?: boolean;
  initialFilter?: Partial<IDeliveryFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {
  start_date: false,
  end_date: false,
  customer_id: false,
  driver_id: false,
};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {
  start_date: false,
  end_date: false,
  customer_id: false,
  driver_id: false,
  driver_name: false,
};

export const DeliveryMain: React.FC<IDeliveryMainProps> = ({ embedded, initialFilter }) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState<boolean>(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsDelivery,
    rowsDelivery,
    defaultDataDelivery,
    handleDataDelivery,
    selectionDelivery,
    handleSelectionDelivery,
    filterDelivery,
    hideFilterDelivery,
    handleFilterDelivery,
    getByIdDelivery,
    deleteIdsDelivery,
  } = useDelivery();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterDelivery(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsDelivery]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterDelivery({});
      return;
    }
    handleFilterDelivery({ ...filterDelivery, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataDelivery(defaultDataDelivery);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdDelivery(+id);
  };

  const handleDeleteData = () => {
    if (!selectionDelivery || selectionDelivery.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsDelivery(selectionDelivery as number[]);
      },
    );
  };

  return (
    <>
      <DeliveryFilterModal />
      <DeliveryModal />

      {!embedded && (
        <DataGridFilters
          columns={columnsDelivery}
          filters={filterDelivery}
          hideFilters={hideFilterDelivery}
          onRemoveFilter={handleRemoveFilter}
        />
      )}

      <DeliveryDataGrid
        selectionModel={selectionDelivery}
        onSelectionModelChange={handleSelectionDelivery}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionDelivery}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionDelivery?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
