/* eslint-disable @typescript-eslint/naming-convention */
import { Field } from 'formik';
import { Input, FilterDialog, DataPicker } from '../../../shared/components';
import { useMedicalLeave } from '../../../shared/hooks';
import { EmployeeInputSelectBase } from '../../employee/components/EmployeeInputSelectBase';

// Create modal filter for MedicalLeave
export const MedicalLeaveFilterModal: React.FC = () => {
  // Use context MedicalLeave
  const {
    isOpenFilterModalMedicalLeave,
    toggleIsOpenFilterModalMedicalLeave,
    handleFilterMedicalLeave,
    filterMedicalLeave,
    defaultFilterMedicalLeave,
  } = useMedicalLeave();

  return (
    <FilterDialog
      isOpen={isOpenFilterModalMedicalLeave}
      onClose={toggleIsOpenFilterModalMedicalLeave}
      setFilter={handleFilterMedicalLeave}
      filter={filterMedicalLeave}
      initialValues={defaultFilterMedicalLeave}
    >
      <EmployeeInputSelectBase />
      <Input label="Motivo" name="reason" />
      <Field name="start_leave" component={DataPicker} label="Início" />
      <Field name="end_leave" component={DataPicker} label="Fim" />
    </FilterDialog>
  );
};
