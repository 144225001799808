import {
  IApiResponseWithPagination,
  IContractList,
  IContractDTO,
  IContractResponse,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { ContractSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<IContractList> | ApiError> => {
  try {
    const response = await Api().get('contracts', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, ContractSchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<IContractResponse | ApiError> => {
  try {
    const response = await Api().get(`contracts/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, ContractSchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: IContractDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('contracts', payload, { headers });
    return response.data.contract_id;
  } catch (error: any) {
    return makeApiError(error, ContractSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: IContractDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`contracts/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, ContractSchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`contracts/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, ContractSchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('contracts/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, ContractSchemaApiError);
  }
};

export const ContractService = { getAll, getById, create, updateById, deleteById, deleteIds };
