import { createContext, useCallback, useMemo, useState } from 'react';
import { ThemeProvider as MuiThemeProvider, CssBaseline } from '@mui/material';
import { ThemeLight, ThemeDark } from '../themes';
import { ENV } from '../environment';

// Define type for theme name
type ThemeNameType = 'light' | 'dark' | '';

// Define interface for theme context
interface IThemeContextData {
  themeName: ThemeNameType;
  toggleTheme: () => void;
}

// Create context
export const ThemeContext = createContext({} as IThemeContextData);

// Crete theme provider
export const ThemeContextProvider: React.FC = ({ children }) => {
  // Define state controle for theme
  const [themeName, setThemeName] = useState<ThemeNameType>('');

  // Toggle theme
  const toggleTheme = useCallback(() => {
    setThemeName((prevState) => (prevState === 'dark' ? 'light' : 'dark'));
  }, []);

  // Set theme
  const theme = useMemo(() => {
    // Load theme from state
    let themeSaved = themeName;

    // if theme state is empty, try load from local storage
    if (themeSaved === '')
      themeSaved = localStorage.getItem(ENV.LOCAL_STORAGE.THEME) as ThemeNameType;

    // if not set theme in local storage, ses default theme
    if (themeSaved === '' || themeSaved === null) themeSaved = 'light';

    // Update state if initial values is empty
    if (themeName === '') setThemeName(themeSaved);

    // Update local storage
    localStorage.setItem(ENV.LOCAL_STORAGE.THEME, themeSaved);

    // return theme
    if (themeSaved === 'dark') return ThemeDark;
    return ThemeLight;
  }, [themeName]);

  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
