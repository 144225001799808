import { useSupplier } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { SupplierMain } from './components/SupplierMain';

export const SupplierPage: React.FC = () => {
  const { toggleIsOpenFilterModalSupplier } = useSupplier();

  return (
    <PageContainer>
      <PageTitle
        title="Fornecedores"
        subtitle="Gerencie o cadastro de fornecedores"
        filterAction={toggleIsOpenFilterModalSupplier}
      />

      <PageMain>
        <SupplierMain />
      </PageMain>
    </PageContainer>
  );
};
