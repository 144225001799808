/* eslint-disable react/jsx-props-no-spreading */
import { IconButton as MuiIconButton, IconButtonProps, Icon } from '@mui/material';
import { useLoader } from '../../hooks';
import { TooltipWrapper } from '../feedback/TooltipWrapper';

export interface IIconButtonProps extends IconButtonProps {
  icon?: string;
  tooltip?: string;
}

export const IconButton: React.FC<IIconButtonProps> = ({ icon, tooltip, children, ...rest }) => {
  // Use "hook" loader
  const isRunning = useLoader();

  const props: Partial<IIconButtonProps> = {
    ...rest,
    disabled: isRunning ? true : rest.disabled,
  };

  return (
    <TooltipWrapper title={tooltip}>
      <MuiIconButton {...props}>{icon ? <Icon>{icon}</Icon> : children}</MuiIconButton>
    </TooltipWrapper>
  );
};
