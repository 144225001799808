import { Delete, Save } from '@mui/icons-material';
import { Breakpoint, DialogActions, DialogContent } from '@mui/material';
import { useMemo } from 'react';
import { generateRandomFormId } from '../../utils';
import { Dialog, IDialogProps } from '../feedback';
import { DialogActionButton, FormSuite } from '../inputs';

// Define interface
interface IDataDialogProps extends Omit<IDialogProps, 'title'> {
  formId?: string;
  title?: string;
  referenceId?: number;
  referenceDisplayName?: string;
  isEmptyData?: boolean;
  initialValues?: object;
  validationSchema?: any;
  onSave: (data: object) => void;
  onDelete: (data: object) => void;
  withTabs?: boolean;
  size?: Breakpoint;
}

// Create component for dialog data
export const DataDialog: React.FC<IDataDialogProps> = ({
  formId,
  title,
  referenceId,
  referenceDisplayName = 'registro',
  initialValues = {},
  validationSchema,
  isEmptyData = referenceId === 0,
  onSave,
  onDelete,
  open,
  close,
  children,
  withTabs,
  size = 'sm',
}) => {
  // Get dialog title
  const dialogTitle = useMemo(() => {
    if (!open) return '';
    if (title) return title;
    return referenceId
      ? `Editar ${referenceDisplayName}: #${referenceId}`
      : `Adicionar ${referenceDisplayName}`;
  }, [open, title, referenceId]);

  // Get form id
  const form = formId || generateRandomFormId('data-dialog-form');

  return (
    <Dialog title={dialogTitle} open={open} close={close} maxWidth={size} fullWidth>
      {withTabs ? (
        children
      ) : (
        <DialogContent dividers>
          <FormSuite
            formId={form}
            initialValues={initialValues}
            validationSchema={validationSchema}
            submit={onSave}
          >
            {children}
          </FormSuite>
        </DialogContent>
      )}

      <DialogActions>
        {!isEmptyData ? (
          <DialogActionButton
            label="Excluir"
            tooltip="Excluir registro"
            startIcon={<Delete />}
            onClick={() => onDelete(initialValues)}
            sx={{ color: 'error' }}
            color="error"
          />
        ) : null}

        <DialogActionButton
          label="Gravar"
          tooltip="Gravar alterações"
          type="submit"
          primaryAction
          form={form}
          startIcon={<Save />}
          color="success"
        />
      </DialogActions>
    </Dialog>
  );
};
