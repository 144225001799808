import { IPeopleResource, IPeopleContactList, IPeopleContactDTO } from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { PeopleContactSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  peopleResource: IPeopleResource,
  peopleId: number,
): Promise<IPeopleContactList[] | ApiError> => {
  try {
    const response = await Api().get(`${peopleResource}/${peopleId}/contacts`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, PeopleContactSchemaApiError);
  }
};

// Create record
const create = async (
  headers: any,
  peopleResource: IPeopleResource,
  peopleId: number,
  payload: IPeopleContactDTO,
): Promise<number | ApiError> => {
  try {
    const response = await Api().post(`${peopleResource}/${peopleId}/contacts`, payload, {
      headers,
    });
    return response.data.business_type_id;
  } catch (error: any) {
    return makeApiError(error, PeopleContactSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  peopleResource: IPeopleResource,
  peopleId: number,
  id: number,
  payload: IPeopleContactDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`${peopleResource}/${peopleId}/contacts/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, PeopleContactSchemaApiError);
  }
};

// delete record by id
const deleteById = async (
  headers: any,
  peopleResource: IPeopleResource,
  peopleId: number,
  id: number,
): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`${peopleResource}/${peopleId}/contacts/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, PeopleContactSchemaApiError);
  }
};

export const PeopleContactService = { getAll, create, updateById, deleteById };
