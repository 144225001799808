/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useChangeLog, useDeviceIsMobile } from '../../../shared/hooks';

type IChangeLogDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const ChangeLogDataGrid: React.FC<IChangeLogDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsChangeLog,
    rowsChangeLog,
    rowCountChangeLog,
    pageChangeLog,
    handlePageChangeLog,
    pageSizeChangeLog,
    handlePageSizeChangeLog,
    filterChangeLog,
    defaultFilterChangeLog,
    handleFilterChangeLog,
    getAllChangeLog,
  } = useChangeLog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => handleFilterChangeLog(defaultFilterChangeLog), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllChangeLog();
  }, [canSearch, filterChangeLog, pageChangeLog, pageSizeChangeLog]);

  return (
    <DataGrid
      rows={rowsChangeLog}
      rowCount={rowCountChangeLog}
      columns={columnsChangeLog}
      pageSize={pageSizeChangeLog}
      onPageSizeChange={handlePageSizeChangeLog}
      page={pageChangeLog}
      onPageChange={handlePageChangeLog}
      getRowId={(row) => `${row.changed_at}_${row.user_nickname}`}
      rowHeight={deviceIsMobile ? 110 : 90}
      {...rest}
    />
  );
};
