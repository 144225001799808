/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

type ISelectFinancialCategoryTypeProps = FieldProps;

const options: ISelectOptions[] = [
  { label: 'Fixa', value: 'FIXA' },
  { label: 'Variável', value: 'VARIAVEL' },
];

export const SelectFinancialCategoryType: React.FC<ISelectFinancialCategoryTypeProps> = ({
  ...rest
}) => {
  return <Select label="Tipo" defaultOption="FIXA" dataOptions={options} {...rest} />;
};
