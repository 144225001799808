import {
  IApiResponseWithPagination,
  IInsurancePlanData,
  IInsurancePlanList,
  IInsurancePlanDTO,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { InsurancePlanSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<IInsurancePlanList> | ApiError> => {
  try {
    const response = await Api().get('/insurance-plans', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, InsurancePlanSchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<IInsurancePlanData | ApiError> => {
  try {
    const response = await Api().get(`/insurance-plans/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, InsurancePlanSchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: IInsurancePlanDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/insurance-plans', payload, { headers });
    return response.data.business_type_id;
  } catch (error: any) {
    return makeApiError(error, InsurancePlanSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: IInsurancePlanDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/insurance-plans/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, InsurancePlanSchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/insurance-plans/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, InsurancePlanSchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/insurance-plans/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, InsurancePlanSchemaApiError);
  }
};

export const InsurancePlanService = { getAll, getById, create, updateById, deleteById, deleteIds };
