import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { ChangeLogDataGrid } from './components/ChangeLogDataGrid';
import { ChangeLogFilter } from './components';

export const ChangeLogPage: React.FC = () => {
  return (
    <PageContainer>
      <PageTitle title="Logs" subtitle="Monitore as atividades" />

      <PageMain>
        <ChangeLogFilter />

        <ChangeLogDataGrid />
      </PageMain>
    </PageContainer>
  );
};
