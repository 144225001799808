import Metadata from '../../metadata.json';

export const getAppVersion = () => {
  const { buildMajor, buildMinor, buildRevision } = Metadata;

  return `${buildMajor}.${buildMinor}.${buildRevision}`;
};

export const getAppVersionTag = () => {
  const { buildTag } = Metadata;
  return buildTag;
};

export const getAppENV = () => {
  const { environment } = Metadata;
  return environment;
};

export const getApiBaseURL = () => {
  const { environment } = Metadata;
  if (environment === 'PROD') {
    return [
      'https://adm.guiaentrega.com.br:12100/lm-administrative/v1',
      'https://adm.guiaentrega.com.br:12100/lm-administrative/v1',
    ];
  }
  return [
    'http://10.10.10.200:9998/lm-administrative/v1',
    'http://dsatlink1.dyndns.info:9000/lm-administrative/v1',
  ];
};
