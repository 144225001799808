/* eslint-disable @typescript-eslint/naming-convention */
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Input,
  DataDialog,
  DataPicker,
  SelectInsurancePlanCoverageType,
  InputMask,
  InputCurrency,
} from '../../../shared/components';
import { useInsurancePlan, useDialog } from '../../../shared/hooks';
import { IInsurancePlanDTO, IInsurancePlanCoverageType } from '../../../shared/types';
import { schemaValidation, toNumberOrZero } from '../../../shared/utils';
import { EmployeeInputSelectBase } from '../../employee/components/EmployeeInputSelectBase';
import { VehicleInputSelectBase } from '../../vehicle/components/VehicleInputSelectBase';

// Define interface for modal
interface IInsurancePlanModalProps {
  fixedCoverageId?: number;
}

// Define validation fields form
const schemaPayloadInsurancePlan = Yup.object({
  coverage_detail: schemaValidation.commons.string(true),
  insurance_name: schemaValidation.commons.string(true),
  start_coverage: schemaValidation.dateTime.date(true),
  end_coverage: schemaValidation.dateTime
    .date(true)
    .min(Yup.ref('start_coverage'), 'Data final não pode ser inferior a inicial'),
});

// Create modal data for InsurancePlan
export const InsurancePlanModal: React.FC<IInsurancePlanModalProps> = ({ fixedCoverageId }) => {
  // State for insurance plan type
  const [coverageType, setCoverageType] = useState<IInsurancePlanCoverageType>('AUTO');

  // Use context InsurancePlan
  const {
    dataInsurancePlan,
    defaultDataInsurancePlan,
    handleDataInsurancePlan,
    createInsurancePlan,
    updateByIdInsurancePlan,
    deleteByIdInsurancePlan,
  } = useInsurancePlan();

  // On change coverage type
  const handleChangeCoverageType = (value: IInsurancePlanCoverageType) => setCoverageType(value);

  // Load initial data
  useEffect(() => {
    if (dataInsurancePlan === undefined) return;
    const coverage: IInsurancePlanCoverageType = dataInsurancePlan.type || 'AUTO';
    handleChangeCoverageType(coverage);
  }, [dataInsurancePlan]);

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Reset data and close modal
  const handleClose = () => handleDataInsurancePlan();

  // Save actions
  const handleSave = (payload: any) => {
    // Normalize payload
    const normalize = { ...payload };
    normalize.total = toNumberOrZero(payload.total);

    const id = dataInsurancePlan?.id || 0;
    const data = normalize as IInsurancePlanDTO;
    if (id > 0) {
      updateByIdInsurancePlan(id, data);
      return;
    }
    createInsurancePlan(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, coverage_detail } = payload;
    dialogConfirmDanger(
      'Excluir seguro?',
      `Deseja realmente excluir o plano de seguro: ${id} - ${coverage_detail} `,
      () => deleteByIdInsurancePlan(id),
    );
  };

  const fixedCoverage = fixedCoverageId && fixedCoverageId > 0;

  return (
    <DataDialog
      referenceId={dataInsurancePlan?.id}
      referenceDisplayName="seguro"
      initialValues={dataInsurancePlan || defaultDataInsurancePlan}
      validationSchema={schemaPayloadInsurancePlan}
      open={dataInsurancePlan !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      <input type="hidden" name="is_active" />
      <Grid container columnSpacing={2}>
        {fixedCoverage ? (
          <>
            <input type="hidden" name="type" />
            <input type="hidden" name="coverage_id" />
            <input type="hidden" name="coverage_detail" />
          </>
        ) : (
          <>
            <Grid item xs={12} md={3}>
              <Field
                name="type"
                component={SelectInsurancePlanCoverageType}
                value={coverageType}
                onSelect={handleChangeCoverageType}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              {coverageType === 'AUTO' && (
                <VehicleInputSelectBase
                  label="Assegurado (Veículo) *"
                  refId="coverage_id"
                  refName="coverage_detail"
                />
              )}
              {coverageType === 'VIDA' && (
                <EmployeeInputSelectBase
                  label="Assegurado (Colaborador) *"
                  refId="coverage_id"
                  refName="coverage_detail"
                />
              )}
            </Grid>
          </>
        )}

        <Grid item xs={12} md={8}>
          <Input label="Seguradora *" name="insurance_name" />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputMask
            label="Contato seguradora"
            name="insurance_phone"
            mask="phone"
            maxLength={14}
            inputMode="numeric"
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <Input label="Corretora" name="broker_name" />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputMask
            label="Contato corretora"
            name="broker_phone"
            mask="phone"
            maxLength={14}
            inputMode="numeric"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputCurrency label="Total cobertura" name="total" />
        </Grid>

        <Grid item xs={6} md={4}>
          <Field
            name="start_coverage"
            component={DataPicker}
            label="Início cobertura *"
            tooltip="Selecione o Início do afastamento"
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <Field
            name="end_coverage"
            component={DataPicker}
            label="Fim cobertura *"
            tooltip="Selecione o fim do afastamento"
          />
        </Grid>
      </Grid>
    </DataDialog>
  );
};
