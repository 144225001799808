/* eslint-disable @typescript-eslint/naming-convention */
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import {
  FilterDialog,
  DataPicker,
  SelectFinancialType,
  Input,
  SelectPeopleType,
} from '../../../shared/components';
import { useFinancial } from '../../../shared/hooks';
import { IPeopleType } from '../../../shared/types';
import { toCapitalize } from '../../../shared/utils';
import { CustomerInputSelectBase } from '../../customer/components';
import { EmployeeInputSelectBase } from '../../employee/components';
import { FinancialCategoryInputSelect } from '../../financial-category/components';
import { SupplierInputSelectBase } from '../../supplier/components';

// Create modal filter for Financial
export const FinancialFilterModal: React.FC = () => {
  // State people type
  const [peopleType, setPeopleType] = useState<IPeopleType>('CLIENTE');

  // Use context Financial
  const {
    isOpenFilterModalFinancial,
    toggleIsOpenFilterModalFinancial,
    handleFilterFinancial,
    filterFinancial,
    defaultFilterFinancial,
  } = useFinancial();

  // On change people type
  const handleChangePeopleType = (type: IPeopleType) => setPeopleType(type);

  // Load people type
  useEffect(() => {
    if (filterFinancial === undefined) return;
    const { people_type } = filterFinancial;
    handleChangePeopleType(people_type || 'CLIENTE');
  }, [filterFinancial]);

  return (
    <FilterDialog
      isOpen={isOpenFilterModalFinancial}
      onClose={toggleIsOpenFilterModalFinancial}
      setFilter={handleFilterFinancial}
      filter={filterFinancial}
      initialValues={defaultFilterFinancial}
    >
      <Field name="start_due_date" component={DataPicker} label="Início" />

      <Field name="end_due_date" component={DataPicker} label="Fim" />

      <Field name="type" component={SelectFinancialType} label="Tipo" enableAll defaultOption="" />

      <Input autoFocus label="Descrição" name="description" />

      <FinancialCategoryInputSelect />

      <Field
        name="people_type"
        component={SelectPeopleType}
        label="Pessoa"
        value={peopleType}
        onSelect={handleChangePeopleType}
      />

      {peopleType === 'CLIENTE' && (
        <CustomerInputSelectBase baseName="people" label={toCapitalize(peopleType)} />
      )}
      {peopleType === 'COLABORADOR' && <EmployeeInputSelectBase baseName="people" />}
      {peopleType === 'FORNECEDOR' && <SupplierInputSelectBase baseName="people" />}
    </FilterDialog>
  );
};
