/* eslint-disable @typescript-eslint/naming-convention */
import { DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { DataDialog, TabPanel } from '../../../shared/components';
import { VehicleInsurancePlan } from '../../../shared/components/vehicle-insurance-plan/VehicleInsurancePlan';
import { useVehicle, useDialog } from '../../../shared/hooks';
import { IPeopleType, IVehicleDTO } from '../../../shared/types';
import { schemaValidation } from '../../../shared/utils';
import { VehicleModalDataTab } from './VehicleModalDataTab';
import { VehicleModalTabs } from './VehicleModalTabs';

// Get form id
const formData = 'data-dialog-form-vehicle';

// Define interface for modal
interface IVehicleModalProps {
  fixedPeopleType?: IPeopleType;
  fixedPeopleId?: number;
}

// Define validation fields form
const schemaPayloadVehicle = Yup.object({
  type: Yup.string().required().oneOf(['PROPRIO', 'TERCEIRO']),
  plate: schemaValidation.vehicles.plate(true),
  model: schemaValidation.commons.string(true),
  owner_name: Yup.string().when('type', {
    is: 'TERCEIRO',
    then: schemaValidation.people.name,
  }),
});

// Create modal data for Vehicle
export const VehicleModal: React.FC<IVehicleModalProps> = ({ fixedPeopleId, fixedPeopleType }) => {
  // State for tab controls
  const [tabIndex, setTabIndex] = useState(0);

  // State for edit mode
  const [isEditMode, setIsEditMode] = useState(false);

  // Use context Vehicle
  const {
    dataVehicle,
    getAllVehicle,
    handleDataVehicle,
    createVehicle,
    updateByIdVehicle,
    deleteByIdVehicle,
  } = useVehicle();

  // Load initial data
  useEffect(() => {
    const hasVehicle = !!(dataVehicle && dataVehicle.id > 0);
    return setIsEditMode(hasVehicle);
  }, [dataVehicle]);

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Change tab index
  const handleChangeTabIndex = (event: React.SyntheticEvent, index: number) => setTabIndex(index);

  // Reset data and close modal
  const handleClose = () => {
    getAllVehicle();
    handleDataVehicle();
    if (tabIndex > 0) setTabIndex(0);
  };

  // Save actions
  const handleSave = (payload: any) => {
    // Normalize payload
    const normalize = { ...payload };

    if (normalize.type === 'PROPRIO') {
      delete normalize.owner_id;
      delete normalize.owner_type;
      delete normalize.owner_name;
    }

    const id = dataVehicle?.id || 0;
    const data = normalize as IVehicleDTO;
    if (id > 0) {
      updateByIdVehicle(id, data);
      return;
    }
    createVehicle(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, plate, model } = payload;
    dialogConfirmDanger(
      'Excluir veículo?',
      `Deseja realmente excluir o veículo: ${id} - ${plate}, ${model} `,
      () => deleteByIdVehicle(id),
    );
  };

  const fixedPeople = !!(fixedPeopleId && fixedPeopleType);

  return (
    <DataDialog
      referenceId={dataVehicle?.id}
      referenceDisplayName="veículo"
      initialValues={dataVehicle}
      validationSchema={schemaPayloadVehicle}
      open={dataVehicle !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
      formId={formData}
      withTabs
    >
      <VehicleModalTabs value={tabIndex} onChange={handleChangeTabIndex} isEditMode={isEditMode} />

      <DialogContent
        dividers
        sx={{
          backgroundColor: (theme) => {
            if (tabIndex === 0) return '';
            return theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900];
          },
        }}
      >
        <TabPanel index={0} tabActive={tabIndex} forceRender>
          <VehicleModalDataTab
            formId={formData}
            onSave={handleSave}
            isEditMode={isEditMode}
            fixedPeople={fixedPeople}
          />
        </TabPanel>

        <TabPanel index={1} tabActive={tabIndex}>
          <VehicleInsurancePlan VehicleId={dataVehicle?.id || 0} />
        </TabPanel>
      </DialogContent>
    </DataDialog>
  );
};
