import { Toolbar, useTheme } from '@mui/material';
import { MedicalServices } from '@mui/icons-material';
import { useEffect } from 'react';
import { IPeopleType } from '../../types/PeopleTypes';
import { Div } from '../display-data/Div';
import { Button } from '../inputs/Button';
import { MedicalLeaveModal, MedicalLeaveMain } from '../../../pages/medical-leave/components';
import { useMedicalLeave } from '../../hooks';

interface IPeopleMedicalLeaveProps {
  peopleType: IPeopleType;
  peopleId: number;
}

export const PeopleMedicalLeave: React.FC<IPeopleMedicalLeaveProps> = ({
  peopleId,
  peopleType,
}) => {
  const theme = useTheme();

  const { defaultDataMedicalLeave, handleDataMedicalLeave, handleClearStorageMedicalLeave } =
    useMedicalLeave();

  const handleAddData = () => {
    const data = defaultDataMedicalLeave;
    data.employee_id = peopleId;
    data.employee_name = 'NOVO ATESTADO';
    handleDataMedicalLeave(data);
  };

  useEffect(() => handleClearStorageMedicalLeave(), []);

  return (
    <Div>
      <MedicalLeaveModal fixedPeopleType={peopleType} fixedPeopleId={peopleId} />

      <Toolbar style={{ paddingRight: 0, paddingLeft: 0, marginBottom: theme.spacing(1) }}>
        <Div flex={1} />
        <Button
          label="Adicionar atestado"
          tooltip={`Adicionar atestado para o ${peopleType.toLowerCase()}`}
          startIcon={<MedicalServices />}
          onClick={handleAddData}
          disableFocusRipple
        />
      </Toolbar>

      <MedicalLeaveMain embedded initialFilter={{ employee_id: peopleId }} />
    </Div>
  );
};
