/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import { FieldProps, useField } from 'formik';
import { useEffect, useState } from 'react';
import { Chip, TooltipWrapper } from '../feedback';
import { ISelectOptions } from './Select';

interface IMultipleSelectProps extends FieldProps {
  label: string;
  dataOptions: ISelectOptions[];
  defaultOption: Array<number | string>;
  tooltip?: string;
}

export const MultipleSelect: React.FC<IMultipleSelectProps> = ({
  label,
  dataOptions,
  defaultOption,
  tooltip,
  form,
  field,
  ...rest
}) => {
  const [currentValue, setCurrentValue] = useState<Array<number | string>>([]);
  const [options] = useState<ISelectOptions[]>(dataOptions);

  const { name, value } = field;
  const { setFieldValue } = form;

  const meta = useField(name)[1];

  const handleGetValues = (data: any) => {
    if (!Array.isArray(data)) return [];
    if (data[0] === null || data[0] === '') data.shift();
    return data;
  };

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newValue = handleGetValues(event.target.value);
    setCurrentValue(newValue);
    setFieldValue(name, newValue);
  };

  useEffect(() => setCurrentValue(handleGetValues(value)), [value]);

  const handleRenderValue = (selected: any) => {
    if (!Array.isArray(selected)) return selected;

    const items: Array<number | string> = [];

    selected.sort().forEach((element) => {
      const data = options
        .filter((option) => option.value === element)
        .map((item) => item.label || item.value);
      if (data) items.push(...data);
    });

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, m: 0, p: 0 }}>
        {items.map((item) => (
          <Chip key={item} label={item} sx={{ m: 0 }} />
        ))}
      </Box>
    );
  };

  const props: Partial<SelectProps> = {
    name,
    value: currentValue,
    onChange: (event) => handleChange(event),
    multiple: true,
    input: <OutlinedInput label={label || 'Dia da semana'} />,
    renderValue: handleRenderValue,
    labelId: `${name}Label`,
    MenuProps: { tabIndex: -1 },
    sx: {
      '& .MuiOutlinedInput-input': {
        paddingY: currentValue.length > 0 ? '12px' : '15.5',
      },
      '& .MuiChip-root': {
        cursor: 'pointer',
      },
    },
    ...rest,
  };

  if (meta && meta.touched && meta.error) {
    props.error = true;
  }

  return (
    <TooltipWrapper title={tooltip}>
      <FormControl fullWidth margin="normal">
        <InputLabel id={`${name}Label`}>{label || 'Dia da semana'}</InputLabel>

        <MuiSelect {...props}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox
                checked={currentValue.filter((selected) => selected === option.value).length > 0}
              />
              <ListItemText primary={option.label || option.value} />
            </MenuItem>
          ))}
        </MuiSelect>

        <FormHelperText error={props.error}>{meta.error}</FormHelperText>
      </FormControl>
    </TooltipWrapper>
  );
};
