/* eslint-disable react/jsx-props-no-spreading */
import { Typography, TypographyProps } from '@mui/material';
import { useDeviceIsMobile } from '../../hooks/useDeviceIsMobile';

// Extend props MUI Typography
interface IPProps extends TypographyProps<'p'> {
  ellipsisOnMobile?: string;
  hiddenOnMobile?: boolean;
}

// Create component P Custom
export const P: React.FC<IPProps> = ({ hiddenOnMobile, ellipsisOnMobile, children, ...rest }) => {
  const deviceIsMobile = useDeviceIsMobile();

  const props: Partial<IPProps> = {};

  if (deviceIsMobile) {
    if (ellipsisOnMobile) {
      props.whiteSpace = 'nowrap';
      props.textOverflow = 'ellipsis';
      props.overflow = 'hidden';
      props.width = ellipsisOnMobile;
    }
    if (hiddenOnMobile) {
      props.hidden = true;
    }
  }

  return (
    <Typography component="p" {...props} {...rest}>
      {children}
    </Typography>
  );
};
