/* eslint-disable prefer-destructuring */
import { AxiosError } from 'axios';
import { isNetworkOrIdempotentRequestError } from 'axios-retry';
import { ENV } from '../../../environment';

export const retryRequestDelay = (attempts: number) => {
  console.log(`[${attempts}] fallback in progress...`);
  return ENV.API.INTERVAL_RETRY;
};

export const retryRequestCondition = (error: AxiosError<any, any>) => {
  let retry = false;

  if (error.message && error.message.includes('timeout')) retry = true;

  if (!retry) retry = isNetworkOrIdempotentRequestError(error);

  if (retry) localStorage.setItem(ENV.LOCAL_STORAGE.RETRY_REQUEST, 'true');

  return retry;
};

export const resolveBaseUrl = (): string => {
  const links = ENV.API.BASE_URL;

  let baseUrl = localStorage.getItem(ENV.LOCAL_STORAGE.BASE_URL) || links[0];
  const currentEnv = localStorage.getItem(ENV.LOCAL_STORAGE.ENV) || 'PROD';

  const needChangeUrl =
    !!localStorage.getItem(ENV.LOCAL_STORAGE.RETRY_REQUEST) || currentEnv !== ENV.APP.ENV;

  if (needChangeUrl) {
    let link = links.indexOf(baseUrl);
    if (link === -1) link = 0;

    if (link < links.length - 1) {
      baseUrl = links[link + 1];
    } else {
      baseUrl = links[0];
    }

    localStorage.removeItem(ENV.LOCAL_STORAGE.RETRY_REQUEST);
    localStorage.setItem(ENV.LOCAL_STORAGE.BASE_URL, baseUrl);
    localStorage.setItem(ENV.LOCAL_STORAGE.ENV, ENV.APP.ENV);
  }

  return baseUrl;
};
