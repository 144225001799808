import { useTheme } from '@mui/material';
import { Div } from '../components';

// Create componente for main content
export const PageMain: React.FC = ({ children }) => {
  const theme = useTheme();

  return (
    <Div component="main" sx={{ mb: theme.spacing(12) }}>
      {children}
    </Div>
  );
};
