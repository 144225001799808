/* eslint-disable @typescript-eslint/naming-convention */
import { Alert, Grid } from '@mui/material';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Input,
  DataDialog,
  SelectPeopleType,
  Checkbox,
  SelectUserRole,
} from '../../../shared/components';
import { useUser, useDialog } from '../../../shared/hooks';
import { IPeopleType, IUserDTO, IUserStatusDTO } from '../../../shared/types';
import { schemaValidation } from '../../../shared/utils';
import { CustomerInputSelectBase } from '../../customer/components/CustomerInputSelectBase';
import { EmployeeInputSelectBase } from '../../employee/components/EmployeeInputSelectBase';
import { UserToggleStatusButton } from './UserToggleStatusButton';

// Define interface for user modal
interface IUserModalProps {
  fixedPeopleType?: IPeopleType;
  fixedPeopleId?: number;
}

// Define validation fields form
const schemaPayloadUser = Yup.object({
  people_name: schemaValidation.people.name,
  username: schemaValidation.contact.email(true),
  role_id: schemaValidation.number.integer.min(1, 'Informe um registro'),
  administrative_platform: Yup.boolean(),
  driver_platform: Yup.boolean(),
  customer_platform: Yup.boolean(),
});

// Create modal data for User
export const UserModal: React.FC<IUserModalProps> = ({ fixedPeopleId, fixedPeopleType }) => {
  // State people type
  const [peopleType, setPeopleType] = useState<IPeopleType>('COLABORADOR');

  // Use context User
  const {
    dataUser,
    defaultDataUser,
    handleDataUser,
    createUser,
    updateByIdUser,
    deleteByIdUser,
    updateStatusByIdUser,
  } = useUser();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // On change people type
  const handleChangePeopleType = (type: IPeopleType) => setPeopleType(type);

  // Load people type
  useEffect(() => {
    if (dataUser === undefined) return;
    const type: IPeopleType = dataUser.type || 'COLABORADOR';
    handleChangePeopleType(type);
  }, [dataUser]);

  // Reset data and close modal
  const handleClose = () => handleDataUser();

  // Save actions
  const handleSave = (payload: object) => {
    const id = dataUser?.id || 0;
    const data = payload as IUserDTO;
    if (id > 0) {
      updateByIdUser(id, data);
      return;
    }
    createUser(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, people_name } = payload;
    dialogConfirmDanger(
      'Excluir usuário?',
      `Deseja realmente excluir o usuário: ${id} - ${people_name} `,
      () => deleteByIdUser(id),
    );
  };

  // Toggle status user
  const handleToggleStatusUser = (status: IUserStatusDTO) => {
    if (!dataUser) return;
    updateStatusByIdUser(dataUser?.id, status);
  };

  const fixedPeople = fixedPeopleId && fixedPeopleType;

  return (
    <DataDialog
      referenceId={dataUser?.id}
      referenceDisplayName="usuário"
      initialValues={dataUser || defaultDataUser}
      validationSchema={schemaPayloadUser}
      open={dataUser !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      <input type="hidden" name="is_active" />

      <Grid container columnSpacing={2}>
        {dataUser && dataUser.id > 0 ? (
          <Grid item xs={12} md={12}>
            <UserToggleStatusButton status={dataUser?.is_active} onClick={handleToggleStatusUser} />
          </Grid>
        ) : null}
        <Grid item xs={12} md={fixedPeople ? 12 : 7}>
          <Field name="role_id" component={SelectUserRole} />
        </Grid>

        {fixedPeople ? (
          <>
            <input type="hidden" name="type" />
            <input type="hidden" name="people_id" />
          </>
        ) : (
          <>
            <Grid item xs={12} md={5}>
              <Field
                name="type"
                component={SelectPeopleType}
                disableSupplier
                value={peopleType}
                onSelect={handleChangePeopleType}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {peopleType === 'CLIENTE' && (
                <CustomerInputSelectBase baseName="people" label="Cliente *" />
              )}
              {peopleType === 'COLABORADOR' && (
                <EmployeeInputSelectBase baseName="people" label="Colaborador *" />
              )}
            </Grid>
          </>
        )}
        <Grid item xs={12} md={12}>
          <Input autoFocus label="E-mail *" name="username" placeholder="E-mail de acesso" />
        </Grid>
        <Grid item xs={12} md={12}>
          <Alert severity="info" sx={{ my: 2 }}>
            Marque as plataformas em que este usuário será habilitado.
          </Alert>
        </Grid>
        {peopleType === 'COLABORADOR' && (
          <>
            <Grid item xs={12} md={12}>
              <Checkbox name="administrative_platform" label="(ADM) Plataforma administrativa" />
            </Grid>
            <Grid item xs={12} md={12}>
              <Checkbox name="driver_platform" label="(APP) APP do motorista" />
            </Grid>
          </>
        )}
        {peopleType === 'CLIENTE' && (
          <Grid item xs={12} md={12}>
            <Checkbox name="customer_platform" label="(CLI) Plataforma do cliente" />
          </Grid>
        )}
      </Grid>
    </DataDialog>
  );
};
