import { ICompanyData, ICompanyDTO } from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { CompanySchemaApiError } from '../schemas';

const getById = async (headers: any, id: number): Promise<ICompanyData | ApiError> => {
  try {
    const response = await Api().get(`/companies/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, CompanySchemaApiError);
  }
};

const updateById = async (
  headers: any,
  id: number,
  payload: ICompanyDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/companies/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, CompanySchemaApiError);
  }
};

export const CompanyService = { getById, updateById };
