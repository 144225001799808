/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import { FieldProps } from 'formik';
import { useEffect, useState } from 'react';
import { useLoader } from '../../hooks';
import { TooltipWrapper } from '../feedback';

export interface ISelectOptions {
  value: string | number;
  label?: string;
}

interface ISelectProps extends FieldProps {
  defaultOption: string | number;
  label: string;
  dataOptions: ISelectOptions[];
  tooltip?: string;
  onSelect?: (value: number | string) => void;
  disabled?: boolean;
}

export const Select: React.FC<ISelectProps> = ({
  label,
  defaultOption,
  dataOptions,
  tooltip,
  form,
  field,
  onSelect,
  ...rest
}) => {
  const [currentValue, setCurrentValue] = useState(defaultOption);
  const [options] = useState<ISelectOptions[]>(dataOptions);

  // Use "hook" loader
  const isRunning = useLoader();

  const { name, value } = field;
  const { setFieldValue } = form;

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newValue = event.target.value as typeof defaultOption;
    setCurrentValue(newValue);
    setFieldValue(name, newValue);
    if (onSelect) onSelect(newValue);
  };

  useEffect(() => setCurrentValue(value || defaultOption), [value]);

  const props: Partial<SelectProps> = {
    name,
    label,
    value: currentValue,
    labelId: `${name}Label`,
    onChange: (event) => handleChange(event),
    sx: {
      '& .Mui-disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'all !important',
      },
    },
    ...rest,
    disabled: isRunning ? true : rest.disabled,
  };

  return (
    <TooltipWrapper title={tooltip}>
      <FormControl fullWidth margin="normal">
        <InputLabel id={`${name}Label`}>{label}</InputLabel>
        <MuiSelect {...props} MenuProps={{ tabIndex: -1 }}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label || option.value}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </TooltipWrapper>
  );
};
