import { Business } from '@mui/icons-material';
import { Card, CardActions, CardContent, Grid, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { Button, Div, H1, P, Span } from '../../../shared/components';
import { useAuth } from '../../../shared/hooks';
import { useCompaniesAvailableModalStore } from '../../../shared/store';

// Create card company
export const CardCompany: React.FC = () => {
  // use hook company
  const theme = useTheme();

  // Use auth
  const { user } = useAuth();

  // Use store
  const { openCompaniesAvailableModal } = useCompaniesAvailableModalStore();

  // Companies available
  const handleCompaniesAvailableClick = useCallback(() => openCompaniesAvailableModal(), []);

  return (
    <Grid item lg={8} sm={7} xl={6} xs={12}>
      <Card
        sx={{
          height: '100%',
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.palette.primary.light
              : theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }}
      >
        <CardContent>
          <Div isFlexRow alignItems="center">
            <Business
              sx={{
                height: '70px',
                width: '70px',
                mr: 2,
                color:
                  theme.palette.mode === 'light'
                    ? theme.palette.primary.main
                    : theme.palette.primary.dark,
              }}
            />
            <Div fillInFlex>
              <Span>Empresa atual</Span>
              <H1 variant="h5">{user.payload?.companies.name}</H1>
            </Div>
          </Div>
        </CardContent>

        <CardContent>
          <P>
            É possível alternar de forma rápida entre as empresas cadastradas utilizando o menu
            principal.
          </P>
        </CardContent>

        <CardActions>
          <Button
            color="inherit"
            variant="text"
            label="Acessar outra empresa"
            onClick={handleCompaniesAvailableClick}
          />
        </CardActions>
      </Card>
    </Grid>
  );
};
