import create from 'zustand';

// Define store interface
interface IPinModalStore {
  isOpen: boolean;
  resendBlocked: boolean;
  resent: boolean;
  closePinModal: () => void;
}

// Create store
export const usePinModalStore = create<IPinModalStore>((set) => ({
  isOpen: false,
  resent: false,
  resendBlocked: false,
  closePinModal: () => set({ isOpen: false, resent: false, resendBlocked: false }),
}));

// Export function for implementation
export const openPinModal = (status?: any) => {
  // Default state pin control
  let resentStatus = false;
  let resendBlockedStatus = false;

  // Get state from param
  if (typeof status === 'string') {
    resendBlockedStatus = status.includes('bloqueado');
    resentStatus = status.includes('resent');
  }

  // Update PIN state
  usePinModalStore.setState({
    isOpen: true,
    resent: resentStatus,
    resendBlocked: resendBlockedStatus,
  });
};
