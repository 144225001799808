import {
  IApiResponseWithPagination,
  ICustomerData,
  ICustomerList,
  ICustomerDTO,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { CustomerSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<ICustomerList> | ApiError> => {
  try {
    const response = await Api().get('/customers', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, CustomerSchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<ICustomerData | ApiError> => {
  try {
    const response = await Api().get(`/customers/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, CustomerSchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: ICustomerDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/customers', payload, { headers });
    return response.data.customer_id;
  } catch (error: any) {
    return makeApiError(error, CustomerSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: ICustomerDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/customers/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, CustomerSchemaApiError);
  }
};

// Update contract by id
const updateContractById = async (
  headers: any,
  customer: number,
  contract: number,
): Promise<boolean | ApiError> => {
  try {
    await Api().patch(`/customers/${customer}/contracts/${contract}`, {}, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, CustomerSchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/customers/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, CustomerSchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/customers/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, CustomerSchemaApiError);
  }
};

export const CustomerService = {
  getAll,
  getById,
  create,
  updateById,
  updateContractById,
  deleteById,
  deleteIds,
};
