/* eslint-disable react/jsx-props-no-spreading */
import { InputAdornment } from '@mui/material';
import { useField } from 'formik';
import { useEffect, useMemo, useCallback, MouseEvent } from 'react';
import { toNumberOrZero } from '../../utils';
import { IconButton } from './IconButton';
import { Input, IInputProps } from './Input';

type IInputSelectProps = Omit<IInputProps, 'name' | 'onClick'> & {
  fieldId: string;
  fieldName: string;
  onClick: (event: MouseEvent<any>) => void;
  disabledClear?: boolean;
};

export const InputSelect: React.FC<IInputSelectProps> = ({
  fieldId,
  fieldName,
  disabled,
  onClick,
  disabledClear = false,
  ...rest
}) => {
  const [propsId, , helperId] = useField(fieldId);
  const [propsName, metaName, helperName] = useField(fieldName);

  // Clear name if id is empty
  useEffect(() => {
    if (propsName.value && propsName.value !== '' && !metaName.touched) {
      if (toNumberOrZero(propsId.value) === 0) helperName.setValue('');
      helperName.setTouched(true);
    }
  }, []);

  // Clear values
  const handleClear = useCallback(() => {
    helperId.setValue(0);
    helperName.setValue('');
  }, [propsId, propsName]);

  // Check if have value
  const showClearButton = useMemo(
    () => !disabledClear && propsId.value && propsId.value > 0,
    [propsId, disabledClear],
  );

  // Create end icon
  const getEndAdornment = useMemo(() => {
    if (disabled) return <IconButton disableRipple icon="block" edge="end" />;
    if (!showClearButton) return <IconButton disableRipple icon="arrow_drop_down" edge="end" />;
    return (
      <IconButton
        disableRipple
        icon={disabled ? 'block' : 'cancel'}
        edge="end"
        onClick={handleClear}
        tooltip="Limpar"
      />
    );
  }, [propsName, disabled, showClearButton]);

  return (
    <>
      <input type="hidden" name={fieldId} />

      <Input
        name={fieldName}
        disabled={disabled}
        sx={{
          '& .MuiOutlinedInput-input': {
            cursor: disabled ? 'not-allowed' : 'pointer',
          },
        }}
        InputProps={{
          inputProps: {
            onClick: (e) => showClearButton && onClick(e),
          },
          endAdornment: <InputAdornment position="end">{getEndAdornment}</InputAdornment>,
          readOnly: true,
        }}
        {...rest}
        onClick={(e) => !showClearButton && onClick(e)}
      />
    </>
  );
};
