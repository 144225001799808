import { AccessTimeOutlined, CheckCircle, Delete, PauseCircle } from '@mui/icons-material';
import { ReactNode } from 'react';
import { Chip } from './Chip';

interface IInfoStatusRecordProps {
  status: boolean | string;
  rederOnFalse?: ReactNode;
  rederOnTrue?: ReactNode;
}

export const InfoStatusRecord: React.FC<IInfoStatusRecordProps> = ({
  status,
  rederOnFalse,
  rederOnTrue,
}) => {
  return (
    <>
      {(status === false || status === 'I' || status === 'INACTIVE') &&
        (rederOnFalse || (
          <Chip
            label="Inativo"
            tooltip="Este registro está inativo"
            icon={<PauseCircle />}
            color="default"
            size="small"
          />
        ))}
      {(status === true || status === 'A' || status === 'ACTIVE') &&
        (rederOnTrue || (
          <Chip
            label="Ativo"
            tooltip="Este registro está ativo"
            icon={<CheckCircle />}
            color="success"
            size="small"
          />
        ))}
      {(status === 'P' || status === 'AWAITING_APPROVAL') &&
        (rederOnTrue || (
          <Chip
            label="Analise"
            tooltip="Este registro está aguardando aprovação"
            icon={<AccessTimeOutlined />}
            color="warning"
            size="small"
          />
        ))}
      {status === 'E' && (
        <Chip
          label="Excluído"
          tooltip="Este registro foi excluído"
          icon={<Delete />}
          color="error"
          size="small"
        />
      )}
    </>
  );
};
