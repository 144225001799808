import {
  IApiResponseWithPagination,
  IVehicleUsageData,
  IVehicleUsageList,
  IVehicleUsageDTO,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { VehicleUsageSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<IVehicleUsageList> | ApiError> => {
  try {
    const response = await Api().get('/vehicles/usages', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, VehicleUsageSchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<IVehicleUsageData | ApiError> => {
  try {
    const response = await Api().get(`/vehicles/usages/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, VehicleUsageSchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: IVehicleUsageDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/vehicles/usages', payload, { headers });
    return response.data.vehicle_usage_id;
  } catch (error: any) {
    return makeApiError(error, VehicleUsageSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: IVehicleUsageDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/vehicles/usages/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, VehicleUsageSchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/vehicles/usages/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, VehicleUsageSchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/vehicles/usages/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, VehicleUsageSchemaApiError);
  }
};

export const VehicleUsageService = {
  getAll,
  getById,
  create,
  updateById,
  deleteById,
  deleteIds,
};
