/* eslint-disable @typescript-eslint/naming-convention */
import {
  Alert,
  AlertTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Switch,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Dialog } from '../../../shared/components';
import { useUser, useDialog, useAuth } from '../../../shared/hooks';
import { IUserChangeTwoStepAuthDTO } from '../../../shared/types';

interface IUserPerfilTwoStepAuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

// Create modal data for User
export const UserPerfilTwoStepAuthModal: React.FC<IUserPerfilTwoStepAuthModalProps> = ({
  isOpen,
  onClose,
}) => {
  // Use context User
  const { updateTwoStepAuthCurrentUser } = useUser();

  // Use auth
  const { user } = useAuth();

  // State for two step auth
  const [isActive, setIsActive] = useState(false);

  // Use hook dialog
  const { dialogConfirmGrant, dialogConfirmDanger } = useDialog();

  // Use theme
  const theme = useTheme();

  // Load initial data
  useEffect(() => {
    if (!isOpen || !user) return;
    setIsActive(!!(user.pin && user.pin !== '' && user.pin !== 'Not applicable'));
  }, [user, isOpen]);

  // Update state
  const handleUpdate = (state: boolean) => {
    const data: IUserChangeTwoStepAuthDTO = state ? 'ENABLE' : 'DISABLE';
    updateTwoStepAuthCurrentUser(data);
  };

  // Change
  const handleToggle = (state: boolean) => {
    if (state) {
      dialogConfirmGrant('Ativar', 'Deseja ativar a autenticação em duas etapas?', () =>
        handleUpdate(state),
      );
    } else {
      dialogConfirmDanger('Desativar', 'Deseja desativar a autenticação em duas etapas?', () =>
        handleUpdate(state),
      );
    }
  };

  return (
    <Dialog
      title="Autenticação em duas etapas"
      open={isOpen}
      close={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent dividers>
        <Alert severity="info" sx={{ my: theme.spacing(1) }}>
          <AlertTitle>O que é?</AlertTitle>A autenticação em duas etapas serve para aumentar o nível
          de segurança do seu acesso. Uma vez ativada, sempre que realizar o login em um novo
          dispositivo, será necessário informar o número PIN recebido em seu e-mail
        </Alert>

        <List sx={{ width: '100%' }}>
          <ListItem>
            <ListItemText
              primary="Usar autenticação em duas etapas?"
              secondary="É necessário realizar o logoff"
            />
            <Switch
              edge="end"
              onChange={(_, checked) => handleToggle(checked)}
              checked={isActive}
            />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
};
