/* eslint-disable react/jsx-props-no-spreading */
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { InputSelect, SelectDialog, IInputProps } from '../../../shared/components';
import { useUser } from '../../../shared/hooks';
import { IDataGridColumnsHidden, IDataGridRowParams } from '../../../shared/types';
import { UserDataGrid } from './UserDataGrid';
import { UserFilterModal } from './UserFilterModal';
import { UserModal } from './UserModal';

interface IUserInputSelectProps extends Omit<IInputProps, 'name' | 'onSelect'> {
  label?: string;
  title?: string;
  baseName?: string;
}

const defaultBaseName = 'user';

const defaultTitle = 'Usuários';

const hiddenColumnsUser: IDataGridColumnsHidden = {
  is_active: false,
  people_id: false,
  administrative_platform: false,
  driver_platform: false,
  customer_platform: false,
  username: false,
  role_name: false,
};

export const UserInputSelect: React.FC<IUserInputSelectProps> = ({
  label = defaultTitle,
  title = defaultTitle,
  baseName = defaultBaseName,
  disabled,
  ...rest
}) => {
  // Define name
  const [fieldId] = useState(`${baseName}_id`);
  const [fieldName] = useState(`${baseName}_name`);

  // State for control opened search dialog
  const [isOpen, setIsOpen] = useState(false);

  // Use hook formik context
  const { setFieldValue } = useFormikContext();

  // Use context
  const { toggleIsOpenFilterModalUser, handleFilterUser, defaultDataUser, handleDataUser } =
    useUser();

  // Open search dialog
  const handleOpen = () => {
    if (disabled) return;
    setIsOpen(true);
  };

  // Close search modal
  const handleClose = () => setIsOpen(false);

  // On click in add button
  const handleAdd = () => handleDataUser(defaultDataUser);

  // On click in filter button
  const handleResetFilter = () => handleFilterUser({});

  // Apply filters
  const handleApplyFilterName = (filter: any) => handleFilterUser({ username: filter });

  // On select record
  const handleSelect = (data: IDataGridRowParams) => {
    setFieldValue(fieldId, data.row.id);
    setFieldValue(fieldName, data.row.people_name);
    handleClose();
  };

  return (
    <>
      <UserModal />
      <UserFilterModal />

      <SelectDialog
        title={title}
        open={isOpen}
        onClose={handleClose}
        applyFilter={handleApplyFilterName}
        showFilter={toggleIsOpenFilterModalUser}
        resetFilter={handleResetFilter}
        add={handleAdd}
      >
        <UserDataGrid
          simpleMode
          onRowClick={handleSelect}
          columnVisibilityModel={hiddenColumnsUser}
        />
      </SelectDialog>

      <InputSelect
        label={label}
        fieldId={fieldId}
        fieldName={fieldName}
        onClick={handleOpen}
        disabled={disabled}
        {...rest}
      />
    </>
  );
};
