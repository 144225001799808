/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/require-default-props */
import {
  AddLocation,
  Business,
  Delete,
  House,
  LocationOn,
  MarkunreadMailbox,
  Paid,
} from '@mui/icons-material';
import {
  Alert,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  IPeopleAddressData,
  IPeopleAddressList,
  IPeopleContactType,
  IPeopleType,
} from '../../types';
import { Button, IconButton } from '../inputs';
import { Div } from '../display-data';
import { useAuth, useDialog, usePeopleAddress } from '../../hooks';
import { PeopleAddressModal } from './PeopleAddressModal';

interface IPeopleAddressProps {
  peopleType: IPeopleType;
  peopleId: number;
  initialData?: IPeopleAddressList[];
}

export const PeopleAddress: React.FC<IPeopleAddressProps> = ({
  peopleType,
  peopleId,
  initialData,
}) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const { dialogConfirmDanger } = useDialog();

  const {
    rowsPeopleAddress,
    defaultDataPeopleAddress,
    handleDataPeopleAddress,
    HandleLoadStoragePeopleAddress,
    getAllPeopleAddress,
    deleteByIdPeopleAddress,
  } = usePeopleAddress();

  const theme = useTheme();

  useEffect(() => {
    if (!user.token || canSearch) return;
    if (initialData) {
      HandleLoadStoragePeopleAddress(initialData);
      return;
    }
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllPeopleAddress(peopleType, peopleId);
  }, [canSearch]);

  const handleAddData = () => handleDataPeopleAddress(defaultDataPeopleAddress);

  const handleEditData = (data: IPeopleAddressData) => handleDataPeopleAddress(data);

  const handleDeleteData = (data: IPeopleAddressData) => {
    const { id, street_name } = data;
    dialogConfirmDanger(
      'Excluir endereço?',
      `Deseja realmente excluir o endereço: ${street_name}`,
      () => deleteByIdPeopleAddress(peopleType, peopleId, id),
    );
  };

  useEffect(() => {
    return () => {
      handleDataPeopleAddress();
      getAllPeopleAddress(peopleType, 0);
    };
  }, []);

  const getIconAddress = (type: IPeopleContactType) => {
    if (type === 'COMERCIAL') return <Business />;
    if (type === 'RESIDENCIAL') return <House />;
    if (type === 'COBRANCA') return <Paid />;
    if (type === 'CORRESPONDENCIA') return <MarkunreadMailbox />;
    return <LocationOn />;
  };

  return (
    <Div>
      <PeopleAddressModal peopleType={peopleType} peopleId={peopleId} />

      <Toolbar style={{ paddingRight: 0, paddingLeft: 0, marginBottom: theme.spacing(1) }}>
        <Div flex={1} />
        <Button
          label="Adicionar endereço"
          tooltip={`Adicionar endereço para o ${peopleType.toLowerCase()}`}
          startIcon={<AddLocation />}
          onClick={handleAddData}
          disableFocusRipple
        />
      </Toolbar>

      {rowsPeopleAddress.length === 0 ? (
        <Alert severity="info" variant="outlined">
          {`Este ${peopleType.toLowerCase()} ainda não possuí endereço cadastrado, clique em `}
          <strong>ADICIONAR ENDEREÇO</strong> para incluir um registro.
        </Alert>
      ) : (
        <List sx={{ width: '100%' }}>
          {rowsPeopleAddress.map((address) => (
            <Paper
              key={address.id}
              elevation={2}
              sx={{
                mb: theme.spacing(1),
                cursor: 'pointer',
                bgcolor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : null,
                '& .MuiListItem-root:hover': {
                  bgcolor:
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[800],
                },
              }}
            >
              <ListItem
                sx={{
                  borderRadius: '4px',
                  '& .MuiListItemButton-root:hover': {
                    bgcolor: 'inherit',
                  },
                }}
                disablePadding
                alignItems="flex-start"
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    tooltip="Deletar endereço"
                    onClick={() => handleDeleteData(address)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemButton
                  onClick={() => handleEditData(address)}
                  disableRipple
                  disableTouchRipple
                  disableGutters
                  sx={{ px: theme.spacing(2), py: theme.spacing(1) }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: address.is_default
                          ? theme.palette.secondary.main
                          : theme.palette.primary.main,
                      }}
                    >
                      {getIconAddress(address.tag || '')}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${address.street_name}, ${address.street_number}`}
                    secondary={
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {`${address.neighborhood} - ${address.city}/${address.state}`}
                        <br />
                        {address.postal_code}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </Paper>
          ))}
        </List>
      )}
    </Div>
  );
};
