/* eslint-disable react/jsx-props-no-spreading */
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Icon,
  ListItemIcon,
  ListItemText,
  InputBase,
  styled,
  alpha,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMenu } from '../../hooks';

// Style component for search
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

// Style component for icon wrapper
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// Style component for Input base
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

// Create SearchTopBar component
export const SearchTopBar: React.FC = () => {
  // Use menu
  const { optionsRoute } = useMenu();

  // Use navigate
  const navigate = useNavigate();

  // onChange filter
  const handleChange = (val: any) => {
    if (val) {
      navigate(val.to);
    }
  };

  // onChange filter
  const handleClick = (link: string) => navigate(link);

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <Autocomplete
        disableClearable
        options={optionsRoute}
        onChange={(e, val) => handleChange(val)}
        renderOption={(props, option) => (
          <Box component="li" {...props} onClick={() => handleClick(option.to)}>
            <ListItemIcon>
              <Icon>{option.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </Box>
        )}
        renderInput={(params) => (
          <StyledInputBase
            placeholder="Pesquisar..."
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            fullWidth
          />
        )}
      />
    </Search>
  );
};
