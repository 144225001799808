/* eslint-disable @typescript-eslint/naming-convention */
// Context for shared status Auth data
import { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useJwt } from 'react-jwt';
import { AuthService, ApiError } from '../services';
import { IAuthUserPayload, IAuthSignInPayload } from '../types';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { openPinModal, usePinModalStore } from '../store';
import { updatePartialStateObject } from '../utils';
import { ENV } from '../environment';

// Define context interface
interface IAuthContext {
  singIn: (payload?: Partial<IAuthSignInPayload>) => void;
  logout: (redirectToLogin?: boolean) => void;
  rememberUser: string;
  user: IAuthUserPayload;
}

// Create context
export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

// Create context provider
export const AuthContextProvider: React.FC = ({ children }) => {
  // Persist state data
  const [signInPayload, setSignInPayload] = useState({} as IAuthSignInPayload);
  const [user, setUser] = useState<IAuthUserPayload>({} as IAuthUserPayload);
  const [token, setToken] = useState<string | null>(null);
  const [rememberUser, setRememberUser] = useState('');

  // Use hook snackbar
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // use pin store
  const { closePinModal } = usePinModalStore();

  // Use navigate to
  const navigate = useNavigate();

  // Use react-jwt
  const { decodedToken } = useJwt(token!);

  // Update data for login payload
  const updateSignInPayload = (value?: Partial<IAuthSignInPayload>) =>
    updatePartialStateObject<IAuthSignInPayload>(signInPayload, setSignInPayload, value);

  // Reset PIN local storage
  const clearPinLocalStorage = useCallback(
    () => localStorage.removeItem(ENV.LOCAL_STORAGE.PIN),
    [],
  );

  // Reset user data from local storage
  const clearTokenLocalStorage = useCallback(
    () => localStorage.removeItem(ENV.LOCAL_STORAGE.TOKEN),
    [],
  );

  // Get token access
  const singIn = (payload?: Partial<IAuthSignInPayload>) => {
    // Updated sign in payload with params
    const payloadUpdated = updateSignInPayload(payload);

    // If has received pin in params, set has activation pin
    const activatePin = !!payload?.pin;

    if (activatePin) {
      // IF activation, clear old value in local storage
      clearPinLocalStorage();
    } else {
      // Check if PIN is defined in local storage
      const pinLocalStorage = localStorage.getItem(ENV.LOCAL_STORAGE.PIN);
      // If defined, update payload sign in
      if (pinLocalStorage) payloadUpdated.pin = pinLocalStorage;
    }

    // Send request with payload from state
    AuthService.singIn(payloadUpdated, activatePin).then((response) => {
      // Check if request send error
      if (response instanceof ApiError) {
        // If error is blocked PIN, open modal
        if (response.message.includes('PIN bloqueado')) {
          openPinModal(response.message);
          return;
        }
        // Show error
        snackbarError(response.message);
        return;
      }
      // Check PIN create and resend by email
      if (response.status === 201) {
        // If create new pin, reset olds from local storage
        clearPinLocalStorage();
        openPinModal(response.data.status);
        return;
      }
      // if response
      if (response.data) {
        // Set token from response
        setToken(response.data.token);
        // Update payload and local storage with token data
        localStorage.setItem(ENV.LOCAL_STORAGE.TOKEN, response.data.token);

        if (activatePin) {
          // Update payload and local storage with pin data
          localStorage.setItem(ENV.LOCAL_STORAGE.PIN, payload.pin!);
          // close modal PIN
          closePinModal();
        }

        // Remember user e-mail
        if (payload?.remember) {
          setRememberUser(payload.username!);
          localStorage.setItem(ENV.LOCAL_STORAGE.REMEMBER_USER, payload.username!);
        } else {
          setRememberUser('');
          localStorage.removeItem(ENV.LOCAL_STORAGE.REMEMBER_USER);
        }

        // Navigate user to home page
        navigate('/pagina-inicial');
        return;
      }
      // Unknown response
      snackbarError('Ops, ocorreu um erro! Tente novamente');
    });
  };

  // Execute logout of user
  const logout = useCallback((redirectToLogin?: boolean) => {
    // Clear user data
    setUser({} as IAuthUserPayload);

    // Clear token
    clearTokenLocalStorage();
    setToken(null);

    if (!redirectToLogin) return;

    snackbarSuccess('Sessão encerrada!');
    navigate('/login');
  }, []);

  // Ou create, load data
  useEffect(() => {
    // Load remember user
    setRememberUser(localStorage.getItem(ENV.LOCAL_STORAGE.REMEMBER_USER) || '');
    // Load token
    setToken(localStorage.getItem(ENV.LOCAL_STORAGE.TOKEN) || null);
  }, []);

  // Decode token JWT and get payload
  useEffect(() => {
    // If token existis
    if (decodedToken) {
      // Load data
      const tokenPayload = decodedToken as any;

      // Extract user data
      const { sub, iss, iat, exp, nickname, email, pin } = tokenPayload;

      // Extract available companies
      const companiesAvailable = JSON.parse(tokenPayload.companies);

      // Only record
      const {
        company_id,
        company_name,
        company_address_city,
        company_address_state,
        role_id,
        role_name,
      } = companiesAvailable[0];

      // Build user payload data
      const authData: IAuthUserPayload = {
        token,
        pin,
        payload: {
          sub,
          iss,
          iat,
          exp,
          nickname,
          email,
          companies: {
            id: company_id,
            name: company_name,
            address: {
              city: company_address_city,
              state: company_address_state,
            },
            role: {
              id: role_id,
              name: role_name,
            },
          },
        },
      };

      setUser(authData);
    }
  }, [decodedToken]);

  return (
    <AuthContext.Provider value={{ singIn, logout, rememberUser, user }}>
      {children}
    </AuthContext.Provider>
  );
};
