/* eslint-disable react/jsx-props-no-spreading */
import { Grid, useTheme } from '@mui/material';
import { Div } from '../../shared/components';
import { useAuth } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { CardCompany, CardThemeDark, CardThemeLight } from './components';

// Create component Home
export const HomePage: React.FC = () => {
  // Check theme atual
  const theme = useTheme();

  // Use auth
  const { user } = useAuth();

  return (
    <PageContainer>
      <PageTitle title="Página inicial" subtitle={`Bem vindo ${user.payload?.nickname} !`}>
        <Div sx={{ flexGrow: 1 }} />
      </PageTitle>

      <PageMain>
        <Grid container spacing={2}>
          <CardCompany />
          {theme.palette.mode === 'light' ? <CardThemeDark /> : <CardThemeLight />}
        </Grid>
      </PageMain>
    </PageContainer>
  );
};
