import { AccountCircle, Login, Key } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Form, Input, Checkbox } from '../../../shared/components';
import { openRecoverPasswordModal } from '../../../shared/store';
import { IAuthSignInPayload } from '../../../shared/types';
import { useAuth } from '../../../shared/hooks';

// Form with inputs for request login
export const LoginForm: React.FC = () => {
  // Use hook Auth
  const { singIn, rememberUser } = useAuth();

  // check if username is set as remember in local storage
  const lastUserRemember = rememberUser || '';

  // Set default payload
  const defaultLoginPayload: IAuthSignInPayload = {
    username: lastUserRemember || '',
    password: '',
    remember: lastUserRemember !== '',
  };

  // Set validation schema
  const schemaLoginPayload = Yup.object({
    username: Yup.string().email('Informe um e-mail válido').required('O e-mail é obrigatório'),
    password: Yup.string().required('A senha é obrigatória'),
    remember: Yup.boolean(),
  });

  // Authentication user data
  const handleSingIn = (payload: IAuthSignInPayload) => {
    singIn(payload);
  };

  // Open recover password modal
  const handleOpenRecoverPasswordModal = () => openRecoverPasswordModal();

  return (
    <Formik
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={defaultLoginPayload}
      validationSchema={schemaLoginPayload}
      onSubmit={(values) => {
        handleSingIn(values);
        // actions.resetForm();
      }}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Input
            autoFocus
            label="E-mail"
            name="username"
            type="email"
            placeholder="Digite o e-mail cadastrado"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />

          <Input
            label="Senha"
            name="password"
            type="password"
            placeholder="Digite sua senha"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Key />
                </InputAdornment>
              ),
            }}
          />

          <Checkbox name="remember" label="Lembrar meu e-mail" />

          <Button
            label="Acessar"
            size="large"
            fullWidth
            type="submit"
            sx={{ mt: 3, mb: 2 }}
            startIcon={<Login />}
          />

          <Button
            label="Não lembra sua senha?"
            fullWidth
            variant="text"
            disableFocusRipple
            onClick={handleOpenRecoverPasswordModal}
          />
        </Form>
      )}
    </Formik>
  );
};
