/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useVehicle } from '../../../shared/hooks';

type IVehicleDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const VehicleDataGrid: React.FC<IVehicleDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsVehicle,
    rowsVehicle,
    rowCountVehicle,
    pageVehicle,
    handlePageVehicle,
    pageSizeVehicle,
    handlePageSizeVehicle,
    sortVehicle,
    filterVehicle,
    handleFilterVehicle,
    handleSortVehicle,
    getAllVehicle,
  } = useVehicle();

  useEffect(() => handleFilterVehicle({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllVehicle();
  }, [canSearch, sortVehicle, filterVehicle, pageVehicle, pageSizeVehicle]);

  return (
    <DataGrid
      rows={rowsVehicle}
      rowCount={rowCountVehicle}
      columns={columnsVehicle}
      onSortModelChange={handleSortVehicle}
      sortModel={sortVehicle}
      pageSize={pageSizeVehicle}
      onPageSizeChange={handlePageSizeVehicle}
      page={pageVehicle}
      onPageChange={handlePageVehicle}
      {...rest}
    />
  );
};
