/* eslint-disable react/jsx-props-no-spreading */
import { Typography, TypographyProps } from '@mui/material';

// Extend props MUI Typography H2
type IH2Props = TypographyProps<'h2'>;

// Create component H2 Custom
export const H2: React.FC<IH2Props> = ({ children, ...rest }) => {
  return (
    <Typography component="h2" variant="subtitle1" {...rest}>
      {children}
    </Typography>
  );
};
