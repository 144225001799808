/* eslint-disable @typescript-eslint/naming-convention */
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import { Input, FilterDialog, SelectInsurancePlanCoverageType } from '../../../shared/components';
import { useInsurancePlan } from '../../../shared/hooks';
import { IInsurancePlanCoverageTypeFilter } from '../../../shared/types';
import { EmployeeInputSelectBase } from '../../employee/components/EmployeeInputSelectBase';
import { VehicleInputSelectBase } from '../../vehicle/components/VehicleInputSelectBase';

// Create modal filter for InsurancePlan
export const InsurancePlanFilterModal: React.FC = () => {
  // State for insurance plan type
  const [coverageType, setCoverageType] = useState<IInsurancePlanCoverageTypeFilter>('');

  // Use context InsurancePlan
  const {
    isOpenFilterModalInsurancePlan,
    toggleIsOpenFilterModalInsurancePlan,
    handleFilterInsurancePlan,
    filterInsurancePlan,
    defaultFilterInsurancePlan,
  } = useInsurancePlan();

  // On change coverage type
  const handleChangeCoverageType = (value: IInsurancePlanCoverageTypeFilter) =>
    setCoverageType(value);

  // Load initial data
  useEffect(() => {
    if (filterInsurancePlan === undefined) return;
    const coverage: IInsurancePlanCoverageTypeFilter = filterInsurancePlan.type || '';
    handleChangeCoverageType(coverage);
  }, [filterInsurancePlan]);

  return (
    <FilterDialog
      isOpen={isOpenFilterModalInsurancePlan}
      onClose={toggleIsOpenFilterModalInsurancePlan}
      setFilter={handleFilterInsurancePlan}
      filter={filterInsurancePlan}
      initialValues={defaultFilterInsurancePlan}
    >
      <Input label="Seguradora" name="insurance_name" />
      <Input label="Corretora" name="broker_name" />
      <Field
        name="type"
        component={SelectInsurancePlanCoverageType}
        enableAll
        value={coverageType}
        onSelect={handleChangeCoverageType}
      />
      {coverageType === 'AUTO' && (
        <VehicleInputSelectBase label="Veículo" refId="coverage_id" refName="coverage_detail" />
      )}
      {coverageType === 'VIDA' && (
        <EmployeeInputSelectBase
          label="Colaborador"
          refId="coverage_id"
          refName="coverage_detail"
        />
      )}
    </FilterDialog>
  );
};
