import { IApiResponseWithPagination, IChangeLogList } from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { ChangeLogSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<IChangeLogList> | ApiError> => {
  try {
    const response = await Api().get('/system/change-log', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, ChangeLogSchemaApiError);
  }
};

export const ChangeLogService = { getAll };
