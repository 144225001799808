import {
  IApiResponseWithPagination,
  IBusinessTypeData,
  IBusinessTypeList,
  IBusinessTypeDTO,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { BusinessTypeSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<IBusinessTypeList> | ApiError> => {
  try {
    const response = await Api().get('/business-types', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, BusinessTypeSchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<IBusinessTypeData | ApiError> => {
  try {
    const response = await Api().get(`/business-types/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, BusinessTypeSchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: IBusinessTypeDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/business-types', payload, { headers });
    return response.data.business_type_id;
  } catch (error: any) {
    return makeApiError(error, BusinessTypeSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: IBusinessTypeDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/business-types/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, BusinessTypeSchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/business-types/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, BusinessTypeSchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/business-types/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, BusinessTypeSchemaApiError);
  }
};

export const BusinessTypeService = { getAll, getById, create, updateById, deleteById, deleteIds };
