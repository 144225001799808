import create from 'zustand';

// Define store interface for store
interface IRecoverPasswordModalStore {
  isOpen: boolean;
  close: () => void;
}

// Create store
export const useRecoverPasswordModalStore = create<IRecoverPasswordModalStore>((set) => ({
  isOpen: false,
  close: () => set({ isOpen: false }),
}));

// Export function for implementation
export const openRecoverPasswordModal = () => {
  useRecoverPasswordModalStore.setState({ isOpen: true });
};
