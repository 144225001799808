import { Input, FilterDialog, InputMask } from '../../../shared/components';
import { useEmployee } from '../../../shared/hooks';

// Create modal filter for Employee
export const EmployeeFilterModal: React.FC = () => {
  // Use context Employee
  const {
    isOpenFilterModalEmployee,
    toggleIsOpenFilterModalEmployee,
    handleFilterEmployee,
    filterEmployee,
    defaultFilterEmployee,
  } = useEmployee();

  return (
    <FilterDialog
      isOpen={isOpenFilterModalEmployee}
      onClose={toggleIsOpenFilterModalEmployee}
      setFilter={handleFilterEmployee}
      filter={filterEmployee}
      initialValues={defaultFilterEmployee}
    >
      <Input label="Código" name="id" type="number" placeholder="Código do colaborador" />
      <Input autoFocus label="Nome" name="name" />
      <InputMask label="CNPJ" name="cnpj" mask="cnpj" maxLength={18} inputMode="numeric" />
      <InputMask label="CPF" name="cpf" mask="cpf" maxLength={14} inputMode="numeric" />
    </FilterDialog>
  );
};
