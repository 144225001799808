import { useVehicle } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { VehicleMain } from './components/VehicleMain';

export const VehiclePage: React.FC = () => {
  const { toggleIsOpenFilterModalVehicle } = useVehicle();

  return (
    <PageContainer>
      <PageTitle
        title="Veículos"
        subtitle="Gerencie o cadastro de veículos"
        filterAction={toggleIsOpenFilterModalVehicle}
      />

      <PageMain>
        <VehicleMain />
      </PageMain>
    </PageContainer>
  );
};
