/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useMedicalLeave } from '../../../shared/hooks';

type IMedicalLeaveDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const MedicalLeaveDataGrid: React.FC<IMedicalLeaveDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsMedicalLeave,
    rowsMedicalLeave,
    rowCountMedicalLeave,
    pageMedicalLeave,
    handlePageMedicalLeave,
    pageSizeMedicalLeave,
    handlePageSizeMedicalLeave,
    sortMedicalLeave,
    filterMedicalLeave,
    handleFilterMedicalLeave,
    handleSortMedicalLeave,
    getAllMedicalLeave,
  } = useMedicalLeave();

  useEffect(() => handleFilterMedicalLeave({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllMedicalLeave();
  }, [canSearch, sortMedicalLeave, filterMedicalLeave, pageMedicalLeave, pageSizeMedicalLeave]);

  return (
    <DataGrid
      rows={rowsMedicalLeave}
      rowCount={rowCountMedicalLeave}
      columns={columnsMedicalLeave}
      onSortModelChange={handleSortMedicalLeave}
      sortModel={sortMedicalLeave}
      pageSize={pageSizeMedicalLeave}
      onPageSizeChange={handlePageSizeMedicalLeave}
      page={pageMedicalLeave}
      onPageChange={handlePageMedicalLeave}
      {...rest}
    />
  );
};
