import { useMemo } from 'react';
import { useAuth } from './useAuth';

export const useSetHeaders = () => {
  const { user } = useAuth();

  const headers = useMemo(() => {
    const { token } = user;
    const company = user.payload?.companies.id;

    const value: any = {};
    value.Authorization = `Bearer ${token}`;
    value['X-Company-ID'] = company;

    return value;
  }, [user]);

  return headers;
};
