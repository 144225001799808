/* eslint-disable react/jsx-props-no-spreading */
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { InputSelect, SelectDialog, IInputProps } from '../../../shared/components';
import { useEmployee } from '../../../shared/hooks';
import { IDataGridColumnsHidden, IDataGridRowParams } from '../../../shared/types';
import { EmployeeDataGrid } from './EmployeeDataGrid';
import { EmployeeFilterModal } from './EmployeeFilterModal';

export interface IEmployeeInputSelectBaseProps extends Omit<IInputProps, 'name' | 'onSelect'> {
  label?: string;
  title?: string;
  baseName?: string;
  refId?: string;
  refName?: string;
  enableAdd?: boolean;
}

const defaultBaseName = 'employee';

const defaultTitle = 'Colaborador';

const hiddenColumnsEmployee: IDataGridColumnsHidden = {
  nickname: false,
  category: false,
  business_type: false,
  payment_type: false,
  type: false,
  phone: false,
  neighborhood: false,
  state: false,
  is_active: false,
};

export const EmployeeInputSelectBase: React.FC<IEmployeeInputSelectBaseProps> = ({
  label = defaultTitle,
  title = defaultTitle,
  baseName = defaultBaseName,
  refId,
  refName,
  disabled,
  enableAdd = false,
  ...rest
}) => {
  // Define name
  const [fieldId] = useState(refId || `${baseName}_id`);
  const [fieldName] = useState(refName || `${baseName}_name`);

  // State for control opened search dialog
  const [isOpen, setIsOpen] = useState(false);

  // Use hook formik context
  const { setFieldValue } = useFormikContext();

  // Use context
  const {
    toggleIsOpenFilterModalEmployee,
    handleFilterEmployee,
    defaultDataEmployee,
    handleDataEmployee,
  } = useEmployee();

  // Open search dialog
  const handleOpen = () => {
    if (disabled) return;
    setIsOpen(true);
  };

  // Close search modal
  const handleClose = () => setIsOpen(false);

  // On click in add button
  const handleAdd = () => handleDataEmployee(defaultDataEmployee);

  // On click in filter button
  const handleResetFilter = () => handleFilterEmployee({});

  // Apply filters
  const handleApplyFilterName = (filter: any) => handleFilterEmployee({ name: filter });

  // On select record
  const handleSelect = (data: IDataGridRowParams) => {
    setFieldValue(fieldId, data.row.id);
    setFieldValue(fieldName, data.row.name);
    handleClose();
  };

  return (
    <>
      <EmployeeFilterModal />

      <SelectDialog
        title={title}
        open={isOpen}
        onClose={handleClose}
        applyFilter={handleApplyFilterName}
        showFilter={toggleIsOpenFilterModalEmployee}
        resetFilter={handleResetFilter}
        add={enableAdd ? handleAdd : undefined}
      >
        <EmployeeDataGrid
          simpleMode
          onRowClick={handleSelect}
          columnVisibilityModel={hiddenColumnsEmployee}
        />
      </SelectDialog>

      <InputSelect
        label={label}
        fieldId={fieldId}
        fieldName={fieldName}
        onClick={handleOpen}
        disabled={disabled}
        {...rest}
      />
    </>
  );
};
