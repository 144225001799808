/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

type ISelectEmployeeTypeProps = FieldProps;

const options: ISelectOptions[] = [
  { label: 'Fixo', value: 'FIXO' },
  { label: 'Avulso', value: 'AVULSO' },
  { label: 'CLT', value: 'CLT' },
];

export const SelectEmployeeType: React.FC<ISelectEmployeeTypeProps> = ({ ...rest }) => {
  return <Select label="Tipo" defaultOption="FIXO" dataOptions={options} {...rest} />;
};
