import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, CompanyService } from '../services';
import { ICompanyData, ICompanyDTO } from '../types';

interface ICompanyContext {
  // Data
  defaultDataCompany: ICompanyData;
  dataCompany: ICompanyData;
  // Api
  getCompany: () => void;
  updateCompany: (payload: ICompanyDTO) => void;
}

const defaultDataCompany: ICompanyData = {
  id: 0,
  name: '',
  corporate_name: '',
  cnpj: '',
  manager_name: '',
  email: '',
  phone_number_1: '',
  phone_number_2: '',
  address_postal_code: '',
  address_street_name: '',
  address_street_number: '',
  address_complement: '',
  address_neighborhood: '',
  address_city: '',
  address_state: '',
  address_reference: '',
  timezone_id: 1,
  is_active: true,
};

export const CompanyContext = createContext<ICompanyContext>({} as ICompanyContext);

export const CompanyContextProvider: React.FC = ({ children }) => {
  const [dataCompany, setDataCompany] = useState<ICompanyData>(defaultDataCompany);

  const headers = useSetHeaders();

  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  const getCompany = useCallback(() => {
    const id = headers['X-Company-ID'];
    CompanyService.getById(headers, id).then((response) => {
      if (response instanceof ApiError) {
        snackbarError(response.message);
        return;
      }
      setDataCompany(response);
    });
  }, [headers]);

  const updateCompany = useCallback(
    (payload: ICompanyDTO) => {
      const id = headers['X-Company-ID'];
      CompanyService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        snackbarSuccess('Registro atualizado!');
      });
    },
    [headers],
  );

  return (
    <CompanyContext.Provider
      value={{
        // Data
        dataCompany,
        defaultDataCompany,
        // Api
        getCompany,
        updateCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
