import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, MedicalLeaveService } from '../services';
import {
  IMedicalLeaveData,
  IMedicalLeaveFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  IMedicalLeaveDTO,
  IDataGridSelection,
  IMedicalLeaveList,
} from '../types';
import { getFilterParams, toObjectWithoutNull, formatterDateBR, toDate, toDateApi } from '../utils';
import { getSortParams } from '../utils/getSortParams';

// Define interface for context
interface IMedicalLeaveContext {
  // Data
  columnsMedicalLeave: IDataGridColumns;
  rowsMedicalLeave: IMedicalLeaveList[];
  defaultDataMedicalLeave: IMedicalLeaveData;
  dataMedicalLeave: IMedicalLeaveData | undefined;
  handleDataMedicalLeave: (data?: IMedicalLeaveData) => void;
  handleClearStorageMedicalLeave: () => void;
  // Pagination
  rowCountMedicalLeave: number;
  pageSizeMedicalLeave: number;
  pageMedicalLeave: number;
  handlePageSizeMedicalLeave: (size: number) => void;
  handlePageMedicalLeave: (page: number) => void;
  // Sort
  sortMedicalLeave: IDataGridSortOptions;
  handleSortMedicalLeave: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionMedicalLeave: IDataGridSelection;
  handleSelectionMedicalLeave: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterMedicalLeave: IMedicalLeaveFilter;
  hideFilterMedicalLeave: string[];
  filterMedicalLeave: IMedicalLeaveFilter;
  isOpenFilterModalMedicalLeave: boolean;
  toggleIsOpenFilterModalMedicalLeave: () => void;
  handleFilterMedicalLeave: (filter: IMedicalLeaveFilter) => void;
  // Api
  getAllMedicalLeave: () => void;
  getByIdMedicalLeave: (id: number) => void;
  createMedicalLeave: (payload: IMedicalLeaveDTO) => void;
  updateByIdMedicalLeave: (id: number, payload: IMedicalLeaveDTO) => void;
  deleteByIdMedicalLeave: (id: number) => void;
  deleteIdsMedicalLeave: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaMedicalLeave: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código do atestado médico',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'employee_id',
    headerName: 'Colaborador',
    hideable: false,
  },
  {
    field: 'employee_name',
    headerName: 'Nome',
    description: 'Nome do colaborador',
    flex: 1,
  },
  {
    field: 'reason',
    headerName: 'Motivo',
    description: 'Motivo do atestado',
    flex: 1,
  },
  {
    field: 'start_leave',
    headerName: 'Início',
    description: 'Início do afastamento',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => formatterDateBR(params.row.start_leave),
  },
  {
    field: 'end_leave',
    headerName: 'Fim',
    description: 'Fim do afastamento',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => formatterDateBR(params.row.end_leave),
  },
];

// Initial values for filter
const defaultFilterMedicalLeave: IMedicalLeaveFilter = {
  employee_id: 0,
  employee_name: '',
  reason: '',
  start_leave: null,
  end_leave: null,
};

// Omit filter on list
const defaultHideFilterMedicalLeave = ['employee_name'];

// initial values for data
const defaultDataMedicalLeave: IMedicalLeaveData = {
  id: 0,
  employee_id: 0,
  employee_name: '',
  reason: '',
  paid: false,
  start_leave: null,
  end_leave: null,
};

// Create context
export const MedicalLeaveContext = createContext<IMedicalLeaveContext>({} as IMedicalLeaveContext);

// Create context provider
export const MedicalLeaveContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsMedicalLeave] = useState<IDataGridColumns>(columnsSchemaMedicalLeave);

  // Rows state
  const [rowsMedicalLeave, setRowsMedicalLeave] = useState<IMedicalLeaveList[]>([]);

  // Data state
  const [dataMedicalLeave, setDataMedicalLeave] = useState<IMedicalLeaveData | undefined>();

  // Sort state
  const [sortMedicalLeave, setSortMedicalLeave] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterMedicalLeave, setFilterMedicalLeave] =
    useState<IMedicalLeaveFilter>(defaultFilterMedicalLeave);

  // Hide Filter state
  const [hideFilterMedicalLeave] = useState(defaultHideFilterMedicalLeave);

  // Modal filters
  const [isOpenFilterModalMedicalLeave, setIsOpenMedicalLeaveFilterModal] = useState(false);

  // Page state
  const [pageMedicalLeave, setPageMedicalLeave] = useState(0);

  // Page size state
  const [pageSizeMedicalLeave, setPageSizeMedicalLeave] = useState(10);

  // Record count state
  const [rowCountMedicalLeave, setRowCountMedicalLeave] = useState(0);

  // Selection state
  const [selectionMedicalLeave, setSelectionMedicalLeave] = useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataMedicalLeave = useCallback(
    (data?: IMedicalLeaveData) => {
      if (!data) return setDataMedicalLeave(data);
      const dataWithoutNull = toObjectWithoutNull<IMedicalLeaveData>(data, defaultDataMedicalLeave);
      dataWithoutNull.start_leave = toDate(dataWithoutNull.start_leave);
      dataWithoutNull.end_leave = toDate(dataWithoutNull.end_leave);
      return setDataMedicalLeave(dataWithoutNull);
    },
    [dataMedicalLeave],
  );

  // Change page status
  const handlePageMedicalLeave = useCallback(
    (page: number) => setPageMedicalLeave(page),
    [pageMedicalLeave],
  );

  // Change page size status
  const handlePageSizeMedicalLeave = useCallback(
    (size: number) => {
      setPageSizeMedicalLeave(size);
      setPageMedicalLeave(0);
    },
    [pageSizeMedicalLeave],
  );

  // Change sort status
  const handleSortMedicalLeave = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortMedicalLeave(sort);
      setPageMedicalLeave(0);
    },
    [sortMedicalLeave],
  );

  // Change filters status
  const handleFilterMedicalLeave = useCallback(
    (filter: IMedicalLeaveFilter) => {
      setFilterMedicalLeave(filter);
      setPageMedicalLeave(0);
    },
    [filterMedicalLeave],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalMedicalLeave = useCallback(
    () => setIsOpenMedicalLeaveFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionMedicalLeave = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionMedicalLeave(selection);
    },
    [selectionMedicalLeave],
  );

  // Clear data rows
  const handleClearStorageMedicalLeave = useCallback(() => {
    setRowsMedicalLeave([]);
    setRowCountMedicalLeave(0);
  }, [setRowsMedicalLeave]);

  // Load data from request
  const handleLoadStorageMedicalLeave = useCallback(
    (response: IApiResponseWithPagination<IMedicalLeaveList>) => {
      setRowsMedicalLeave(response.data);
      setRowCountMedicalLeave(response.records);
    },
    [setRowsMedicalLeave],
  );

  // API - Get all records
  const getAllMedicalLeave = useCallback(() => {
    // Reset selection
    handleSelectionMedicalLeave([]);
    // Add filters in params
    const params: any = getFilterParams(filterMedicalLeave);
    // Normalize date
    if (params.start_leave) params.start_leave = toDateApi(params.start_leave);
    if (params.end_leave) params.end_leave = toDateApi(params.end_leave);
    // Add sort in params
    const sort = getSortParams(sortMedicalLeave);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeMedicalLeave;
    params.offset = pageMedicalLeave * pageSizeMedicalLeave;
    // Get data
    MedicalLeaveService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        handleClearStorageMedicalLeave();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageMedicalLeave(0);
        return;
      }
      handleLoadStorageMedicalLeave(response);
    });
  }, [
    headers,
    filterMedicalLeave,
    sortMedicalLeave,
    pageMedicalLeave,
    pageSizeMedicalLeave,
    dataMedicalLeave,
  ]);

  // API - Get record by id
  const getByIdMedicalLeave = useCallback(
    (id: number) => {
      // Get details
      MedicalLeaveService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataMedicalLeave(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createMedicalLeave = useCallback(
    (payload: IMedicalLeaveDTO) => {
      // Get details
      MedicalLeaveService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataMedicalLeave();
        snackbarSuccess('Registro adicionado!');
        if (pageMedicalLeave > 0) {
          handlePageMedicalLeave(0);
          return;
        }
        getAllMedicalLeave();
      });
    },
    [getAllMedicalLeave],
  );

  // API - Update record by id
  const updateByIdMedicalLeave = useCallback(
    (id: number, payload: IMedicalLeaveDTO) => {
      // Get details
      MedicalLeaveService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataMedicalLeave();
        getAllMedicalLeave();
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllMedicalLeave],
  );

  // API - Delete record by id
  const deleteByIdMedicalLeave = useCallback(
    (id: number) => {
      MedicalLeaveService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataMedicalLeave();
        getAllMedicalLeave();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllMedicalLeave],
  );

  // API - Delete record by ids
  const deleteIdsMedicalLeave = useCallback(
    (ids: number[]) => {
      MedicalLeaveService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllMedicalLeave();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllMedicalLeave],
  );

  return (
    <MedicalLeaveContext.Provider
      value={{
        // Data
        columnsMedicalLeave,
        rowsMedicalLeave,
        dataMedicalLeave,
        defaultDataMedicalLeave,
        hideFilterMedicalLeave,
        handleDataMedicalLeave,
        handleClearStorageMedicalLeave,
        // Pagination
        rowCountMedicalLeave,
        pageSizeMedicalLeave,
        pageMedicalLeave,
        handlePageSizeMedicalLeave,
        handlePageMedicalLeave,
        // Sort
        sortMedicalLeave,
        handleSortMedicalLeave,
        // Selection
        selectionMedicalLeave,
        handleSelectionMedicalLeave,
        // Filter
        defaultFilterMedicalLeave,
        filterMedicalLeave,
        isOpenFilterModalMedicalLeave,
        toggleIsOpenFilterModalMedicalLeave,
        handleFilterMedicalLeave,
        // Api
        getAllMedicalLeave,
        getByIdMedicalLeave,
        createMedicalLeave,
        updateByIdMedicalLeave,
        deleteByIdMedicalLeave,
        deleteIdsMedicalLeave,
      }}
    >
      {children}
    </MedicalLeaveContext.Provider>
  );
};
