import { format } from 'date-fns';
import { isNumber } from './validators';

// Convert to number
export const toNumber = (value: any) => {
  const formatted = String(value).replace('.', '').replace(',', '.').trim();
  return Number(formatted);
};

// Return an number valid or zero
export const toNumberOrZero = (value: any) => {
  if (isNumber(value)) return Number(value);
  const valueToNumber = toNumber(value);
  if (isNumber(valueToNumber)) return valueToNumber;
  return 0;
};

// Remove null values from object
export function toObjectWithoutNull<T>(data: T, emptyData: T): T {
  const objWithoutNull: any = data;
  const objEmptyData: any = emptyData;
  Object.keys(objWithoutNull).forEach((key) => {
    if (objWithoutNull[key] === null) {
      objWithoutNull[key] = objEmptyData[key];
    }
  });
  return objWithoutNull as T;
}

// Complete object with default values
export function toObjectWithAllProps<T>(data: T, propsComparer: T): T {
  const objWithAllProps: any = data;
  const objToComparer: any = propsComparer;
  Object.keys(objToComparer).forEach((key) => {
    if (objWithAllProps[key] === null || objWithAllProps[key] === undefined) {
      objWithAllProps[key] = objToComparer[key];
    }
  });
  return objWithAllProps as T;
}

// Convert first letter to uppercase
export const toCapitalize = (value: any) => {
  const str = String(value);
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// Convert date from api to date object
export const toDate = (value: any) => {
  if (typeof value !== 'string') return value;
  const onlyDate = value.split(' ');
  const decodeDate = onlyDate[0].split('-');
  const year = Number(decodeDate[0]);
  const month = Number(decodeDate[1]) - 1;
  const day = Number(decodeDate[2]);
  return new Date(year, month, day);
};

// Convert date time from api to date object
export const toDateTime = (value: any) => {
  if (typeof value !== 'string') return value;
  const dateTime = value.split(' ');

  const decodeDate = dateTime[0].split('-');
  const year = Number(decodeDate[0]);
  const month = Number(decodeDate[1]) - 1;
  const day = Number(decodeDate[2]);

  const decodeTime = dateTime[1].split(':');
  const hour = Number(decodeTime[0]);
  const minute = Number(decodeTime[1]);
  const second = Number(decodeTime[2].split('.')[0]);

  return new Date(year, month, day, hour, minute, second);
};

// Convert date to API format (yyyy-mm-dd)
export const toDateApi = (value: any) => {
  const date = toDate(value);
  if (date instanceof Date) return format(date, 'yyyy-MM-dd');
  return null;
};

// Convert date tome to API format (yyyy-mm-dd hh:mm:ss)
export const toDateTimeApi = (value: any) => {
  const date = toDateTime(value);
  if (date instanceof Date) return format(date, 'yyyy-MM-dd HH:mm:ss');
  return null;
};
