import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, AttachmentService } from '../services';
import { IAttachmentData, IAttachmentDTO, IAttachmentList, IAttachmentResource } from '../types';
import { toObjectWithoutNull } from '../utils';

// Define interface for context
interface IAttachmentContext {
  // Data
  rowsAttachment: IAttachmentList[];
  defaultDataAttachment: IAttachmentData;
  dataAttachment: IAttachmentData | undefined;
  handleDataAttachment: (data?: IAttachmentData) => void;
  HandleLoadStorageAttachment: (data: IAttachmentList[]) => void;
  // Api
  getAllAttachment: (resource: IAttachmentResource, resourceId: number) => void;
  createAttachment: (
    resource: IAttachmentResource,
    resourceId: number,
    payload: IAttachmentDTO,
  ) => void;
  updateByIdAttachment: (
    resource: IAttachmentResource,
    resourceId: number,
    attachmentId: number,
    payload: IAttachmentDTO,
  ) => void;
  deleteByIdAttachment: (
    resource: IAttachmentResource,
    attachmentId: number,
    resourceId: number,
  ) => void;
}

// initial values for data
const defaultDataAttachment: IAttachmentData = {
  id: 0,
  type: '',
  description: '',
  file: '',
  file_base64: '',
  file_name: '',
};

// Create context
export const AttachmentContext = createContext<IAttachmentContext>({} as IAttachmentContext);

// Create context provider
export const AttachmentContextProvider: React.FC = ({ children }) => {
  // Rows state
  const [rowsAttachment, setRowsAttachment] = useState<IAttachmentList[]>([]);

  // Data state
  const [dataAttachment, setDataAttachment] = useState<IAttachmentData | undefined>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataAttachment = useCallback(
    (data?: IAttachmentData) => {
      if (!data) return setDataAttachment(data);
      const dataWithoutNull = toObjectWithoutNull<IAttachmentData>(data, defaultDataAttachment);
      return setDataAttachment(dataWithoutNull);
    },
    [dataAttachment],
  );

  // Clear data rows
  const HandleClearStorageAttachment = useCallback(() => {
    setRowsAttachment([]);
  }, [setRowsAttachment]);

  // Load data from request
  const HandleLoadStorageAttachment = useCallback(
    (response: IAttachmentList[]) => {
      setRowsAttachment(response);
    },
    [setRowsAttachment],
  );

  // API - Get all records
  const getAllAttachment = useCallback(
    (resource: IAttachmentResource, resourceId: number) => {
      if (resourceId === 0) {
        HandleClearStorageAttachment();
        return;
      }
      // Get data
      AttachmentService.getAll(headers, resource, resourceId).then((response) => {
        if (response instanceof ApiError) {
          HandleClearStorageAttachment();
          snackbarError(response.message);
          return;
        }
        HandleLoadStorageAttachment(response);
      });
    },
    [headers, dataAttachment],
  );

  // API - Create record
  const createAttachment = useCallback(
    (resource: IAttachmentResource, resourceId: number, payload: IAttachmentDTO) => {
      // Get details
      AttachmentService.create(headers, resource, resourceId, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataAttachment();
        snackbarSuccess('Registro adicionado!');
        getAllAttachment(resource, resourceId);
      });
    },
    [getAllAttachment],
  );

  // API - Update record by id
  const updateByIdAttachment = useCallback(
    (
      resource: IAttachmentResource,
      resourceId: number,
      attachmentId: number,
      payload: IAttachmentDTO,
    ) => {
      // Get details
      AttachmentService.updateById(headers, resource, resourceId, attachmentId, payload).then(
        (response) => {
          if (response instanceof ApiError) {
            snackbarError(response.message);
            return;
          }
          handleDataAttachment();
          getAllAttachment(resource, resourceId);
          snackbarSuccess('Registro atualizado!');
        },
      );
    },
    [getAllAttachment],
  );

  // API - Delete record by id
  const deleteByIdAttachment = useCallback(
    (resource: IAttachmentResource, resourceId: number, attachmentId: number) => {
      // Delete record
      AttachmentService.deleteById(headers, resource, resourceId, attachmentId).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataAttachment();
        getAllAttachment(resource, resourceId);
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllAttachment],
  );

  return (
    <AttachmentContext.Provider
      value={{
        // Data
        rowsAttachment,
        dataAttachment,
        defaultDataAttachment,
        handleDataAttachment,
        HandleLoadStorageAttachment,
        // Api
        getAllAttachment,
        createAttachment,
        updateByIdAttachment,
        deleteByIdAttachment,
      }}
    >
      {children}
    </AttachmentContext.Provider>
  );
};
