import { Container, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Div, H1, P, Button, Img } from '../../shared/components';
import NotFoundSVG from '../../assets/images/not-found-page.svg';

// Page 404
export const Page404: React.FC = () => {
  // use theme
  const theme = useTheme();

  // use navigate
  const navigate = useNavigate();

  // Click to home
  const handleClick = () => navigate('/pagina-inicial');
  return (
    <Container sx={{ p: theme.spacing(8) }}>
      <Div isFlexColumn>
        <Img src={NotFoundSVG} width="300px" />
        <H1 variant="h2" sx={{ mt: theme.spacing(6) }}>
          OOPS!
        </H1>
        <P variant="h5">Erro 404: Página não localizada</P>
        <Button
          variant="outlined"
          label="Voltar para o início"
          onClick={handleClick}
          sx={{ mt: theme.spacing(6) }}
        />
      </Div>
    </Container>
  );
};
