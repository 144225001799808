/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox as MuiCheckbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useLoader } from '../../hooks';

// Extend props MUI checkbox
interface ICheckboxProps extends CheckboxProps {
  name: string;
  label: string;
  onChecked?: (checked: boolean) => void;
}

// Create component checkbox Custom
export const Checkbox: React.FC<ICheckboxProps> = ({ label, name, onChecked, ...rest }) => {
  // Use hook formik field
  const [field] = useField<ICheckboxProps>(name);

  // Use hook formik context
  const { setFieldValue } = useFormikContext();

  // Use "hook" loader
  const isRunning = useLoader();

  // On change checked
  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;
    setFieldValue(name, checked);
    onChecked?.(checked);
  };

  // Set default props
  const props: Partial<ICheckboxProps> = {
    ...field,
    name,
    onChange: handleToggle,
    ...rest,
    disabled: isRunning ? true : rest.disabled,
  };

  return (
    <FormControlLabel
      control={<MuiCheckbox {...props} checked={props.value === true} />}
      label={label}
    />
  );
};
