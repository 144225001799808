import { Toolbar, useTheme } from '@mui/material';
import { Support } from '@mui/icons-material';
import { useEffect } from 'react';
import { Div } from '../display-data/Div';
import { Button } from '../inputs/Button';
import { InsurancePlanModal, InsurancePlanMain } from '../../../pages/insurance-plan/components';
import { useInsurancePlan } from '../../hooks';

interface IVehicleInsurancePlanProps {
  VehicleId: number;
}

export const VehicleInsurancePlan: React.FC<IVehicleInsurancePlanProps> = ({ VehicleId }) => {
  const theme = useTheme();

  const { defaultDataInsurancePlan, handleDataInsurancePlan, handleClearStorageInsurancePlan } =
    useInsurancePlan();

  const handleAddData = () => {
    const data = defaultDataInsurancePlan;
    data.type = 'AUTO';
    data.coverage_id = VehicleId;
    data.coverage_detail = 'NOVO SEGURO';
    handleDataInsurancePlan(data);
  };

  useEffect(() => handleClearStorageInsurancePlan(), []);

  return (
    <Div>
      <InsurancePlanModal fixedCoverageId={VehicleId} />

      <Toolbar style={{ paddingRight: 0, paddingLeft: 0, marginBottom: theme.spacing(1) }}>
        <Div flex={1} />
        <Button
          label="Adicionar seguro"
          tooltip="Adicionar seguro para o veículo"
          startIcon={<Support />}
          onClick={handleAddData}
          disableFocusRipple
        />
      </Toolbar>

      <InsurancePlanMain embedded initialFilter={{ type: 'AUTO', coverage_id: VehicleId }} />
    </Div>
  );
};
