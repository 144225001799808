import { ISchemaApiError } from '../../../types';

export const UserPerfilSchemaApiError: ISchemaApiError[] = [
  {
    statusCode: 404,
    errors: [
      {
        error: 'User not found',
        message: 'Senha atual não confere',
      },
    ],
  },
  {
    statusCode: 400,
    errors: [
      {
        error: 'Same password',
        message: 'A nova senha deve ser diferente',
      },
    ],
  },
];
