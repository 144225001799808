import { CheckCircle } from '@mui/icons-material';
import { Alert, Grid } from '@mui/material';
import { Button } from '../../../shared/components';
import { useDialog } from '../../../shared/hooks';

interface IEmployeeApprovalStatusProps {
  onClick: () => void;
}

export const EmployeeApprovalStatus: React.FC<IEmployeeApprovalStatusProps> = ({ onClick }) => {
  const { dialogConfirmGrant } = useDialog();

  const handleClick = () =>
    dialogConfirmGrant(
      'Aprovar cadastro?',
      'Deseja realmente aprovar o cadastro e liberar acesso do colaborador atual?',
      () => {
        onClick();
      },
    );

  return (
    <Grid item xs={12} md={12}>
      <Alert
        severity="warning"
        action={
          <Button
            tooltip="Aprovar cadastro e liberar acesso"
            startIcon={<CheckCircle />}
            color="warning"
            size="small"
            label="Aprovar"
            onClick={handleClick}
          />
        }
      >
        Aguardando aprovação.
      </Alert>
      {/* <UserToggleStatusButton status={dataUser?.is_active} onClick={handleToggleStatusUser} /> */}
    </Grid>
  );
};
