import { Grid } from '@mui/material';
import { InputCurrency, InputNumber } from '../../../shared/components';

export const ContractParamsMonthly: React.FC = () => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <InputCurrency label="Mensalidade" name="paramsMonthly.price" placeholder="Valor mensal" />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputNumber
          label="Limite de entregas"
          name="paramsMonthly.delivery_limit"
          placeholder="Limite de entregas mensal"
        />
      </Grid>
    </>
  );
};
