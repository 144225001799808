import { Alert, Grid } from '@mui/material';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormSuite, SelectPersonType, InputMask, Input } from '../../../shared/components';
import { useCustomer, useSnackbarDispatch } from '../../../shared/hooks';
import { IPersonType } from '../../../shared/types';
import { schemaValidation } from '../../../shared/utils';
import { BusinessTypeInputSelect } from '../../business-type/components';
import { ContractInputSelect } from '../../contract/components';

interface ICustomerModalDataTabProps {
  formId: string;
  isEditMode?: boolean;
  onSave: (data: object) => void;
}

// Define validation fields form
const schemaPayloadCustomer = Yup.object({
  person_type: Yup.string(),
  cnpj: Yup.string().when('person_type', {
    is: 'JURIDICA',
    then: schemaValidation.documents.cnpj,
  }),
  cpf: Yup.string().when('person_type', {
    is: 'FISICA',
    then: schemaValidation.documents.cpf,
  }),
  name: schemaValidation.people.corporate_name,
  nickname: Yup.string().when('person_type', {
    is: 'JURIDICA',
    then: schemaValidation.people.name,
  }),
});

export const CustomerModalDataTab: React.FC<ICustomerModalDataTabProps> = ({
  formId,
  isEditMode,
  onSave,
}) => {
  // State people type
  const [personType, setPersonType] = useState<IPersonType>('FISICA');

  // Use context Customer
  const { updateContractByIdCustomer, dataCustomer, defaultDataCustomer } = useCustomer();

  // Use snack dispatch
  const { snackbarError } = useSnackbarDispatch();

  // On change people type
  const handleChangePersonType = (type: IPersonType) => setPersonType(type);

  // Load people type
  useEffect(() => {
    if (dataCustomer === undefined) return;
    const type: IPersonType = dataCustomer.person_type || 'FISICA';
    handleChangePersonType(type);
  }, [dataCustomer]);

  // Update contract
  const handleUpdateContract = (contract: number) => {
    const id = dataCustomer?.id || 0;
    if (id === 0) return snackbarError('É necessário salvar o cliente primeiro');
    return updateContractByIdCustomer(id, contract);
  };

  return (
    <FormSuite
      formId={formId}
      initialValues={dataCustomer || defaultDataCustomer}
      validationSchema={schemaPayloadCustomer}
      submit={onSave}
    >
      {!isEditMode && (
        <Alert severity="info">
          Ao adicionar um cliente, é necessário gravar para liberar acesso ao contrato e demais
          abas.
        </Alert>
      )}
      <input type="hidden" name="id" />
      <input type="hidden" name="is_active" />
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={3}>
          <Field
            name="person_type"
            component={SelectPersonType}
            value={personType}
            onSelect={handleChangePersonType}
          />
        </Grid>

        <Grid item xs={7} md={5}>
          {personType === 'JURIDICA' && (
            <InputMask label="CNPJ *" name="cnpj" mask="cnpj" maxLength={18} inputMode="numeric" />
          )}

          {personType === 'FISICA' && (
            <InputMask label="CPF *" name="cpf" mask="cpf" maxLength={14} inputMode="numeric" />
          )}
        </Grid>

        <Grid item xs={5} md={4}>
          {personType === 'JURIDICA' && (
            <Input label="IE" name="ie" maxLength={18} inputMode="numeric" />
          )}

          {personType === 'FISICA' && <InputMask label="RG" name="rg" mask="rg" maxLength={12} />}
        </Grid>

        <Grid item xs={12} md={12}>
          <Input label={personType === 'JURIDICA' ? 'Razão social *' : 'Nome *'} name="name" />
        </Grid>

        <Grid item xs={12} md={12}>
          <Input
            label={personType === 'JURIDICA' ? 'Nome fantasia *' : 'Apelido'}
            name="nickname"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <BusinessTypeInputSelect />
        </Grid>

        <Grid item xs={12} md={12}>
          <ContractInputSelect
            onSelect={handleUpdateContract}
            disabled={!isEditMode}
            tooltip={!isEditMode ? 'Grave o cliente antes selecionar' : undefined}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Input label="Observações" name="tags" />
        </Grid>
      </Grid>
    </FormSuite>
  );
};
