import {
  IApiResponseWithPagination,
  IDeliveryData,
  IDeliveryList,
  IDeliveryDTO,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { DeliverySchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<IDeliveryList> | ApiError> => {
  try {
    const response = await Api().get('/deliveries', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, DeliverySchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<IDeliveryData | ApiError> => {
  try {
    const response = await Api().get(`/deliveries/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, DeliverySchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: IDeliveryDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/deliveries', payload, { headers });
    return response.data.Delivery_id;
  } catch (error: any) {
    return makeApiError(error, DeliverySchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: IDeliveryDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/deliveries/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, DeliverySchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/deliveries/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, DeliverySchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/deliveries/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, DeliverySchemaApiError);
  }
};

export const DeliveryService = { getAll, getById, create, updateById, deleteById, deleteIds };
