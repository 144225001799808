import { Backdrop, CircularProgress } from '@mui/material';
import create from 'zustand';

// Define interface for store context
interface ILoaderContextStore {
  isRunning: boolean;
}

// Create runtime "hook" for use state
export const useLoaderStore = create<ILoaderContextStore>(() => ({
  isRunning: false,
}));

// Export implementations for active loader
export const enableLoader = () => {
  useLoaderStore.setState({
    isRunning: true,
  });
};

// Export implementations for deactivate loader
export const disableLoader = () => {
  useLoaderStore.setState({
    isRunning: false,
  });
};

// Create loader component
export const LoaderContext: React.FC = ({ children }) => {
  // Use "hook" loader
  const isRunning = useLoaderStore((state) => state.isRunning);

  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: 35001,
          cursor: 'wait',
        }}
        open={isRunning}
        transitionDuration={200}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </>
  );
};
