/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { List, ListSubheader } from '@mui/material';
import { useDeviceIsMobile, useMenu } from '../../hooks';
import { SidebarMenuFooter } from './SidebarMenuFooter';
import { SidebarMenuItem } from './SidebarMenuItem';

// Generate sidebar data
export const SidebarMenuBuilder: React.FC = () => {
  // Use hook menu
  const { isExpandedSidebarMenu, optionsSidebarMenu } = useMenu();

  // Use hook is mobile
  const isMobile = useDeviceIsMobile();

  return (
    <>
      {optionsSidebarMenu.map((list, idxList) => (
        <List
          key={idxList}
          component="nav"
          sx={{ ml: 1, mr: 1, pb: isExpandedSidebarMenu || isMobile ? 1 : 0 }}
          subheader={
            list.subheader && (
              <ListSubheader
                hidden={!isExpandedSidebarMenu && !isMobile}
                component="div"
                id="nested-list-subheader"
                sx={{ bgcolor: 'inherit' }}
              >
                {list.subheader}
              </ListSubheader>
            )
          }
        >
          {list.items.map((item, idxItem) => (
            <SidebarMenuItem key={idxItem} {...item} />
          ))}
        </List>
      ))}
      <SidebarMenuFooter />
    </>
  );
};
