/* eslint-disable react/jsx-props-no-spreading */

import { Tab, Tabs, ITabsProps } from '../../../shared/components';

interface ISupplierModalTabs extends ITabsProps {
  isEditMode?: boolean;
}

export const SupplierModalTabs: React.FC<ISupplierModalTabs> = ({ isEditMode, ...rest }) => {
  return (
    <Tabs {...rest}>
      <Tab label="Geral" />
      <Tab label="Contatos" disabled={!isEditMode} />
      <Tab label="Endereços" disabled={!isEditMode} />
    </Tabs>
  );
};
