/* eslint-disable react/jsx-props-no-spreading */
import { Divider, Menu, MenuProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { P } from '../display-data';
import { useAuth, useDialog, useMenu, useTheme } from '../../hooks';
import { DropDownMenuItem } from './DropDownMenuItem';

// Define interface inherited MenuProps
interface IAccountDropDownMenuProps extends Omit<MenuProps, 'open'> {
  open?: boolean;
}

// Create account menu
export const AccountDropDownMenu: React.FC<IAccountDropDownMenuProps> = ({ ...rest }) => {
  // Use hook auth
  const { logout } = useAuth();

  // Use hook theme
  const { themeName, toggleTheme } = useTheme();

  // Use navigate
  const navigate = useNavigate();

  // Use hook dialog
  const { dialogConfirm } = useDialog();

  // Use store account menu
  const { isOpenAccountDropDownMenu, anchorElementAccountDropDownMenu, closeAccountDropDownMenu } =
    useMenu();

  // Use auth context
  const { user } = useAuth();

  // Execute logout
  const handleLogout = () => {
    closeAccountDropDownMenu();
    dialogConfirm('Sair da sua conta?', 'Deseja realmente efetuar o logoff da sua conta?', () =>
      logout(true),
    );
  };

  // Navigate to user perfil
  const handleUserPerfil = () => {
    closeAccountDropDownMenu();
    navigate('/perfil');
  };

  // Change theme
  const handleToggleTheme = () => {
    closeAccountDropDownMenu();
    toggleTheme();
  };

  // Set default props
  const props: Partial<IAccountDropDownMenuProps> = {
    id: 'account-dropdown-menu',
    anchorEl: anchorElementAccountDropDownMenu,
    onClose: closeAccountDropDownMenu,
    PaperProps: {
      elevation: 0,
      sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
          width: 32,
          height: 32,
          ml: -0.5,
          mr: 1,
        },
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: 'background.paper',
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0,
        },
      },
    },
    transformOrigin: { horizontal: 'right', vertical: 'top' },
    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    ...rest,
  };

  return (
    <Menu open={isOpenAccountDropDownMenu} {...props}>
      <DropDownMenuItem icon="account_circle" iconSize="large" onClick={handleUserPerfil}>
        <P>
          <strong>{user.payload?.nickname}</strong> <br /> Meu perfil
        </P>
      </DropDownMenuItem>

      <Divider />

      <DropDownMenuItem
        label={themeName === 'dark' ? 'Modo claro' : 'Modo escuro'}
        icon={themeName === 'dark' ? 'light_mode' : 'dark_mode'}
        onClick={handleToggleTheme}
      />

      <DropDownMenuItem label="Sair" icon="logout" onClick={handleLogout} />
    </Menu>
  );
};
