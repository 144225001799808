import * as Yup from 'yup';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import { useValidationsBR } from 'validations-br';
import { Checkbox, Input, InputMask, SelectContactType } from '../inputs';
import { DataDialog } from '../data-dialog/DataDialog';
import { usePeopleContact, useDialog } from '../../hooks';
import { IPeopleContactDTO, IPeopleContactType, IPeopleType } from '../../types';
import { schemaValidation, toCapitalize } from '../../utils';

// Define validation fields form
const schemaPayloadPeopleContact = Yup.object({
  type: schemaValidation.commons.string(true),
  contact: schemaValidation.commons
    .string(true)
    .test('is-contact', 'Informe um contato válido', (item, context) => {
      if (['TELEFONE', 'CELULAR', 'WHATSAPP'].includes(context.parent.type || ''))
        return useValidationsBR('phone', item || '');
      if (context.parent.type === 'EMAIL') return useValidationsBR('email', item || '');
      return true;
    }),
  name: schemaValidation.contact.name(false),
  complement: schemaValidation.commons.string(false),
  is_default: Yup.boolean(),
});

interface IPeopleContractModalProps {
  peopleType: IPeopleType;
  peopleId: number;
}

// Create modal data for PeopleContact
export const PeopleContactModal: React.FC<IPeopleContractModalProps> = ({
  peopleType,
  peopleId,
}) => {
  // State for contact type
  const [contactType, setContactType] = useState<IPeopleContactType>('TELEFONE');
  // Use context PeopleContact
  const {
    dataPeopleContact,
    defaultDataPeopleContact,
    handleDataPeopleContact,
    createPeopleContact,
    updateByIdPeopleContact,
    deleteByIdPeopleContact,
  } = usePeopleContact();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Reset data and close modal
  const handleClose = () => handleDataPeopleContact();

  // Save actions
  const handleSave = (payload: object) => {
    const id = dataPeopleContact?.id || 0;
    const data = payload as IPeopleContactDTO;
    if (id > 0) {
      updateByIdPeopleContact(peopleType, peopleId, id, data);
      return;
    }
    createPeopleContact(peopleType, peopleId, data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, contact } = payload;
    dialogConfirmDanger('Excluir contato?', `Deseja realmente excluir o contato: ${contact}`, () =>
      deleteByIdPeopleContact(peopleType, peopleId, id),
    );
  };

  // On change contact type
  const handleChangeContactType = (type: IPeopleContactType) => setContactType(type);

  // Apply initial state for contact type
  useEffect(() => handleChangeContactType(dataPeopleContact?.type || 'TELEFONE'), []);

  return (
    <DataDialog
      referenceId={dataPeopleContact?.id}
      referenceDisplayName="contato"
      initialValues={dataPeopleContact || defaultDataPeopleContact}
      validationSchema={schemaPayloadPeopleContact}
      open={dataPeopleContact !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      <Field name="type" component={SelectContactType} onSelect={handleChangeContactType} />
      {['TELEFONE', 'CELULAR', 'WHATSAPP'].includes(contactType) ? (
        <InputMask
          label={`${toCapitalize(contactType)} *`}
          name="contact"
          mask="phone"
          maxLength={14}
          inputMode="numeric"
          autoFocus
        />
      ) : null}
      {contactType === 'EMAIL' ? <Input label="E-mail *" name="contact" type="text" /> : null}
      {['TELEGRAM', 'FACEBOOK', 'INSTAGRAM', 'SITE', 'OUTROS'].includes(contactType) ? (
        <Input label={`${toCapitalize(contactType)} *`} name="contact" type="text" />
      ) : null}
      <Input label="Nome" name="name" type="text" placeholder="Nome do contato" />
      <Input
        label="Observações"
        name="complement"
        type="text"
        placeholder="Observações do contato"
      />
      <Checkbox name="is_default" label="Marcar como contato padrão" />
    </DataDialog>
  );
};
