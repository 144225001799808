import { Alert, Grid } from '@mui/material';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormSuite, SelectPersonType, InputMask, Input } from '../../../shared/components';
import { useSupplier } from '../../../shared/hooks';
import { IPersonType } from '../../../shared/types';
import { schemaValidation } from '../../../shared/utils';
import { BusinessTypeInputSelect } from '../../business-type/components';

interface ISupplierModalDataTabProps {
  formId: string;
  isEditMode?: boolean;
  onSave: (data: object) => void;
}

// Define validation fields form
const schemaPayloadSupplier = Yup.object({
  person_type: Yup.string(),
  cnpj: Yup.string().when('person_type', {
    is: 'JURIDICA',
    then: schemaValidation.documents.cnpj,
  }),
  cpf: Yup.string().when('person_type', {
    is: 'FISICA',
    then: schemaValidation.documents.cpf,
  }),
  name: schemaValidation.people.corporate_name,
  nickname: Yup.string().when('person_type', {
    is: 'JURIDICA',
    then: schemaValidation.people.name,
  }),
});

export const SupplierModalDataTab: React.FC<ISupplierModalDataTabProps> = ({
  formId,
  isEditMode,
  onSave,
}) => {
  // State people type
  const [peopleType, setPeopleType] = useState<IPersonType>('FISICA');

  // Use context Supplier
  const { dataSupplier, defaultDataSupplier } = useSupplier();

  // On change people type
  const handleChangePeopleType = (type: IPersonType) => setPeopleType(type);

  // Load people type
  useEffect(() => {
    if (dataSupplier === undefined) return;
    const type: IPersonType = dataSupplier.person_type || 'FISICA';
    handleChangePeopleType(type);
  }, [dataSupplier]);

  return (
    <FormSuite
      formId={formId}
      initialValues={dataSupplier || defaultDataSupplier}
      validationSchema={schemaPayloadSupplier}
      submit={onSave}
    >
      {!isEditMode && (
        <Alert severity="info">
          Ao adicionar um fornecedor, é necessário gravar para liberar acesso as demais abas.
        </Alert>
      )}
      <input type="hidden" name="id" />
      <input type="hidden" name="is_active" />
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={3}>
          <Field
            name="person_type"
            component={SelectPersonType}
            value={peopleType}
            onSelect={handleChangePeopleType}
          />
        </Grid>

        <Grid item xs={7} md={5}>
          {peopleType === 'JURIDICA' && (
            <InputMask label="CNPJ *" name="cnpj" mask="cnpj" maxLength={18} inputMode="numeric" />
          )}

          {peopleType === 'FISICA' && (
            <InputMask label="CPF *" name="cpf" mask="cpf" maxLength={14} inputMode="numeric" />
          )}
        </Grid>

        <Grid item xs={5} md={4}>
          {peopleType === 'JURIDICA' && (
            <Input label="IE" name="ie" maxLength={18} inputMode="numeric" />
          )}

          {peopleType === 'FISICA' && <InputMask label="RG" name="rg" mask="rg" maxLength={12} />}
        </Grid>

        <Grid item xs={12} md={12}>
          <Input label={peopleType === 'JURIDICA' ? 'Razão social *' : 'Nome *'} name="name" />
        </Grid>

        <Grid item xs={12} md={12}>
          <Input
            label={peopleType === 'JURIDICA' ? 'Nome fantasia *' : 'Apelido'}
            name="nickname"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <BusinessTypeInputSelect />
        </Grid>

        <Grid item xs={12} md={12}>
          <Input label="Observações" name="tags" />
        </Grid>
      </Grid>
    </FormSuite>
  );
};
