/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, TravelService } from '../services';
import {
  ITravelData,
  ITravelFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  ITravelDTO,
  IDataGridSelection,
  ITravelList,
} from '../types';
import {
  formatterCurrency,
  formatterDateBR,
  getFilterParams,
  toDateApi,
  toDateTime,
  toObjectWithoutNull,
} from '../utils';
import { getSortParams } from '../utils/getSortParams';
import { DisplayColumWithIcon } from '../components/feedback';

// Define interface for context
interface ITravelContext {
  // Data
  columnsTravel: IDataGridColumns;
  rowsTravel: ITravelList[];
  defaultDataTravel: ITravelData;
  dataTravel: ITravelData | undefined;
  handleDataTravel: (data?: ITravelData) => void;
  // Pagination
  rowCountTravel: number;
  pageSizeTravel: number;
  pageTravel: number;
  handlePageSizeTravel: (size: number) => void;
  handlePageTravel: (page: number) => void;
  // Sort
  sortTravel: IDataGridSortOptions;
  handleSortTravel: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionTravel: IDataGridSelection;
  handleSelectionTravel: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterTravel: ITravelFilter;
  hideFilterTravel: string[];
  filterTravel: ITravelFilter;
  isOpenFilterModalTravel: boolean;
  toggleIsOpenFilterModalTravel: () => void;
  handleFilterTravel: (filter: ITravelFilter) => void;
  // Api
  getAllTravel: () => void;
  getByIdTravel: (id: number) => void;
  createTravel: (payload: ITravelDTO) => void;
  updateByIdTravel: (id: number, payload: ITravelDTO) => void;
  deleteByIdTravel: (id: number) => void;
  deleteIdsTravel: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaTravel: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código da viagem',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
  {
    field: 'started_at',
    headerName: 'Saída',
    description: 'Início da viagem',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { type, started_at } = params.row;
      return (
        <DisplayColumWithIcon
          icon={type === 'AGENDADA' ? 'today' : 'check_circle'}
          cell={formatterDateBR(started_at)}
        />
      );
    },
    flex: 1,
  },
  {
    field: 'finished_at',
    headerName: 'Retorno',
    hideable: false,
  },
  {
    field: 'type',
    headerName: 'Tipo',
    hideable: false,
  },
  {
    field: 'customer_id',
    headerName: 'Cliente',
    hideable: false,
  },
  {
    field: 'customer_name',
    headerName: 'Cliente',
    description: 'Tomador da viagem',
    flex: 1,
  },
  {
    field: 'driver_id',
    headerName: 'Motorista',
    hideable: false,
  },
  {
    field: 'driver_name',
    headerName: 'Motorista',
    description: 'Motorista que realizou o serviço',
    flex: 1,
  },
  {
    field: 'vehicle_id',
    headerName: 'Veículo',
    hideable: false,
  },
  {
    field: 'origin',
    headerName: 'Origem',
    hideable: false,
  },
  {
    field: 'destination',
    headerName: 'Destino',
    description: 'Destino da viagem',
    flex: 1,
  },
  {
    field: 'total',
    headerName: 'Total',
    description: 'Valor total do serviço realizado',
    headerAlign: 'right',
    align: 'right',
    valueFormatter: ({ value }) => formatterCurrency(value, true),
  },
];

// Initial values for filter
const defaultFilterTravel: ITravelFilter = {
  type: '',
  started_at: null,
  finished_at: null,
  customer_id: 0,
  customer_name: '',
  driver_id: 0,
  driver_name: '',
  vehicle_id: 0,
  vehicle_detail: '',
  origin: '',
  destination: '',
};

// Omit filter on list
const defaultHideFilterTravel = ['customer_name', 'driver_name', 'vehicle_detail'];

// initial values for data
const defaultDataTravel: ITravelData = {
  id: 0,
  type: 'REALIZADA',
  started_at: new Date(),
  finished_at: new Date(),
  customer_id: 0,
  customer_name: '',
  driver_id: 0,
  driver_name: '',
  vehicle_id: 0,
  vehicle_detail: '',
  origin: '',
  destination: '',
  start_km: 0,
  end_km: 0,
  total_km: 0,
  price_km: 0,
  toll_price: 0,
  service_price: 0,
  total: 0,
};

// Create context
export const TravelContext = createContext<ITravelContext>({} as ITravelContext);

// Create context provider
export const TravelContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsTravel] = useState<IDataGridColumns>(columnsSchemaTravel);

  // Rows state
  const [rowsTravel, setRowsTravel] = useState<ITravelList[]>([]);

  // Data state
  const [dataTravel, setDataTravel] = useState<ITravelData | undefined>();

  // Sort state
  const [sortTravel, setSortTravel] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterTravel, setFilterTravel] = useState<ITravelFilter>(defaultFilterTravel);

  // Hide Filter state
  const [hideFilterTravel] = useState(defaultHideFilterTravel);

  // Modal filters
  const [isOpenFilterModalTravel, setIsOpenTravelFilterModal] = useState(false);

  // Page state
  const [pageTravel, setPageTravel] = useState(0);

  // Page size state
  const [pageSizeTravel, setPageSizeTravel] = useState(10);

  // Record count state
  const [rowCountTravel, setRowCountTravel] = useState(0);

  // Selection state
  const [selectionTravel, setSelectionTravel] = useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataTravel = useCallback(
    (data?: ITravelData) => {
      if (!data) return setDataTravel(data);
      const dataWithoutNull = toObjectWithoutNull<ITravelData>(data, defaultDataTravel);
      dataWithoutNull.started_at = toDateTime(dataWithoutNull.started_at);
      dataWithoutNull.finished_at = toDateTime(dataWithoutNull.finished_at);
      return setDataTravel(dataWithoutNull);
    },
    [dataTravel],
  );

  // Change page status
  const handlePageTravel = useCallback((page: number) => setPageTravel(page), [pageTravel]);

  // Change page size status
  const handlePageSizeTravel = useCallback(
    (size: number) => {
      setPageSizeTravel(size);
      setPageTravel(0);
    },
    [pageSizeTravel],
  );

  // Change sort status
  const handleSortTravel = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortTravel(sort);
      setPageTravel(0);
    },
    [sortTravel],
  );

  // Change filters status
  const handleFilterTravel = useCallback(
    (filter: ITravelFilter) => {
      setFilterTravel(filter);
      setPageTravel(0);
    },
    [filterTravel],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalTravel = useCallback(
    () => setIsOpenTravelFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionTravel = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionTravel(selection);
    },
    [selectionTravel],
  );

  // Clear data rows
  const clearStorageTravel = useCallback(() => {
    setRowsTravel([]);
    setRowCountTravel(0);
  }, [setRowsTravel]);

  // Load data from request
  const loadStorageTravel = useCallback(
    (response: IApiResponseWithPagination<ITravelList>) => {
      setRowsTravel(response.data);
      setRowCountTravel(response.records);
    },
    [setRowsTravel],
  );

  // API - Get all records
  const getAllTravel = useCallback(() => {
    // Reset selection
    handleSelectionTravel([]);
    // Add filters in params
    const params: any = getFilterParams(filterTravel);
    // Normalize date
    if (params.started_at) params.started_at = toDateApi(params.started_at);
    if (params.finished_at) params.finished_at = toDateApi(params.finished_at);
    // Add sort in params
    const sort = getSortParams(sortTravel);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeTravel;
    params.offset = pageTravel * pageSizeTravel;
    // Get data
    TravelService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        clearStorageTravel();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageTravel(0);
        return;
      }
      loadStorageTravel(response);
    });
  }, [headers, filterTravel, sortTravel, pageTravel, pageSizeTravel, dataTravel]);

  // API - Get record by id
  const getByIdTravel = useCallback(
    (id: number) => {
      // Get details
      TravelService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataTravel(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createTravel = useCallback(
    (payload: ITravelDTO) => {
      // Get details
      TravelService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataTravel();
        snackbarSuccess('Registro adicionado!');
        if (pageTravel > 0) {
          handlePageTravel(0);
          return;
        }
        getAllTravel();
      });
    },
    [getAllTravel],
  );

  // API - Update record by id
  const updateByIdTravel = useCallback(
    (id: number, payload: ITravelDTO) => {
      // Get details
      TravelService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataTravel();
        getAllTravel();
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllTravel],
  );

  // API - Delete record by id
  const deleteByIdTravel = useCallback(
    (id: number) => {
      TravelService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataTravel();
        getAllTravel();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllTravel],
  );

  // API - Delete record by ids
  const deleteIdsTravel = useCallback(
    (ids: number[]) => {
      TravelService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllTravel();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllTravel],
  );

  return (
    <TravelContext.Provider
      value={{
        // Data
        columnsTravel,
        rowsTravel,
        dataTravel,
        defaultDataTravel,
        handleDataTravel,
        // Pagination
        rowCountTravel,
        pageSizeTravel,
        pageTravel,
        handlePageSizeTravel,
        handlePageTravel,
        // Sort
        sortTravel,
        handleSortTravel,
        // Selection
        selectionTravel,
        handleSelectionTravel,
        // Filter
        defaultFilterTravel,
        hideFilterTravel,
        filterTravel,
        isOpenFilterModalTravel,
        toggleIsOpenFilterModalTravel,
        handleFilterTravel,
        // Api
        getAllTravel,
        getByIdTravel,
        createTravel,
        updateByIdTravel,
        deleteByIdTravel,
        deleteIdsTravel,
      }}
    >
      {children}
    </TravelContext.Provider>
  );
};
