/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

type ISelectVehicleSupplyTypeProps = FieldProps;

const options: ISelectOptions[] = [
  { label: 'Avulso', value: 'AVULSO' },
  { label: 'Convênio', value: 'CONVENIO' },
];

export const SelectVehicleSupplyType: React.FC<ISelectVehicleSupplyTypeProps> = ({ ...rest }) => {
  return <Select label="Tipo" defaultOption="AVULSO" dataOptions={options} {...rest} />;
};
