import { useEmployee } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { EmployeeMain } from './components/EmployeeMain';

export const EmployeePage: React.FC = () => {
  const { toggleIsOpenFilterModalEmployee } = useEmployee();

  return (
    <PageContainer>
      <PageTitle
        title="Colaboradores"
        subtitle="Gerencie o cadastro de colaboradores"
        filterAction={toggleIsOpenFilterModalEmployee}
      />

      <PageMain>
        <EmployeeMain />
      </PageMain>
    </PageContainer>
  );
};
