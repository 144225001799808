/* eslint-disable @typescript-eslint/naming-convention */
import { Alert, Grid } from '@mui/material';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Input,
  DataDialog,
  InputCurrency,
  DataPicker,
  Attachment,
  SelectPeopleType,
} from '../../../shared/components';
import { useVehicleMaintenance, useDialog } from '../../../shared/hooks';
import { IPeopleType, IVehicleMaintenanceDTO } from '../../../shared/types';
import { schemaValidation, toNumberOrZero } from '../../../shared/utils';
import { EmployeeInputSelectBase } from '../../employee/components';
import { SupplierInputSelectBase } from '../../supplier/components';
import { VehicleInputSelectBase } from '../../vehicle/components/VehicleInputSelectBase';

// Define validation fields form
const schemaPayloadVehicleMaintenance = Yup.object({
  maintenance_date: schemaValidation.dateTime.date(true),
  people_name: schemaValidation.people.name,
  vehicle_detail: schemaValidation.people.name,
  service_description: schemaValidation.commons.string(true),
  total: schemaValidation.number.float.test('is-empty', 'Informe um valor', (value) => {
    const number = Number(String(value).replace('.', '').replace(',', '.').trim());
    if (Number.isNaN(number)) return false;
    return number > 0;
  }),
});

// Create modal data for VehicleMaintenance
export const VehicleMaintenanceModal: React.FC = () => {
  // State people type
  const [peopleType, setPeopleType] = useState<IPeopleType>('FORNECEDOR');

  // Use context VehicleMaintenance
  const {
    dataVehicleMaintenance,
    defaultDataVehicleMaintenance,
    handleDataVehicleMaintenance,
    createVehicleMaintenance,
    updateByIdVehicleMaintenance,
    deleteByIdVehicleMaintenance,
  } = useVehicleMaintenance();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // On change people type
  const handleChangePeopleType = (type: IPeopleType) => setPeopleType(type);

  // Load people type
  useEffect(() => {
    if (dataVehicleMaintenance === undefined) return;
    const { people_type } = dataVehicleMaintenance;

    handleChangePeopleType(people_type);
  }, [dataVehicleMaintenance]);

  // Reset data and close modal
  const handleClose = () => handleDataVehicleMaintenance();

  // Save actions
  const handleSave = (payload: any) => {
    const normalize = { ...payload };
    normalize.service_price = toNumberOrZero(payload.service_price);
    normalize.parts_price = toNumberOrZero(payload.parts_price);
    normalize.total = toNumberOrZero(payload.total);

    const id = dataVehicleMaintenance?.id || 0;
    const data = normalize as IVehicleMaintenanceDTO;
    if (id > 0) {
      updateByIdVehicleMaintenance(id, data);
      return;
    }
    createVehicleMaintenance(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, service_description } = payload;
    dialogConfirmDanger(
      'Excluir manutenção?',
      `Deseja realmente excluir a manutenção: ${id} - ${service_description} `,
      () => deleteByIdVehicleMaintenance(id),
    );
  };

  return (
    <DataDialog
      referenceId={dataVehicleMaintenance?.id}
      referenceDisplayName="manutenção"
      initialValues={dataVehicleMaintenance || defaultDataVehicleMaintenance}
      validationSchema={schemaPayloadVehicleMaintenance}
      open={dataVehicleMaintenance !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={4}>
          <Field name="maintenance_date" component={DataPicker} label="Data *" />
        </Grid>

        <Grid item xs={12} md={8}>
          <VehicleInputSelectBase label="Veículo *" refId="vehicle_id" refName="vehicle_detail" />
        </Grid>

        <Grid item xs={12} md={4}>
          <Field
            name="people_type"
            component={SelectPeopleType}
            label="Prestador"
            value={peopleType}
            onSelect={handleChangePeopleType}
            disableCustomer
          />
        </Grid>

        <Grid item xs={12} md={8}>
          {peopleType === 'COLABORADOR' && (
            <EmployeeInputSelectBase
              baseName="people"
              label="Prestador responsável (Colaborador) *"
            />
          )}
          {peopleType === 'FORNECEDOR' && (
            <SupplierInputSelectBase
              baseName="people"
              label="Prestador responsável (Fornecedor) *"
            />
          )}
        </Grid>

        {peopleType === 'FORNECEDOR' && (
          <Grid item xs={12} md={12}>
            <Input
              label="Mecânico"
              name="mechanical"
              placeholder="Nome do mecânico responsável pelo serviço"
            />
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          <Input
            label="Serviço *"
            name="service_description"
            placeholder="Detalhes do serviço executado"
            minRows={2}
            multiline
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Input
            label="Peças"
            name="parts_description"
            placeholder="Descritivo de todas as peças utilizadas"
            minRows={2}
            multiline
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <InputCurrency label="Valor do serviço" name="service_price" />
        </Grid>

        <Grid item xs={6} md={4}>
          <InputCurrency label="Valor das peças" name="parts_price" />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputCurrency
            label="Total *"
            name="total"
            inputProps={{ style: { fontWeight: 'bold', textAlign: 'right' } }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          {dataVehicleMaintenance && dataVehicleMaintenance.id > 0 ? (
            <Attachment
              resource="vehicles/maintenances"
              resourceId={dataVehicleMaintenance?.id || 0}
              initialData={dataVehicleMaintenance?.attachments}
              disableType
            />
          ) : (
            <Alert severity="info" sx={{ my: 1 }}>
              Ao adicionar um registro, é necessário gravar para liberar acesso aos anexos
            </Alert>
          )}
        </Grid>
      </Grid>
    </DataDialog>
  );
};
