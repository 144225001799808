import create from 'zustand';

// Define interface for store
interface ICompaniesAvailableModalStore {
  isOpenCompaniesAvailableModal: boolean;
  openCompaniesAvailableModal: () => void;
  closeCompaniesAvailableModal: () => void;
}

// Create store
export const useCompaniesAvailableModalStore = create<ICompaniesAvailableModalStore>((set) => ({
  isOpenCompaniesAvailableModal: false,
  openCompaniesAvailableModal: () => set({ isOpenCompaniesAvailableModal: true }),
  closeCompaniesAvailableModal: () => set({ isOpenCompaniesAvailableModal: false }),
}));
