import { useUser } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { UserMain } from './components/UserMain';

export const UserPage: React.FC = () => {
  const { toggleIsOpenFilterModalUser } = useUser();

  return (
    <PageContainer>
      <PageTitle
        title="Usuários"
        subtitle="Gerencie o cadastro de usuários"
        filterAction={toggleIsOpenFilterModalUser}
      />

      <PageMain>
        <UserMain />
      </PageMain>
    </PageContainer>
  );
};
