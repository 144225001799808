/* eslint-disable react/require-default-props */
import {
  AddIcCall,
  ContactPage,
  Delete,
  Email,
  Facebook,
  Instagram,
  Language,
  Phone,
  PhoneIphone,
  Telegram,
  WhatsApp,
} from '@mui/icons-material';
import {
  Alert,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  IPeopleContactData,
  IPeopleContactList,
  IPeopleContactType,
  IPeopleType,
} from '../../types';
import { Button, IconButton } from '../inputs';
import { Div, P } from '../display-data';
import { useAuth, useDialog, usePeopleContact } from '../../hooks';
import { toCapitalize } from '../../utils';
import { PeopleContactModal } from './PeopleContactModal';

interface IPeopleContactProps {
  peopleType: IPeopleType;
  peopleId: number;
  initialData?: IPeopleContactList[];
}

export const PeopleContact: React.FC<IPeopleContactProps> = ({
  peopleType,
  peopleId,
  initialData,
}) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const { dialogConfirmDanger } = useDialog();

  const {
    rowsPeopleContact,
    defaultDataPeopleContact,
    handleDataPeopleContact,
    HandleLoadStoragePeopleContact,
    getAllPeopleContact,
    deleteByIdPeopleContact,
  } = usePeopleContact();

  const theme = useTheme();

  useEffect(() => {
    if (!user.token || canSearch) return;
    if (initialData) {
      HandleLoadStoragePeopleContact(initialData);
      return;
    }
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllPeopleContact(peopleType, peopleId);
  }, [canSearch]);

  const handleAddData = () => handleDataPeopleContact(defaultDataPeopleContact);

  const handleEditData = (data: IPeopleContactData) => handleDataPeopleContact(data);

  const handleDeleteData = (data: IPeopleContactData) => {
    const { id, contact } = data;
    dialogConfirmDanger('Excluir contato?', `Deseja realmente excluir o contato: ${contact}`, () =>
      deleteByIdPeopleContact(peopleType, peopleId, id),
    );
  };

  useEffect(() => {
    return () => {
      handleDataPeopleContact();
      getAllPeopleContact(peopleType, 0);
    };
  }, []);

  const getIconContact = (type: IPeopleContactType) => {
    if (type === 'TELEFONE') return <Phone />;
    if (type === 'CELULAR') return <PhoneIphone />;
    if (type === 'EMAIL') return <Email />;
    if (type === 'WHATSAPP') return <WhatsApp />;
    if (type === 'TELEGRAM') return <Telegram />;
    if (type === 'INSTAGRAM') return <Instagram />;
    if (type === 'FACEBOOK') return <Facebook />;
    if (type === 'SITE') return <Language />;
    return <ContactPage />;
  };

  return (
    <Div>
      <PeopleContactModal peopleType={peopleType} peopleId={peopleId} />

      <Toolbar style={{ paddingRight: 0, paddingLeft: 0, marginBottom: theme.spacing(1) }}>
        <Div flex={1} />
        <Button
          label="Adicionar contato"
          tooltip={`Adicionar contato para o ${peopleType.toLowerCase()}`}
          startIcon={<AddIcCall />}
          onClick={handleAddData}
          disableFocusRipple
        />
      </Toolbar>

      {rowsPeopleContact.length === 0 ? (
        <Alert severity="info" variant="outlined">
          {`Este ${peopleType.toLowerCase()} ainda não possuí contatos como telefone ou e-mail cadastrado, clique em `}
          <strong>ADICIONAR CONTATO</strong> para incluir um registro.
        </Alert>
      ) : (
        <List sx={{ width: '100%' }}>
          {rowsPeopleContact.map((contact) => (
            <Paper
              key={contact.id}
              elevation={2}
              sx={{
                mb: theme.spacing(1),
                cursor: 'pointer',
                bgcolor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : null,
                '& .MuiListItem-root:hover': {
                  bgcolor:
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[800],
                },
              }}
            >
              <ListItem
                sx={{
                  borderRadius: '4px',
                  '& .MuiListItemButton-root:hover': {
                    bgcolor: 'inherit',
                  },
                }}
                disablePadding
                alignItems="flex-start"
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    tooltip="Deletar contato"
                    onClick={() => handleDeleteData(contact)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemButton
                  onClick={() => handleEditData(contact)}
                  disableRipple
                  disableTouchRipple
                  disableGutters
                  sx={{ px: theme.spacing(2), py: theme.spacing(1) }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: contact.is_default
                          ? theme.palette.secondary.main
                          : theme.palette.primary.main,
                      }}
                    >
                      {getIconContact(contact.type)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<P ellipsisOnMobile="13rem">{contact.contact}</P>}
                    secondary={
                      <>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {toCapitalize(contact.type)}
                          <strong>{contact.name ? ` - ${contact.name}` : ''}</strong>
                        </Typography>
                        {contact.complement ? ` - "${contact.complement}"` : ''}
                        {contact.is_default ? ` - (Principal)` : ''}
                      </>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </Paper>
          ))}
        </List>
      )}
    </Div>
  );
};
