/* eslint-disable react/jsx-props-no-spreading */
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { InputSelect, SelectDialog, IInputProps } from '../../../shared/components';
import { useSupplier } from '../../../shared/hooks';
import { IDataGridColumnsHidden, IDataGridRowParams } from '../../../shared/types';
import { SupplierDataGrid } from './SupplierDataGrid';
import { SupplierFilterModal } from './SupplierFilterModal';

export interface ISupplierInputSelectBaseProps extends Omit<IInputProps, 'name' | 'onSelect'> {
  label?: string;
  title?: string;
  baseName?: string;
  enableAdd?: boolean;
}

const defaultBaseName = 'supplier';

const defaultTitle = 'Fornecedor';

const hiddenColumnsSupplier: IDataGridColumnsHidden = {
  nickname: false,
  business_type: false,
  phone: false,
  neighborhood: false,
  state: false,
  is_active: false,
};

export const SupplierInputSelectBase: React.FC<ISupplierInputSelectBaseProps> = ({
  label = defaultTitle,
  title = defaultTitle,
  baseName = defaultBaseName,
  disabled,
  enableAdd = false,
  ...rest
}) => {
  // Define name
  const [fieldId] = useState(`${baseName}_id`);
  const [fieldName] = useState(`${baseName}_name`);

  // State for control opened search dialog
  const [isOpen, setIsOpen] = useState(false);

  // Use hook formik context
  const { setFieldValue } = useFormikContext();

  // Use context
  const {
    toggleIsOpenFilterModalSupplier,
    handleFilterSupplier,
    defaultDataSupplier,
    handleDataSupplier,
  } = useSupplier();

  // Open search dialog
  const handleOpen = () => {
    if (disabled) return;
    setIsOpen(true);
  };

  // Close search modal
  const handleClose = () => setIsOpen(false);

  // On click in add button
  const handleAdd = () => handleDataSupplier(defaultDataSupplier);

  // On click in filter button
  const handleResetFilter = () => handleFilterSupplier({});

  // Apply filters
  const handleApplyFilterName = (filter: any) => handleFilterSupplier({ name: filter });

  // On select record
  const handleSelect = (data: IDataGridRowParams) => {
    setFieldValue(fieldId, data.row.id);
    setFieldValue(fieldName, data.row.name);
    handleClose();
  };

  return (
    <>
      <SupplierFilterModal />

      <SelectDialog
        title={title}
        open={isOpen}
        onClose={handleClose}
        applyFilter={handleApplyFilterName}
        showFilter={toggleIsOpenFilterModalSupplier}
        resetFilter={handleResetFilter}
        add={enableAdd ? handleAdd : undefined}
      >
        <SupplierDataGrid
          simpleMode
          onRowClick={handleSelect}
          columnVisibilityModel={hiddenColumnsSupplier}
        />
      </SelectDialog>

      <InputSelect
        label={label}
        fieldId={fieldId}
        fieldName={fieldName}
        onClick={handleOpen}
        disabled={disabled}
        {...rest}
      />
    </>
  );
};
