import { Backdrop, SpeedDial } from '@mui/material';
import { useMemo, useState } from 'react';
import { IDataGridSelection } from '../../types';
import { FloatingButton } from '../inputs';

interface IDataGridSelectionProps {
  selectionMode?: boolean;
  rowSelection?: IDataGridSelection;
  singleAction?: boolean;
  onSelectionModeChange: (state?: boolean) => void;
}

export const DataGridSelection: React.FC<IDataGridSelectionProps> = ({
  selectionMode,
  rowSelection,
  singleAction,
  onSelectionModeChange,
  children,
}) => {
  // State for open speed dial
  const [isOpen, setIsOpen] = useState(false);

  // Change state open speed dial
  const handleOpen = () => setIsOpen(true);

  // Close speed dial
  const handleClose = () => setIsOpen(false);

  // Change state open speed dial
  const handleToggleIsOpen = () => setIsOpen((prevState) => !prevState);

  // Cancel selection mode
  const handleCancel = () => onSelectionModeChange(false);

  // Check if has selected record
  const hasSelected = useMemo(() => rowSelection && rowSelection?.length > 0, [rowSelection]);

  return selectionMode ? (
    <>
      {!singleAction && (
        <>
          <Backdrop open={isOpen} />

          <SpeedDial
            open={isOpen}
            hidden
            ariaLabel="Registros selecionados"
            sx={{ position: 'absolute', bottom: 70, right: 30 }}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            {children}
          </SpeedDial>
        </>
      )}

      {!hasSelected && (
        <FloatingButton
          color="default"
          variant="extended"
          label="Modo de seleção"
          tooltip="Cancelar seleção"
          icon="disabled_by_default"
          onClick={handleCancel}
        >
          Cancelar seleção
        </FloatingButton>
      )}

      {hasSelected && !singleAction ? (
        <FloatingButton
          color="secondary"
          variant="extended"
          label="Registros selecionados"
          icon="done_all"
          onClick={handleToggleIsOpen}
        >
          {`${rowSelection?.length} selecionado(s)`}
        </FloatingButton>
      ) : null}

      {hasSelected && singleAction ? children : null}
    </>
  ) : null;
};
