export const pinMask = (value: string) => {
  return value.replace(/\D/g, '').replace(/^(\d{3})(\d)/, '$1-$2');
};

export const cnpjMask = (value: string) => {
  const cnpj = value.replace(/\D/g, '');
  if (cnpj.length <= 2) return cnpj;
  if (cnpj.length <= 5) return cnpj.replace(/^(\d{2})\.?(\d)/, '$1.$2');
  if (cnpj.length <= 8) return cnpj.replace(/^(\d{2})\.?(\d{3})\.?(\d)/, '$1.$2.$3');
  if (cnpj.length <= 12) return cnpj.replace(/^(\d{2})\.?(\d{3})\.?(\d{3})\/?(\d)/, '$1.$2.$3/$4');

  return cnpj.replace(/^(\d{2})\.?(\d{3})\.?(\d{3})\/?(\d{4})-?(\d)/, '$1.$2.$3/$4-$5');
};

export const cpfMask = (value: string) => {
  const cpf = value.replace(/\D/g, '');
  if (cpf.length <= 3) return cpf;
  if (cpf.length <= 6) return cpf.replace(/^(\d{3})\.?(\d)/, '$1.$2');
  if (cpf.length <= 9) return cpf.replace(/^(\d{3})\.?(\d{3})\.?(\d)/, '$1.$2.$3');
  return cpf.replace(/^(\d{3})\.?(\d{3})\.?(\d{3})(\d)/, '$1.$2.$3-$4');
};

export const rgMask = (value: string) => {
  const onlyNumbers = value.replace(/\D+/g, '');
  const lastCharIsX =
    value.charAt(value.length - 1).toUpperCase() === 'X' && onlyNumbers.length === 8;
  const rg = onlyNumbers + (lastCharIsX === true ? 'X' : '');
  if (rg.length <= 2) return rg;
  if (rg.length <= 5) return rg.replace(/^(\d{2})\.?(\d)/, '$1.$2');
  if (rg.length <= 8) return rg.replace(/^(\d{2})\.?(\d{3})\.?(\d)/, '$1.$2.$3');
  return rg.replace(/^(\d{2})\.?(\d{3})\.?(\d{3})-?([xX\d]{1})$/, '$1.$2.$3-$4');
};

export const phoneMask = (value: string) => {
  const phone = value.replace(/\D/g, '');
  if (phone.length <= 2) return phone.replace(/^\(?(\d)/, '($1');
  if (phone.length <= 6) return phone.replace(/^\(?(\d{2})\)?(\d)/, '($1)$2');
  if (phone.length <= 10) return phone.replace(/^\(?(\d{2})\)?(\d{4})-?(\d)/, '($1)$2-$3');
  return phone.replace(/^\(?(\d{2})\)?(\d{5})-?(\d)/, '($1)$2-$3');
};

export const postalCodeMask = (value: string) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d)/, '$1-$2');
};

export const currencyMask = (value: string) => {
  return (Number(value.replace(/\D/g, '')) / 100).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const quantityMask = (value: string, decimals?: number) => {
  let quantity = Number(value.replace(/\D/g, ''));
  if (decimals && decimals > 0) quantity /= 10 ** decimals;
  return quantity.toLocaleString('pt-BR', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

export const vehiclePlateMask = (value: string) => {
  const plate = value
    .trim()
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '');
  if (plate.length <= 3) return plate.replace(/[^A-Z]/g, '');
  return `${plate.substring(0, 3).replace(/[^A-Z]/g, '')}-${plate.substring(3, 8)}`;
};
