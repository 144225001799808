/* eslint-disable react/require-default-props */
import { AccountBalance, AddCard, Delete } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IPeoplePaymentAccountData, IPeoplePaymentAccountList, IPeopleType } from '../../types';
import { Button, IconButton } from '../inputs';
import { Div } from '../display-data';
import { useAuth, useDialog, usePeoplePaymentAccount } from '../../hooks';
import { toCapitalize } from '../../utils';
import { PeoplePaymentAccountModal } from './PeoplePaymentAccountModal';

interface IPeoplePaymentAccountProps {
  peopleType?: IPeopleType;
  peopleId: number;
  initialData?: IPeoplePaymentAccountList[];
}

export const PeoplePaymentAccount: React.FC<IPeoplePaymentAccountProps> = ({
  peopleType = 'COLABORADOR',
  peopleId,
  initialData,
}) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const { dialogConfirmDanger } = useDialog();

  const {
    rowsPeoplePaymentAccount,
    defaultDataPeoplePaymentAccount,
    handleDataPeoplePaymentAccount,
    HandleLoadStoragePeoplePaymentAccount,
    getAllPeoplePaymentAccount,
    deleteByIdPeoplePaymentAccount,
  } = usePeoplePaymentAccount();

  const theme = useTheme();

  useEffect(() => {
    if (!user.token || canSearch) return;
    if (initialData) {
      HandleLoadStoragePeoplePaymentAccount(initialData);
      return;
    }
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllPeoplePaymentAccount(peopleType, peopleId);
  }, [canSearch]);

  const handleAddData = () => handleDataPeoplePaymentAccount(defaultDataPeoplePaymentAccount);

  const handleEditData = (data: IPeoplePaymentAccountData) => handleDataPeoplePaymentAccount(data);

  const handleDeleteData = (data: IPeoplePaymentAccountData) => {
    const { id, account } = data;
    dialogConfirmDanger('Excluir conta?', `Deseja realmente excluir a conta: ${account}`, () =>
      deleteByIdPeoplePaymentAccount(peopleType, peopleId, id),
    );
  };

  useEffect(() => {
    return () => {
      handleDataPeoplePaymentAccount();
      getAllPeoplePaymentAccount(peopleType, 0);
    };
  }, []);

  return (
    <Div>
      <PeoplePaymentAccountModal peopleType={peopleType} peopleId={peopleId} />

      <Toolbar style={{ paddingRight: 0, paddingLeft: 0, marginBottom: theme.spacing(1) }}>
        <Div flex={1} />
        <Button
          label="Adicionar conta"
          tooltip={`Adicionar conta para o ${peopleType.toLowerCase()}`}
          startIcon={<AddCard />}
          onClick={handleAddData}
          disableFocusRipple
        />
      </Toolbar>

      {rowsPeoplePaymentAccount.length === 0 ? (
        <Alert severity="info" variant="outlined">
          {`Este ${peopleType.toLowerCase()} ainda não possuí contas de pagamento cadastrada, clique em `}
          <strong>ADICIONAR CONTA</strong> para incluir um registro.
        </Alert>
      ) : (
        <List sx={{ width: '100%' }}>
          {rowsPeoplePaymentAccount.map((account) => (
            <Paper
              key={account.id}
              elevation={2}
              sx={{
                mb: theme.spacing(1),
                cursor: 'pointer',
                bgcolor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : null,
                '& .MuiListItem-root:hover': {
                  bgcolor:
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[800],
                },
              }}
            >
              <ListItem
                sx={{
                  borderRadius: '4px',
                  '& .MuiListItemButton-root:hover': {
                    bgcolor: 'inherit',
                  },
                }}
                disablePadding
                alignItems="flex-start"
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    tooltip="Deletar conta"
                    onClick={() => handleDeleteData(account)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemButton
                  onClick={() => handleEditData(account)}
                  disableRipple
                  disableTouchRipple
                  disableGutters
                  sx={{ px: theme.spacing(2), py: theme.spacing(1) }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: account.is_default
                          ? theme.palette.secondary.main
                          : theme.palette.primary.main,
                      }}
                    >
                      <AccountBalance />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={account.account}
                    secondary={
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {toCapitalize(account.type)}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </Paper>
          ))}
        </List>
      )}
    </Div>
  );
};
