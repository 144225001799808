/* eslint-disable @typescript-eslint/naming-convention */
import {
  Android,
  Delete,
  DesktopWindows,
  DeviceUnknown,
  LaptopMac,
  PhoneIphone,
} from '@mui/icons-material';
import {
  Alert,
  Avatar,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { Dialog } from '../../../shared/components';
import { useUser, useDialog, useAuth } from '../../../shared/hooks';
import { IUserPinList } from '../../../shared/types';
import { formatterDateTimeBR } from '../../../shared/utils';

interface IUserPerfilDeviceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const getIconDevice = (os: string) => {
  const device = os.toLowerCase();
  if (device === 'windows') return <DesktopWindows />;
  if (device === 'android') return <Android />;
  if (device === 'mac') return <LaptopMac />;
  if (device === 'ios') return <PhoneIphone />;
  return <DeviceUnknown />;
};

const isCurrentPin = (userPin: string, devicePin: string) => {
  const currentPin = userPin.replace(/\D/g, '');
  const testPin = devicePin.replace(/\D/g, '');

  return currentPin === testPin;
};

// Create modal data for User
export const UserPerfilDeviceModal: React.FC<IUserPerfilDeviceModalProps> = ({
  isOpen,
  onClose,
}) => {
  // Use context User
  const { getAllUserPins, rowsUserPin, deleteUserPin } = useUser();

  // Use auth
  const { user } = useAuth();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Use theme
  const theme = useTheme();

  // Load initial data
  useEffect(() => {
    if (!isOpen || !user) return;
    getAllUserPins();
  }, [user, isOpen]);

  // Update state
  const handleDelete = (device: IUserPinList) => {
    dialogConfirmDanger('Desativar', `Deseja desativar o dispositivo ${device.os}`, () =>
      deleteUserPin(device.pin),
    );
  };

  return (
    <Dialog title="Dispositivos autorizados" open={isOpen} close={onClose} maxWidth="xs" fullWidth>
      <DialogContent dividers>
        {rowsUserPin.length === 0 ? (
          <Alert severity="info" variant="outlined">
            Você não possuí dispositivos autorizados
          </Alert>
        ) : (
          <>
            {!user.pin || user.pin === '' || user.pin === 'Not applicable' ? (
              <Alert severity="error" sx={{ my: theme.spacing(1) }}>
                Atualmente você não esta utilizando a autenticação em duas etapas
              </Alert>
            ) : null}

            <List sx={{ width: '100%' }}>
              {rowsUserPin.map((device) => (
                <ListItem key={device.pin} divider>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: isCurrentPin(user.pin || '', device.pin)
                          ? theme.palette.success.main
                          : null,
                      }}
                    >
                      {getIconDevice(device.os)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`(***-${device.pin.substring(device.pin.length - 3)}) - ${device.os}${
                      isCurrentPin(user.pin || '', device.pin) ? ' - Atual' : ''
                    }`}
                    secondary={`Último acesso: ${formatterDateTimeBR(device.last_access)}`}
                  />
                  <IconButton onClick={() => handleDelete(device)}>
                    <Delete />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
