import { DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataDialog, PeopleAddress, PeopleContact, TabPanel } from '../../../shared/components';
import { useSupplier, useDialog } from '../../../shared/hooks';
import { ISupplierDTO } from '../../../shared/types';
import { SupplierModalTabs } from './SupplierModalTabs';
import { SupplierModalDataTab } from './SupplierModalDataTab';

// Get form id
const formData = 'data-dialog-form-supplier';

// Create modal data for Supplier
export const SupplierModal: React.FC = () => {
  // State for tab controls
  const [tabIndex, setTabIndex] = useState(0);

  // State for edit mode
  const [isEditMode, setIsEditMode] = useState(false);

  // Use context Supplier
  const {
    dataSupplier,
    handleDataSupplier,
    getAllSupplier,
    createSupplier,
    updateByIdSupplier,
    deleteByIdSupplier,
  } = useSupplier();

  // Check if is edit mode
  useEffect(() => {
    const hasSupplier = !!(dataSupplier && dataSupplier.id > 0);
    return setIsEditMode(hasSupplier);
  }, [dataSupplier]);

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Change tab index
  const handleChangeTabIndex = (event: React.SyntheticEvent, index: number) => setTabIndex(index);

  // Reset data and close modal
  const handleClose = () => {
    getAllSupplier();
    handleDataSupplier();
    if (tabIndex > 0) setTabIndex(0);
  };

  // Save actions
  const handleSave = (payload: any) => {
    const id = dataSupplier?.id || 0;
    const data = payload as ISupplierDTO;
    if (payload.person_type === 'JURIDICA') {
      data.cpf = null;
      data.rg = null;
    } else {
      data.cnpj = null;
      data.ie = null;
    }
    if (id > 0) {
      updateByIdSupplier(id, data);
      return;
    }
    createSupplier(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, name } = payload;
    dialogConfirmDanger(
      'Excluir Fornecedor?',
      `Deseja realmente excluir o Fornecedor: ${id} - ${name} `,
      () => deleteByIdSupplier(id),
    );
  };

  return (
    <DataDialog
      referenceId={dataSupplier?.id}
      referenceDisplayName="fornecedor"
      initialValues={dataSupplier}
      open={dataSupplier !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
      formId={formData}
      withTabs
    >
      <SupplierModalTabs value={tabIndex} onChange={handleChangeTabIndex} isEditMode={isEditMode} />
      <DialogContent
        dividers
        sx={{
          backgroundColor: (theme) => {
            if (tabIndex === 0) return '';
            return theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900];
          },
        }}
      >
        <TabPanel index={0} tabActive={tabIndex} forceRender>
          <SupplierModalDataTab formId={formData} onSave={handleSave} isEditMode={isEditMode} />
        </TabPanel>

        <TabPanel index={1} tabActive={tabIndex}>
          <PeopleContact peopleType="FORNECEDOR" peopleId={dataSupplier?.id || 0} />
        </TabPanel>

        <TabPanel index={2} tabActive={tabIndex}>
          <PeopleAddress peopleType="FORNECEDOR" peopleId={dataSupplier?.id || 0} />
        </TabPanel>
      </DialogContent>
    </DataDialog>
  );
};
