import { LightMode } from '@mui/icons-material';
import { Card, CardActions, CardContent, Grid, useTheme as useThemeMui } from '@mui/material';
import { Button, H1, P } from '../../../shared/components';
import { useTheme } from '../../../shared/hooks';

// Create card change to theme light
export const CardThemeLight: React.FC = () => {
  // use hook company
  const theme = useThemeMui();

  // Use toggle theme
  const { toggleTheme } = useTheme();

  // Toggle theme
  const handleClick = () => toggleTheme();

  return (
    <Grid item lg={4} sm={5} xl={6} xs={12}>
      <Card sx={{ height: '100%', bgcolor: theme.palette.grey[900] }}>
        <CardContent sx={{ p: 1, height: '105px', bgcolor: theme.palette.primary.main }}>
          <LightMode sx={{ color: theme.palette.primary.dark, height: '90px', width: '90px' }} />
        </CardContent>

        <CardContent>
          <H1 variant="h5">Modo claro</H1>
          <P>Altere o teme para o modo claro!</P>
        </CardContent>

        <CardActions sx={{ pt: 0 }}>
          <Button variant="text" label="Ativar modo claro" onClick={handleClick} />
        </CardActions>
      </Card>
    </Grid>
  );
};
