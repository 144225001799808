/* eslint-disable react/jsx-props-no-spreading */
import { ImgHTMLAttributes } from 'react';

// Extend props from img tag html
type IImgProps = ImgHTMLAttributes<HTMLImageElement>;

// Create component Img Custom
export const Img: React.FC<IImgProps> = ({ ...rest }) => {
  return <img src="" alt="" loading="lazy" {...rest} />;
};
