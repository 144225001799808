import { ISchemaApiError } from '../../../types';

export const FinancialSchemaApiError: ISchemaApiError[] = [
  {
    statusCode: 404,
    errors: [
      {
        error: 'People not found',
        message: 'Pagador / beneficiário não localizado',
      },
    ],
  },
];
