import { useDelivery } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { DeliveryMain } from './components/DeliveryMain';

export const DeliveryPage: React.FC = () => {
  const { toggleIsOpenFilterModalDelivery } = useDelivery();

  return (
    <PageContainer>
      <PageTitle
        title="Entregas"
        subtitle="Gerencie os serviços de entrega"
        filterAction={toggleIsOpenFilterModalDelivery}
      />

      <PageMain>
        <DeliveryMain />
      </PageMain>
    </PageContainer>
  );
};
