import { IAuthSignInPayload } from '../../../types';
import { Api, makeApiError } from '../config';
import { AuthSchemaApiError } from '../schemas';

// Send recover password request
const recoverPassword = async (username: string): Promise<any> => {
  try {
    const response = await Api().post('/auth/recover-password', { username, platform: 'ADM' });
    return response;
  } catch (error: any) {
    return makeApiError(error, AuthSchemaApiError);
  }
};

// Reset password
const resetPassword = async (password: string, resetToken: string): Promise<any> => {
  try {
    // Create custom header for request for reset password
    const headers: any = {};
    headers.Authorization = `Bearer ${resetToken}`;

    const response = await Api().post('/auth/reset-password', { password }, { headers });
    return response;
  } catch (error: any) {
    return makeApiError(error, AuthSchemaApiError);
  }
};

// Send login request
const singIn = async (
  { username, password, pin }: IAuthSignInPayload,
  activeNewPin?: boolean,
): Promise<any> => {
  try {
    // Create custom header for request for activation PIN
    const headers: any = {};
    if (activeNewPin) {
      headers['X-PIN-Activate'] = 'true';
    }

    // Send request
    const response = await Api().post('/auth/token', { username, password, pin }, { headers });
    return response;
  } catch (error: any) {
    return makeApiError(error, AuthSchemaApiError);
  }
};

export const AuthService = { recoverPassword, resetPassword, singIn };
