import { Toolbar, useTheme } from '@mui/material';
import { AdminPanelSettings } from '@mui/icons-material';
import { useEffect } from 'react';
import { IPeopleType } from '../../types/PeopleTypes';
import { Div } from '../display-data/Div';
import { Button } from '../inputs/Button';
import { UserModal, UserMain } from '../../../pages/user/components';
import { useUser } from '../../hooks';

interface IPeopleUserProps {
  peopleType: IPeopleType;
  peopleId: number;
}

export const PeopleUser: React.FC<IPeopleUserProps> = ({ peopleId, peopleType }) => {
  const theme = useTheme();

  const { defaultDataUser, handleDataUser, handleClearStorageUser } = useUser();

  const handleAddData = () => {
    const data = defaultDataUser;
    data.people_id = peopleId;
    data.people_name = 'NOVO USUARIO';
    data.type = peopleType;
    handleDataUser(data);
  };

  useEffect(() => handleClearStorageUser(), []);

  return (
    <Div>
      <UserModal fixedPeopleType={peopleType} fixedPeopleId={peopleId} />

      <Toolbar style={{ paddingRight: 0, paddingLeft: 0, marginBottom: theme.spacing(1) }}>
        <Div flex={1} />
        <Button
          label="Adicionar usuário"
          tooltip={`Adicionar usuário para o ${peopleType.toLowerCase()}`}
          startIcon={<AdminPanelSettings />}
          onClick={handleAddData}
          disableFocusRipple
        />
      </Toolbar>

      <UserMain embedded initialFilter={{ people_id: peopleId }} />
    </Div>
  );
};
