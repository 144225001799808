/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Business, ChevronRight } from '@mui/icons-material';
import {
  Alert,
  DialogContent,
  DialogContentText,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useCallback } from 'react';
import { P } from '../display-data';
import { useAuth } from '../../hooks/useAuth';
import { useCompaniesAvailableModalStore } from '../../store';
import { Dialog } from '../feedback';

interface ICompaniesAvailableItemProps {
  label: string;
  subtitle: string;
  onClick: () => void;
}

// Create component for listing available companies
export const CompaniesAvailableItem: React.FC<ICompaniesAvailableItemProps> = ({
  label,
  subtitle,
  onClick,
}) => {
  return (
    <ListItemButton onClick={onClick}>
      <ListItemIcon>
        <Business />
      </ListItemIcon>
      <ListItemText>
        <P style={{ fontWeight: 800 }}>{label}</P>
        <P>{subtitle}</P>
      </ListItemText>
      <ChevronRight />
    </ListItemButton>
  );
};

// Modal for activation pin
export const CompaniesAvailableModal: React.FC = () => {
  // Use Store
  const { isOpenCompaniesAvailableModal, closeCompaniesAvailableModal } =
    useCompaniesAvailableModalStore();

  // Use Auth Context
  const { user } = useAuth();

  const handleClick = useCallback(() => closeCompaniesAvailableModal(), []);

  return (
    <Dialog
      title="Selecione uma empresa"
      open={isOpenCompaniesAvailableModal}
      close={closeCompaniesAvailableModal}
    >
      <DialogContent dividers>
        <DialogContentText>
          <Alert severity="info">No momento apenas uma empresa está disponível.</Alert>
        </DialogContentText>
        <List>
          <CompaniesAvailableItem
            label={user.payload?.companies.name!}
            subtitle={`${user.payload?.companies.address.city} / ${user.payload?.companies.address.state}`}
            onClick={handleClick}
          />
        </List>
      </DialogContent>
    </Dialog>
  );
};
