/* eslint-disable react/no-array-index-key */
import { FilterAlt } from '@mui/icons-material';
import { Grid, IconButton, Paper, Toolbar, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDeviceIsMobile } from '../../hooks';
import { IDataGridColumns, IDataGridFilterOptions } from '../../types';
import { formatterDateBR, getFilterParams } from '../../utils';
import { Div } from '../display-data';
import { Chip } from '../feedback';
import { Button } from '../inputs';

// Define props
interface IDataGridFiltersProps {
  columns: IDataGridColumns;
  filters: object;
  hideFilters?: string[];
  onRemoveFilter: (filter: string) => void;
}

// Create component
export const DataGridFilters: React.FC<IDataGridFiltersProps> = ({
  columns,
  filters,
  hideFilters,
  onRemoveFilter,
}) => {
  // Use state for filters
  const [filterOptions, setFilterOptions] = useState<IDataGridFilterOptions[] | null>(null);

  // Use theme
  const theme = useTheme();

  // Use hook device type
  const deviceIsMobile = useDeviceIsMobile();

  // Load filter list
  useEffect(() => {
    // Clear filter list
    setFilterOptions(null);
    // Get only fields with values
    const onlyFilterWithValue = getFilterParams(filters);
    // Generate list
    const newListFilter: IDataGridFilterOptions[] = [];
    // For in keys obj filters
    Object.keys(onlyFilterWithValue).forEach((key: string) => {
      if (onlyFilterWithValue[key]) {
        // Check if omit
        if (hideFilters?.includes(key)) return;
        // Get name from column
        const name = key;
        // Get label from column
        const label = columns.find((column) => column.field === key)?.headerName || key;
        // Get value from filters
        const valueDefault = onlyFilterWithValue[key] || '---';
        const value = valueDefault instanceof Date ? formatterDateBR(valueDefault) : valueDefault;
        // Push status
        newListFilter.push({ name, label, value });
      }
    });
    // Update status
    if (newListFilter.length > 0) setFilterOptions(newListFilter);
  }, [filters]);

  return filterOptions ? (
    <Toolbar
      component={Paper}
      elevation={2}
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        mb: theme.spacing(2),
      }}
    >
      <Div isFlexRow alignItems="center">
        {!deviceIsMobile && (
          <IconButton disabled>
            <FilterAlt />
          </IconButton>
        )}

        <Grid container spacing={1} sx={{ py: 2 }}>
          {filterOptions?.map((filter, index) => (
            <Grid item key={index}>
              <Chip
                label={`${filter.label}: ${filter.value}`}
                onClick={() => onRemoveFilter(filter.name)}
                onDelete={() => onRemoveFilter(filter.name)}
                tooltip="Remover este filtro"
              />
            </Grid>
          ))}

          <Grid item>
            <Button
              variant="text"
              size="small"
              label="Limpar filtros"
              tooltip="Remover todos os filtros"
              onClick={() => onRemoveFilter('*')}
            />
          </Grid>
        </Grid>
      </Div>
    </Toolbar>
  ) : (
    <span />
  );
};
