import {
  IApiResponseWithPagination,
  IFinancialData,
  IFinancialList,
  IFinancialDTO,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { FinancialSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<IFinancialList> | ApiError> => {
  try {
    const response = await Api().get('/financial', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, FinancialSchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<IFinancialData | ApiError> => {
  try {
    const response = await Api().get(`/financial/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, FinancialSchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: IFinancialDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/financial', payload, { headers });
    return response.data.financial_category_id;
  } catch (error: any) {
    return makeApiError(error, FinancialSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: IFinancialDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/financial/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, FinancialSchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/financial/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, FinancialSchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/financial/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, FinancialSchemaApiError);
  }
};

export const FinancialService = {
  getAll,
  getById,
  create,
  updateById,
  deleteById,
  deleteIds,
};
