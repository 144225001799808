/* eslint-disable @typescript-eslint/naming-convention */
import { Alert, Grid } from '@mui/material';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Input,
  SelectVehicleOwnerType,
  SelectPeopleType,
  InputMask,
  FormSuite,
} from '../../../shared/components';
import { useVehicle } from '../../../shared/hooks';
import { IPeopleType, IVehicleOwnerType } from '../../../shared/types';
import { schemaValidation, toCapitalize } from '../../../shared/utils';
import { CustomerInputSelectBase } from '../../customer/components/CustomerInputSelectBase';
import { EmployeeInputSelectBase } from '../../employee/components/EmployeeInputSelectBase';
import { SupplierInputSelectBase } from '../../supplier/components/SupplierInputSelectBase';

// Define interface for modal
interface IVehicleModalDataTabProps {
  formId: string;
  fixedPeople?: boolean;
  isEditMode?: boolean;
  onSave: (data: object) => void;
}

// Define validation fields form
const schemaPayloadVehicle = Yup.object({
  type: Yup.string().required().oneOf(['PROPRIO', 'TERCEIRO']),
  plate: schemaValidation.vehicles.plate(true),
  model: schemaValidation.commons.string(true),
  owner_name: Yup.string().when('type', {
    is: 'TERCEIRO',
    then: schemaValidation.people.name,
  }),
});

// Create modal data for Vehicle
export const VehicleModalDataTab: React.FC<IVehicleModalDataTabProps> = ({
  formId,
  fixedPeople,
  isEditMode,
  onSave,
}) => {
  // State for vehicle owner type
  const [ownerType, setOwnerType] = useState<IVehicleOwnerType>('PROPRIO');

  // State for people type
  const [peopleType, setPeopleType] = useState<IPeopleType>('COLABORADOR');

  // Use context Vehicle
  const { dataVehicle, defaultDataVehicle } = useVehicle();

  // On change owner type
  const handleChangeOwnerType = (value: IVehicleOwnerType) => setOwnerType(value);

  // On change people type
  const handleChangePeopleType = (value: IPeopleType) => setPeopleType(value);

  // Load initial data
  useEffect(() => {
    if (dataVehicle === undefined) return;
    const { owner_type, type } = dataVehicle;
    const owner: IVehicleOwnerType = type || 'PROPRIO';
    handleChangeOwnerType(owner);
    const people: IPeopleType =
      !owner_type || owner_type === 'EMPRESA' ? 'COLABORADOR' : owner_type;
    handleChangePeopleType(people);
  }, [dataVehicle]);

  return (
    <FormSuite
      formId={formId}
      initialValues={dataVehicle || defaultDataVehicle}
      validationSchema={schemaPayloadVehicle}
      submit={onSave}
    >
      {!isEditMode && (
        <Alert severity="info">
          Ao adicionar um veículo, é necessário gravar para liberar acesso as demais abas.
        </Alert>
      )}
      <input type="hidden" name="id" />
      <input type="hidden" name="is_active" />
      <Grid container columnSpacing={2}>
        <Grid item xs={5} md={4}>
          <InputMask autoFocus label="Placa *" name="plate" mask="plate" maxLength={8} />
        </Grid>

        <Grid item xs={7} md={8}>
          <Input label="Renavam" name="registration_number" />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input label="Marca" name="brand" />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input label="Modelo *" name="model" />
        </Grid>

        {fixedPeople ? (
          <>
            <input type="hidden" name="type" />
            <input type="hidden" name="owner_type" />
            <input type="hidden" name="owner_id" />
            <input type="hidden" name="owner_name" />
          </>
        ) : (
          <>
            <Grid item xs={12} md={12}>
              <Field
                name="type"
                component={SelectVehicleOwnerType}
                value={ownerType}
                onSelect={handleChangeOwnerType}
              />
            </Grid>

            {ownerType === 'TERCEIRO' && (
              <>
                <Grid item xs={12} md={4}>
                  <Field
                    name="owner_type"
                    component={SelectPeopleType}
                    label="Tipo do proprietário"
                    value={peopleType}
                    onSelect={handleChangePeopleType}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  {peopleType === 'CLIENTE' && (
                    <CustomerInputSelectBase
                      baseName="owner"
                      label={`Proprietário (${toCapitalize(peopleType)}) *`}
                    />
                  )}
                  {peopleType === 'COLABORADOR' && (
                    <EmployeeInputSelectBase
                      baseName="owner"
                      label={`Proprietário (${toCapitalize(peopleType)}) *`}
                    />
                  )}
                  {peopleType === 'FORNECEDOR' && (
                    <SupplierInputSelectBase
                      baseName="owner"
                      label={`Proprietário (${toCapitalize(peopleType)}) *`}
                    />
                  )}
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </FormSuite>
  );
};
