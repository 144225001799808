import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, FinancialCategoryService } from '../services';
import {
  IFinancialCategoryData,
  IFinancialCategoryFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  IFinancialCategoryDTO,
  IDataGridSelection,
  IFinancialCategoryList,
} from '../types';
import { InfoStatusRecord } from '../components/feedback/InfoStatusRecord';
import { getFilterParams } from '../utils';
import { getSortParams } from '../utils/getSortParams';

// Define interface for context
interface IFinancialCategoryContext {
  // Data
  columnsFinancialCategory: IDataGridColumns;
  rowsFinancialCategory: IFinancialCategoryList[];
  defaultDataFinancialCategory: IFinancialCategoryData;
  dataFinancialCategory: IFinancialCategoryData | undefined;
  handleDataFinancialCategory: (data?: IFinancialCategoryData) => void;
  // Pagination
  rowCountFinancialCategory: number;
  pageSizeFinancialCategory: number;
  pageFinancialCategory: number;
  handlePageSizeFinancialCategory: (size: number) => void;
  handlePageFinancialCategory: (page: number) => void;
  // Sort
  sortFinancialCategory: IDataGridSortOptions;
  handleSortFinancialCategory: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionFinancialCategory: IDataGridSelection;
  handleSelectionFinancialCategory: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterFinancialCategory: IFinancialCategoryFilter;
  filterFinancialCategory: IFinancialCategoryFilter;
  isOpenFilterModalFinancialCategory: boolean;
  toggleIsOpenFilterModalFinancialCategory: () => void;
  handleFilterFinancialCategory: (filter: IFinancialCategoryFilter) => void;
  // Api
  getAllFinancialCategory: () => void;
  getByIdFinancialCategory: (id: number) => void;
  createFinancialCategory: (payload: IFinancialCategoryDTO) => void;
  updateByIdFinancialCategory: (id: number, payload: IFinancialCategoryDTO) => void;
  deleteByIdFinancialCategory: (id: number) => void;
  deleteIdsFinancialCategory: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaFinancialCategory: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código da categoria',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'name',
    headerName: 'Nome',
    description: 'Nome da categoria',
    flex: 1,
  },
  {
    field: 'is_active',
    headerName: 'Status',
    description: 'Indica se o registro esta ativo ou não',
    renderCell: (params) => <InfoStatusRecord status={params.row.is_active} />,
    headerAlign: 'center',
    align: 'center',
  },
];

// Initial values for filter
const defaultFilterFinancialCategory: IFinancialCategoryFilter = {
  name: '',
};

// initial values for data
const defaultDataFinancialCategory: IFinancialCategoryData = {
  id: 0,
  name: '',
  type: 'FIXA',
  report_order: 0,
  is_active: true,
};

// Create context
export const FinancialCategoryContext = createContext<IFinancialCategoryContext>(
  {} as IFinancialCategoryContext,
);

// Create context provider
export const FinancialCategoryContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsFinancialCategory] = useState<IDataGridColumns>(columnsSchemaFinancialCategory);

  // Rows state
  const [rowsFinancialCategory, setRowsFinancialCategory] = useState<IFinancialCategoryList[]>([]);

  // Data state
  const [dataFinancialCategory, setDataFinancialCategory] = useState<
    IFinancialCategoryData | undefined
  >();

  // Sort state
  const [sortFinancialCategory, setSortFinancialCategory] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterFinancialCategory, setFilterFinancialCategory] = useState<IFinancialCategoryFilter>(
    defaultFilterFinancialCategory,
  );

  // Modal filters
  const [isOpenFilterModalFinancialCategory, setIsOpenFinancialCategoryFilterModal] =
    useState(false);

  // Page state
  const [pageFinancialCategory, setPageFinancialCategory] = useState(0);

  // Page size state
  const [pageSizeFinancialCategory, setPageSizeFinancialCategory] = useState(10);

  // Record count state
  const [rowCountFinancialCategory, setRowCountFinancialCategory] = useState(0);

  // Selection state
  const [selectionFinancialCategory, setSelectionFinancialCategory] =
    useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataFinancialCategory = useCallback(
    (data?: IFinancialCategoryData) => setDataFinancialCategory(data),
    [dataFinancialCategory],
  );

  // Change page status
  const handlePageFinancialCategory = useCallback(
    (page: number) => setPageFinancialCategory(page),
    [pageFinancialCategory],
  );

  // Change page size status
  const handlePageSizeFinancialCategory = useCallback(
    (size: number) => {
      setPageSizeFinancialCategory(size);
      setPageFinancialCategory(0);
    },
    [pageSizeFinancialCategory],
  );

  // Change sort status
  const handleSortFinancialCategory = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortFinancialCategory(sort);
      setPageFinancialCategory(0);
    },
    [sortFinancialCategory],
  );

  // Change filters status
  const handleFilterFinancialCategory = useCallback(
    (filter: IFinancialCategoryFilter) => {
      setFilterFinancialCategory(filter);
      setPageFinancialCategory(0);
    },
    [filterFinancialCategory],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalFinancialCategory = useCallback(
    () => setIsOpenFinancialCategoryFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionFinancialCategory = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionFinancialCategory(selection);
    },
    [selectionFinancialCategory],
  );

  // Clear data rows
  const clearStorageFinancialCategory = useCallback(() => {
    setRowsFinancialCategory([]);
    setRowCountFinancialCategory(0);
  }, [setRowsFinancialCategory]);

  // Load data from request
  const loadStorageFinancialCategory = useCallback(
    (response: IApiResponseWithPagination<IFinancialCategoryList>) => {
      setRowsFinancialCategory(response.data);
      setRowCountFinancialCategory(response.records);
    },
    [setRowsFinancialCategory],
  );

  // API - Get all records
  const getAllFinancialCategory = useCallback(() => {
    // Reset selection
    handleSelectionFinancialCategory([]);
    // Add filters in params
    const params: any = getFilterParams(filterFinancialCategory);
    // Add sort in params
    const sort = getSortParams(sortFinancialCategory);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeFinancialCategory;
    params.offset = pageFinancialCategory * pageSizeFinancialCategory;
    // Get data
    FinancialCategoryService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        clearStorageFinancialCategory();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageFinancialCategory(0);
        return;
      }
      loadStorageFinancialCategory(response);
    });
  }, [
    headers,
    filterFinancialCategory,
    sortFinancialCategory,
    pageFinancialCategory,
    pageSizeFinancialCategory,
    dataFinancialCategory,
  ]);

  // API - Get record by id
  const getByIdFinancialCategory = useCallback(
    (id: number) => {
      // Get details
      FinancialCategoryService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        setDataFinancialCategory(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createFinancialCategory = useCallback(
    (payload: IFinancialCategoryDTO) => {
      // Get details
      FinancialCategoryService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataFinancialCategory();
        snackbarSuccess('Registro adicionado!');
        if (pageFinancialCategory > 0) {
          handlePageFinancialCategory(0);
          return;
        }
        getAllFinancialCategory();
      });
    },
    [getAllFinancialCategory],
  );

  // API - Update record by id
  const updateByIdFinancialCategory = useCallback(
    (id: number, payload: IFinancialCategoryDTO) => {
      // Get details
      FinancialCategoryService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataFinancialCategory();
        getAllFinancialCategory();
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllFinancialCategory],
  );

  // API - Delete record by id
  const deleteByIdFinancialCategory = useCallback(
    (id: number) => {
      FinancialCategoryService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataFinancialCategory();
        getAllFinancialCategory();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllFinancialCategory],
  );

  // API - Delete record by ids
  const deleteIdsFinancialCategory = useCallback(
    (ids: number[]) => {
      FinancialCategoryService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllFinancialCategory();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllFinancialCategory],
  );

  return (
    <FinancialCategoryContext.Provider
      value={{
        // Data
        columnsFinancialCategory,
        rowsFinancialCategory,
        dataFinancialCategory,
        defaultDataFinancialCategory,
        handleDataFinancialCategory,
        // Pagination
        rowCountFinancialCategory,
        pageSizeFinancialCategory,
        pageFinancialCategory,
        handlePageSizeFinancialCategory,
        handlePageFinancialCategory,
        // Sort
        sortFinancialCategory,
        handleSortFinancialCategory,
        // Selection
        selectionFinancialCategory,
        handleSelectionFinancialCategory,
        // Filter
        defaultFilterFinancialCategory,
        filterFinancialCategory,
        isOpenFilterModalFinancialCategory,
        toggleIsOpenFilterModalFinancialCategory,
        handleFilterFinancialCategory,
        // Api
        getAllFinancialCategory,
        getByIdFinancialCategory,
        createFinancialCategory,
        updateByIdFinancialCategory,
        deleteByIdFinancialCategory,
        deleteIdsFinancialCategory,
      }}
    >
      {children}
    </FinancialCategoryContext.Provider>
  );
};
