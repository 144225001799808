import { Container, Toolbar } from '@mui/material';
import { TopBarMenu, SidebarMenu, Div } from '../components';

export const PageContainer: React.FC = ({ children }) => {
  return (
    <Div sx={{ display: 'flex' }}>
      <TopBarMenu />
      <SidebarMenu />
      <Div
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container>{children}</Container>
      </Div>
    </Div>
  );
};
