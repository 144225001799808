import { CheckCircle } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import * as Yup from 'yup';
import { Button, Div, FormSuite, H1, Input } from '../../../shared/components';
import { useSnackbarDispatch } from '../../../shared/hooks';
import { ApiError, AuthService } from '../../../shared/services';
import { schemaValidation } from '../../../shared/utils';

// Define props
interface IResetPasswordFormProps {
  token: string;
  onSubmit: () => void;
}

// Reset password form
export const ResetPasswordForm: React.FC<IResetPasswordFormProps> = ({ token, onSubmit }) => {
  // Define form ID
  const formId = 'reset-password';

  // Define validation fields
  const schemaPayload = Yup.object({
    new_password: schemaValidation.commons
      .string(true)
      .min(3, 'No mínimo 3 caracteres')
      .max(10, 'No máximo 10 caracteres'),
    new_password_confirmation: schemaValidation.commons
      .string(true)
      .min(3, 'No mínimo 3 caracteres')
      .max(10, 'No máximo 10 caracteres')
      .oneOf([Yup.ref('new_password')], 'A senha não confere'),
  });

  // Define default values
  const defaultValues = {
    new_password: '',
    new_password_confirmation: '',
  };

  // Use MUI Theme
  const theme = useTheme();

  // Use hook snackbar
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Send request
  const handleResetPassword = ({ new_password }: any) => {
    AuthService.resetPassword(new_password, token).then((result) => {
      if (result instanceof ApiError) {
        snackbarError(result.message);
      } else {
        onSubmit();
        snackbarSuccess('Senha alterada com sucesso');
      }
    });
  };

  return (
    <>
      <Div display="flex" flexDirection="column" alignItems="center">
        <H1 sx={{ mb: theme.spacing(2) }}>Redefinir senha</H1>
      </Div>

      <FormSuite
        formId={formId}
        initialValues={defaultValues}
        validationSchema={schemaPayload}
        submit={handleResetPassword}
      >
        <Input
          name="new_password"
          label="Nova senha *"
          placeholder="Informe a nova senha"
          type="password"
        />
        <Input
          name="new_password_confirmation"
          label="Confirme a nova senha *"
          placeholder="Confirme sua nova senha"
          type="password"
        />

        <Button
          label="Confirmar"
          size="large"
          fullWidth
          type="submit"
          sx={{ mt: 3, mb: 2 }}
          startIcon={<CheckCircle />}
        />
      </FormSuite>
    </>
  );
};
