/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSnackbarDispatch } from '../../hooks';
import { UploadSingleFileAccept } from './UploadSingleFileAccept';
import { UploadSingleFileReject } from './UploadSingleFileReject';
import { UploadSingleFileEmpty } from './UploadSingleFileEmpty';
import { UploadSingleFileDropZone } from './UploadSingleFileDropZone';
import { UploadSingleFileSelected } from './UploadSingleFileSelected';

type IUploadSingleFileProps = FieldProps;

const acceptFiles = [
  'image/*',
  '*.txt',
  'text/*',
  '.pdf',
  'application/pdf',
  '.doc',
  '.docx',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.xls',
  '.xlsx',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const UploadSingleFile: React.FC<IUploadSingleFileProps> = ({ form, field }) => {
  // State for file
  const [file, setFile] = useState<any>(null);

  // Use dropzone
  const {
    acceptedFiles,
    fileRejections,
    isDragAccept,
    isDragActive,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    maxSize: 2000000,
    multiple: false,
    accept: acceptFiles.join(','),
  });

  // Use form and field from formik context
  const { name, value } = field;
  const { setFieldValue } = form;

  // Use snackbar dispatch
  const { snackbarError } = useSnackbarDispatch();

  // On change selected file
  const handleChange = (selectedFile: any) => {
    setFile(selectedFile);
    setFieldValue(name, selectedFile);
  };

  // Apply initial value
  useEffect(() => handleChange(value || ''), [value]);

  // On accept file
  useEffect(() => {
    if (acceptedFiles.length === 0) return;
    handleChange(acceptedFiles[0]);
  }, [acceptedFiles]);

  // On reject file
  useEffect(() => {
    if (fileRejections.length === 0) return;

    console.log('reject', fileRejections);

    handleChange(null);

    const { errors } = fileRejections[0];

    const invalidType = errors.find((error) => error.code === 'file-invalid-type');
    if (invalidType) {
      snackbarError('O arquivo selecionado não é permitido');
      return;
    }

    const fileTooLarge = errors.find((error) => error.code === 'file-too-large');
    if (fileTooLarge) {
      snackbarError('O arquivo selecionado é maior que 2mb');
      return;
    }

    snackbarError('Não foi possível selecionar o arquivo');
  }, [fileRejections]);

  return file && file.name ? (
    <UploadSingleFileSelected file={file} onDelete={handleChange} />
  ) : (
    <UploadSingleFileDropZone {...getRootProps({ refKey: 'innerRef' })}>
      <input {...getInputProps()} />
      {isDragAccept && <UploadSingleFileAccept />}
      {isDragReject && <UploadSingleFileReject />}
      {!isDragActive && <UploadSingleFileEmpty />}
    </UploadSingleFileDropZone>
  );
};
