/* eslint-disable react/jsx-props-no-spreading */
import { Chip as MuiChip, ChipProps } from '@mui/material';
import { TooltipWrapper } from './TooltipWrapper';

interface IChipProps extends ChipProps {
  tooltip?: string;
  ellipsisOn?: number;
}

export const Chip: React.FC<IChipProps> = ({ tooltip, ellipsisOn, ...rest }) => {
  // Set default props
  const props: Partial<IChipProps> = {
    variant: 'filled',
    color: 'primary',
    sx: {
      maxWidth: ellipsisOn || 280,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    ...rest,
  };

  return (
    <TooltipWrapper title={tooltip}>
      <MuiChip {...props} />
    </TooltipWrapper>
  );
};
