/* eslint-disable @typescript-eslint/naming-convention */
import { Grid } from '@mui/material';
import { Field } from 'formik';
import * as Yup from 'yup';
import {
  Input,
  DataDialog,
  InputNumber,
  InputCurrency,
  SelectVehicleSupplyType,
  DataPicker,
  SelectVehicleSupplyFuelType,
  Checkbox,
} from '../../../shared/components';
import { useVehicleSupply, useDialog } from '../../../shared/hooks';
import { IVehicleSupplyDTO } from '../../../shared/types';
import { schemaValidation, toNumber, toNumberOrZero } from '../../../shared/utils';
import { EmployeeInputSelectBase } from '../../employee/components';
import { VehicleInputSelectBase } from '../../vehicle/components/VehicleInputSelectBase';

// Define validation fields form
const schemaPayloadVehicleSupply = Yup.object({
  date: schemaValidation.dateTime.date(true),
  driver_name: schemaValidation.people.name,
  vehicle_detail: schemaValidation.people.name,
  gas_station: schemaValidation.commons.string(true),
  total: schemaValidation.number.float.test('is-empty', 'Informe um valor', (value) => {
    const number = Number(String(value).replace('.', '').replace(',', '.').trim());
    if (Number.isNaN(number)) return false;
    return number > 0;
  }),
});

// Create modal data for VehicleSupply
export const VehicleSupplyModal: React.FC = () => {
  // Use context VehicleSupply
  const {
    dataVehicleSupply,
    defaultDataVehicleSupply,
    handleDataVehicleSupply,
    createVehicleSupply,
    updateByIdVehicleSupply,
    deleteByIdVehicleSupply,
  } = useVehicleSupply();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Reset data and close modal
  const handleClose = () => handleDataVehicleSupply();

  // Save actions
  const handleSave = (payload: any) => {
    const normalize = { ...payload };
    normalize.liters = toNumber(payload.liters);
    normalize.total = toNumberOrZero(payload.total);

    const id = dataVehicleSupply?.id || 0;
    const data = normalize as IVehicleSupplyDTO;
    if (id > 0) {
      updateByIdVehicleSupply(id, data);
      return;
    }
    createVehicleSupply(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, gas_station } = payload;
    dialogConfirmDanger(
      'Excluir abastecimento?',
      `Deseja realmente excluir o abastecimento: ${id} - ${gas_station} `,
      () => deleteByIdVehicleSupply(id),
    );
  };

  return (
    <DataDialog
      referenceId={dataVehicleSupply?.id}
      referenceDisplayName="abastecimento"
      initialValues={dataVehicleSupply || defaultDataVehicleSupply}
      validationSchema={schemaPayloadVehicleSupply}
      open={dataVehicleSupply !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      <Grid container columnSpacing={2}>
        <Grid item xs={6} md={6}>
          <Field name="type" component={SelectVehicleSupplyType} />
        </Grid>

        <Grid item xs={6} md={6}>
          <Field name="fueled_at" component={DataPicker} label="Data *" />
        </Grid>

        <Grid item xs={12} md={12}>
          <Input label="Posto *" name="gas_station" />
        </Grid>

        <Grid item xs={12} md={12}>
          <EmployeeInputSelectBase baseName="driver" label="Motorista *" />
        </Grid>

        <Grid item xs={12} md={12}>
          <VehicleInputSelectBase refId="vehicle_id" refName="vehicle_detail" label="Veículo *" />
        </Grid>

        <Grid item xs={12} md={4}>
          <Field name="fuel_type" component={SelectVehicleSupplyFuelType} />
        </Grid>

        <Grid item xs={6} md={4}>
          <InputNumber label="Litros" name="liters" />
        </Grid>

        <Grid item xs={6} md={4}>
          <InputCurrency
            label="Total *"
            name="total"
            inputProps={{ style: { fontWeight: 'bold', textAlign: 'right' } }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Checkbox label="Conferido" name="audited" />
        </Grid>
      </Grid>
    </DataDialog>
  );
};
