import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, SupplierService } from '../services';
import {
  ISupplierData,
  ISupplierFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  ISupplierDTO,
  IDataGridSelection,
  ISupplierList,
} from '../types';
import { InfoStatusRecord } from '../components/feedback/InfoStatusRecord';
import { getFilterParams, toObjectWithoutNull } from '../utils';
import { getSortParams } from '../utils/getSortParams';

// Define interface for context
interface ISupplierContext {
  // Data
  columnsSupplier: IDataGridColumns;
  rowsSupplier: ISupplierList[];
  defaultDataSupplier: ISupplierData;
  dataSupplier: ISupplierData | undefined;
  handleDataSupplier: (data?: ISupplierData) => void;
  // Pagination
  rowCountSupplier: number;
  pageSizeSupplier: number;
  pageSupplier: number;
  handlePageSizeSupplier: (size: number) => void;
  handlePageSupplier: (page: number) => void;
  // Sort
  sortSupplier: IDataGridSortOptions;
  handleSortSupplier: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionSupplier: IDataGridSelection;
  handleSelectionSupplier: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterSupplier: ISupplierFilter;
  filterSupplier: ISupplierFilter;
  isOpenFilterModalSupplier: boolean;
  toggleIsOpenFilterModalSupplier: () => void;
  handleFilterSupplier: (filter: ISupplierFilter) => void;
  // Api
  getAllSupplier: () => void;
  getByIdSupplier: (id: number) => void;
  createSupplier: (payload: ISupplierDTO) => void;
  updateByIdSupplier: (id: number, payload: ISupplierDTO) => void;
  deleteByIdSupplier: (id: number) => void;
  deleteIdsSupplier: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaSupplier: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código do fornecedor',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
  {
    field: 'name',
    headerName: 'Nome',
    description: 'Razão social / Nome',
    hideable: false,
    flex: 2,
  },
  {
    field: 'nickname',
    headerName: 'Apelido',
    description: 'Nome fantasia / Apelido',
  },
  {
    field: 'business_type',
    headerName: 'Ramo de Atividade',
    description: 'Ramo de atividade do fornecedor',
  },
  {
    field: 'phone',
    headerName: 'Telefone',
    description: 'Telefone padrão do fornecedor',
    flex: 1,
  },
  {
    field: 'document',
    headerName: 'Documento',
    description: 'CNPJ / CPF do fornecedor',
    flex: 1,
  },
  {
    field: 'neighborhood',
    headerName: 'Bairro',
    description: 'Bairro do endereço padrão do fornecedor',
  },
  {
    field: 'city',
    headerName: 'Cidade',
    description: 'Cidade do endereço padrão do fornecedor',
  },
  {
    field: 'state',
    headerName: 'UF',
    description: 'UF do endereço padrão do fornecedor',
  },
  {
    field: 'is_active',
    headerName: 'Status',
    description: 'Indica se o registro esta ativo ou não',
    renderCell: (params) => <InfoStatusRecord status={params.row.is_active} />,
    headerAlign: 'center',
    align: 'center',
  },
];

// Initial values for filter
const defaultFilterSupplier: ISupplierFilter = {
  id: 0,
  name: '',
  cnpj: '',
  cpf: '',
};

// initial values for data
const defaultDataSupplier: ISupplierData = {
  id: 0,
  cnpj: '',
  ie: '',
  cpf: '',
  rg: '',
  name: '',
  nickname: '',
  business_type_id: 0,
  business_type_name: '',
  tags: '',
  is_active: true,
  contacts: [],
  addresses: [],
};

// Create context
export const SupplierContext = createContext<ISupplierContext>({} as ISupplierContext);

// Create context provider
export const SupplierContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsSupplier] = useState<IDataGridColumns>(columnsSchemaSupplier);

  // Rows state
  const [rowsSupplier, setRowsSupplier] = useState<ISupplierList[]>([]);

  // Data state
  const [dataSupplier, setDataSupplier] = useState<ISupplierData | undefined>();

  // Sort state
  const [sortSupplier, setSortSupplier] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterSupplier, setFilterSupplier] = useState<ISupplierFilter>(defaultFilterSupplier);

  // Modal filters
  const [isOpenFilterModalSupplier, setIsOpenSupplierFilterModal] = useState(false);

  // Page state
  const [pageSupplier, setPageSupplier] = useState(0);

  // Page size state
  const [pageSizeSupplier, setPageSizeSupplier] = useState(10);

  // Record count state
  const [rowCountSupplier, setRowCountSupplier] = useState(0);

  // Selection state
  const [selectionSupplier, setSelectionSupplier] = useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataSupplier = useCallback(
    (data?: ISupplierData) => {
      if (!data) return setDataSupplier(data);
      const dataWithoutNull = toObjectWithoutNull<ISupplierData>(data, defaultDataSupplier);
      dataWithoutNull.person_type = data.cnpj ? 'JURIDICA' : 'FISICA';
      return setDataSupplier(dataWithoutNull);
    },
    [dataSupplier],
  );

  // Change page status
  const handlePageSupplier = useCallback((page: number) => setPageSupplier(page), [pageSupplier]);

  // Change page size status
  const handlePageSizeSupplier = useCallback(
    (size: number) => {
      setPageSizeSupplier(size);
      setPageSupplier(0);
    },
    [pageSizeSupplier],
  );

  // Change sort status
  const handleSortSupplier = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortSupplier(sort);
      setPageSupplier(0);
    },
    [sortSupplier],
  );

  // Change filters status
  const handleFilterSupplier = useCallback(
    (filter: ISupplierFilter) => {
      setFilterSupplier(filter);
      setPageSupplier(0);
    },
    [filterSupplier],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalSupplier = useCallback(
    () => setIsOpenSupplierFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionSupplier = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionSupplier(selection);
    },
    [selectionSupplier],
  );

  // Clear data rows
  const clearStorageSupplier = useCallback(() => {
    setRowsSupplier([]);
    setRowCountSupplier(0);
  }, [setRowsSupplier]);

  // Load data from request
  const loadStorageSupplier = useCallback(
    (response: IApiResponseWithPagination<ISupplierList>) => {
      setRowsSupplier(response.data);
      setRowCountSupplier(response.records);
    },
    [setRowsSupplier],
  );

  // API - Get all records
  const getAllSupplier = useCallback(() => {
    // Reset selection
    handleSelectionSupplier([]);
    // Add filters in params
    const params: any = getFilterParams(filterSupplier);
    // Add sort in params
    const sort = getSortParams(sortSupplier);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeSupplier;
    params.offset = pageSupplier * pageSizeSupplier;
    // Get data
    SupplierService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        clearStorageSupplier();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageSupplier(0);
        return;
      }
      loadStorageSupplier(response);
    });
  }, [headers, filterSupplier, sortSupplier, pageSupplier, pageSizeSupplier, dataSupplier]);

  // API - Get record by id
  const getByIdSupplier = useCallback(
    (id: number) => {
      // Get details
      SupplierService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataSupplier(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createSupplier = useCallback(
    (payload: ISupplierDTO) => {
      // Get details
      SupplierService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        // Update data
        const updatedData: ISupplierData = { ...payload, id: response };
        handleDataSupplier(updatedData);
        //
        snackbarSuccess('Registro adicionado!');
      });
    },
    [getAllSupplier],
  );

  // API - Update record by id
  const updateByIdSupplier = useCallback(
    (id: number, payload: ISupplierDTO) => {
      // Get details
      SupplierService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        // Update data
        const updatedData: ISupplierData = { ...payload, id };
        handleDataSupplier(updatedData);
        //
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllSupplier],
  );

  // API - Delete record by id
  const deleteByIdSupplier = useCallback(
    (id: number) => {
      SupplierService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataSupplier();
        getAllSupplier();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllSupplier],
  );

  // API - Delete record by ids
  const deleteIdsSupplier = useCallback(
    (ids: number[]) => {
      SupplierService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllSupplier();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllSupplier],
  );

  return (
    <SupplierContext.Provider
      value={{
        // Data
        columnsSupplier,
        rowsSupplier,
        dataSupplier,
        defaultDataSupplier,
        handleDataSupplier,
        // Pagination
        rowCountSupplier,
        pageSizeSupplier,
        pageSupplier,
        handlePageSizeSupplier,
        handlePageSupplier,
        // Sort
        sortSupplier,
        handleSortSupplier,
        // Selection
        selectionSupplier,
        handleSelectionSupplier,
        // Filter
        defaultFilterSupplier,
        filterSupplier,
        isOpenFilterModalSupplier,
        toggleIsOpenFilterModalSupplier,
        handleFilterSupplier,
        // Api
        getAllSupplier,
        getByIdSupplier,
        createSupplier,
        updateByIdSupplier,
        deleteByIdSupplier,
        deleteIdsSupplier,
      }}
    >
      {children}
    </SupplierContext.Provider>
  );
};
