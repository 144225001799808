/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import { FormControl, TextField, TextFieldProps } from '@mui/material';
import { FieldProps, useField } from 'formik';
import { useEffect, useState } from 'react';
import { DatePicker as MuiDataPicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { TooltipWrapper } from '../feedback';
import { useLoader } from '../../hooks';

interface IDataPickerProps extends FieldProps {
  label: string;
  tooltip?: string;
  disabled?: boolean;
}

export const DataPicker: React.FC<IDataPickerProps> = ({
  label,
  tooltip,
  form,
  field,
  disabled,
  ...rest
}) => {
  const [currentValue, setCurrentValue] = useState(null);

  // Use "hook" loader
  const isRunning = useLoader();

  const { name, value } = field;
  const { setFieldValue } = form;
  const propsField = useField(name);
  const metaField = propsField[1];

  const handleChange = (valeu: any) => {
    const newValue = valeu;
    setCurrentValue(newValue);
    setFieldValue(name, newValue);
  };

  useEffect(() => setCurrentValue(value), [value]);

  const props: Partial<TextFieldProps> = {};

  if (metaField && metaField.touched && metaField.error) {
    props.error = true;
    props.helperText = metaField.error;
  }

  return (
    <TooltipWrapper title={tooltip}>
      <FormControl fullWidth margin="normal">
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
          <MuiDataPicker
            showTodayButton
            todayText="Hoje"
            clearText="Limpar"
            cancelText="Fechar"
            clearable
            label={label}
            value={currentValue}
            onChange={(newValue) => {
              handleChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} {...props} />}
            {...rest}
            disabled={isRunning ? true : disabled}
          />
        </LocalizationProvider>
      </FormControl>
    </TooltipWrapper>
  );
};
