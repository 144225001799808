import { ISchemaApiError } from '../../../types';

// Set schema for error request in recover password
export const AuthSchemaApiError: ISchemaApiError[] = [
  {
    statusCode: 400,
    errors: [
      {
        error: 'Blocked email sending. Password already requested, check your email',
        message: 'Procedimento para redefinir seu senha já enviado, verifique seu e-mail...',
      },
      {
        error: 'Username is empty',
        message: 'Ops, falha na comunicação, tente novamente',
      },
      {
        error: 'Unexpected status for reset password',
        message: 'Novo login detectado',
      },
      {
        error: 'Blocked email sending. Resending already requested, check your email',
        message: 'PIN bloqueado, várias tentativas de acesso, aguarde.',
        isPartial: true,
      },
    ],
  },
  {
    statusCode: 404,
    errors: [
      {
        error: 'User not found',
        message: 'Usuário não localizado',
      },
      {
        error: 'PIN not found',
        message: 'O PIN informado não é válido',
      },
    ],
  },
  {
    statusCode: 500,
    errors: [
      {
        error: 'Error send email',
        message: 'Não foi possível enviar o e-mail de resposta. Tente novamente',
      },
    ],
  },
];
