import { Alert, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useState } from 'react';
import { useDialog } from '../../../shared/hooks';
import { IUserStatusDTO } from '../../../shared/types';

interface IUserToggleStatusButtonProps {
  status: boolean;
  onClick: (value: IUserStatusDTO) => void;
}

export const UserToggleStatusButton: React.FC<IUserToggleStatusButtonProps> = ({
  status,
  onClick,
}) => {
  const [isActive, setIsActive] = useState(status);

  const { dialogConfirmDanger, dialogConfirmGrant } = useDialog();

  const handleDisable = () =>
    dialogConfirmDanger('Desativar usuário?', 'Deseja realmente desativar o usuário atual?', () => {
      onClick('INACTIVE');
      setIsActive(false);
    });

  const handleEnable = () =>
    dialogConfirmGrant('Ativar usuário?', 'Deseja realmente reativar o usuário atual?', () => {
      onClick('ACTIVE');
      setIsActive(true);
    });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    event.currentTarget.checked ? handleEnable() : handleDisable();

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={isActive}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label="Usuário ativo"
      />
      {!isActive && (
        <Alert severity="warning" sx={{ my: 2 }}>
          Este usuário foi desativado, ele não poderá realizar um novo acesso a plataforma.
        </Alert>
      )}
    </FormGroup>
  );
};
