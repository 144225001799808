/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useFinancialCategory } from '../../../shared/hooks';

type IFinancialCategoryDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const FinancialCategoryDataGrid: React.FC<IFinancialCategoryDataGridProps> = ({
  ...rest
}) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsFinancialCategory,
    rowsFinancialCategory,
    rowCountFinancialCategory,
    pageFinancialCategory,
    handlePageFinancialCategory,
    pageSizeFinancialCategory,
    handlePageSizeFinancialCategory,
    sortFinancialCategory,
    filterFinancialCategory,
    handleFilterFinancialCategory,
    handleSortFinancialCategory,
    getAllFinancialCategory,
  } = useFinancialCategory();

  useEffect(() => handleFilterFinancialCategory({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllFinancialCategory();
  }, [
    canSearch,
    sortFinancialCategory,
    filterFinancialCategory,
    pageFinancialCategory,
    pageSizeFinancialCategory,
  ]);

  return (
    <DataGrid
      rows={rowsFinancialCategory}
      rowCount={rowCountFinancialCategory}
      columns={columnsFinancialCategory}
      onSortModelChange={handleSortFinancialCategory}
      sortModel={sortFinancialCategory}
      pageSize={pageSizeFinancialCategory}
      onPageSizeChange={handlePageSizeFinancialCategory}
      page={pageFinancialCategory}
      onPageChange={handlePageFinancialCategory}
      {...rest}
    />
  );
};
