export const daysOfWeek = [
  { index: 0, longName: 'Domingo', name: 'Domingo', shortName: 'DOM' },
  { index: 1, longName: 'Segunda-feira', name: 'Segunda', shortName: 'SEG' },
  { index: 2, longName: 'Terça-feira', name: 'Terça', shortName: 'TER' },
  { index: 3, longName: 'Quarta-feira', name: 'Quarta', shortName: 'QUA' },
  { index: 4, longName: 'Quinta-feira', name: 'Quinta', shortName: 'QUI' },
  { index: 5, longName: 'Sexta-feira', name: 'Sexta', shortName: 'SEX' },
  { index: 6, longName: 'Sábado', name: 'Sábado', shortName: 'SAB' },
  { index: 8, longName: 'Feriado', name: 'Feriado', shortName: 'FER' },
];
