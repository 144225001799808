import { ISchemaApiError } from '../../../types';

// Set schema for error request in vehicle service
export const VehicleSchemaApiError: ISchemaApiError[] = [
  {
    statusCode: 400,
    errors: [
      {
        error: 'Plate already registered for the vehicle',
        message: 'Placa já cadastrada',
        isPartial: true,
      },
      {
        error: 'Invalid content: RENAVAM',
        message: 'RENAVAM inválido',
      },
      {
        error: 'Invalid content: Placa',
        message: 'Placa inválida',
      },
    ],
  },
  {
    statusCode: 404,
    errors: [
      {
        error: 'Owner not found',
        message: 'Proprietário não localizado',
      },
    ],
  },
];
