import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Menu, MenuOpen } from '@mui/icons-material';
import { H2, P } from '../display-data';
import { AccountDropDownMenu } from './AccountDropDownMenu';
import { useAuth, useDeviceIsMobile, useMenu } from '../../hooks';
import { SearchTopBar } from './TopBarMenuSearch';
import { ENV } from '../../environment';

// Create App Bar
export const TopBarMenu: React.FC = () => {
  // Use hook for check device type
  const isMobile = useDeviceIsMobile();

  // Use auth context
  const { user } = useAuth();

  // Use store navbar
  const {
    openAccountDropDownMenu,
    isOpenSidebarMenu,
    isExpandedSidebarMenu,
    openSidebarMenu,
    closeSidebarMenu,
    toggleIsExpandedSidebarMenu,
  } = useMenu();

  // Toggle status menu
  const handleMainMenuClick = () => {
    // toggle permanent / temporary
    if (isMobile) {
      // Remove permanent type
      toggleIsExpandedSidebarMenu(false);
    } else {
      toggleIsExpandedSidebarMenu();
    }
    // toggle state open/close
    if (isOpenSidebarMenu) {
      closeSidebarMenu();
    } else {
      openSidebarMenu();
    }
  };

  return (
    <>
      <AppBar position="absolute" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar
          sx={{
            pr: '24px',
          }}
        >
          <IconButton
            aria-label="Abrir menu"
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={handleMainMenuClick}
          >
            {isOpenSidebarMenu && isExpandedSidebarMenu ? <MenuOpen /> : <Menu />}
          </IconButton>

          <H2 variant="h6" noWrap sx={{ display: { xs: 'none', sm: 'block' } }}>
            {ENV.APP.SHORT_NAME}
          </H2>

          <SearchTopBar />

          <Box sx={{ flexGrow: 1 }} />

          <Box
            sx={{ display: 'flex', cursor: 'pointer' }}
            aria-controls="account-dropdown-menu"
            aria-haspopup="true"
            onClick={openAccountDropDownMenu}
          >
            <P
              sx={{
                display: { xs: 'none', sm: 'flex' },
                alignSelf: 'center',
                height: '100%',
              }}
            >
              {user.payload?.nickname}
            </P>

            <IconButton edge="end" size="large" color="inherit">
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <AccountDropDownMenu />
    </>
  );
};
