import { DateRange, EventBusy } from '@mui/icons-material';
import { Alert, Toolbar, useTheme } from '@mui/material';
import { useState } from 'react';
import { Button, DataGrid, Div } from '../../../shared/components';
import { useContract, useDialog } from '../../../shared/hooks';
import {
  IContractParamsDailyData,
  IDataGridColumns,
  IDataGridSortOptions,
} from '../../../shared/types';
import { formatterCurrency, formatterDayWeek } from '../../../shared/utils';
import { ContractParamsDailyModal } from './ContractParamsDailyModal';

// Schema for columns daily params
const columnsParamsDailyContract: IDataGridColumns = [
  {
    field: 'week_day',
    headerName: 'Dia',
    description: 'Dia da semana',
    flex: 1,
    valueFormatter: ({ value }) => formatterDayWeek(value),
  },
  {
    field: 'delivery_price',
    headerName: 'Delivery',
    description: 'Preço por delivery',
    headerAlign: 'right',
    align: 'right',
    maxWidth: 85,
    valueFormatter: ({ value }) => formatterCurrency(value, true),
  },
  {
    field: 'km_price',
    headerName: 'KM',
    description: 'Preço do KM',
    headerAlign: 'right',
    align: 'right',
    maxWidth: 85,
    valueFormatter: ({ value }) => formatterCurrency(value, true),
  },
  {
    field: 'fixed_price',
    headerName: 'Fixo',
    description: 'Preço fixo',
    headerAlign: 'right',
    align: 'right',
    maxWidth: 85,
    valueFormatter: ({ value }) => formatterCurrency(value, true),
  },
];

// Default sort
const defaultSortModelDailyContract: IDataGridSortOptions = [{ field: 'week_day', sort: 'asc' }];

// Create component for list params daily contract
export const ContractParamsDaily: React.FC = () => {
  // State for edit data
  const [dataModal, setDataModal] = useState<IContractParamsDailyData | undefined>(undefined);

  // Use contract
  const { dataParamsDailyContract, defaultDataParamsDailyContract, handleDataParamsDailyContract } =
    useContract();

  // Use theme
  const theme = useTheme();

  // Use Dialog builder
  const { dialogConfirmDanger } = useDialog();

  // Delete all items
  const handleDeleteAllParamsDaily = () =>
    dialogConfirmDanger(
      'Limpar lista?',
      'Deseja realmente limpar a configuração do contrato atual?',
      () => handleDataParamsDailyContract([]),
    );

  // Open modal data
  const handleOpenParamsDailyModal = (payload?: object) => {
    const data = payload ? (payload as IContractParamsDailyData) : defaultDataParamsDailyContract;
    setDataModal(data);
  };

  // Close modal
  const handleCloseParamsDailyModal = () => {
    setDataModal(undefined);
  };

  return (
    <>
      <ContractParamsDailyModal
        open={dataModal !== undefined}
        onClose={handleCloseParamsDailyModal}
        data={dataModal}
      />

      <Div sx={{ width: '100%', ml: theme.spacing(2) }}>
        <Toolbar style={{ paddingRight: 0, paddingLeft: 0, marginBottom: theme.spacing(1) }}>
          <Div flex={1} />
          {dataParamsDailyContract.length ? (
            <Button
              label="Limpar lista"
              tooltip="Apagar a configuração de todos os dias da semana"
              variant="text"
              color="error"
              startIcon={<EventBusy />}
              sx={{ mr: theme.spacing(2) }}
              onClick={handleDeleteAllParamsDaily}
              disableFocusRipple
            />
          ) : null}
          <Button
            label="Adicionar dia"
            tooltip="Adicionar configuração para um dia da semana"
            startIcon={<DateRange />}
            onClick={() => handleOpenParamsDailyModal()}
            disableFocusRipple
          />
        </Toolbar>

        {dataParamsDailyContract.length ? (
          <DataGrid
            columns={columnsParamsDailyContract}
            rows={dataParamsDailyContract || []}
            getRowId={(row) => row.week_day}
            paginationMode="client"
            sortingMode="client"
            disableColumnMenu
            sortModel={defaultSortModelDailyContract}
            hideFooter
            onRowClick={(data) => handleOpenParamsDailyModal(data.row)}
          />
        ) : (
          <Alert variant="outlined" severity="info">
            Não há configurações de dias da semana para este contrato, clique em{' '}
            <strong>ADICIONAR DIA</strong> para configurar.
          </Alert>
        )}
      </Div>
    </>
  );
};
