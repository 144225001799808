import { Box, Grid, Paper } from '@mui/material';
import { Span } from '../../../shared/components';
import image from '../../../assets/images/login-background.jpg';
import { ENV } from '../../../shared/environment';

// Container for elements login
// The screen contains an image on the left and login in the sidebar on the right
export const LoginContainer: React.FC = ({ children }) => {
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={5}
        md={7}
        sx={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid
        item
        xs={12}
        sm={7}
        md={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: 10,
        }}
        component={Paper}
        elevation={6}
      >
        <Box
          maxWidth="450px"
          sx={{
            marginLeft: 3,
            marginRight: 3,
            marginTop: 7,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {children}
          <Span mt={2}>{`${ENV.APP.VERSION} - ${ENV.APP.TAG}`}</Span>
        </Box>
      </Grid>
    </Grid>
  );
};
