/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, VehicleUsageService } from '../services';
import {
  IVehicleUsageData,
  IVehicleUsageFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  IVehicleUsageDTO,
  IDataGridSelection,
  IVehicleUsageList,
} from '../types';
import {
  formatterDateBR,
  getFilterParams,
  toDateApi,
  toDateTime,
  toObjectWithoutNull,
} from '../utils';
import { getSortParams } from '../utils/getSortParams';
import { DisplayColumWithIcon } from '../components/feedback';

// Define interface for context
interface IVehicleUsageContext {
  // Data
  columnsVehicleUsage: IDataGridColumns;
  rowsVehicleUsage: IVehicleUsageList[];
  defaultDataVehicleUsage: IVehicleUsageData;
  dataVehicleUsage: IVehicleUsageData | undefined;
  handleDataVehicleUsage: (data?: IVehicleUsageData) => void;
  // Pagination
  rowCountVehicleUsage: number;
  pageSizeVehicleUsage: number;
  pageVehicleUsage: number;
  handlePageSizeVehicleUsage: (size: number) => void;
  handlePageVehicleUsage: (page: number) => void;
  // Sort
  sortVehicleUsage: IDataGridSortOptions;
  handleSortVehicleUsage: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionVehicleUsage: IDataGridSelection;
  handleSelectionVehicleUsage: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterVehicleUsage: IVehicleUsageFilter;
  hideFilterVehicleUsage: string[];
  filterVehicleUsage: IVehicleUsageFilter;
  isOpenFilterModalVehicleUsage: boolean;
  toggleIsOpenFilterModalVehicleUsage: () => void;
  handleFilterVehicleUsage: (filter: IVehicleUsageFilter) => void;
  // Api
  getAllVehicleUsage: () => void;
  getByIdVehicleUsage: (id: number) => void;
  createVehicleUsage: (payload: IVehicleUsageDTO) => void;
  updateByIdVehicleUsage: (id: number, payload: IVehicleUsageDTO) => void;
  deleteByIdVehicleUsage: (id: number) => void;
  deleteIdsVehicleUsage: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaVehicleUsage: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código de utilização do veículo',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
  {
    field: 'withdrew_at',
    headerName: 'Retirada',
    description: 'Data de retirada',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => formatterDateBR(params.row.withdrew_at),
  },
  {
    field: 'driver_id',
    headerName: 'Motorista',
    hideable: false,
  },
  {
    field: 'driver_name',
    headerName: 'Motorista',
    description: 'Motorista responsável',
    flex: 1,
  },
  {
    field: 'vehicle_id',
    headerName: 'Veículo',
    hideable: false,
  },
  {
    field: 'vehicle_detail',
    headerName: 'Veículo',
    description: 'Veículo utilizado',
    flex: 1,
    renderCell: (params) => {
      const { returned, with_events, vehicle_detail } = params.row;

      if (!returned || with_events) {
        return (
          <DisplayColumWithIcon icon={!returned ? 'schedule' : 'warning'} cell={vehicle_detail} />
        );
      }

      return <DisplayColumWithIcon icon="check_circle" cell={vehicle_detail} />;
    },
  },
  {
    field: 'start_date_withdrawn',
    headerName: 'Início',
    hideable: false,
  },
  {
    field: 'end_date_withdrawn',
    headerName: 'Fim',
    hideable: false,
  },
];

// Initial values for filter
const defaultFilterVehicleUsage: IVehicleUsageFilter = {
  start_date_withdrawn: null,
  end_date_withdrawn: null,
  vehicle_id: 0,
  vehicle_detail: '',
  driver_id: 0,
  driver_name: '',
};

// Omit filter on list
const defaultHideFilterVehicleUsage = ['driver_name', 'vehicle_detail'];

// initial values for data
const defaultDataVehicleUsage: IVehicleUsageData = {
  id: 0,
  withdrew_at: new Date(),
  returned_at: null,
  vehicle_id: 0,
  vehicle_detail: '',
  driver_id: 0,
  driver_name: '',
  start_km: 0,
  end_km: 0,
  events: '',
  attachments: [],
};

// Create context
export const VehicleUsageContext = createContext<IVehicleUsageContext>({} as IVehicleUsageContext);

// Create context provider
export const VehicleUsageContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsVehicleUsage] = useState<IDataGridColumns>(columnsSchemaVehicleUsage);

  // Rows state
  const [rowsVehicleUsage, setRowsVehicleUsage] = useState<IVehicleUsageList[]>([]);

  // Data state
  const [dataVehicleUsage, setDataVehicleUsage] = useState<IVehicleUsageData | undefined>();

  // Sort state
  const [sortVehicleUsage, setSortVehicleUsage] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterVehicleUsage, setFilterVehicleUsage] =
    useState<IVehicleUsageFilter>(defaultFilterVehicleUsage);

  // Hide Filter state
  const [hideFilterVehicleUsage] = useState(defaultHideFilterVehicleUsage);

  // Modal filters
  const [isOpenFilterModalVehicleUsage, setIsOpenVehicleUsageFilterModal] = useState(false);

  // Page state
  const [pageVehicleUsage, setPageVehicleUsage] = useState(0);

  // Page size state
  const [pageSizeVehicleUsage, setPageSizeVehicleUsage] = useState(10);

  // Record count state
  const [rowCountVehicleUsage, setRowCountVehicleUsage] = useState(0);

  // Selection state
  const [selectionVehicleUsage, setSelectionVehicleUsage] = useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataVehicleUsage = useCallback(
    (data?: IVehicleUsageData) => {
      if (!data) return setDataVehicleUsage(data);
      const dataWithoutNull = toObjectWithoutNull<IVehicleUsageData>(data, defaultDataVehicleUsage);
      dataWithoutNull.withdrew_at = toDateTime(dataWithoutNull.withdrew_at);
      dataWithoutNull.returned_at = toDateTime(dataWithoutNull.returned_at);
      return setDataVehicleUsage(dataWithoutNull);
    },
    [dataVehicleUsage],
  );

  // Change page status
  const handlePageVehicleUsage = useCallback(
    (page: number) => setPageVehicleUsage(page),
    [pageVehicleUsage],
  );

  // Change page size status
  const handlePageSizeVehicleUsage = useCallback(
    (size: number) => {
      setPageSizeVehicleUsage(size);
      setPageVehicleUsage(0);
    },
    [pageSizeVehicleUsage],
  );

  // Change sort status
  const handleSortVehicleUsage = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortVehicleUsage(sort);
      setPageVehicleUsage(0);
    },
    [sortVehicleUsage],
  );

  // Change filters status
  const handleFilterVehicleUsage = useCallback(
    (filter: IVehicleUsageFilter) => {
      setFilterVehicleUsage(filter);
      setPageVehicleUsage(0);
    },
    [filterVehicleUsage],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalVehicleUsage = useCallback(
    () => setIsOpenVehicleUsageFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionVehicleUsage = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionVehicleUsage(selection);
    },
    [selectionVehicleUsage],
  );

  // Clear data rows
  const clearStorageVehicleUsage = useCallback(() => {
    setRowsVehicleUsage([]);
    setRowCountVehicleUsage(0);
  }, [setRowsVehicleUsage]);

  // Load data from request
  const loadStorageVehicleUsage = useCallback(
    (response: IApiResponseWithPagination<IVehicleUsageList>) => {
      setRowsVehicleUsage(response.data);
      setRowCountVehicleUsage(response.records);
    },
    [setRowsVehicleUsage],
  );

  // API - Get all records
  const getAllVehicleUsage = useCallback(() => {
    // Reset selection
    handleSelectionVehicleUsage([]);
    // Add filters in params
    const params: any = getFilterParams(filterVehicleUsage);
    // Normalize date
    if (params.start_date_withdrawn)
      params.start_date_withdrawn = toDateApi(params.start_date_withdrawn);
    if (params.end_date_withdrawn) params.end_date_withdrawn = toDateApi(params.end_date_withdrawn);
    // Add sort in params
    const sort = getSortParams(sortVehicleUsage);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeVehicleUsage;
    params.offset = pageVehicleUsage * pageSizeVehicleUsage;
    // Get data
    VehicleUsageService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        clearStorageVehicleUsage();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageVehicleUsage(0);
        return;
      }
      loadStorageVehicleUsage(response);
    });
  }, [
    headers,
    filterVehicleUsage,
    sortVehicleUsage,
    pageVehicleUsage,
    pageSizeVehicleUsage,
    dataVehicleUsage,
  ]);

  // API - Get record by id
  const getByIdVehicleUsage = useCallback(
    (id: number) => {
      // Get details
      VehicleUsageService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicleUsage(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createVehicleUsage = useCallback(
    (payload: IVehicleUsageDTO) => {
      // Get details
      VehicleUsageService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicleUsage();
        snackbarSuccess('Registro adicionado!');
        if (pageVehicleUsage > 0) {
          handlePageVehicleUsage(0);
          return;
        }
        getAllVehicleUsage();
      });
    },
    [getAllVehicleUsage],
  );

  // API - Update record by id
  const updateByIdVehicleUsage = useCallback(
    (id: number, payload: IVehicleUsageDTO) => {
      // Get details
      VehicleUsageService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicleUsage();
        getAllVehicleUsage();
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllVehicleUsage],
  );

  // API - Delete record by id
  const deleteByIdVehicleUsage = useCallback(
    (id: number) => {
      VehicleUsageService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicleUsage();
        getAllVehicleUsage();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllVehicleUsage],
  );

  // API - Delete record by ids
  const deleteIdsVehicleUsage = useCallback(
    (ids: number[]) => {
      VehicleUsageService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllVehicleUsage();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllVehicleUsage],
  );

  return (
    <VehicleUsageContext.Provider
      value={{
        // Data
        columnsVehicleUsage,
        rowsVehicleUsage,
        dataVehicleUsage,
        defaultDataVehicleUsage,
        handleDataVehicleUsage,
        // Pagination
        rowCountVehicleUsage,
        pageSizeVehicleUsage,
        pageVehicleUsage,
        handlePageSizeVehicleUsage,
        handlePageVehicleUsage,
        // Sort
        sortVehicleUsage,
        handleSortVehicleUsage,
        // Selection
        selectionVehicleUsage,
        handleSelectionVehicleUsage,
        // Filter
        defaultFilterVehicleUsage,
        hideFilterVehicleUsage,
        filterVehicleUsage,
        isOpenFilterModalVehicleUsage,
        toggleIsOpenFilterModalVehicleUsage,
        handleFilterVehicleUsage,
        // Api
        getAllVehicleUsage,
        getByIdVehicleUsage,
        createVehicleUsage,
        updateByIdVehicleUsage,
        deleteByIdVehicleUsage,
        deleteIdsVehicleUsage,
      }}
    >
      {children}
    </VehicleUsageContext.Provider>
  );
};
