import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useTravel, useDialog, useDeviceIsMobile } from '../../../shared/hooks';
import { ITravelFilter, IDataGridRowParams, IDataGridColumnsHidden } from '../../../shared/types';
import { TravelDataGrid } from './TravelDataGrid';
import { TravelFilterModal } from './TravelFilterModal';
import { TravelModal } from './TravelModal';

interface ITravelMainProps {
  embedded?: boolean;
  initialFilter?: Partial<ITravelFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {
  finished_at: false,
  customer_id: false,
  driver_id: false,
  vehicle_id: false,
  origin: false,
  type: false,
};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {
  finished_at: false,
  customer_id: false,
  driver_id: false,
  vehicle_id: false,
  origin: false,
  total: false,
  driver_name: false,
  destination: false,
  type: false,
};

export const TravelMain: React.FC<ITravelMainProps> = ({ embedded, initialFilter }) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState<boolean>(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsTravel,
    rowsTravel,
    defaultDataTravel,
    handleDataTravel,
    selectionTravel,
    handleSelectionTravel,
    filterTravel,
    hideFilterTravel,
    handleFilterTravel,
    getByIdTravel,
    deleteIdsTravel,
  } = useTravel();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterTravel(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsTravel]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterTravel({});
      return;
    }
    handleFilterTravel({ ...filterTravel, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataTravel(defaultDataTravel);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdTravel(+id);
  };

  const handleDeleteData = () => {
    if (!selectionTravel || selectionTravel.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsTravel(selectionTravel as number[]);
      },
    );
  };

  return (
    <>
      <TravelFilterModal />
      <TravelModal />

      {!embedded && (
        <DataGridFilters
          columns={columnsTravel}
          filters={filterTravel}
          hideFilters={hideFilterTravel}
          onRemoveFilter={handleRemoveFilter}
        />
      )}

      <TravelDataGrid
        selectionModel={selectionTravel}
        onSelectionModelChange={handleSelectionTravel}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionTravel}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionTravel?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
