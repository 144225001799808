import {
  IApiResponseWithPagination,
  IEmployeeData,
  IEmployeeList,
  IEmployeeDTO,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { EmployeeSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<IEmployeeList> | ApiError> => {
  try {
    const response = await Api().get('/employees', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, EmployeeSchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<IEmployeeData | ApiError> => {
  try {
    const response = await Api().get(`/employees/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, EmployeeSchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: IEmployeeDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/employees', payload, { headers });
    return response.data.employee_id;
  } catch (error: any) {
    return makeApiError(error, EmployeeSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: IEmployeeDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/employees/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, EmployeeSchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/employees/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, EmployeeSchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/employees/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, EmployeeSchemaApiError);
  }
};

// toggle approved status
const approveRegistrationById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().patch(`/employees/${id}/status/ACTIVE`, {}, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, EmployeeSchemaApiError);
  }
};

export const EmployeeService = {
  getAll,
  getById,
  create,
  updateById,
  deleteById,
  deleteIds,
  approveRegistrationById,
};
