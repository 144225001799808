import { useFinancial } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { FinancialMain } from './components/FinancialMain';

export const FinancialPage: React.FC = () => {
  const { toggleIsOpenFilterModalFinancial } = useFinancial();

  return (
    <PageContainer>
      <PageTitle
        title="Financeiro"
        subtitle="Gerencie as contas à pagar e receber"
        filterAction={toggleIsOpenFilterModalFinancial}
      />

      <PageMain>
        <FinancialMain />
      </PageMain>
    </PageContainer>
  );
};
