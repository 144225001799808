import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, VehicleService } from '../services';
import {
  IVehicleData,
  IVehicleFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  IVehicleDTO,
  IDataGridSelection,
  IVehicleList,
} from '../types';
import { InfoStatusRecord } from '../components/feedback/InfoStatusRecord';
import { getFilterParams, toObjectWithoutNull } from '../utils';
import { getSortParams } from '../utils/getSortParams';

// Define interface for context
interface IVehicleContext {
  // Data
  columnsVehicle: IDataGridColumns;
  rowsVehicle: IVehicleList[];
  defaultDataVehicle: IVehicleData;
  dataVehicle: IVehicleData | undefined;
  handleDataVehicle: (data?: IVehicleData) => void;
  handleClearStorageVehicle: () => void;
  // Pagination
  rowCountVehicle: number;
  pageSizeVehicle: number;
  pageVehicle: number;
  handlePageSizeVehicle: (size: number) => void;
  handlePageVehicle: (page: number) => void;
  // Sort
  sortVehicle: IDataGridSortOptions;
  handleSortVehicle: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionVehicle: IDataGridSelection;
  handleSelectionVehicle: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterVehicle: IVehicleFilter;
  hideFilterVehicle: string[];
  filterVehicle: IVehicleFilter;
  isOpenFilterModalVehicle: boolean;
  toggleIsOpenFilterModalVehicle: () => void;
  handleFilterVehicle: (filter: IVehicleFilter) => void;
  // Api
  getAllVehicle: () => void;
  getByIdVehicle: (id: number) => void;
  createVehicle: (payload: IVehicleDTO) => void;
  updateByIdVehicle: (id: number, payload: IVehicleDTO) => void;
  deleteByIdVehicle: (id: number) => void;
  deleteIdsVehicle: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaVehicle: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código do veículo',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
  {
    field: 'owner_id',
    headerName: 'Proprietário',
    hideable: false,
  },
  {
    field: 'brand',
    headerName: 'Marca',
    hideable: false,
  },
  {
    field: 'type',
    headerName: 'Tipo',
    description: 'Tipo do veículo',
    renderCell: (params) => (params.row.type === 'PROPRIO' ? 'Próprio' : 'Terceiro'),
  },
  {
    field: 'plate',
    headerName: 'Placa',
    hideable: false,
  },
  {
    field: 'model',
    headerName: 'Veículo',
    description: 'Detalhes do veículo (placa e modelo)',
    flex: 1,
    renderCell: (params) => {
      const { plate, model } = params.row;
      return (
        <>
          <strong>{plate}</strong>
          {`, ${model}`}
        </>
      );
    },
  },
  {
    field: 'is_active',
    headerName: 'Status',
    description: 'Indica se o registro esta ativo ou não',
    renderCell: (params) => <InfoStatusRecord status={params.row.is_active} />,
    headerAlign: 'center',
    align: 'center',
  },
];

// Initial values for filter
const defaultFilterVehicle: IVehicleFilter = {
  id: 0,
  owner_type: 'COLABORADOR',
  owner_id: 0,
  owner_name: '',
  type: '',
  plate: '',
  brand: '',
  model: '',
};

// Omit filter on list
const defaultHideFilterVehicle = ['owner_type', 'owner_name'];

// initial values for data
const defaultDataVehicle: IVehicleData = {
  id: 0,
  type: 'PROPRIO',
  owner_id: 0,
  owner_name: '',
  owner_type: 'COLABORADOR',
  plate: '',
  brand: '',
  model: '',
  registration_number: '',
  is_active: true,
};

// Create context
export const VehicleContext = createContext<IVehicleContext>({} as IVehicleContext);

// Create context provider
export const VehicleContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsVehicle] = useState<IDataGridColumns>(columnsSchemaVehicle);

  // Rows state
  const [rowsVehicle, setRowsVehicle] = useState<IVehicleList[]>([]);

  // Data state
  const [dataVehicle, setDataVehicle] = useState<IVehicleData | undefined>();

  // Sort state
  const [sortVehicle, setSortVehicle] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterVehicle, setFilterVehicle] = useState<IVehicleFilter>(defaultFilterVehicle);

  // Hide Filter state
  const [hideFilterVehicle] = useState(defaultHideFilterVehicle);

  // Modal filters
  const [isOpenFilterModalVehicle, setIsOpenVehicleFilterModal] = useState(false);

  // Page state
  const [pageVehicle, setPageVehicle] = useState(0);

  // Page size state
  const [pageSizeVehicle, setPageSizeVehicle] = useState(10);

  // Record count state
  const [rowCountVehicle, setRowCountVehicle] = useState(0);

  // Selection state
  const [selectionVehicle, setSelectionVehicle] = useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataVehicle = useCallback(
    (data?: IVehicleData) => {
      if (!data) return setDataVehicle(data);
      const dataWithoutNull = toObjectWithoutNull<IVehicleData>(data, defaultDataVehicle);
      return setDataVehicle(dataWithoutNull);
    },
    [dataVehicle],
  );

  // Change page status
  const handlePageVehicle = useCallback((page: number) => setPageVehicle(page), [pageVehicle]);

  // Change page size status
  const handlePageSizeVehicle = useCallback(
    (size: number) => {
      setPageSizeVehicle(size);
      setPageVehicle(0);
    },
    [pageSizeVehicle],
  );

  // Change sort status
  const handleSortVehicle = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortVehicle(sort);
      setPageVehicle(0);
    },
    [sortVehicle],
  );

  // Change filters status
  const handleFilterVehicle = useCallback(
    (filter: IVehicleFilter) => {
      setFilterVehicle(filter);
      setPageVehicle(0);
    },
    [filterVehicle],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalVehicle = useCallback(
    () => setIsOpenVehicleFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionVehicle = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionVehicle(selection);
    },
    [selectionVehicle],
  );

  // Clear data rows
  const handleClearStorageVehicle = useCallback(() => {
    setRowsVehicle([]);
    setRowCountVehicle(0);
  }, [setRowsVehicle]);

  // Load data from request
  const handleLoadStorageVehicle = useCallback(
    (response: IApiResponseWithPagination<IVehicleList>) => {
      setRowsVehicle(response.data);
      setRowCountVehicle(response.records);
    },
    [setRowsVehicle],
  );

  // API - Get all records
  const getAllVehicle = useCallback(() => {
    // Reset selection
    handleSelectionVehicle([]);
    // Add filters in params
    const params: any = getFilterParams(filterVehicle);
    // Add sort in params
    const sort = getSortParams(sortVehicle);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeVehicle;
    params.offset = pageVehicle * pageSizeVehicle;
    // Get data
    VehicleService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        handleClearStorageVehicle();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageVehicle(0);
        return;
      }
      handleLoadStorageVehicle(response);
    });
  }, [headers, filterVehicle, sortVehicle, pageVehicle, pageSizeVehicle, dataVehicle]);

  // API - Get record by id
  const getByIdVehicle = useCallback(
    (id: number) => {
      // Get details
      VehicleService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicle(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createVehicle = useCallback(
    (payload: IVehicleDTO) => {
      // Get details
      VehicleService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        // Update data
        const updatedData: IVehicleData = { ...payload, id: response };
        handleDataVehicle(updatedData);
        //
        snackbarSuccess('Registro adicionado!');
      });
    },
    [getAllVehicle],
  );

  // API - Update record by id
  const updateByIdVehicle = useCallback(
    (id: number, payload: IVehicleDTO) => {
      // Get details
      VehicleService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        // Update data
        const updatedData: IVehicleData = { ...payload, id };
        handleDataVehicle(updatedData);
        //
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllVehicle],
  );

  // API - Delete record by id
  const deleteByIdVehicle = useCallback(
    (id: number) => {
      VehicleService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataVehicle();
        getAllVehicle();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllVehicle],
  );

  // API - Delete record by ids
  const deleteIdsVehicle = useCallback(
    (ids: number[]) => {
      VehicleService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllVehicle();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllVehicle],
  );

  return (
    <VehicleContext.Provider
      value={{
        // Data
        columnsVehicle,
        rowsVehicle,
        dataVehicle,
        defaultDataVehicle,
        hideFilterVehicle,
        handleDataVehicle,
        handleClearStorageVehicle,
        // Pagination
        rowCountVehicle,
        pageSizeVehicle,
        pageVehicle,
        handlePageSizeVehicle,
        handlePageVehicle,
        // Sort
        sortVehicle,
        handleSortVehicle,
        // Selection
        selectionVehicle,
        handleSelectionVehicle,
        // Filter
        defaultFilterVehicle,
        filterVehicle,
        isOpenFilterModalVehicle,
        toggleIsOpenFilterModalVehicle,
        handleFilterVehicle,
        // Api
        getAllVehicle,
        getByIdVehicle,
        createVehicle,
        updateByIdVehicle,
        deleteByIdVehicle,
        deleteIdsVehicle,
      }}
    >
      {children}
    </VehicleContext.Provider>
  );
};
