/* eslint-disable @typescript-eslint/naming-convention */
import { Refresh } from '@mui/icons-material';
import { Alert, AlertTitle, DialogActions, DialogContent, Divider, useTheme } from '@mui/material';
import * as Yup from 'yup';
import { Input, Dialog, FormSuite, DialogActionButton } from '../../../shared/components';
import { useUser, useDialog } from '../../../shared/hooks';
import { IUserChangePasswordDTO } from '../../../shared/types';
import { schemaValidation } from '../../../shared/utils';

interface IUserPerfilPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

// Define validation fields form
const schemaPayload = Yup.object({
  old_password: schemaValidation.commons.string(true),
  new_password: schemaValidation.commons
    .string(true)
    .min(3, 'No mínimo 3 caracteres')
    .max(10, 'No máximo 10 caracteres'),
  new_password_confirmation: schemaValidation.commons
    .string(true)
    .min(3, 'No mínimo 3 caracteres')
    .max(10, 'No máximo 10 caracteres')
    .oneOf([Yup.ref('new_password')], 'A senha não confere'),
});

const defaultValues = {
  old_password: '',
  new_password: '',
  new_password_confirmation: '',
};

const formId = 'user-perfil-change-password';

// Create modal data for User
export const UserPerfilPasswordModal: React.FC<IUserPerfilPasswordModalProps> = ({
  isOpen,
  onClose,
}) => {
  // Use context User
  const { updatePasswordCurrentUser } = useUser();

  // Use hook dialog
  const { dialogConfirmGrant } = useDialog();

  // Use theme
  const theme = useTheme();

  // Save actions
  const handleSubmit = (payload: object) => {
    dialogConfirmGrant('Alterar senha', 'Deseja realmente alterar sua senha?', () => {
      const data = payload as IUserChangePasswordDTO;
      updatePasswordCurrentUser(data);
    });
  };

  return (
    <Dialog title="Alterar senha" open={isOpen} close={onClose} maxWidth="xs" fullWidth>
      <DialogContent dividers>
        <FormSuite
          formId={formId}
          initialValues={defaultValues}
          validationSchema={schemaPayload}
          submit={handleSubmit}
        >
          <Input
            name="old_password"
            label="Senha atual *"
            placeholder="Informe sua senha atual"
            type="password"
          />

          <Divider sx={{ my: theme.spacing(2) }} />

          <Input
            name="new_password"
            label="Nova senha *"
            placeholder="Informe a nova senha"
            type="password"
          />
          <Input
            name="new_password_confirmation"
            label="Confirme a nova senha *"
            placeholder="Confirme sua nova senha"
            type="password"
          />

          <Alert severity="error" sx={{ mt: theme.spacing(1) }}>
            <AlertTitle>Atenção</AlertTitle>
            Ao alterar a senha será necessário realizar um novo login
          </Alert>
        </FormSuite>
      </DialogContent>

      <DialogActions>
        <DialogActionButton
          label="Alterar minha senha"
          tooltip="Alterar senha"
          type="submit"
          primaryAction
          form={formId}
          startIcon={<Refresh />}
          color="success"
        />
      </DialogActions>
    </Dialog>
  );
};
