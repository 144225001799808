/* eslint-disable react/jsx-props-no-spreading */
import { Tab as MuiTab, TabProps } from '@mui/material';

interface ITabProps extends TabProps {
  disabled?: boolean;
}

export const Tab: React.FC<ITabProps> = ({ disabled, ...rest }) => {
  return (
    <MuiTab
      {...rest}
      disabled={disabled}
      sx={{
        '&:disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'all !important',
        },
      }}
    />
  );
};
