import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useVehicle, useDialog, useDeviceIsMobile } from '../../../shared/hooks';
import { IVehicleFilter, IDataGridRowParams, IDataGridColumnsHidden } from '../../../shared/types';
import { VehicleDataGrid } from './VehicleDataGrid';
import { VehicleFilterModal } from './VehicleFilterModal';
import { VehicleModal } from './VehicleModal';

interface IVehicleMainProps {
  embedded?: boolean;
  initialFilter?: Partial<IVehicleFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {
  owner_id: false,
  brand: false,
  plate: false,
};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {
  plate: false,
  owner_id: false,
  brand: false,
  type: false,
};

export const VehicleMain: React.FC<IVehicleMainProps> = ({ embedded, initialFilter }) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState<boolean>(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsVehicle,
    rowsVehicle,
    defaultDataVehicle,
    handleDataVehicle,
    selectionVehicle,
    handleSelectionVehicle,
    filterVehicle,
    hideFilterVehicle,
    handleFilterVehicle,
    getByIdVehicle,
    deleteIdsVehicle,
  } = useVehicle();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterVehicle(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsVehicle]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterVehicle({});
      return;
    }
    handleFilterVehicle({ ...filterVehicle, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataVehicle(defaultDataVehicle);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdVehicle(+id);
  };

  const handleDeleteData = () => {
    if (!selectionVehicle || selectionVehicle.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsVehicle(selectionVehicle as number[]);
      },
    );
  };

  return (
    <>
      <VehicleFilterModal />

      {!embedded && (
        <>
          <VehicleModal />
          <DataGridFilters
            columns={columnsVehicle}
            filters={filterVehicle}
            hideFilters={hideFilterVehicle}
            onRemoveFilter={handleRemoveFilter}
          />
        </>
      )}

      <VehicleDataGrid
        selectionModel={selectionVehicle}
        onSelectionModelChange={handleSelectionVehicle}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionVehicle}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionVehicle?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
