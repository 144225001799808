import { Container, Grid, Paper, useTheme } from '@mui/material';
import { Field, Formik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { FloatingButton, Form, Input, InputMask, SelectTimezone } from '../../shared/components';
import { InputGroupAddress } from '../../shared/components/address';
import { useAuth, useCompany, useSnackbarDispatch } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { ICompanyDTO } from '../../shared/types';
import { schemaValidation } from '../../shared/utils';

const schemaPayloadCompany = Yup.object({
  name: schemaValidation.people.name,
  corporate_name: schemaValidation.people.corporate_name,
  cnpj: schemaValidation.documents.cnpj,
  manager_name: schemaValidation.contact.name(true),
  email: schemaValidation.contact.email(true),
  phone_number_1: schemaValidation.contact.phone(true),
  phone_number_2: schemaValidation.contact.phone(),
  address_postal_code: schemaValidation.address.postalCode,
  address_street_name: schemaValidation.address.streetName,
  address_street_number: schemaValidation.address.streetNumber,
  address_complement: schemaValidation.address.complement,
  address_neighborhood: schemaValidation.address.neighborhood,
  address_city: schemaValidation.address.city,
  address_state: schemaValidation.address.state,
  address_reference: schemaValidation.address.reference,
  timezone_id: schemaValidation.dateTime.timeZone,
  is_active: schemaValidation.status.active,
});

export const CompanyPage: React.FC = () => {
  const { user } = useAuth();

  const theme = useTheme();

  const { dataCompany, getCompany, updateCompany } = useCompany();

  const { snackbarError } = useSnackbarDispatch();

  const handleSave = (payload: ICompanyDTO) => updateCompany(payload);

  useEffect(() => {
    if (!user.token) return;
    getCompany();
  }, [user]);

  const handleShowFormErrors = (errors: any) => {
    const messages: string[] = [];
    Object.keys(errors).forEach((key: string) => {
      if (errors[key]) messages.push(errors[key]);
    });
    if (messages.length === 0) return;
    snackbarError(messages.join(', '));
  };

  return (
    <PageContainer>
      <PageTitle title="Empresa" subtitle="Dados da empresa atual" />

      <PageMain>
        <Container
          component={Paper}
          elevation={2}
          sx={{ py: 1, bgcolor: theme.palette.mode === 'light' ? null : theme.palette.grey[900] }}
        >
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={schemaPayloadCompany}
            initialValues={dataCompany}
            enableReinitialize
            onSubmit={(values) => {
              handleSave(values);
            }}
          >
            {({ handleSubmit, handleReset, validateForm, setFieldValue }) => (
              <>
                <Form id="form-company" onSubmit={handleSubmit} onReset={handleReset}>
                  <Grid container columnSpacing={2}>
                    <input type="hidden" name="id" />
                    <input type="hidden" name="is_active" />

                    <Grid item xs={12} md={8} xl={9}>
                      <Input autoFocus label="Nome fantasia *" name="name" />
                    </Grid>

                    <Grid item xs={12} md={4} xl={3}>
                      <InputMask
                        label="CNPJ *"
                        name="cnpj"
                        mask="cnpj"
                        maxLength={18}
                        inputMode="numeric"
                      />
                    </Grid>

                    <Grid item xs={12} md={8} xl={9}>
                      <Input label="Razão social *" name="corporate_name" />
                    </Grid>

                    <Grid item xs={12} md={4} xl={3}>
                      <Input label="Responsável *" name="manager_name" />
                    </Grid>

                    <Grid item xs={12} md={6} xl={8}>
                      <Input label="E-mail *" name="email" />
                    </Grid>

                    <Grid item xs={12} md={3} xl={2}>
                      <InputMask
                        label="Telefone *"
                        name="phone_number_1"
                        mask="phone"
                        maxLength={14}
                        inputMode="numeric"
                      />
                    </Grid>

                    <Grid item xs={12} md={3} xl={2}>
                      <InputMask
                        label="Celular"
                        name="phone_number_2"
                        mask="phone"
                        maxLength={14}
                        inputMode="numeric"
                      />
                    </Grid>

                    <InputGroupAddress setFieldValue={setFieldValue} baseName="address" />

                    <Grid item xs={12} md={3} xl={2}>
                      <Field name="timezone_id" component={SelectTimezone} />
                    </Grid>
                  </Grid>
                </Form>

                <FloatingButton
                  type="submit"
                  form="form-company"
                  color="success"
                  variant="extended"
                  label="Gravar"
                  icon="save"
                  onClick={() => validateForm().then((errors) => handleShowFormErrors(errors))}
                >
                  Salvar
                </FloatingButton>
              </>
            )}
          </Formik>
        </Container>
      </PageMain>
    </PageContainer>
  );
};
