/* eslint-disable react/jsx-props-no-spreading */
import { InputMask, IInputMaskProps } from './InputMask';

export interface IInputNumberProps extends Omit<IInputMaskProps, 'name'> {
  name: string;
}

export const InputNumber: React.FC<IInputNumberProps> = ({ name, ...rest }) => {
  return (
    <InputMask
      name={name}
      inputMode="numeric"
      mask="quantity"
      inputProps={{
        style: {
          textAlign: 'right',
        },
      }}
      sx={{
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
          display: 'none',
        },
        '& input[type=number]': {
          MozAppearance: 'textfield',
        },
      }}
      onFocus={(event) => {
        event.target.select();
      }}
      {...rest}
    />
  );
};
