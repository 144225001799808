/* eslint-disable @typescript-eslint/naming-convention */
import { Grid } from '@mui/material';
import { Field } from 'formik';
import * as Yup from 'yup';
import {
  DataDialog,
  Input,
  InputNumber,
  SelectFinancialCategoryType,
} from '../../../shared/components';
import { useFinancialCategory, useDialog } from '../../../shared/hooks';
import { IFinancialCategoryDTO } from '../../../shared/types';
import { schemaValidation, toNumber } from '../../../shared/utils';

// Define validation fields form
const schemaPayloadFinancialCategory = Yup.object({
  name: schemaValidation.people.name,
});

// Create modal data for FinancialCategory
export const FinancialCategoryModal: React.FC = () => {
  // Use context FinancialCategory
  const {
    dataFinancialCategory,
    defaultDataFinancialCategory,
    handleDataFinancialCategory,
    createFinancialCategory,
    updateByIdFinancialCategory,
    deleteByIdFinancialCategory,
  } = useFinancialCategory();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Reset data and close modal
  const handleClose = () => handleDataFinancialCategory();

  // Save actions
  const handleSave = (payload: any) => {
    const normalize = { ...payload };
    normalize.report_order = toNumber(payload.report_order);

    const id = dataFinancialCategory?.id || 0;
    const data = normalize as IFinancialCategoryDTO;
    if (id > 0) {
      updateByIdFinancialCategory(id, data);
      return;
    }
    createFinancialCategory(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, name } = payload;
    dialogConfirmDanger(
      'Excluir categoria?',
      `Deseja realmente registro a categoria financeira: ${id} - ${name} `,
      () => deleteByIdFinancialCategory(id),
    );
  };

  return (
    <DataDialog
      referenceId={dataFinancialCategory?.id}
      referenceDisplayName="categoria"
      initialValues={dataFinancialCategory || defaultDataFinancialCategory}
      validationSchema={schemaPayloadFinancialCategory}
      open={dataFinancialCategory !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      <input type="hidden" name="is_active" />

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={12}>
          <Input autoFocus label="Nome *" name="name" />
        </Grid>

        <Grid item xs={12} md={8}>
          <Field name="type" component={SelectFinancialCategoryType} />
        </Grid>

        <Grid item xs={6} md={4}>
          <InputNumber label="Ordem DRE" name="report_order" />
        </Grid>
      </Grid>
    </DataDialog>
  );
};
