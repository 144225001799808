import { IPeopleResource, IPeopleAddressList, IPeopleAddressDTO } from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { PeopleAddressSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  peopleResource: IPeopleResource,
  peopleId: number,
): Promise<IPeopleAddressList[] | ApiError> => {
  try {
    const response = await Api().get(`${peopleResource}/${peopleId}/addresses`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, PeopleAddressSchemaApiError);
  }
};

// Create record
const create = async (
  headers: any,
  peopleResource: IPeopleResource,
  peopleId: number,
  payload: IPeopleAddressDTO,
): Promise<number | ApiError> => {
  try {
    const response = await Api().post(`${peopleResource}/${peopleId}/addresses`, payload, {
      headers,
    });
    return response.data.business_type_id;
  } catch (error: any) {
    return makeApiError(error, PeopleAddressSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  peopleResource: IPeopleResource,
  peopleId: number,
  id: number,
  payload: IPeopleAddressDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`${peopleResource}/${peopleId}/addresses/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, PeopleAddressSchemaApiError);
  }
};

// delete record by id
const deleteById = async (
  headers: any,
  peopleResource: IPeopleResource,
  peopleId: number,
  id: number,
): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`${peopleResource}/${peopleId}/addresses/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, PeopleAddressSchemaApiError);
  }
};

export const PeopleAddressService = { getAll, create, updateById, deleteById };
