import { useSnackbar, VariantType } from 'notistack';
import { createContext, useCallback } from 'react';

// Define interface for context
interface ISnackbarDispatchContextData {
  snackbarSuccess: (message: string) => void;
  snackbarError: (message: string) => void;
  snackbarWarning: (message: string) => void;
  snackbarInfo: (message: string) => void;
}

// Crete context
export const SnackbarDispatchContext = createContext({} as ISnackbarDispatchContextData);

// Create snackbar dispatch context
export const SnackbarDispatchContextProvider: React.FC = ({ children }) => {
  // Use hook snackbar
  const { enqueueSnackbar } = useSnackbar();

  // Dispatch default
  const dispatchSnackbar = useCallback(
    (message: string, type: VariantType) => enqueueSnackbar(message, { variant: type }),
    [],
  );

  // Dispatch snack success custom
  const snackbarSuccess = useCallback(
    (message: string) => dispatchSnackbar(message, 'success'),
    [],
  );

  // Dispatch snack error custom
  const snackbarError = useCallback((message: string) => dispatchSnackbar(message, 'error'), []);

  // Dispatch snack warning custom
  const snackbarWarning = useCallback(
    (message: string) => dispatchSnackbar(message, 'warning'),
    [],
  );

  // Dispatch snack info custom
  const snackbarInfo = useCallback((message: string) => dispatchSnackbar(message, 'info'), []);

  return (
    <SnackbarDispatchContext.Provider
      value={{ snackbarSuccess, snackbarError, snackbarWarning, snackbarInfo }}
    >
      {children}
    </SnackbarDispatchContext.Provider>
  );
};
