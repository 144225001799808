import { useEffect } from 'react';
import { useAuth } from '../../shared/hooks';
import {
  LoginContainer,
  LoginForm,
  LoginIntro,
  LoginPinModal,
  LoginRecoverPasswordModal,
} from './components';

// Page login
export const LoginPage: React.FC = () => {
  // Use rook auth
  const { logout } = useAuth();

  // On load, clear token access data in local storage
  useEffect(() => {
    logout(false);
  }, []);

  return (
    <LoginContainer>
      <LoginRecoverPasswordModal />
      <LoginPinModal />
      <LoginIntro />
      <LoginForm />
    </LoginContainer>
  );
};
