import { useVehicleMaintenance } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { VehicleMaintenanceMain } from './components/VehicleMaintenanceMain';

export const VehicleMaintenancePage: React.FC = () => {
  const { toggleIsOpenFilterModalVehicleMaintenance } = useVehicleMaintenance();

  return (
    <PageContainer>
      <PageTitle
        title="Manutenção"
        subtitle="Gerencie os serviços de manutenção"
        filterAction={toggleIsOpenFilterModalVehicleMaintenance}
      />

      <PageMain>
        <VehicleMaintenanceMain />
      </PageMain>
    </PageContainer>
  );
};
