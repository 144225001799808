import { Field } from 'formik';
import { FilterDialog, DataPicker } from '../../../shared/components';
import { useVehicleUsage } from '../../../shared/hooks';
import { EmployeeInputSelectBase } from '../../employee/components';
import { VehicleInputSelectBase } from '../../vehicle/components/VehicleInputSelectBase';

// Create modal filter for VehicleUsage
export const VehicleUsageFilterModal: React.FC = () => {
  // Use context VehicleUsage
  const {
    isOpenFilterModalVehicleUsage,
    toggleIsOpenFilterModalVehicleUsage,
    handleFilterVehicleUsage,
    filterVehicleUsage,
    defaultFilterVehicleUsage,
  } = useVehicleUsage();

  return (
    <FilterDialog
      isOpen={isOpenFilterModalVehicleUsage}
      onClose={toggleIsOpenFilterModalVehicleUsage}
      setFilter={handleFilterVehicleUsage}
      filter={filterVehicleUsage}
      initialValues={defaultFilterVehicleUsage}
    >
      <Field name="start_date_withdrawn" component={DataPicker} label="Início" />

      <Field name="end_date_withdrawn" component={DataPicker} label="Fim" />

      <VehicleInputSelectBase refId="vehicle_id" refName="vehicle_detail" />

      <EmployeeInputSelectBase baseName="driver" label="Motorista" />
    </FilterDialog>
  );
};
