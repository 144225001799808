import { ChevronRight, Devices, Password, Pin } from '@mui/icons-material';
import {
  Avatar,
  Container,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { H1, P, Div } from '../../shared/components';
import { useAuth } from '../../shared/hooks';
import { PageContainer } from '../../shared/layouts';
import {
  UserPerfilDeviceModal,
  UserPerfilPasswordModal,
  UserPerfilTwoStepAuthModal,
} from './components';

export const UserPerfilPage: React.FC = () => {
  const [isOpenPassword, setIsOpenPassword] = useState(false);
  const [isOpenTwoStepAuth, setIsOpenTwoStepAuth] = useState(false);
  const [isOpenDevice, setIsOpenDevice] = useState(false);

  const { user } = useAuth();

  const theme = useTheme();

  const handleToggleIsOpenPassword = () => setIsOpenPassword((prevState) => !prevState);

  const handleToggleIsOpenTwoStepAuth = () => setIsOpenTwoStepAuth((prevState) => !prevState);

  const handleToggleIsOpenDevice = () => setIsOpenDevice((prevState) => !prevState);

  return (
    <PageContainer>
      <UserPerfilPasswordModal isOpen={isOpenPassword} onClose={handleToggleIsOpenPassword} />
      <UserPerfilTwoStepAuthModal
        isOpen={isOpenTwoStepAuth}
        onClose={handleToggleIsOpenTwoStepAuth}
      />
      <UserPerfilDeviceModal isOpen={isOpenDevice} onClose={handleToggleIsOpenDevice} />

      <Container
        component={Paper}
        elevation={2}
        maxWidth="sm"
        sx={{
          mt: theme.spacing(3),
          p: theme.spacing(2),
          bgcolor: theme.palette.mode === 'light' ? null : theme.palette.grey[900],
        }}
      >
        <Div display="flex" flexDirection="column" alignItems="center">
          <Avatar
            sx={{
              color: theme.palette.secondary.contrastText,
              bgcolor: theme.palette.primary.main,
              width: 100,
              height: 100,
              fontSize: 42,
              my: theme.spacing(2),
            }}
          >
            W
          </Avatar>
          <H1 sx={{ mb: theme.spacing(2) }}>{user.payload?.nickname}</H1>

          <P color={theme.palette.text.secondary}>{user.payload?.email}</P>
          <P color={theme.palette.text.secondary}>{user.payload?.companies.role.name}</P>
        </Div>

        <Divider sx={{ my: theme.spacing(2) }} />

        <List sx={{ width: '100%' }}>
          <ListItemButton onClick={handleToggleIsOpenPassword}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  color: theme.palette.secondary.contrastText,
                  bgcolor: theme.palette.secondary.main,
                }}
              >
                <Password />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Alterar senha"
              secondary="Cadastre uma nova senha (É necessário fazer o logoff)"
            />
            <ChevronRight />
          </ListItemButton>

          <ListItemButton onClick={handleToggleIsOpenTwoStepAuth}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  color: theme.palette.secondary.contrastText,
                  bgcolor: theme.palette.secondary.main,
                }}
              >
                <Pin />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Autenticação dupla"
              secondary="Receba um PIN por e-mail para autorizar novos dispositivos"
            />
            <ChevronRight />
          </ListItemButton>

          <ListItemButton onClick={handleToggleIsOpenDevice}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  color: theme.palette.secondary.contrastText,
                  bgcolor: theme.palette.secondary.main,
                }}
              >
                <Devices />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Dispositivos"
              secondary="Lista de dispositivos autorizados por PIN"
            />
            <ChevronRight />
          </ListItemButton>
        </List>
      </Container>
    </PageContainer>
  );
};
