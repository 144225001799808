import {
  IApiResponseWithPagination,
  ISupplierData,
  ISupplierList,
  ISupplierDTO,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { SupplierSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<ISupplierList> | ApiError> => {
  try {
    const response = await Api().get('/suppliers', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, SupplierSchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<ISupplierData | ApiError> => {
  try {
    const response = await Api().get(`/suppliers/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, SupplierSchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: ISupplierDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/suppliers', payload, { headers });
    return response.data.supplier_id;
  } catch (error: any) {
    return makeApiError(error, SupplierSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: ISupplierDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/suppliers/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, SupplierSchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/suppliers/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, SupplierSchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/suppliers/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, SupplierSchemaApiError);
  }
};

export const SupplierService = { getAll, getById, create, updateById, deleteById, deleteIds };
