/* eslint-disable react/jsx-props-no-spreading */
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { InputSelect, SelectDialog, IInputProps } from '../../../shared/components';
import { useVehicle } from '../../../shared/hooks';
import { IDataGridColumnsHidden, IDataGridRowParams } from '../../../shared/types';
import { VehicleDataGrid } from './VehicleDataGrid';
import { VehicleFilterModal } from './VehicleFilterModal';

export interface IVehicleInputSelectBaseProps extends Omit<IInputProps, 'name' | 'onSelect'> {
  label?: string;
  title?: string;
  baseName?: string;
  refId?: string;
  refName?: string;
  enableAdd?: boolean;
}

const defaultBaseName = 'vehicle';

const defaultTitle = 'Veículo';

const hiddenColumnsVehicle: IDataGridColumnsHidden = {
  plate: false,
  owner_id: false,
  brand: false,
  type: false,
};

export const VehicleInputSelectBase: React.FC<IVehicleInputSelectBaseProps> = ({
  label = defaultTitle,
  title = defaultTitle,
  baseName = defaultBaseName,
  refId,
  refName,
  disabled,
  enableAdd = false,
  ...rest
}) => {
  // Define name
  const [fieldId] = useState(refId || `${baseName}_id`);
  const [fieldName] = useState(refName || `${baseName}_name`);

  // State for control opened search dialog
  const [isOpen, setIsOpen] = useState(false);

  // Use hook formik context
  const { setFieldValue } = useFormikContext();

  // Use context
  const {
    toggleIsOpenFilterModalVehicle,
    handleFilterVehicle,
    defaultDataVehicle,
    handleDataVehicle,
  } = useVehicle();

  // Open search dialog
  const handleOpen = () => {
    if (disabled) return;
    setIsOpen(true);
  };

  // Close search modal
  const handleClose = () => setIsOpen(false);

  // On click in add button
  const handleAdd = () => handleDataVehicle(defaultDataVehicle);

  // On click in filter button
  const handleResetFilter = () => handleFilterVehicle({});

  // Apply filters
  const handleApplyFilterName = (filter: any) => handleFilterVehicle({ plate: filter });

  // On select record
  const handleSelect = (data: IDataGridRowParams) => {
    setFieldValue(fieldId, data.row.id);
    const { plate, model } = data.row;
    setFieldValue(fieldName, plate + (model ? `, ${model}` : ''));
    handleClose();
  };

  return (
    <>
      <VehicleFilterModal />

      <SelectDialog
        title={title}
        placeholder="Pesquisa por placa"
        open={isOpen}
        onClose={handleClose}
        applyFilter={handleApplyFilterName}
        showFilter={toggleIsOpenFilterModalVehicle}
        resetFilter={handleResetFilter}
        add={enableAdd ? handleAdd : undefined}
      >
        <VehicleDataGrid
          simpleMode
          onRowClick={handleSelect}
          columnVisibilityModel={hiddenColumnsVehicle}
        />
      </SelectDialog>

      <InputSelect
        label={label}
        fieldId={fieldId}
        fieldName={fieldName}
        onClick={handleOpen}
        disabled={disabled}
        {...rest}
      />
    </>
  );
};
