import { HashRouter, Route, Routes as Switch } from 'react-router-dom';
import {
  LoginPage,
  HomePage,
  Page404,
  BusinessTypePage,
  CompanyPage,
  TravelPage,
  ContractPage,
  CustomerPage,
  SupplierPage,
  UserPage,
  EmployeePage,
  VehiclePage,
  MedicalLeavePage,
  InsurancePlanPage,
  ChangeLogPage,
  DeliveryPage,
  VehicleSupplyPage,
  VehicleMaintenancePage,
  VehicleUsagePage,
  Page403,
  FinancialCategoryPage,
  FinancialPage,
  UserPerfilPage,
  ResetPasswordPage,
} from '../pages';
import {
  AuthContextProvider,
  CompanyContextProvider,
  TravelContextProvider,
  BusinessTypeContextProvider,
  ContractContextProvider,
  PeopleContactContextProvider,
  PeopleAddressContextProvider,
  UserContextProvider,
  EmployeeContextProvider,
  CustomerContextProvider,
  SupplierContextProvider,
  PeoplePaymentAccountContextProvider,
  VehicleContextProvider,
  MedicalLeaveContextProvider,
  InsurancePlanContextProvider,
  ChangeLogContextProvider,
  DeliveryContextProvider,
  VehicleSupplyContextProvider,
  VehicleMaintenanceContextProvider,
  VehicleUsageContextProvider,
  FinancialCategoryContextProvider,
  FinancialContextProvider,
  AttachmentContextProvider,
} from '../shared/contexts';
import { ENV } from '../shared/environment';
import { PrivateRoute } from './PrivateRoute';

// Component routes
export const Routes = () => {
  return (
    <HashRouter basename={ENV.APP.URL_BASE}>
      <AuthContextProvider>
        <AttachmentContextProvider>
          <CompanyContextProvider>
            <ChangeLogContextProvider>
              <UserContextProvider>
                <BusinessTypeContextProvider>
                  <VehicleContextProvider>
                    <ContractContextProvider>
                      <MedicalLeaveContextProvider>
                        <InsurancePlanContextProvider>
                          <PeopleContactContextProvider>
                            <PeopleAddressContextProvider>
                              <PeoplePaymentAccountContextProvider>
                                <EmployeeContextProvider>
                                  <CustomerContextProvider>
                                    <SupplierContextProvider>
                                      <TravelContextProvider>
                                        <DeliveryContextProvider>
                                          <VehicleSupplyContextProvider>
                                            <VehicleMaintenanceContextProvider>
                                              <VehicleUsageContextProvider>
                                                <FinancialCategoryContextProvider>
                                                  <FinancialContextProvider>
                                                    <Switch>
                                                      <Route path="/" element={<LoginPage />} />
                                                      <Route
                                                        path="/login"
                                                        element={<LoginPage />}
                                                      />
                                                      <Route
                                                        path="/reset-password"
                                                        element={<ResetPasswordPage />}
                                                      />
                                                      <Route
                                                        path="/pagina-inicial"
                                                        element={
                                                          <PrivateRoute component={HomePage} />
                                                        }
                                                      />
                                                      <Route
                                                        path="/ramos-de-atividade"
                                                        element={
                                                          <PrivateRoute
                                                            component={BusinessTypePage}
                                                          />
                                                        }
                                                      />
                                                      <Route
                                                        path="/contratos"
                                                        element={
                                                          <PrivateRoute component={ContractPage} />
                                                        }
                                                      />
                                                      <Route
                                                        path="/clientes"
                                                        element={
                                                          <PrivateRoute component={CustomerPage} />
                                                        }
                                                      />
                                                      <Route
                                                        path="/colaboradores"
                                                        element={
                                                          <PrivateRoute component={EmployeePage} />
                                                        }
                                                      />
                                                      <Route
                                                        path="/fornecedores"
                                                        element={
                                                          <PrivateRoute component={SupplierPage} />
                                                        }
                                                      />
                                                      <Route
                                                        path="/veiculos"
                                                        element={
                                                          <PrivateRoute component={VehiclePage} />
                                                        }
                                                      />
                                                      <Route
                                                        path="/atestados"
                                                        element={
                                                          <PrivateRoute
                                                            component={MedicalLeavePage}
                                                          />
                                                        }
                                                      />
                                                      <Route
                                                        path="/seguros"
                                                        element={
                                                          <PrivateRoute
                                                            component={InsurancePlanPage}
                                                          />
                                                        }
                                                      />
                                                      <Route
                                                        path="/empresa"
                                                        element={
                                                          <PrivateRoute component={CompanyPage} />
                                                        }
                                                      />
                                                      <Route
                                                        path="/viagens"
                                                        element={
                                                          <PrivateRoute component={TravelPage} />
                                                        }
                                                      />
                                                      <Route
                                                        path="/entregas"
                                                        element={
                                                          <PrivateRoute component={DeliveryPage} />
                                                        }
                                                      />
                                                      <Route
                                                        path="/uso-veiculos"
                                                        element={
                                                          <PrivateRoute
                                                            component={VehicleUsagePage}
                                                          />
                                                        }
                                                      />
                                                      <Route
                                                        path="/manutencoes"
                                                        element={
                                                          <PrivateRoute
                                                            component={VehicleMaintenancePage}
                                                          />
                                                        }
                                                      />
                                                      <Route
                                                        path="/abastecimentos"
                                                        element={
                                                          <PrivateRoute
                                                            component={VehicleSupplyPage}
                                                          />
                                                        }
                                                      />
                                                      <Route
                                                        path="/financeiro/categorias"
                                                        element={
                                                          <PrivateRoute
                                                            component={FinancialCategoryPage}
                                                          />
                                                        }
                                                      />
                                                      <Route
                                                        path="/financeiro/lancamentos"
                                                        element={
                                                          <PrivateRoute component={FinancialPage} />
                                                        }
                                                      />
                                                      <Route
                                                        path="/usuarios"
                                                        element={
                                                          <PrivateRoute component={UserPage} />
                                                        }
                                                      />
                                                      <Route
                                                        path="/logs"
                                                        element={
                                                          <PrivateRoute component={ChangeLogPage} />
                                                        }
                                                      />
                                                      <Route
                                                        path="/perfil"
                                                        element={
                                                          <PrivateRoute
                                                            component={UserPerfilPage}
                                                          />
                                                        }
                                                      />
                                                      <Route
                                                        path="/403-nao-autorizado"
                                                        element={<Page403 />}
                                                      />
                                                      <Route path="*" element={<Page404 />} />
                                                    </Switch>
                                                  </FinancialContextProvider>
                                                </FinancialCategoryContextProvider>
                                              </VehicleUsageContextProvider>
                                            </VehicleMaintenanceContextProvider>
                                          </VehicleSupplyContextProvider>
                                        </DeliveryContextProvider>
                                      </TravelContextProvider>
                                    </SupplierContextProvider>
                                  </CustomerContextProvider>
                                </EmployeeContextProvider>
                              </PeoplePaymentAccountContextProvider>
                            </PeopleAddressContextProvider>
                          </PeopleContactContextProvider>
                        </InsurancePlanContextProvider>
                      </MedicalLeaveContextProvider>
                    </ContractContextProvider>
                  </VehicleContextProvider>
                </BusinessTypeContextProvider>
              </UserContextProvider>
            </ChangeLogContextProvider>
          </CompanyContextProvider>
        </AttachmentContextProvider>
      </AuthContextProvider>
    </HashRouter>
  );
};
