import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useVehicleUsage, useDialog, useDeviceIsMobile } from '../../../shared/hooks';
import {
  IVehicleUsageFilter,
  IDataGridRowParams,
  IDataGridColumnsHidden,
} from '../../../shared/types';
import { VehicleUsageDataGrid } from './VehicleUsageDataGrid';
import { VehicleUsageFilterModal } from './VehicleUsageFilterModal';
import { VehicleUsageModal } from './VehicleUsageModal';

interface IVehicleUsageMainProps {
  embedded?: boolean;
  initialFilter?: Partial<IVehicleUsageFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {
  start_date_withdrawn: false,
  end_date_withdrawn: false,
  vehicle_id: false,
  driver_id: false,
};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {
  start_date_withdrawn: false,
  end_date_withdrawn: false,
  vehicle_id: false,
  driver_id: false,
  driver_name: false,
};

export const VehicleUsageMain: React.FC<IVehicleUsageMainProps> = ({ embedded, initialFilter }) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState<boolean>(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsVehicleUsage,
    rowsVehicleUsage,
    defaultDataVehicleUsage,
    handleDataVehicleUsage,
    selectionVehicleUsage,
    handleSelectionVehicleUsage,
    filterVehicleUsage,
    hideFilterVehicleUsage,
    handleFilterVehicleUsage,
    getByIdVehicleUsage,
    deleteIdsVehicleUsage,
  } = useVehicleUsage();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterVehicleUsage(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsVehicleUsage]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterVehicleUsage({});
      return;
    }
    handleFilterVehicleUsage({ ...filterVehicleUsage, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataVehicleUsage(defaultDataVehicleUsage);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdVehicleUsage(+id);
  };

  const handleDeleteData = () => {
    if (!selectionVehicleUsage || selectionVehicleUsage.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsVehicleUsage(selectionVehicleUsage as number[]);
      },
    );
  };

  return (
    <>
      <VehicleUsageFilterModal />
      <VehicleUsageModal />

      {!embedded && (
        <DataGridFilters
          columns={columnsVehicleUsage}
          filters={filterVehicleUsage}
          hideFilters={hideFilterVehicleUsage}
          onRemoveFilter={handleRemoveFilter}
        />
      )}

      <VehicleUsageDataGrid
        selectionModel={selectionVehicleUsage}
        onSelectionModelChange={handleSelectionVehicleUsage}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionVehicleUsage}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionVehicleUsage?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
