/* eslint-disable react/jsx-props-no-spreading */
// import { Grid } from '@mui/material';
import { Grid, GridProps } from '@mui/material';
import { Field } from 'formik';
import { useCallback, useState } from 'react';
import { validateCep } from 'validations-br';
import { useSnackbarDispatch } from '../../hooks';
import { IViaCepResponse, ViaCepService } from '../../services';

import { IconButton } from '../inputs/IconButton';
import { Input } from '../inputs/Input';
import { InputMask } from '../inputs/InputMask';
import { SearchPostalCodeDialog } from './SearchPostalCodeDialog';
import { SelectAddressState } from './SelectAddressState';

type IInputGroupAddressGridItemProps = GridProps;

interface IInputGroupAddressGridItems {
  postalCode?: IInputGroupAddressGridItemProps;
  streetName?: IInputGroupAddressGridItemProps;
  streetNumber?: IInputGroupAddressGridItemProps;
  complement?: IInputGroupAddressGridItemProps;
  neighborhood?: IInputGroupAddressGridItemProps;
  city?: IInputGroupAddressGridItemProps;
  state?: IInputGroupAddressGridItemProps;
}

interface IInputGroupAddressProps {
  baseName?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  gridItemsProps?: IInputGroupAddressGridItems;
}

export const InputGroupAddress: React.FC<IInputGroupAddressProps> = ({
  baseName = '',
  setFieldValue,
  gridItemsProps = {},
}) => {
  const [initialValuesPostalCode, setInitialValuesPostalCode] = useState('');

  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);

  const { snackbarError } = useSnackbarDispatch();

  const prefix = baseName === '' ? '' : `${baseName}_`;

  const handleToggleIsOpenSearchModal = useCallback(
    () => setIsOpenSearchModal((prevState) => !prevState),
    [setIsOpenSearchModal],
  );

  const handleClearFields = useCallback(() => {
    setInitialValuesPostalCode('');
    setFieldValue(`${prefix}street_name`, '');
    setFieldValue(`${prefix}neighborhood`, '');
    setFieldValue(`${prefix}city`, '');
    setFieldValue(`${prefix}state`, '');
  }, [setInitialValuesPostalCode]);

  const handleSetFields = useCallback(
    (address: IViaCepResponse) => {
      setInitialValuesPostalCode(address.cep);
      setFieldValue(`${prefix}postal_code`, address.cep);
      setFieldValue(`${prefix}street_name`, address.logradouro);
      setFieldValue(`${prefix}neighborhood`, address.bairro);
      setFieldValue(`${prefix}city`, address.localidade);
      setFieldValue(`${prefix}state`, address.uf);
    },
    [setInitialValuesPostalCode],
  );

  const handleSearchPostalCode = useCallback(
    (element: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      const currentPostalCode = element.currentTarget.value;

      if (currentPostalCode === '') return;

      const isValidPostalCode = validateCep(currentPostalCode);

      if (!isValidPostalCode) {
        snackbarError('CEP inválido');
        return;
      }

      if (currentPostalCode === initialValuesPostalCode) return;

      ViaCepService.getByCep(currentPostalCode).then((response) => {
        if (response instanceof Error) {
          snackbarError('CEP não localizado');
          handleClearFields();
          return;
        }
        handleSetFields(response);
      });
    },
    [initialValuesPostalCode],
  );

  return (
    <>
      <SearchPostalCodeDialog
        isOpen={isOpenSearchModal}
        onClose={handleToggleIsOpenSearchModal}
        onSelect={handleSetFields}
      />

      <Grid item xs={12} md={3} xl={2} {...gridItemsProps.postalCode}>
        <InputMask
          label="CEP *"
          name={`${prefix}postal_code`}
          mask="postalCode"
          maxLength={9}
          inputMode="numeric"
          onFocus={(e) => setInitialValuesPostalCode(e.currentTarget.value)}
          onBlur={handleSearchPostalCode}
          InputProps={{
            endAdornment: (
              <IconButton
                tabIndex={-1}
                icon="search"
                tooltip="Nào sabe o CEP? Clique aqui pra consultar"
                onClick={handleToggleIsOpenSearchModal}
              />
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} md={9} xl={10} {...gridItemsProps.streetName}>
        <Input label="Logradouro *" name={`${prefix}street_name`} />
      </Grid>

      <Grid item xs={12} md={2} xl={2} {...gridItemsProps.streetNumber}>
        <Input label="Número *" name={`${prefix}street_number`} />
      </Grid>

      <Grid item xs={12} md={4} xl={4} {...gridItemsProps.complement}>
        <Input label="Complemento" name={`${prefix}complement`} />
      </Grid>

      <Grid item xs={12} md={6} xl={6} {...gridItemsProps.neighborhood}>
        <Input label="Bairro *" name={`${prefix}neighborhood`} />
      </Grid>

      <Grid item xs={12} md={6} xl={8} {...gridItemsProps.city}>
        <Input label="Cidade *" name={`${prefix}city`} />
      </Grid>

      <Grid item xs={12} md={3} xl={2} {...gridItemsProps.state}>
        <Field name={`${prefix}state`} component={SelectAddressState} />
      </Grid>
    </>
  );
};
