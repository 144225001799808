/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useVehicleSupply } from '../../../shared/hooks';

type IVehicleSupplyDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const VehicleSupplyDataGrid: React.FC<IVehicleSupplyDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsVehicleSupply,
    rowsVehicleSupply,
    rowCountVehicleSupply,
    pageVehicleSupply,
    handlePageVehicleSupply,
    pageSizeVehicleSupply,
    handlePageSizeVehicleSupply,
    sortVehicleSupply,
    filterVehicleSupply,
    handleFilterVehicleSupply,
    handleSortVehicleSupply,
    getAllVehicleSupply,
  } = useVehicleSupply();

  useEffect(() => handleFilterVehicleSupply({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllVehicleSupply();
  }, [canSearch, sortVehicleSupply, filterVehicleSupply, pageVehicleSupply, pageSizeVehicleSupply]);

  return (
    <DataGrid
      rows={rowsVehicleSupply}
      rowCount={rowCountVehicleSupply}
      columns={columnsVehicleSupply}
      onSortModelChange={handleSortVehicleSupply}
      sortModel={sortVehicleSupply}
      pageSize={pageSizeVehicleSupply}
      onPageSizeChange={handlePageSizeVehicleSupply}
      page={pageVehicleSupply}
      onPageChange={handlePageVehicleSupply}
      {...rest}
    />
  );
};
