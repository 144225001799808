import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useVehicleSupply, useDialog, useDeviceIsMobile } from '../../../shared/hooks';
import {
  IVehicleSupplyFilter,
  IDataGridRowParams,
  IDataGridColumnsHidden,
} from '../../../shared/types';
import { VehicleSupplyDataGrid } from './VehicleSupplyDataGrid';
import { VehicleSupplyFilterModal } from './VehicleSupplyFilterModal';
import { VehicleSupplyModal } from './VehicleSupplyModal';

interface IVehicleSupplyMainProps {
  embedded?: boolean;
  initialFilter?: Partial<IVehicleSupplyFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {
  start_date: false,
  end_date: false,
  driver_id: false,
  vehicle_id: false,
  vehicle_detail: false,
  liter: false,
};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {
  start_date: false,
  end_date: false,
  driver_id: false,
  vehicle_id: false,
  vehicle_detail: false,
  type: false,
  fueled_at: false,
  liter: false,
  gas_station: false,
  fuel_type: false,
  audited: false,
};

export const VehicleSupplyMain: React.FC<IVehicleSupplyMainProps> = ({
  embedded,
  initialFilter,
}) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState<boolean>(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsVehicleSupply,
    rowsVehicleSupply,
    defaultDataVehicleSupply,
    handleDataVehicleSupply,
    selectionVehicleSupply,
    handleSelectionVehicleSupply,
    filterVehicleSupply,
    hideFilterVehicleSupply,
    handleFilterVehicleSupply,
    getByIdVehicleSupply,
    deleteIdsVehicleSupply,
  } = useVehicleSupply();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterVehicleSupply(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsVehicleSupply]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterVehicleSupply({});
      return;
    }
    handleFilterVehicleSupply({ ...filterVehicleSupply, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataVehicleSupply(defaultDataVehicleSupply);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdVehicleSupply(+id);
  };

  const handleDeleteData = () => {
    if (!selectionVehicleSupply || selectionVehicleSupply.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsVehicleSupply(selectionVehicleSupply as number[]);
      },
    );
  };

  return (
    <>
      <VehicleSupplyFilterModal />
      <VehicleSupplyModal />

      {!embedded && (
        <DataGridFilters
          columns={columnsVehicleSupply}
          filters={filterVehicleSupply}
          hideFilters={hideFilterVehicleSupply}
          onRemoveFilter={handleRemoveFilter}
        />
      )}

      <VehicleSupplyDataGrid
        selectionModel={selectionVehicleSupply}
        onSelectionModelChange={handleSelectionVehicleSupply}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionVehicleSupply}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionVehicleSupply?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
