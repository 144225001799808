/* eslint-disable react/jsx-props-no-spreading */
import { Typography, TypographyProps } from '@mui/material';

// Extend props MUI Typography
type ISpanProps = TypographyProps<'span'>;

// Create component Span Custom
export const Span: React.FC<ISpanProps> = ({ children, ...rest }) => {
  return (
    <Typography component="span" variant="overline" {...rest}>
      {children}
    </Typography>
  );
};
