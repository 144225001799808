import { createTheme } from '@mui/material';

// Default theme light mode
export const ThemeLight = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3E4095',
      dark: '#001a66',
      light: '#706bc6',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ED3237',
      dark: '#b30010',
      light: '#ff6b62',
      contrastText: '#ffffff',
    },
  },
});
