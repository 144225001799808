/* eslint-disable react/jsx-props-no-spreading */
import { Fab, FabProps, Icon, useTheme, Zoom } from '@mui/material';
import { TooltipWrapper } from '../feedback';
import { useDeviceIsMobile, useLoader } from '../../hooks';

// Extend props MUI Button
export interface IFloatingButtonProps extends FabProps {
  label: string;
  icon: React.ReactNode | string;
  tooltip?: string;
}
// Create component Button Custom
export const FloatingButton: React.FC<IFloatingButtonProps> = ({
  label,
  icon,
  tooltip,
  children,
  hidden,
  ...rest
}) => {
  // use theme
  const theme = useTheme();

  const deviceIsMobile = useDeviceIsMobile();

  // Use "hook" loader
  const isRunning = useLoader();

  // Set default props
  const props: Partial<IFloatingButtonProps> = {
    color: 'primary',
    sx: {
      position: deviceIsMobile ? 'fixed' : 'absolute',
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
    ...rest,
    disabled: isRunning ? true : rest.disabled,
  };

  return hidden ? null : (
    <TooltipWrapper title={tooltip}>
      <Zoom in unmountOnExit>
        <Fab aria-label={label} {...props}>
          {typeof icon === 'string' ? <Icon sx={{ mr: children ? 1 : 0 }}>{icon}</Icon> : { icon }}
          {children}
        </Fab>
      </Zoom>
    </TooltipWrapper>
  );
};
