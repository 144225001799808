/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useVehicleUsage } from '../../../shared/hooks';

type IVehicleUsageDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const VehicleUsageDataGrid: React.FC<IVehicleUsageDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsVehicleUsage,
    rowsVehicleUsage,
    rowCountVehicleUsage,
    pageVehicleUsage,
    handlePageVehicleUsage,
    pageSizeVehicleUsage,
    handlePageSizeVehicleUsage,
    sortVehicleUsage,
    filterVehicleUsage,
    handleFilterVehicleUsage,
    handleSortVehicleUsage,
    getAllVehicleUsage,
  } = useVehicleUsage();

  useEffect(() => handleFilterVehicleUsage({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllVehicleUsage();
  }, [canSearch, sortVehicleUsage, filterVehicleUsage, pageVehicleUsage, pageSizeVehicleUsage]);

  return (
    <DataGrid
      rows={rowsVehicleUsage}
      rowCount={rowCountVehicleUsage}
      columns={columnsVehicleUsage}
      onSortModelChange={handleSortVehicleUsage}
      sortModel={sortVehicleUsage}
      pageSize={pageSizeVehicleUsage}
      onPageSizeChange={handlePageSizeVehicleUsage}
      page={pageVehicleUsage}
      onPageChange={handlePageVehicleUsage}
      {...rest}
    />
  );
};
