/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material';
import { Div } from '../display-data';

export const UploadSingleFileDropZone: React.FC = ({ children, ...rest }) => {
  const theme = useTheme();

  return (
    <Div
      sx={{ mb: theme.spacing(1) }}
      style={{
        cursor: 'pointer',
      }}
      {...rest}
    >
      {children}
    </Div>
  );
};
