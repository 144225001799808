import { Field } from 'formik';
import { useState } from 'react';
import { Input, FilterDialog, SelectPeopleType } from '../../../shared/components';
import { useUser } from '../../../shared/hooks';
import { IPeopleType } from '../../../shared/types';
import { CustomerInputSelectBase } from '../../customer/components/CustomerInputSelectBase';
import { EmployeeInputSelectBase } from '../../employee/components/EmployeeInputSelectBase';

// Create modal filter for User
export const UserFilterModal: React.FC = () => {
  // State people type
  const [peopleType, setPeopleType] = useState<IPeopleType>('COLABORADOR');

  // On change people type
  const handleChangePeopleType = (type: IPeopleType) => setPeopleType(type);

  // Use context User
  const {
    isOpenFilterModalUser,
    toggleIsOpenFilterModalUser,
    handleFilterUser,
    filterUser,
    defaultFilterUser,
  } = useUser();

  return (
    <FilterDialog
      isOpen={isOpenFilterModalUser}
      onClose={toggleIsOpenFilterModalUser}
      setFilter={handleFilterUser}
      filter={filterUser}
      initialValues={defaultFilterUser}
    >
      <Field
        name="people_type"
        component={SelectPeopleType}
        disableSupplier
        value={peopleType}
        onSelect={handleChangePeopleType}
      />
      {peopleType === 'CLIENTE' && <CustomerInputSelectBase baseName="people" />}
      {peopleType === 'COLABORADOR' && <EmployeeInputSelectBase baseName="people" />}
      <Input label="E-mail" name="username" placeholder="E-mail de acesso do usuário" />
    </FilterDialog>
  );
};
