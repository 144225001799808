import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useUser, useDialog, useDeviceIsMobile } from '../../../shared/hooks';
import { IUserFilter, IDataGridRowParams, IDataGridColumnsHidden } from '../../../shared/types';
import { UserDataGrid } from './UserDataGrid';
import { UserFilterModal } from './UserFilterModal';
import { UserModal } from './UserModal';

interface IUserMainProps {
  embedded?: boolean;
  initialFilter?: Partial<IUserFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {
  username: false,
  people_id: false,
};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {
  username: false,
  people_id: false,
  role_name: false,
  administrative_platform: false,
  driver_platform: false,
  customer_platform: false,
};

export const UserMain: React.FC<IUserMainProps> = ({ embedded, initialFilter }) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState<boolean>(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsUser,
    rowsUser,
    defaultDataUser,
    handleDataUser,
    selectionUser,
    handleSelectionUser,
    filterUser,
    hideFilterUser,
    handleFilterUser,
    getByIdUser,
    deleteIdsUser,
  } = useUser();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterUser(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsUser]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterUser({});
      return;
    }
    handleFilterUser({ ...filterUser, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataUser(defaultDataUser);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdUser(+id);
  };

  const handleDeleteData = () => {
    if (!selectionUser || selectionUser.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsUser(selectionUser as number[]);
      },
    );
  };

  return (
    <>
      <UserFilterModal />

      {!embedded && (
        <>
          <UserModal />
          <DataGridFilters
            columns={columnsUser}
            filters={filterUser}
            hideFilters={hideFilterUser}
            onRemoveFilter={handleRemoveFilter}
          />
        </>
      )}

      <UserDataGrid
        selectionModel={selectionUser}
        onSelectionModelChange={handleSelectionUser}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionUser}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionUser?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
