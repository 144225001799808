/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

interface ISelectFinancialTypeProps extends FieldProps {
  defaultOption?: string;
  enableAll?: boolean;
  label?: string;
}

const options: ISelectOptions[] = [
  { label: 'Todos', value: '' },
  { label: 'Crédito', value: 'C' },
  { label: 'Débito', value: 'D' },
];

export const SelectFinancialType: React.FC<ISelectFinancialTypeProps> = ({
  label = 'Tipo',
  enableAll = false,
  defaultOption = 'C',
  ...rest
}) => {
  if (!enableAll) {
    const indexAll = options.findIndex((option) => option.label === 'Todos');
    if (indexAll > -1) options.splice(indexAll, 1);
  }
  return <Select label={label} defaultOption={defaultOption} dataOptions={options} {...rest} />;
};
