import { Alert, Divider, Grid, useTheme } from '@mui/material';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  FormSuite,
  SelectPersonType,
  InputMask,
  Input,
  SelectEmployeeCategory,
  SelectEmployeeType,
  InputCurrency,
  Checkbox,
  SelectEmployeePaymentType,
  InputNumber,
} from '../../../shared/components';
import { useEmployee } from '../../../shared/hooks';
import { IPersonType, IEmployeePaymentType } from '../../../shared/types';
import { schemaValidation } from '../../../shared/utils';
import { EmployeeApprovalStatus } from './EmployeeApprovalStatus';

interface IEmployeeModalDataTabProps {
  formId: string;
  isEditMode?: boolean;
  onSave: (data: object) => void;
}
// Define validation fields form
const schemaPayloadEmployee = Yup.object({
  person_type: Yup.string().required().oneOf(['FISICA', 'JURIDICA']),
  cnpj: Yup.string().when('person_type', {
    is: 'JURIDICA',
    then: schemaValidation.documents.cnpj,
  }),
  cpf: Yup.string().when('person_type', {
    is: 'FISICA',
    then: schemaValidation.documents.cpf,
  }),
  name: schemaValidation.people.corporate_name,
  nickname: Yup.string().when('person_type', {
    is: 'JURIDICA',
    then: schemaValidation.people.name,
  }),
  cnh: Yup.string().when('category', {
    is: 'MOTORISTA',
    then: schemaValidation.commons.string(true),
  }),
});

export const EmployeeModalDataTab: React.FC<IEmployeeModalDataTabProps> = ({
  formId,
  isEditMode,
  onSave,
}) => {
  // State people type
  const [peopleType, setPeopleType] = useState<IPersonType>('FISICA');

  // State payment type
  const [paymentType, setPaymentType] = useState<IEmployeePaymentType>('DIARIO');

  // Use context Employee
  const { dataEmployee, defaultDataEmployee, approveRegistrationById } = useEmployee();

  // Use theme
  const theme = useTheme();

  // On change people type
  const handleChangePeopleType = (value: IPersonType) => setPeopleType(value);

  // On change payment type
  const handleChangePaymentType = (value: IEmployeePaymentType) => setPaymentType(value);

  // Load initial data
  useEffect(() => {
    if (dataEmployee === undefined) return;
    const type: IPersonType = dataEmployee.person_type || 'FISICA';
    handleChangePeopleType(type);
    const payment: IEmployeePaymentType = dataEmployee.payment_type || 'DIARIO';
    handleChangePaymentType(payment);
  }, [dataEmployee]);

  // Toggle status user
  const handleApproveRegistration = () => {
    if (!dataEmployee) return;
    approveRegistrationById(dataEmployee?.id);
  };

  return (
    <FormSuite
      formId={formId}
      initialValues={dataEmployee || defaultDataEmployee}
      validationSchema={schemaPayloadEmployee}
      submit={onSave}
    >
      {!isEditMode && (
        <Alert severity="info">
          Ao adicionar um colaborador, é necessário gravar para liberar acesso as demais abas.
        </Alert>
      )}

      {dataEmployee && dataEmployee.id > 0 && dataEmployee.status === 'AWAITING_APPROVAL' ? (
        <EmployeeApprovalStatus onClick={handleApproveRegistration} />
      ) : null}
      <input type="hidden" name="id" />
      <input type="hidden" name="is_active" />
      <Grid container columnSpacing={2}>
        <Grid item xs={5} md={4}>
          <Field name="category" component={SelectEmployeeCategory} />
        </Grid>

        <Grid item xs={7} md={4}>
          <Field name="type" component={SelectEmployeeType} />
        </Grid>

        <Grid item xs={5} md={4}>
          <Field
            name="person_type"
            component={SelectPersonType}
            value={peopleType}
            onSelect={handleChangePeopleType}
          />
        </Grid>

        <Grid item xs={7} md={5}>
          {peopleType === 'JURIDICA' && (
            <InputMask label="CNPJ *" name="cnpj" mask="cnpj" maxLength={18} inputMode="numeric" />
          )}

          {peopleType === 'FISICA' && (
            <InputMask label="CPF *" name="cpf" mask="cpf" maxLength={14} inputMode="numeric" />
          )}
        </Grid>

        <Grid item xs={5} md={3}>
          {peopleType === 'JURIDICA' && (
            <Input label="IE" name="ie" maxLength={18} inputMode="numeric" />
          )}

          {peopleType === 'FISICA' && <InputMask label="RG" name="rg" mask="rg" maxLength={12} />}
        </Grid>

        <Grid item xs={7} md={4}>
          <Input label="CNH" name="cnh" maxLength={11} inputMode="numeric" />
        </Grid>

        <Grid item xs={12} md={12}>
          <Input label={peopleType === 'JURIDICA' ? 'Razão social *' : 'Nome *'} name="name" />
        </Grid>

        <Grid item xs={12} md={12}>
          <Input
            label={peopleType === 'JURIDICA' ? 'Nome fantasia *' : 'Apelido'}
            name="nickname"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Divider sx={{ my: theme.spacing(1) }} textAlign="left">
            Pagamento
          </Divider>
        </Grid>

        <Grid item xs={paymentType === 'MENSAL' ? 6 : 12} md={paymentType === 'MENSAL' ? 8 : 12}>
          <Field
            name="payment_type"
            component={SelectEmployeePaymentType}
            value={paymentType}
            onSelect={handleChangePaymentType}
          />
        </Grid>

        {paymentType === 'MENSAL' && (
          <Grid item xs={6} md={4}>
            <InputNumber label="Dia" name="payment_day" />
          </Grid>
        )}

        <Grid item xs={6} md={4}>
          <InputCurrency label="Valor" name="payment_value" />
        </Grid>

        <Grid item xs={6} md={4}>
          <InputCurrency label="Vale refeição" name="food_voucher" />
        </Grid>

        <Grid item xs={12} md={4} sx={{ pt: theme.spacing(3) }}>
          <Checkbox label="Cesta básica?" name="basic_basket" />
        </Grid>
      </Grid>

      <Grid item xs={12} md={12}>
        <Divider sx={{ my: theme.spacing(1) }} textAlign="left" />
      </Grid>

      <Grid item xs={12} md={12}>
        <Input label="Observações" name="tags" />
      </Grid>
    </FormSuite>
  );
};
