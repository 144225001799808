import { useCustomer } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { CustomerMain } from './components/CustomerMain';

export const CustomerPage: React.FC = () => {
  const { toggleIsOpenFilterModalCustomer } = useCustomer();

  return (
    <PageContainer>
      <PageTitle
        title="Cliente"
        subtitle="Gerencie o cadastro de clientes"
        filterAction={toggleIsOpenFilterModalCustomer}
      />

      <PageMain>
        <CustomerMain />
      </PageMain>
    </PageContainer>
  );
};
