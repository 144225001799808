/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useDelivery } from '../../../shared/hooks';

type IDeliveryDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const DeliveryDataGrid: React.FC<IDeliveryDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsDelivery,
    rowsDelivery,
    rowCountDelivery,
    pageDelivery,
    handlePageDelivery,
    pageSizeDelivery,
    handlePageSizeDelivery,
    sortDelivery,
    filterDelivery,
    handleFilterDelivery,
    handleSortDelivery,
    getAllDelivery,
  } = useDelivery();

  useEffect(() => handleFilterDelivery({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllDelivery();
  }, [canSearch, sortDelivery, filterDelivery, pageDelivery, pageSizeDelivery]);

  return (
    <DataGrid
      rows={rowsDelivery}
      rowCount={rowCountDelivery}
      columns={columnsDelivery}
      onSortModelChange={handleSortDelivery}
      sortModel={sortDelivery}
      pageSize={pageSizeDelivery}
      onPageSizeChange={handlePageSizeDelivery}
      page={pageDelivery}
      onPageChange={handlePageDelivery}
      {...rest}
    />
  );
};
