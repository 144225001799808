import { Field } from 'formik';
import { FilterDialog, DataPicker } from '../../../shared/components';
import { useDelivery } from '../../../shared/hooks';
import { CustomerInputSelectBase } from '../../customer/components';
import { EmployeeInputSelectBase } from '../../employee/components';

// Create modal filter for Delivery
export const DeliveryFilterModal: React.FC = () => {
  // Use context Delivery
  const {
    isOpenFilterModalDelivery,
    toggleIsOpenFilterModalDelivery,
    handleFilterDelivery,
    filterDelivery,
    defaultFilterDelivery,
  } = useDelivery();

  return (
    <FilterDialog
      isOpen={isOpenFilterModalDelivery}
      onClose={toggleIsOpenFilterModalDelivery}
      setFilter={handleFilterDelivery}
      filter={filterDelivery}
      initialValues={defaultFilterDelivery}
    >
      <Field name="start_date" component={DataPicker} label="Início" />

      <Field name="end_date" component={DataPicker} label="Fim" />

      <CustomerInputSelectBase />

      <EmployeeInputSelectBase baseName="driver" label="Motorista" />
    </FilterDialog>
  );
};
