import { useFinancialCategory } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { FinancialCategoryMain } from './components/FinancialCategoryMain';

export const FinancialCategoryPage: React.FC = () => {
  const { toggleIsOpenFilterModalFinancialCategory } = useFinancialCategory();

  return (
    <PageContainer>
      <PageTitle
        title="Categorias"
        subtitle="Gerencie as categorias financeiras"
        filterAction={toggleIsOpenFilterModalFinancialCategory}
      />

      <PageMain>
        <FinancialCategoryMain />
      </PageMain>
    </PageContainer>
  );
};
