/* eslint-disable react/jsx-props-no-spreading */
import { Tabs as MuiTabs, TabsProps } from '@mui/material';

export type ITabsProps = TabsProps;

export const Tabs: React.FC<ITabsProps> = ({ children, ...rest }) => {
  return (
    <MuiTabs
      textColor="primary"
      indicatorColor="primary"
      aria-label="Navegação por abas"
      variant="fullWidth"
      scrollButtons
      allowScrollButtonsMobile
      sx={{ borderTop: 1, borderColor: 'divider' }}
      {...rest}
    >
      {children}
    </MuiTabs>
  );
};
