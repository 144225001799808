/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-types */
import { Paper, PaperProps } from '@mui/material';
import { EmptyData } from '../feedback';

interface IGridDataWrapperProps extends PaperProps {
  isEmpty: boolean;
}

export const DataGridWrapper: React.FC<IGridDataWrapperProps> = ({
  isEmpty,
  children,
  ...rest
}) => {
  return !isEmpty ? (
    <Paper sx={{ width: '100%', overflow: 'hidden' }} {...rest}>
      {children}
    </Paper>
  ) : (
    <EmptyData />
  );
};
