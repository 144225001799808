import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useFinancial, useDialog, useDeviceIsMobile } from '../../../shared/hooks';
import {
  IFinancialFilter,
  IDataGridRowParams,
  IDataGridColumnsHidden,
} from '../../../shared/types';
import { FinancialDataGrid } from './FinancialDataGrid';
import { FinancialFilterModal } from './FinancialFilterModal';
import { FinancialModal } from './FinancialModal';

interface IFinancialMainProps {
  embedded?: boolean;
  initialFilter?: Partial<IFinancialFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {
  start_due_date: false,
  end_due_date: false,
  type: false,
  category_id: false,
  people_id: false,
};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {
  start_due_date: false,
  end_due_date: false,
  type: false,
  category_id: false,
  category_name: false,
  people_name: false,
  people_id: false,
  due_date: false,
  its_paid: false,
};

export const FinancialMain: React.FC<IFinancialMainProps> = ({ embedded, initialFilter }) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState<boolean>(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsFinancial,
    rowsFinancial,
    defaultDataFinancial,
    handleDataFinancial,
    selectionFinancial,
    handleSelectionFinancial,
    filterFinancial,
    hideFilterFinancial,
    handleFilterFinancial,
    getByIdFinancial,
    deleteIdsFinancial,
  } = useFinancial();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterFinancial(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsFinancial]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterFinancial({});
      return;
    }
    handleFilterFinancial({ ...filterFinancial, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataFinancial(defaultDataFinancial);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdFinancial(+id);
  };

  const handleDeleteData = () => {
    if (!selectionFinancial || selectionFinancial.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsFinancial(selectionFinancial as number[]);
      },
    );
  };

  return (
    <>
      <FinancialFilterModal />
      <FinancialModal />

      {!embedded && (
        <DataGridFilters
          columns={columnsFinancial}
          filters={filterFinancial}
          hideFilters={hideFilterFinancial}
          onRemoveFilter={handleRemoveFilter}
        />
      )}

      <FinancialDataGrid
        selectionModel={selectionFinancial}
        onSelectionModelChange={handleSelectionFinancial}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionFinancial}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionFinancial?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
