import { Input, FilterDialog } from '../../../shared/components';
import { useBusinessType } from '../../../shared/hooks';

// Create modal filter for business type
export const BusinessTypeFilterModal: React.FC = () => {
  // Use context business type
  const {
    isOpenFilterModalBusinessType,
    toggleIsOpenFilterModalBusinessType,
    handleFilterBusinessType,
    filterBusinessType,
    defaultFilterBusinessType,
  } = useBusinessType();

  return (
    <FilterDialog
      isOpen={isOpenFilterModalBusinessType}
      onClose={toggleIsOpenFilterModalBusinessType}
      setFilter={handleFilterBusinessType}
      filter={filterBusinessType}
      initialValues={defaultFilterBusinessType}
    >
      <Input
        autoFocus
        label="Código"
        name="id"
        type="number"
        placeholder="Código do ramo de atividade"
      />
      <Input
        autoFocus
        label="Nome"
        name="name"
        type="text"
        placeholder="Nome do ramo de atividade"
      />
    </FilterDialog>
  );
};
