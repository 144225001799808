import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, PeopleContactService } from '../services';
import { IPeopleContactData, IPeopleContactDTO, IPeopleContactList, IPeopleType } from '../types';
import { getPeopleResource, toObjectWithoutNull } from '../utils';

// Define interface for context
interface IPeopleContactContext {
  // Data
  rowsPeopleContact: IPeopleContactList[];
  defaultDataPeopleContact: IPeopleContactData;
  dataPeopleContact: IPeopleContactData | undefined;
  handleDataPeopleContact: (data?: IPeopleContactData) => void;
  HandleLoadStoragePeopleContact: (data: IPeopleContactList[]) => void;
  // Api
  getAllPeopleContact: (peopleType: IPeopleType, peopleId: number) => void;
  createPeopleContact: (
    peopleType: IPeopleType,
    peopleId: number,
    payload: IPeopleContactDTO,
  ) => void;
  updateByIdPeopleContact: (
    peopleType: IPeopleType,
    peopleId: number,
    id: number,
    payload: IPeopleContactDTO,
  ) => void;
  deleteByIdPeopleContact: (peopleType: IPeopleType, peopleId: number, id: number) => void;
}

// initial values for data
const defaultDataPeopleContact: IPeopleContactData = {
  id: 0,
  type: 'TELEFONE',
  contact: '',
  name: '',
  complement: '',
  is_default: true,
};

// Create context
export const PeopleContactContext = createContext<IPeopleContactContext>(
  {} as IPeopleContactContext,
);

// Create context provider
export const PeopleContactContextProvider: React.FC = ({ children }) => {
  // Rows state
  const [rowsPeopleContact, setRowsPeopleContact] = useState<IPeopleContactList[]>([]);

  // Data state
  const [dataPeopleContact, setDataPeopleContact] = useState<IPeopleContactData | undefined>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataPeopleContact = useCallback(
    (data?: IPeopleContactData) => {
      if (!data) return setDataPeopleContact(data);
      const dataWithoutNull = toObjectWithoutNull<IPeopleContactData>(
        data,
        defaultDataPeopleContact,
      );
      return setDataPeopleContact(dataWithoutNull);
    },
    [dataPeopleContact],
  );

  // Clear data rows
  const HandleClearStoragePeopleContact = useCallback(() => {
    setRowsPeopleContact([]);
  }, [setRowsPeopleContact]);

  // Load data from request
  const HandleLoadStoragePeopleContact = useCallback(
    (response: IPeopleContactList[]) => {
      setRowsPeopleContact(response);
    },
    [setRowsPeopleContact],
  );

  // API - Get all records
  const getAllPeopleContact = useCallback(
    (peopleType: IPeopleType, peopleId: number) => {
      if (peopleId === 0) {
        HandleClearStoragePeopleContact();
        return;
      }
      // Get people resource
      const peopleResource = getPeopleResource(peopleType);
      // Get data
      PeopleContactService.getAll(headers, peopleResource, peopleId).then((response) => {
        if (response instanceof ApiError) {
          HandleClearStoragePeopleContact();
          snackbarError(response.message);
          return;
        }
        HandleLoadStoragePeopleContact(response);
      });
    },
    [headers, dataPeopleContact],
  );

  // API - Create record
  const createPeopleContact = useCallback(
    (peopleType: IPeopleType, peopleId: number, payload: IPeopleContactDTO) => {
      // Get people resource
      const peopleResource = getPeopleResource(peopleType);
      // Get details
      PeopleContactService.create(headers, peopleResource, peopleId, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataPeopleContact();
        snackbarSuccess('Registro adicionado!');
        getAllPeopleContact(peopleType, peopleId);
      });
    },
    [getAllPeopleContact],
  );

  // API - Update record by id
  const updateByIdPeopleContact = useCallback(
    (peopleType: IPeopleType, peopleId: number, id: number, payload: IPeopleContactDTO) => {
      // Get people resource
      const peopleResource = getPeopleResource(peopleType);
      // Get details
      PeopleContactService.updateById(headers, peopleResource, peopleId, id, payload).then(
        (response) => {
          if (response instanceof ApiError) {
            snackbarError(response.message);
            return;
          }
          handleDataPeopleContact();
          getAllPeopleContact(peopleType, peopleId);
          snackbarSuccess('Registro atualizado!');
        },
      );
    },
    [getAllPeopleContact],
  );

  // API - Delete record by id
  const deleteByIdPeopleContact = useCallback(
    (peopleType: IPeopleType, peopleId: number, id: number) => {
      // Get people resource
      const peopleResource = getPeopleResource(peopleType);
      // Delete record
      PeopleContactService.deleteById(headers, peopleResource, peopleId, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataPeopleContact();
        getAllPeopleContact(peopleType, peopleId);
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllPeopleContact],
  );

  return (
    <PeopleContactContext.Provider
      value={{
        // Data
        rowsPeopleContact,
        dataPeopleContact,
        defaultDataPeopleContact,
        handleDataPeopleContact,
        HandleLoadStoragePeopleContact,
        // Api
        getAllPeopleContact,
        createPeopleContact,
        updateByIdPeopleContact,
        deleteByIdPeopleContact,
      }}
    >
      {children}
    </PeopleContactContext.Provider>
  );
};
