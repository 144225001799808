/* eslint-disable react/jsx-props-no-spreading */
import { Typography, TypographyProps } from '@mui/material';

// Extend props MUI Typography H1
type IH1Props = TypographyProps<'h1'>;

// Create component H1 Custom
export const H1: React.FC<IH1Props> = ({ children, ...rest }) => {
  return (
    <Typography component="h1" variant="h4" {...rest}>
      {children}
    </Typography>
  );
};
