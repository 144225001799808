/* eslint-disable react/jsx-props-no-spreading */
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import { useLoader } from '../../hooks';
import { TooltipWrapper } from '../feedback';

// Extend props MUI TextField
export interface IInputProps extends Omit<TextFieldProps, 'name'> {
  name: string;
  maxLength?: number;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  tooltip?: string;
}

// Create component TextField Custom
export const Input: React.FC<IInputProps> = ({ name, maxLength, inputMode, tooltip, ...rest }) => {
  // Use hook formik
  const [field, meta] = useField(name);

  // Use "hook" loader
  const isRunning = useLoader();

  // Set default props
  const props: Partial<IInputProps> = {
    ...field,
    margin: 'normal',
    id: name,
    fullWidth: true,
    autoComplete: 'off',
    ...rest,
    disabled: isRunning ? true : rest.disabled,
  };

  // Append props with maxLength
  if (maxLength) {
    props.inputProps = {
      maxLength,
      ...props.inputProps,
    };
  }

  // Append props with inputMode
  if (inputMode) {
    props.inputProps = {
      inputMode,
      ...props.inputProps,
    };
  }

  if (!props.placeholder || props.placeholder === '') {
    const { label } = props;
    props.placeholder = String(label);
  }

  // Append props with erros and helpers
  if (meta && meta.touched && meta.error) {
    props.error = true;
    props.helperText = meta.error;
  }

  return (
    <TooltipWrapper title={tooltip}>
      <TextField {...props} />
    </TooltipWrapper>
  );
};
