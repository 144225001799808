import { useTravel } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { TravelMain } from './components/TravelMain';

export const TravelPage: React.FC = () => {
  const { toggleIsOpenFilterModalTravel } = useTravel();

  return (
    <PageContainer>
      <PageTitle
        title="Viagem"
        subtitle="Gerencie os serviços de viagem"
        filterAction={toggleIsOpenFilterModalTravel}
      />

      <PageMain>
        <TravelMain />
      </PageMain>
    </PageContainer>
  );
};
