import { FilterAlt } from '@mui/icons-material';
import { Grid, Paper, Toolbar, useTheme } from '@mui/material';
import { Field } from 'formik';
import * as Yup from 'yup';
import { Button, DataPicker, Div, FormSuite, Input } from '../../../shared/components';
import { useChangeLog } from '../../../shared/hooks';
import { schemaValidation } from '../../../shared/utils';
import { UserInputSelect } from '../../user/components';
import { IChangeLogFilter } from '../../../shared/types';

const formId = 'form-search-change-log';

const schemaPayloadChangeLog = Yup.object({
  start_date: schemaValidation.dateTime.date(),
  end_date: schemaValidation.dateTime
    .date(true)
    .min(Yup.ref('start_date'), 'Data final não pode ser inferior a inicial'),
});

export const ChangeLogFilter: React.FC = () => {
  const { filterChangeLog, handleFilterChangeLog, defaultFilterChangeLog } = useChangeLog();

  const theme = useTheme();

  const handleFilter = (payload: object) => handleFilterChangeLog(payload as IChangeLogFilter);

  const handleResetFilter = () => handleFilterChangeLog(defaultFilterChangeLog);

  return (
    <Toolbar component={Paper} elevation={2} sx={{ py: theme.spacing(2), mb: theme.spacing(2) }}>
      <FormSuite
        formId={formId}
        initialValues={filterChangeLog}
        validationSchema={schemaPayloadChangeLog}
        submit={handleFilter}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={6} md={3}>
            <Field name="start_date" component={DataPicker} label="Início *" />
          </Grid>

          <Grid item xs={6} md={3}>
            <Field name="end_date" component={DataPicker} label="Fim *" />
          </Grid>

          <Grid item xs={12} md={6}>
            <UserInputSelect baseName="user" />
          </Grid>

          <Grid item xs={12} md={12}>
            <Input label="Alterações" name="change_log" placeholder="Ex.: Alterou cliente..." />
          </Grid>

          <Grid item xs={12} md={12}>
            <Div isFlexRow sx={{ mt: theme.spacing(1) }}>
              <Div flexGrow={1} />

              <Button
                label="Limpar filtros"
                variant="text"
                onClick={handleResetFilter}
                sx={{ mr: theme.spacing(1) }}
              />

              <Button
                label="Filtrar"
                tooltip="Aplicar filtros"
                type="submit"
                form={formId}
                startIcon={<FilterAlt />}
              />
            </Div>
          </Grid>
        </Grid>
      </FormSuite>
    </Toolbar>
  );
};
