// Update partial data state object
export function updatePartialStateObject<Type>(
  object: any,
  setObject: (value: Type) => void,
  newValue?: Partial<Type>,
): Type {
  if (!newValue) return object;
  const updated = { ...object, ...newValue };
  setObject(updated);
  return updated;
}
