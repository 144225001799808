/* eslint-disable react/require-default-props */
import {
  Delete,
  AttachFile,
  UploadFile,
  Image,
  PictureAsPdf,
  ListAlt,
  Article,
} from '@mui/icons-material';
import {
  Alert,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IAttachmentData, IAttachmentList, IAttachmentResource } from '../../types';
import { Button, IconButton } from '../inputs';
import { Div } from '../display-data';
import { useAuth, useDialog, useAttachment } from '../../hooks';
import { AttachmentModal } from './AttachmentModal';
import { TooltipWrapper } from '../feedback';

interface IAttachmentProps {
  resource: IAttachmentResource;
  resourceId: number;
  initialData?: IAttachmentList[];
  disableType?: boolean;
}

export const Attachment: React.FC<IAttachmentProps> = ({
  resource,
  resourceId,
  initialData,
  disableType,
}) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const { dialogConfirmDanger } = useDialog();

  const {
    rowsAttachment,
    defaultDataAttachment,
    handleDataAttachment,
    HandleLoadStorageAttachment,
    getAllAttachment,
    deleteByIdAttachment,
  } = useAttachment();

  const theme = useTheme();

  useEffect(() => {
    if (!user.token || canSearch) return;
    if (initialData) {
      HandleLoadStorageAttachment(initialData);
      return;
    }
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllAttachment(resource, resourceId);
  }, [canSearch]);

  const handleAddData = () => handleDataAttachment(defaultDataAttachment);

  const handleEditData = (data: IAttachmentData) => handleDataAttachment(data);

  const handleDeleteData = (data: IAttachmentData) => {
    const { id, type, description } = data;

    const fileDescription = type && type !== '' ? type : description;

    dialogConfirmDanger(
      'Excluir anexo?',
      `Deseja realmente excluir o arquivo em anexo: ${fileDescription}`,
      () => deleteByIdAttachment(resource, resourceId, id),
    );
  };

  const handleOpenFile = (file: string) => window.open(file, '_blank');

  useEffect(() => {
    return () => {
      handleDataAttachment();
      getAllAttachment(resource, 0);
    };
  }, []);

  const getIconAttachment = (file: string) => {
    if (
      file.includes('.png') ||
      file.includes('.bmp') ||
      file.includes('.jfif') ||
      file.includes('.jpeg') ||
      file.includes('.jpg')
    )
      return <Image />;
    if (file.includes('.pdf')) return <PictureAsPdf />;
    if (file.includes('.xls')) return <ListAlt />;
    if (file.includes('.doc')) return <Article />;
    return <AttachFile />;
  };

  return (
    <Div>
      <AttachmentModal resource={resource} resourceId={resourceId} disableType={disableType} />

      <Toolbar style={{ paddingRight: 0, paddingLeft: 0, marginBottom: theme.spacing(1) }}>
        <Div flex={1} />
        <Button
          label="Adicionar anexo"
          startIcon={<UploadFile />}
          onClick={handleAddData}
          disableFocusRipple
        />
      </Toolbar>

      {rowsAttachment.length === 0 ? (
        <Alert severity="info" variant="outlined">
          Não há anexos para exibir, clique em <strong>ADICIONAR ANEXO</strong> para incluir um
          registro.
        </Alert>
      ) : (
        <List sx={{ width: '100%' }}>
          {rowsAttachment.map((attachment) => (
            <Paper
              key={attachment.id}
              elevation={2}
              sx={{
                mb: theme.spacing(1),
                cursor: 'pointer',
                bgcolor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : null,
                '& .MuiListItem-root:hover': {
                  bgcolor:
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[800],
                },
              }}
            >
              <ListItem
                sx={{
                  borderRadius: '4px',
                  '& .MuiListItemButton-root:hover': {
                    bgcolor: 'inherit',
                  },
                }}
                disablePadding
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    tooltip="Deletar anexo"
                    onClick={() => handleDeleteData(attachment)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemAvatar
                  sx={{
                    mx: 0,
                    my: '6px',
                    px: theme.spacing(2),
                    py: theme.spacing(1),
                    '& .MuiAvatar-root:hover': {
                      bgcolor: theme.palette.secondary.main,
                    },
                  }}
                  onClick={() => handleOpenFile(attachment.file)}
                >
                  <TooltipWrapper title="Clique para visualizar o arquivo">
                    <Avatar src={attachment.file} variant="rounded" component={Paper} elevation={2}>
                      {getIconAttachment(attachment.file)}
                    </Avatar>
                  </TooltipWrapper>
                </ListItemAvatar>

                <ListItemButton
                  onClick={() => handleEditData(attachment)}
                  disableRipple
                  disableTouchRipple
                  disableGutters
                  sx={{ pr: theme.spacing(2), py: theme.spacing(1) }}
                >
                  <ListItemText
                    primary={attachment.type}
                    secondary={
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {attachment.description || ''}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </Paper>
          ))}
        </List>
      )}
    </Div>
  );
};
