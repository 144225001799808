import {
  IApiResponseWithPagination,
  IMedicalLeaveData,
  IMedicalLeaveList,
  IMedicalLeaveDTO,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { MedicalLeaveSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<IMedicalLeaveList> | ApiError> => {
  try {
    const response = await Api().get('/medical-leaves', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, MedicalLeaveSchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<IMedicalLeaveData | ApiError> => {
  try {
    const response = await Api().get(`/medical-leaves/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, MedicalLeaveSchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: IMedicalLeaveDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/medical-leaves', payload, { headers });
    return response.data.business_type_id;
  } catch (error: any) {
    return makeApiError(error, MedicalLeaveSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: IMedicalLeaveDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/medical-leaves/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, MedicalLeaveSchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/medical-leaves/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, MedicalLeaveSchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/medical-leaves/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, MedicalLeaveSchemaApiError);
  }
};

export const MedicalLeaveService = { getAll, getById, create, updateById, deleteById, deleteIds };
