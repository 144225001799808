/* eslint-disable no-underscore-dangle */
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { DataDialog, InputCurrency, SelectWeekDay } from '../../../shared/components';
import { useContract, useDialog, useSnackbarDispatch } from '../../../shared/hooks';
import { IContractParamsDaily, IContractParamsDailyData } from '../../../shared/types';
import { formatterDayWeek, schemaValidation, toNumberOrZero } from '../../../shared/utils';

// Define interface for dialog
interface IContractParamsDailyModalProps {
  open: boolean;
  onClose: () => void;
  data?: IContractParamsDailyData | undefined;
}

// Define schema validator for form data
const schemaPayloadContract = Yup.object({
  week_day: Yup.number().required('Informe o dia da semana').notRequired(),
  week_day_list: Yup.array()
    .of(Yup.number())
    .min(1, 'Selecione ao menos um dia da semana')
    .notRequired(),
  delivery_price: schemaValidation.number.float,
  km_price: schemaValidation.number.float,
  fixed_price: schemaValidation.number.float,
});

// Create component dialog for params daily
export const ContractParamsDailyModal: React.FC<IContractParamsDailyModalProps> = ({
  open,
  onClose,
  data,
}) => {
  // State for edit mode
  const [isEditMode, setIsEditMode] = useState(false);
  // Use contract state
  const { dataParamsDailyContract, defaultDataParamsDailyContract, handleDataParamsDailyContract } =
    useContract();

  // Use dialog builders
  const { dialogConfirmDanger } = useDialog();

  // Use Snack dispatch
  const { snackbarSuccess, snackbarError } = useSnackbarDispatch();

  // Check if is edit mode
  useEffect(() => setIsEditMode(data?.week_day !== undefined), [data]);

  // Save data in params daily state
  const handleSave = useCallback(
    (payload: any) => {
      // Normalize values in payload data
      const normalizePayload: any = {
        delivery_price: toNumberOrZero(payload.delivery_price),
        km_price: toNumberOrZero(payload.km_price),
        fixed_price: toNumberOrZero(payload.fixed_price),
      };

      // Validate values
      const hasValue =
        normalizePayload.delivery_price > 0 ||
        normalizePayload.km_price > 0 ||
        normalizePayload.fixed_price > 0;

      // if there is not at least one value
      if (!hasValue) {
        snackbarError('Informe ao menos um valor');
        return;
      }

      // Get current data from state
      const currentParams = [...(dataParamsDailyContract || [])];

      // Define days array
      let days: number[] = [];
      if (isEditMode) {
        days.push(payload.week_day);
      } else {
        days = [...payload.week_day_list];
      }

      // Update or insert day in array state
      days.forEach((day: number) => {
        // Add day current to normalize object
        normalizePayload.week_day = day;

        // Create object with interface
        const newData: IContractParamsDaily = { ...normalizePayload };

        // If day in state, update... else append status
        const indexParams = currentParams.findIndex((item) => {
          return item.week_day === newData.week_day;
        });
        if (indexParams > -1) {
          currentParams[indexParams] = { ...newData };
        } else {
          currentParams.push({ ...newData });
        }
      });

      // Sort object
      currentParams.sort();

      // Update contract
      handleDataParamsDailyContract(currentParams);

      // Close dialog
      onClose();
      snackbarSuccess('Lista atualizada');
    },
    [dataParamsDailyContract, isEditMode],
  );

  // Drop item form state
  const handleDelete = useCallback(
    (payload: any) => {
      // Get week day for info message
      const dayOfWeek = formatterDayWeek(payload.week_day, 'longName').toLowerCase();

      // Confirm action
      dialogConfirmDanger(
        'Excluir dia da semana?',
        `Deseja realmente excluir a configuração de ${dayOfWeek}?`,
        () => {
          // Get current data from state
          const currentParams = [...(dataParamsDailyContract || [])];

          // Remove item from array
          const newData = currentParams.filter((item) => item.week_day !== payload.week_day);

          // Update contract
          handleDataParamsDailyContract(newData);

          // Close dialog
          onClose();
          snackbarSuccess('Item removido');
        },
      );
    },
    [dataParamsDailyContract],
  );

  return (
    <DataDialog
      formId="params-daily"
      referenceId={data?.week_day || -1}
      referenceDisplayName="dia da semana"
      title={isEditMode ? 'Editar dia da semana' : 'Adicionar dia da semana'}
      isEmptyData={!isEditMode}
      initialValues={data || defaultDataParamsDailyContract}
      validationSchema={schemaPayloadContract}
      open={open}
      close={onClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={12}>
          <Field
            name={isEditMode ? 'week_day' : 'week_day_list'}
            component={SelectWeekDay}
            multiple={!isEditMode}
            disabled={isEditMode}
            label="Dia da semana *"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputCurrency label="Preço por delivery" name="delivery_price" />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputCurrency label="Preço por KM" name="km_price" />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputCurrency label="Valor fixo" name="fixed_price" />
        </Grid>
      </Grid>
    </DataDialog>
  );
};
