/* eslint-disable react/no-unstable-nested-components */
import { alpha, IconButton } from '@mui/material';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarDispatchContextProvider } from './SnackbarDispatchContext';
import { ENV } from '../environment';

// Added close button action in all snackbar
const snackbarActionButton = (snackbar: any) => {
  const { closeSnackbar } = useSnackbar();
  const { snackbarKey } = snackbar;
  return (
    <IconButton
      onClick={() => closeSnackbar(snackbarKey)}
      sx={{ position: 'absolute', right: 8, top: 4, color: alpha('#fff', 0.5) }}
    >
      <CloseIcon />
    </IconButton>
  );
};

// Create notification center with snackbar
export const SnackbarContext: React.FC = ({ children }) => {
  return (
    <SnackbarProvider
      autoHideDuration={ENV.SNACKBAR.TIME_AUTOCLOSE}
      maxSnack={ENV.SNACKBAR.MAX_SNACK}
      preventDuplicate
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      action={snackbarActionButton}
    >
      <SnackbarDispatchContextProvider>{children}</SnackbarDispatchContextProvider>
    </SnackbarProvider>
  );
};
