/* eslint-disable @typescript-eslint/naming-convention */
import { Alert, Grid } from '@mui/material';
import { Field } from 'formik';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  Attachment,
  DataDialog,
  DataTimePicker,
  Input,
  InputNumber,
} from '../../../shared/components';
import { useVehicleUsage, useDialog } from '../../../shared/hooks';
import { IVehicleUsageDTO } from '../../../shared/types';
import {
  formatterQuantity,
  schemaValidation,
  toDateTimeApi,
  toNumber,
  toNumberOrZero,
} from '../../../shared/utils';
import { EmployeeInputSelectBase } from '../../employee/components';
import { VehicleInputSelectBase } from '../../vehicle/components/VehicleInputSelectBase';

// Define validation fields form
const schemaPayloadVehicleUsage = Yup.object({
  withdrew_at: schemaValidation.dateTime.timestamp(true),
  returned_at: schemaValidation.dateTime
    .timestamp(false)
    .min(Yup.ref('withdrew_at'), 'Data final não pode ser inferior a inicial'),
  vehicle_detail: schemaValidation.people.name,
  driver_name: schemaValidation.people.name,
});

// Create modal data for VehicleUsage
export const VehicleUsageModal: React.FC = () => {
  const endKmRef = useRef<HTMLInputElement>(null);
  const startKmRef = useRef<HTMLInputElement>(null);

  const [distance, setDistance] = useState(0);

  // Use context VehicleUsage
  const {
    dataVehicleUsage,
    defaultDataVehicleUsage,
    handleDataVehicleUsage,
    createVehicleUsage,
    updateByIdVehicleUsage,
    deleteByIdVehicleUsage,
  } = useVehicleUsage();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Calculate distance
  const handleCalculateDistance = (start_km?: any, end_km?: any) => {
    const start = toNumberOrZero(start_km || startKmRef.current?.value);
    const end = toNumberOrZero(end_km || endKmRef.current?.value);

    const km = end - start;

    setDistance(km >= 0 ? km : 0);
  };

  // Reset data and close modal
  const handleClose = () => handleDataVehicleUsage();

  // Save actions
  const handleSave = (payload: any) => {
    const normalize = { ...payload };
    normalize.withdrew_at = toDateTimeApi(payload.withdrew_at);
    if (payload.returned_at) normalize.returned_at = toDateTimeApi(payload.returned_at);
    normalize.start_km = toNumber(payload.start_km);
    normalize.end_km = toNumber(payload.end_km);

    const id = dataVehicleUsage?.id || 0;
    const data = normalize as IVehicleUsageDTO;
    if (id > 0) {
      updateByIdVehicleUsage(id, data);
      return;
    }
    createVehicleUsage(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, vehicle_detail } = payload;
    dialogConfirmDanger(
      'Excluir uso de veículo?',
      `Deseja realmente registro de uso do veículo: ${id} - ${vehicle_detail} `,
      () => deleteByIdVehicleUsage(id),
    );
  };

  useEffect(() => {
    if (!dataVehicleUsage) return;
    const { start_km, end_km } = dataVehicleUsage;
    handleCalculateDistance(start_km, end_km);
  }, [dataVehicleUsage]);

  return (
    <DataDialog
      referenceId={dataVehicleUsage?.id}
      referenceDisplayName="uso de veículo"
      initialValues={dataVehicleUsage || defaultDataVehicleUsage}
      validationSchema={schemaPayloadVehicleUsage}
      open={dataVehicleUsage !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={7}>
          <Field name="withdrew_at" component={DataTimePicker} label="Saída *" />
        </Grid>

        <Grid item xs={12} md={5}>
          <InputNumber
            inputRef={startKmRef}
            label="KM Inicial"
            name="start_km"
            onBlur={() => handleCalculateDistance()}
          />
        </Grid>

        <Grid item xs={12} md={7}>
          <Field name="returned_at" component={DataTimePicker} label="Retorno" />
        </Grid>

        <Grid item xs={12} md={5}>
          <InputNumber
            inputRef={endKmRef}
            label="KM Final"
            name="end_km"
            onBlur={() => handleCalculateDistance()}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          {distance > 0 && (
            <Alert sx={{ my: 1 }} severity="info">{`Distância total percorrida: ${formatterQuantity(
              distance,
            )} KM.`}</Alert>
          )}
        </Grid>

        <Grid item xs={12} md={12}>
          <VehicleInputSelectBase label="Veículo *" refId="vehicle_id" refName="vehicle_detail" />
        </Grid>

        <Grid item xs={12} md={12}>
          <EmployeeInputSelectBase baseName="driver" label="Motorista *" />
        </Grid>

        <Grid item xs={12} md={12}>
          <Input
            label="Ocorrências"
            name="events"
            placeholder="Registro de ocorrências / eventos relevantes durante o uso do veículo"
            minRows={3}
            multiline
          />
        </Grid>

        <Grid item xs={12} md={12}>
          {dataVehicleUsage && dataVehicleUsage.id > 0 ? (
            <Attachment
              resource="vehicles/usages"
              resourceId={dataVehicleUsage?.id || 0}
              initialData={dataVehicleUsage?.attachments}
              disableType
            />
          ) : (
            <Alert severity="info" sx={{ my: 1 }}>
              Ao adicionar um registro, é necessário gravar para liberar acesso aos anexos
            </Alert>
          )}
        </Grid>
      </Grid>
    </DataDialog>
  );
};
