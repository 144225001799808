import {
  DialogContent,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import { Form, Input, IconButton } from '../inputs';
import { Div } from '../display-data';
import { Dialog } from '../feedback';

export interface ISelectDialogProps {
  title: string;
  placeholder?: string;
  open: boolean;
  onClose: () => void;
  disableMenuOptions?: boolean;
  applyFilter?: (value: any) => void;
  showFilter?: () => void;
  resetFilter?: () => void;
  add?: () => void;
}

export const SelectDialog: React.FC<ISelectDialogProps> = ({
  title,
  placeholder = 'Pesquisa por nome',
  open,
  onClose,
  disableMenuOptions,
  applyFilter,
  showFilter,
  resetFilter,
  add,
  children,
}) => {
  const [anchorElMenuOptions, setAnchorElMenuOptions] = useState<null | HTMLElement>(null);

  const isOpenMenuOptions = Boolean(anchorElMenuOptions);

  const theme = useTheme();

  const handleClose = () => onClose();

  const handleCloseMenuOptions = () => setAnchorElMenuOptions(null);

  const handleAdd = () => {
    add?.();
    handleCloseMenuOptions();
  };

  const handleApplyFilter = (value: any) => {
    applyFilter?.(value.filter);
    handleCloseMenuOptions();
  };

  const handleShowFilter = () => {
    showFilter?.();
    handleCloseMenuOptions();
  };

  const handleResetFilter = () => {
    resetFilter?.();
    handleCloseMenuOptions();
  };

  const handleShowMenuOptions = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElMenuOptions(event.currentTarget);

  return (
    <Dialog
      title={title}
      classType="primary"
      open={open}
      maxWidth="md"
      close={handleClose}
      dividers
    >
      {applyFilter && (
        <Div
          sx={{
            px: theme.spacing(2),
            pb: theme.spacing(1),
            bgcolor:
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          }}
        >
          <Div display="flex" alignItems="start" width="100%">
            <Div flex={1}>
              <Formik
                initialValues={{ filter: '' }}
                onSubmit={(values) => {
                  handleApplyFilter(values);
                }}
              >
                {({ handleSubmit }) => (
                  <Form id="form-filter" onSubmit={handleSubmit}>
                    <Input
                      autoFocus
                      label="Pesquisar"
                      name="filter"
                      placeholder={placeholder}
                      InputProps={{
                        endAdornment: (
                          <IconButton type="submit" icon="search" tooltip="Pesquisar" />
                        ),
                      }}
                    />
                  </Form>
                )}
              </Formik>
            </Div>

            {!disableMenuOptions && (
              <IconButton
                sx={{ mt: theme.spacing(3) }}
                icon="more_vert"
                tooltip="Mais opções"
                onClick={handleShowMenuOptions}
                disableRipple
              />
            )}

            <Menu
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorElMenuOptions}
              open={isOpenMenuOptions}
              onClose={handleCloseMenuOptions}
            >
              {add && (
                <MenuItem onClick={handleAdd}>
                  <ListItemIcon>
                    <Icon>add_circle</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Adicionar" />
                </MenuItem>
              )}

              {showFilter && (
                <MenuItem onClick={handleShowFilter}>
                  <ListItemIcon>
                    <Icon>filter_list</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Mais filtros" />
                </MenuItem>
              )}

              {resetFilter && (
                <MenuItem onClick={handleResetFilter}>
                  <ListItemIcon>
                    <Icon>filter_alt_off</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Limpar filtros" />
                </MenuItem>
              )}
            </Menu>
          </Div>
        </Div>
      )}
      <DialogContent sx={{ m: 0, p: 0 }}>{children}</DialogContent>
    </Dialog>
  );
};
