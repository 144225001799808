import { useTheme } from '@mui/material';
import { P } from '../display-data';
import { UploadSingleFileIcon } from './UploadSingleFileIcon';
import { UploadSingleFileContainer } from './UploadSingleFileContainer';

export const UploadSingleFileEmpty: React.FC = () => {
  const theme = useTheme();

  return (
    <UploadSingleFileContainer
      bgColor={theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]}
      border={theme.palette.mode === 'light' ? '1px dashed #C4C4C4' : '1px dashed #666666'}
      enabled
    >
      <UploadSingleFileIcon icon="outbox" color={theme.palette.primary.main} />
      <P>
        Arraste o arquivo ou <strong>clique</strong> para selecionar
      </P>
    </UploadSingleFileContainer>
  );
};
