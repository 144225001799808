import { useContract } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { ContractMain } from './components/ContractMain';

export const ContractPage: React.FC = () => {
  const { toggleIsOpenFilterModalContract } = useContract();

  return (
    <PageContainer>
      <PageTitle
        title="Contratos"
        subtitle="Gerencie o cadastro de contratos"
        filterAction={toggleIsOpenFilterModalContract}
      />

      <PageMain>
        <ContractMain />
      </PageMain>
    </PageContainer>
  );
};
