/* eslint-disable @typescript-eslint/naming-convention */
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import {
  Input,
  FilterDialog,
  SelectPeopleType,
  SelectVehicleOwnerType,
  InputMask,
} from '../../../shared/components';
import { useVehicle } from '../../../shared/hooks';
import { IPeopleType, IVehicleOwnerTypeFilter } from '../../../shared/types';
import { toCapitalize } from '../../../shared/utils';
import { CustomerInputSelectBase } from '../../customer/components/CustomerInputSelectBase';
import { EmployeeInputSelectBase } from '../../employee/components/EmployeeInputSelectBase';
import { SupplierInputSelectBase } from '../../supplier/components/SupplierInputSelectBase';

// Create modal filter for Vehicle
export const VehicleFilterModal: React.FC = () => {
  // State for vehicle owner type
  const [ownerType, setOwnerType] = useState<IVehicleOwnerTypeFilter>('PROPRIO');

  // State for people type
  const [peopleType, setPeopleType] = useState<IPeopleType>('COLABORADOR');

  // On change owner type
  const handleChangeOwnerType = (value: IVehicleOwnerTypeFilter) => setOwnerType(value);

  // On change people type
  const handleChangePeopleType = (value: IPeopleType) => setPeopleType(value);

  // Use context Vehicle
  const {
    isOpenFilterModalVehicle,
    toggleIsOpenFilterModalVehicle,
    handleFilterVehicle,
    filterVehicle,
    defaultFilterVehicle,
  } = useVehicle();

  // Load initial data
  useEffect(() => {
    if (filterVehicle === undefined) return;
    const { owner_type, type } = filterVehicle;
    const owner: IVehicleOwnerTypeFilter = type || '';
    handleChangeOwnerType(owner);
    const people: IPeopleType =
      !owner_type || owner_type === 'EMPRESA' ? 'COLABORADOR' : owner_type;
    handleChangePeopleType(people);
  }, [filterVehicle]);

  return (
    <FilterDialog
      isOpen={isOpenFilterModalVehicle}
      onClose={toggleIsOpenFilterModalVehicle}
      setFilter={handleFilterVehicle}
      filter={filterVehicle}
      initialValues={defaultFilterVehicle}
    >
      <Input label="Código" name="id" type="number" placeholder="Código do veículo" />
      <InputMask autoFocus label="Placa" name="plate" mask="plate" maxLength={8} />
      <Input label="Marca" name="brand" />
      <Input label="Modelo" name="model" />

      <Field
        name="type"
        component={SelectVehicleOwnerType}
        defaultOption=""
        enableAll
        value={ownerType}
        onSelect={handleChangeOwnerType}
      />

      {ownerType === 'TERCEIRO' && (
        <>
          <Field
            name="owner_type"
            component={SelectPeopleType}
            label="Tipo do proprietário"
            value={peopleType}
            onSelect={handleChangePeopleType}
          />

          {peopleType === 'CLIENTE' && (
            <CustomerInputSelectBase
              baseName="owner"
              label={`Proprietário (${toCapitalize(peopleType)})`}
            />
          )}
          {peopleType === 'COLABORADOR' && (
            <EmployeeInputSelectBase
              baseName="owner"
              label={`Proprietário (${toCapitalize(peopleType)})`}
            />
          )}
          {peopleType === 'FORNECEDOR' && (
            <SupplierInputSelectBase
              baseName="owner"
              label={`Proprietário (${toCapitalize(peopleType)})`}
            />
          )}
        </>
      )}
    </FilterDialog>
  );
};
