/* eslint-disable react/no-array-index-key */
import {
  Alert,
  AlertTitle,
  Collapse,
  DialogContent,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useAuth, useSnackbarDispatch } from '../../hooks';
import { IViaCepResponse, IViaCepSearchByStreetNameDTO, ViaCepService } from '../../services';
import { Div } from '../display-data';
import { Dialog, EmptyData } from '../feedback';
import { Form, Input, IconButton } from '../inputs';
import { SelectAddressState } from './SelectAddressState';
import { schemaValidation } from '../../utils';

interface ISearchPostalCodeDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (payload: IViaCepResponse) => void;
}

const schemaPayloadSearchPostalCode = Yup.object({
  logradouro: schemaValidation.address.streetName.min(
    3,
    'Deve informar ao menos 3 caracteres para realizar a consulta',
  ),
  localidade: schemaValidation.address.city,
  uf: schemaValidation.address.state,
});

export const SearchPostalCodeDialog: React.FC<ISearchPostalCodeDialogProps> = ({
  isOpen,
  onClose,
  onSelect,
}) => {
  const [isExpandedFilter, setIsExpandedFilter] = useState(false);
  const [data, setData] = useState<IViaCepResponse[]>([]);

  const { user } = useAuth();
  const { snackbarError } = useSnackbarDispatch();
  const theme = useTheme();

  const dataSearchPostalCode: IViaCepSearchByStreetNameDTO = useMemo(() => {
    return {
      logradouro: '',
      localidade: user.payload?.companies.address.city || 'Bauru',
      uf: user.payload?.companies.address.state || 'SP',
    };
  }, [user]);

  const handleToggleIsExpandedFilter = useCallback(
    () => setIsExpandedFilter((prevState) => !prevState),
    [setIsExpandedFilter],
  );

  const handleSearchStreetName = useCallback(
    (values: IViaCepSearchByStreetNameDTO) => {
      const { uf, localidade, logradouro } = values;
      ViaCepService.getByLogradouro(uf, localidade, logradouro).then((response) => {
        if (response instanceof Error) {
          snackbarError('CEP não localizado');
          setData([]);
          return;
        }
        setData(response);
      });
    },
    [setData],
  );

  const handleClose = useCallback(() => {
    onClose();
    setData([]);
    setIsExpandedFilter(false);
  }, [onClose, setData]);

  const handleSelectAddress = useCallback(
    (payload: IViaCepResponse) => {
      onSelect(payload);
      handleClose();
    },
    [onSelect, handleClose],
  );

  return (
    <Dialog
      title="Consultar CEP"
      classType="primary"
      open={isOpen}
      maxWidth="md"
      close={handleClose}
      dividers
    >
      <Div
        sx={{
          px: theme.spacing(2),
          pb: theme.spacing(1),
          bgcolor:
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        }}
      >
        <Div display="flex" alignItems="start" width="100%">
          <Div flex={1}>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={dataSearchPostalCode}
              validationSchema={schemaPayloadSearchPostalCode}
              enableReinitialize
              onSubmit={(values) => {
                handleSearchStreetName(values);
              }}
            >
              {({ handleSubmit, handleReset }) => (
                <Form id="modal-postalCode-search" onSubmit={handleSubmit} onReset={handleReset}>
                  <Input
                    autoFocus
                    label="Logradouro"
                    placeholder="Informe parte do logradouro"
                    name="logradouro"
                    InputProps={{
                      endAdornment: (
                        <IconButton type="submit" icon="search" tooltip="Pesquisar logradouro" />
                      ),
                    }}
                  />

                  <Collapse in={isExpandedFilter} timeout="auto">
                    <Div display="flex" alignItems="center" width="100%">
                      <Div flex={1} sx={{ mr: 2 }}>
                        <Input label="Cidade" name="localidade" />
                      </Div>
                      <Field name="uf" component={SelectAddressState} short />
                    </Div>
                  </Collapse>
                </Form>
              )}
            </Formik>
          </Div>

          <IconButton
            sx={{ mt: theme.spacing(3) }}
            icon={isExpandedFilter ? 'filter_list_off' : 'filter_list'}
            tooltip={isExpandedFilter ? 'Ocultar filtros' : 'Mais filtros'}
            onClick={handleToggleIsExpandedFilter}
          />
        </Div>
      </Div>

      <Divider />

      {data.length === 0 ? (
        <EmptyData
          title="Sem resultado"
          subtitle="Utilize o campo logradouro para realizar uma consulta"
          message=" "
        />
      ) : (
        <DialogContent sx={{ m: 0, p: 0 }}>
          <List disablePadding>
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <ListItemButton onClick={() => handleSelectAddress(item)}>
                  <ListItemText
                    primary={item.logradouro + (item.complemento && ` - ${item.complemento}`)}
                    secondary={`${item.bairro} - ${item.localidade}/${item.uf} - ${item.cep}`}
                  />
                </ListItemButton>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          <Div sx={{ p: theme.spacing(2) }}>
            <Alert severity="info">
              <AlertTitle>{`Encontramos ${data.length} endereço(s)`}</AlertTitle>
              {`Se o endereço desejado não for em ${dataSearchPostalCode.localidade}/${dataSearchPostalCode.uf}, ajuste os campos cidade e uf em filtros avançados e realize uma nova consulta.`}
            </Alert>
          </Div>
        </DialogContent>
      )}
    </Dialog>
  );
};
