import { FilterAlt, FilterAltOff } from '@mui/icons-material';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Formik } from 'formik';
import { useCallback } from 'react';
import { toObjectWithAllProps } from '../../utils';
import { Dialog } from '../feedback';
import { Form, DialogActionButton } from '../inputs';

// Define interface
interface IFilterDialogProps {
  title?: string;
  description?: string;
  isOpen: boolean;
  onClose: () => void;
  setFilter: (value: object) => void;
  filter: object;
  initialValues: object;
}

// Create component for dialog filters
export const FilterDialog: React.FC<IFilterDialogProps> = ({
  title,
  description,
  isOpen,
  onClose,
  setFilter,
  filter,
  initialValues,
  children,
}) => {
  // Apply filter
  const handleApplyFilter = useCallback(
    (filters: object) => {
      setFilter(filters);
      onClose();
    },
    [setFilter],
  );

  // Reset filters
  const handleResetFilter = useCallback(() => {
    setFilter(initialValues);
    onClose();
  }, [onClose]);

  return (
    <Dialog title={title || 'Filtros'} open={isOpen} close={onClose}>
      <DialogContent dividers>
        <DialogContentText>
          {description || 'Utilize os campos a seguir para filtrar os registros.'}
        </DialogContentText>

        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={toObjectWithAllProps(filter, initialValues)}
          enableReinitialize
          onSubmit={(values) => {
            handleApplyFilter(values);
          }}
        >
          {({ handleSubmit, handleReset }) => (
            <Form id="modal-form-filter" onSubmit={handleSubmit} onReset={handleReset}>
              {children}
            </Form>
          )}
        </Formik>
      </DialogContent>

      <DialogActions>
        <DialogActionButton
          label="Limpar filtros"
          tooltip="Limpar todos os filtros"
          type="reset"
          form="modal-form-filter"
          startIcon={<FilterAltOff />}
          onClick={handleResetFilter}
        />
        <DialogActionButton
          label="Filtrar"
          tooltip="Aplicar filtros"
          type="submit"
          primaryAction
          form="modal-form-filter"
          startIcon={<FilterAlt />}
        />
      </DialogActions>
    </Dialog>
  );
};
