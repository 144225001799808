import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, EmployeeService } from '../services';
import {
  IEmployeeData,
  IEmployeeFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  IEmployeeDTO,
  IDataGridSelection,
  IEmployeeList,
} from '../types';
import { InfoStatusRecord } from '../components/feedback/InfoStatusRecord';
import { getFilterParams, toObjectWithoutNull } from '../utils';
import { getSortParams } from '../utils/getSortParams';

// Define interface for context
interface IEmployeeContext {
  // Data
  columnsEmployee: IDataGridColumns;
  rowsEmployee: IEmployeeList[];
  defaultDataEmployee: IEmployeeData;
  dataEmployee: IEmployeeData | undefined;
  handleDataEmployee: (data?: IEmployeeData) => void;
  // Pagination
  rowCountEmployee: number;
  pageSizeEmployee: number;
  pageEmployee: number;
  handlePageSizeEmployee: (size: number) => void;
  handlePageEmployee: (page: number) => void;
  // Sort
  sortEmployee: IDataGridSortOptions;
  handleSortEmployee: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionEmployee: IDataGridSelection;
  handleSelectionEmployee: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterEmployee: IEmployeeFilter;
  filterEmployee: IEmployeeFilter;
  isOpenFilterModalEmployee: boolean;
  toggleIsOpenFilterModalEmployee: () => void;
  handleFilterEmployee: (filter: IEmployeeFilter) => void;
  // Api
  getAllEmployee: () => void;
  getByIdEmployee: (id: number) => void;
  createEmployee: (payload: IEmployeeDTO) => void;
  updateByIdEmployee: (id: number, payload: IEmployeeDTO) => void;
  deleteByIdEmployee: (id: number) => void;
  deleteIdsEmployee: (ids: number[]) => void;
  approveRegistrationById: (id: number) => void;
}

// Define columns schema
const columnsSchemaEmployee: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código do colaborador',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
  {
    field: 'name',
    headerName: 'Nome',
    description: 'Razão social / Nome',
    hideable: false,
    flex: 2,
  },
  {
    field: 'nickname',
    headerName: 'Apelido',
    description: 'Nome fantasia / Apelido',
  },
  {
    field: 'category',
    headerName: 'Categoria',
    description: 'Categoria do colaborador',
  },
  {
    field: 'type',
    headerName: 'Tipo',
    description: 'Bairro do endereço padrão do colaborador',
  },
  {
    field: 'payment_type',
    headerName: 'Pagamento',
    description: 'Bairro do endereço padrão do colaborador',
  },
  {
    field: 'phone',
    headerName: 'Telefone',
    description: 'Telefone padrão do colaborador',
    flex: 1,
  },
  {
    field: 'document',
    headerName: 'Documento',
    description: 'CNPJ / CPF do colaborador',
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    description: 'Indica se o registro esta ativo ou não',
    renderCell: (params) => <InfoStatusRecord status={params.row.status} />,
    headerAlign: 'center',
    align: 'center',
  },
];

// Initial values for filter
const defaultFilterEmployee: IEmployeeFilter = {
  id: 0,
  name: '',
  cnpj: '',
  cpf: '',
};

// initial values for data
const defaultDataEmployee: IEmployeeData = {
  id: 0,
  person_type: 'FISICA',
  name: '',
  nickname: '',
  cnpj: '',
  cpf: '',
  rg: '',
  ie: '',
  cnh: '',
  category: 'GERAL',
  type: 'FIXO',
  payment_type: 'DIARIO',
  payment_value: 0,
  payment_day: 0,
  food_voucher: 0,
  basic_basket: false,
  tags: '',
  status: 'ACTIVE',
  contacts: [],
  addresses: [],
  payment_accounts: [],
};

// Create context
export const EmployeeContext = createContext<IEmployeeContext>({} as IEmployeeContext);

// Create context provider
export const EmployeeContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsEmployee] = useState<IDataGridColumns>(columnsSchemaEmployee);

  // Rows state
  const [rowsEmployee, setRowsEmployee] = useState<IEmployeeList[]>([]);

  // Data state
  const [dataEmployee, setDataEmployee] = useState<IEmployeeData | undefined>();

  // Sort state
  const [sortEmployee, setSortEmployee] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterEmployee, setFilterEmployee] = useState<IEmployeeFilter>(defaultFilterEmployee);

  // Modal filters
  const [isOpenFilterModalEmployee, setIsOpenEmployeeFilterModal] = useState(false);

  // Page state
  const [pageEmployee, setPageEmployee] = useState(0);

  // Page size state
  const [pageSizeEmployee, setPageSizeEmployee] = useState(10);

  // Record count state
  const [rowCountEmployee, setRowCountEmployee] = useState(0);

  // Selection state
  const [selectionEmployee, setSelectionEmployee] = useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataEmployee = useCallback(
    (data?: IEmployeeData) => {
      if (!data) return setDataEmployee(data);
      const dataWithoutNull = toObjectWithoutNull<IEmployeeData>(data, defaultDataEmployee);
      dataWithoutNull.person_type = data.cnpj ? 'JURIDICA' : 'FISICA';
      return setDataEmployee(dataWithoutNull);
    },
    [dataEmployee],
  );

  // Change page status
  const handlePageEmployee = useCallback((page: number) => setPageEmployee(page), [pageEmployee]);

  // Change page size status
  const handlePageSizeEmployee = useCallback(
    (size: number) => {
      setPageSizeEmployee(size);
      setPageEmployee(0);
    },
    [pageSizeEmployee],
  );

  // Change sort status
  const handleSortEmployee = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortEmployee(sort);
      setPageEmployee(0);
    },
    [sortEmployee],
  );

  // Change filters status
  const handleFilterEmployee = useCallback(
    (filter: IEmployeeFilter) => {
      setFilterEmployee(filter);
      setPageEmployee(0);
    },
    [filterEmployee],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalEmployee = useCallback(
    () => setIsOpenEmployeeFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionEmployee = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionEmployee(selection);
    },
    [selectionEmployee],
  );

  // Clear data rows
  const clearStorageEmployee = useCallback(() => {
    setRowsEmployee([]);
    setRowCountEmployee(0);
  }, [setRowsEmployee]);

  // Load data from request
  const loadStorageEmployee = useCallback(
    (response: IApiResponseWithPagination<IEmployeeList>) => {
      setRowsEmployee(response.data);
      setRowCountEmployee(response.records);
    },
    [setRowsEmployee],
  );

  // API - Get all records
  const getAllEmployee = useCallback(() => {
    // Reset selection
    handleSelectionEmployee([]);
    // Add filters in params
    const params: any = getFilterParams(filterEmployee);
    // Add sort in params
    const sort = getSortParams(sortEmployee);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeEmployee;
    params.offset = pageEmployee * pageSizeEmployee;
    // Get data
    EmployeeService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        clearStorageEmployee();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageEmployee(0);
        return;
      }
      loadStorageEmployee(response);
    });
  }, [headers, filterEmployee, sortEmployee, pageEmployee, pageSizeEmployee, dataEmployee]);

  // API - Get record by id
  const getByIdEmployee = useCallback(
    (id: number) => {
      // Get details
      EmployeeService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataEmployee(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createEmployee = useCallback(
    (payload: IEmployeeDTO) => {
      // Get details
      EmployeeService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        // Update data
        const updatedData: IEmployeeData = { ...payload, id: response };
        handleDataEmployee(updatedData);
        //
        snackbarSuccess('Registro adicionado!');
      });
    },
    [getAllEmployee],
  );

  // API - Update record by id
  const updateByIdEmployee = useCallback(
    (id: number, payload: IEmployeeDTO) => {
      // Get details
      EmployeeService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        // Update data
        const updatedData: IEmployeeData = { ...payload, id };
        handleDataEmployee(updatedData);
        //
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getByIdEmployee],
  );

  // API - Delete record by id
  const deleteByIdEmployee = useCallback(
    (id: number) => {
      EmployeeService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataEmployee();
        getAllEmployee();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllEmployee],
  );

  // API - Delete record by ids
  const deleteIdsEmployee = useCallback(
    (ids: number[]) => {
      EmployeeService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllEmployee();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllEmployee],
  );

  // API - Set has approved
  const approveRegistrationById = useCallback(
    (id: number) => {
      // Get details
      EmployeeService.approveRegistrationById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getByIdEmployee(id);
        snackbarSuccess('Cadastro aprovado!');
      });
    },
    [getAllEmployee],
  );

  return (
    <EmployeeContext.Provider
      value={{
        // Data
        columnsEmployee,
        rowsEmployee,
        dataEmployee,
        defaultDataEmployee,
        handleDataEmployee,
        // Pagination
        rowCountEmployee,
        pageSizeEmployee,
        pageEmployee,
        handlePageSizeEmployee,
        handlePageEmployee,
        // Sort
        sortEmployee,
        handleSortEmployee,
        // Selection
        selectionEmployee,
        handleSelectionEmployee,
        // Filter
        defaultFilterEmployee,
        filterEmployee,
        isOpenFilterModalEmployee,
        toggleIsOpenFilterModalEmployee,
        handleFilterEmployee,
        // Api
        getAllEmployee,
        getByIdEmployee,
        createEmployee,
        updateByIdEmployee,
        deleteByIdEmployee,
        deleteIdsEmployee,
        approveRegistrationById,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};
