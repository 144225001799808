import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, BusinessTypeService } from '../services';
import {
  IBusinessTypeData,
  IBusinessTypeFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  IBusinessTypeDTO,
  IDataGridSelection,
  IBusinessTypeList,
} from '../types';
import { InfoStatusRecord } from '../components/feedback/InfoStatusRecord';
import { getFilterParams } from '../utils';
import { getSortParams } from '../utils/getSortParams';

// Define interface for context
interface IBusinessTypeContext {
  // Data
  columnsBusinessType: IDataGridColumns;
  rowsBusinessType: IBusinessTypeList[];
  defaultDataBusinessType: IBusinessTypeData;
  dataBusinessType: IBusinessTypeData | undefined;
  handleDataBusinessType: (data?: IBusinessTypeData) => void;
  // Pagination
  rowCountBusinessType: number;
  pageSizeBusinessType: number;
  pageBusinessType: number;
  handlePageSizeBusinessType: (size: number) => void;
  handlePageBusinessType: (page: number) => void;
  // Sort
  sortBusinessType: IDataGridSortOptions;
  handleSortBusinessType: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionBusinessType: IDataGridSelection;
  handleSelectionBusinessType: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterBusinessType: IBusinessTypeFilter;
  filterBusinessType: IBusinessTypeFilter;
  isOpenFilterModalBusinessType: boolean;
  toggleIsOpenFilterModalBusinessType: () => void;
  handleFilterBusinessType: (filter: IBusinessTypeFilter) => void;
  // Api
  getAllBusinessType: () => void;
  getByIdBusinessType: (id: number) => void;
  createBusinessType: (payload: IBusinessTypeDTO) => void;
  updateByIdBusinessType: (id: number, payload: IBusinessTypeDTO) => void;
  deleteByIdBusinessType: (id: number) => void;
  deleteIdsBusinessType: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaBusinessType: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código do ramo de atividade',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'name',
    headerName: 'Nome',
    description: 'Nome do ramo de atividade',
    flex: 1,
  },
  {
    field: 'is_active',
    headerName: 'Status',
    description: 'Indica se o registro esta ativo ou não',
    renderCell: (params) => <InfoStatusRecord status={params.row.is_active} />,
    headerAlign: 'center',
    align: 'center',
  },
];

// Initial values for filter
const defaultFilterBusinessType: IBusinessTypeFilter = {
  id: 0,
  name: '',
};

// initial values for data
const defaultDataBusinessType: IBusinessTypeData = {
  id: 0,
  name: '',
  is_active: true,
};

// Create context
export const BusinessTypeContext = createContext<IBusinessTypeContext>({} as IBusinessTypeContext);

// Create context provider
export const BusinessTypeContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsBusinessType] = useState<IDataGridColumns>(columnsSchemaBusinessType);

  // Rows state
  const [rowsBusinessType, setRowsBusinessType] = useState<IBusinessTypeList[]>([]);

  // Data state
  const [dataBusinessType, setDataBusinessType] = useState<IBusinessTypeData | undefined>();

  // Sort state
  const [sortBusinessType, setSortBusinessType] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterBusinessType, setFilterBusinessType] =
    useState<IBusinessTypeFilter>(defaultFilterBusinessType);

  // Modal filters
  const [isOpenFilterModalBusinessType, setIsOpenBusinessTypeFilterModal] = useState(false);

  // Page state
  const [pageBusinessType, setPageBusinessType] = useState(0);

  // Page size state
  const [pageSizeBusinessType, setPageSizeBusinessType] = useState(10);

  // Record count state
  const [rowCountBusinessType, setRowCountBusinessType] = useState(0);

  // Selection state
  const [selectionBusinessType, setSelectionBusinessType] = useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataBusinessType = useCallback(
    (data?: IBusinessTypeData) => setDataBusinessType(data),
    [dataBusinessType],
  );

  // Change page status
  const handlePageBusinessType = useCallback(
    (page: number) => setPageBusinessType(page),
    [pageBusinessType],
  );

  // Change page size status
  const handlePageSizeBusinessType = useCallback(
    (size: number) => {
      setPageSizeBusinessType(size);
      setPageBusinessType(0);
    },
    [pageSizeBusinessType],
  );

  // Change sort status
  const handleSortBusinessType = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortBusinessType(sort);
      setPageBusinessType(0);
    },
    [sortBusinessType],
  );

  // Change filters status
  const handleFilterBusinessType = useCallback(
    (filter: IBusinessTypeFilter) => {
      setFilterBusinessType(filter);
      setPageBusinessType(0);
    },
    [filterBusinessType],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalBusinessType = useCallback(
    () => setIsOpenBusinessTypeFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionBusinessType = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionBusinessType(selection);
    },
    [selectionBusinessType],
  );

  // Clear data rows
  const clearStorageBusinessType = useCallback(() => {
    setRowsBusinessType([]);
    setRowCountBusinessType(0);
  }, [setRowsBusinessType]);

  // Load data from request
  const loadStorageBusinessType = useCallback(
    (response: IApiResponseWithPagination<IBusinessTypeList>) => {
      setRowsBusinessType(response.data);
      setRowCountBusinessType(response.records);
    },
    [setRowsBusinessType],
  );

  // API - Get all records
  const getAllBusinessType = useCallback(() => {
    // Reset selection
    handleSelectionBusinessType([]);
    // Add filters in params
    const params: any = getFilterParams(filterBusinessType);
    // Add sort in params
    const sort = getSortParams(sortBusinessType);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeBusinessType;
    params.offset = pageBusinessType * pageSizeBusinessType;
    // Get data
    BusinessTypeService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        clearStorageBusinessType();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageBusinessType(0);
        return;
      }
      loadStorageBusinessType(response);
    });
  }, [
    headers,
    filterBusinessType,
    sortBusinessType,
    pageBusinessType,
    pageSizeBusinessType,
    dataBusinessType,
  ]);

  // API - Get record by id
  const getByIdBusinessType = useCallback(
    (id: number) => {
      // Get details
      BusinessTypeService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        setDataBusinessType(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createBusinessType = useCallback(
    (payload: IBusinessTypeDTO) => {
      // Get details
      BusinessTypeService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataBusinessType();
        snackbarSuccess('Registro adicionado!');
        if (pageBusinessType > 0) {
          handlePageBusinessType(0);
          return;
        }
        getAllBusinessType();
      });
    },
    [getAllBusinessType],
  );

  // API - Update record by id
  const updateByIdBusinessType = useCallback(
    (id: number, payload: IBusinessTypeDTO) => {
      // Get details
      BusinessTypeService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataBusinessType();
        getAllBusinessType();
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllBusinessType],
  );

  // API - Delete record by id
  const deleteByIdBusinessType = useCallback(
    (id: number) => {
      BusinessTypeService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataBusinessType();
        getAllBusinessType();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllBusinessType],
  );

  // API - Delete record by ids
  const deleteIdsBusinessType = useCallback(
    (ids: number[]) => {
      BusinessTypeService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllBusinessType();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllBusinessType],
  );

  return (
    <BusinessTypeContext.Provider
      value={{
        // Data
        columnsBusinessType,
        rowsBusinessType,
        dataBusinessType,
        defaultDataBusinessType,
        handleDataBusinessType,
        // Pagination
        rowCountBusinessType,
        pageSizeBusinessType,
        pageBusinessType,
        handlePageSizeBusinessType,
        handlePageBusinessType,
        // Sort
        sortBusinessType,
        handleSortBusinessType,
        // Selection
        selectionBusinessType,
        handleSelectionBusinessType,
        // Filter
        defaultFilterBusinessType,
        filterBusinessType,
        isOpenFilterModalBusinessType,
        toggleIsOpenFilterModalBusinessType,
        handleFilterBusinessType,
        // Api
        getAllBusinessType,
        getByIdBusinessType,
        createBusinessType,
        updateByIdBusinessType,
        deleteByIdBusinessType,
        deleteIdsBusinessType,
      }}
    >
      {children}
    </BusinessTypeContext.Provider>
  );
};
