/* eslint-disable react/jsx-props-no-spreading */
import { Button as MuiButton, ButtonProps } from '@mui/material';
import { useLoader } from '../../hooks/useLoader';
import { TooltipWrapper } from '../feedback';

// Extend props MUI Button
export interface IButtonProps extends ButtonProps {
  label?: string;
  tooltip?: string;
}
// Create component Button Custom
export const Button: React.FC<IButtonProps> = ({ label, tooltip, children, ...rest }) => {
  // Use "hook" loader
  const isRunning = useLoader();

  // Set default props
  const props: Partial<IButtonProps> = {
    type: 'button',
    variant: 'contained',
    disableElevation: true,
    ...rest,
    disabled: isRunning ? true : rest.disabled,
  };

  return (
    <TooltipWrapper title={tooltip}>
      <MuiButton {...props}>{label || children}</MuiButton>
    </TooltipWrapper>
  );
};
