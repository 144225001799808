import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { disableLoader, enableLoader } from '../../contexts/LoaderContext';

export interface IViaCepResponse {
  cep: string;
  logradouro: string;
  bairro: string;
  uf: string;
  localidade: string;
  complemento: string;
}

export type IViaCepSearchByStreetNameDTO = Omit<IViaCepResponse, 'cep' | 'bairro' | 'complemento'>;

const errorInterceptor = (error: AxiosError) => {
  disableLoader();
  console.log(error);
  return Promise.reject(new Error('Não foi possível consultar o CEP'));
};

const requestInterceptor = (request: AxiosRequestConfig) => {
  enableLoader();
  return request;
};

const responseInterceptor = (response: AxiosResponse) => {
  disableLoader();
  return response;
};

const ViaCep = () => {
  const params = axios.create({
    baseURL: 'https://viacep.com.br/ws',
    headers: {
      'Content-type': 'application/json',
    },
    timeout: 5000,
  });

  params.interceptors.request.use(
    (request) => requestInterceptor(request),
    (error) => errorInterceptor(error),
  );

  params.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error),
  );

  return params;
};

const getByCep = async (cep: string | number): Promise<IViaCepResponse | Error> => {
  try {
    const response = await ViaCep().get(`/${cep}/json/`);
    if (response.data.erro) return new Error('CEP não localizado');
    return response.data;
  } catch (error: any) {
    return error;
  }
};

const getByLogradouro = async (
  uf: string,
  cidade: string,
  logradouro: string,
): Promise<IViaCepResponse[] | Error> => {
  try {
    const query = `${uf}/${cidade}/${logradouro}`;
    const response = await ViaCep().get(`/${query}/json/`);
    return response.data;
  } catch (error: any) {
    return error;
  }
};

export const ViaCepService = { getByCep, getByLogradouro };
