import { Field } from 'formik';
import { Input, FilterDialog, DataPicker, SelectTravelType } from '../../../shared/components';
import { useTravel } from '../../../shared/hooks';
import { CustomerInputSelectBase } from '../../customer/components';
import { EmployeeInputSelectBase } from '../../employee/components';
import { VehicleInputSelectBase } from '../../vehicle/components/VehicleInputSelectBase';

// Create modal filter for Travel
export const TravelFilterModal: React.FC = () => {
  // Use context Travel
  const {
    isOpenFilterModalTravel,
    toggleIsOpenFilterModalTravel,
    handleFilterTravel,
    filterTravel,
    defaultFilterTravel,
  } = useTravel();

  return (
    <FilterDialog
      isOpen={isOpenFilterModalTravel}
      onClose={toggleIsOpenFilterModalTravel}
      setFilter={handleFilterTravel}
      filter={filterTravel}
      initialValues={defaultFilterTravel}
    >
      <Field name="type" component={SelectTravelType} label="Tipo" enableAll defaultOption="" />

      <Field name="started_at" component={DataPicker} label="Saída" />

      <Field name="finished_at" component={DataPicker} label="Retorno" />

      <Input label="Origem" name="origin" />

      <Input label="Destino" name="destination" />

      <CustomerInputSelectBase />

      <EmployeeInputSelectBase baseName="driver" label="Motorista" />

      <VehicleInputSelectBase refId="vehicle_id" refName="vehicle_detail" />
    </FilterDialog>
  );
};
