/* eslint-disable react/jsx-props-no-spreading */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTheme } from '@mui/material';
import { StyleClassType } from '../../types';
import { Button, IButtonProps } from './Button';

// Extend props Button
interface IDialogActionButtonProps extends IButtonProps {
  classType?: StyleClassType;
  primaryAction?: boolean;
}

// Create component Button Actions for Dialog modal
export const DialogActionButton: React.FC<IDialogActionButtonProps> = ({
  classType = 'default',
  primaryAction = false,
  label,
  children,
  ...rest
}) => {
  const theme = useTheme();
  // Set default props
  const props: Partial<IDialogActionButtonProps> = {
    startIcon: primaryAction ? <CheckCircleIcon /> : <HighlightOffIcon />,
    size: 'large',
    variant: primaryAction ? 'contained' : 'text',
    sx: {
      color: !primaryAction ? theme.palette.text.secondary : null,
    },
    color: classType === 'default' ? 'primary' : classType,
    disableRipple: !primaryAction,
    tabIndex: !primaryAction ? -1 : undefined,
    ...rest,
  };

  return <Button {...props}>{label || children}</Button>;
};
