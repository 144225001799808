import { Routes } from './routes/Routes';
import {
  ThemeContextProvider,
  DialogContextProvider,
  LoaderContext,
  SnackbarContext,
  MenuContextProvider,
} from './shared/contexts';

// Main
export const App: React.FC = () => {
  return (
    <ThemeContextProvider>
      <LoaderContext>
        <SnackbarContext>
          <DialogContextProvider>
            <MenuContextProvider>
              <Routes />
            </MenuContextProvider>
          </DialogContextProvider>
        </SnackbarContext>
      </LoaderContext>
    </ThemeContextProvider>
  );
};
