import { Toolbar, useTheme } from '@mui/material';
import { DirectionsCar } from '@mui/icons-material';
import { useEffect } from 'react';
import { IPeopleType } from '../../types/PeopleTypes';
import { Div } from '../display-data/Div';
import { Button } from '../inputs/Button';
import { VehicleModal, VehicleMain } from '../../../pages/vehicle/components';
import { useVehicle } from '../../hooks';

interface IPeopleVehicleProps {
  peopleType: IPeopleType;
  peopleId: number;
}

export const PeopleVehicle: React.FC<IPeopleVehicleProps> = ({ peopleId, peopleType }) => {
  const theme = useTheme();

  const { defaultDataVehicle, handleDataVehicle, handleClearStorageVehicle } = useVehicle();

  const handleAddData = () => {
    const data = defaultDataVehicle;
    data.type = 'TERCEIRO';
    data.owner_type = peopleType;
    data.owner_id = peopleId;
    data.owner_name = 'NOVO VEÍCULO';
    handleDataVehicle(data);
  };

  useEffect(() => handleClearStorageVehicle(), []);

  return (
    <Div>
      <VehicleModal fixedPeopleType={peopleType} fixedPeopleId={peopleId} />

      <Toolbar style={{ paddingRight: 0, paddingLeft: 0, marginBottom: theme.spacing(1) }}>
        <Div flex={1} />
        <Button
          label="Adicionar veículo"
          tooltip={`Adicionar veículo para o ${peopleType.toLowerCase()}`}
          startIcon={<DirectionsCar />}
          onClick={handleAddData}
          disableFocusRipple
        />
      </Toolbar>

      <VehicleMain embedded initialFilter={{ type: 'TERCEIRO', owner_id: peopleId }} />
    </Div>
  );
};
