/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

type ISelectEmployeePaymentTypeProps = FieldProps;

const options: ISelectOptions[] = [
  { label: 'Diário', value: 'DIARIO' },
  { label: 'Semanal', value: 'SEMANAL' },
  { label: 'Quinzenal', value: 'QUINZENAL' },
  { label: 'Mensal', value: 'MENSAL' },
];

export const SelectEmployeePaymentType: React.FC<ISelectEmployeePaymentTypeProps> = ({
  ...rest
}) => {
  return <Select label="Pagamento" defaultOption="DIARIO" dataOptions={options} {...rest} />;
};
