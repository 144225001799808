import { useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useContract, useDialog } from '../../../shared/hooks';
import { IContractFilter, IDataGridRowParams } from '../../../shared/types';
import { ContractDataGrid } from './ContractDataGrid';
import { ContractFilterModal } from './ContractFilterModal';
import { ContractModal } from './ContractModal';

interface IContractMainProps {
  embedded?: boolean;
  initialFilter?: Partial<IContractFilter>;
}

export const ContractMain: React.FC<IContractMainProps> = ({ embedded, initialFilter }) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState<boolean>(false);

  const {
    columnsContract,
    rowsContract,
    defaultDataContract,
    handleDataContract,
    selectionContract,
    handleSelectionContract,
    filterContract,
    handleFilterContract,
    getByIdContract,
    deleteIdsContract,
  } = useContract();

  const { dialogConfirmDanger } = useDialog();

  useEffect(() => {
    if (initialFilter) handleFilterContract(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsContract]);

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterContract({});
      return;
    }
    handleFilterContract({ ...filterContract, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataContract(defaultDataContract);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdContract(+id);
  };

  const handleDeleteData = () => {
    if (!selectionContract || selectionContract.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsContract(selectionContract as number[]);
      },
    );
  };

  return (
    <>
      <ContractFilterModal />
      <ContractModal />

      {!embedded && (
        <DataGridFilters
          columns={columnsContract}
          filters={filterContract}
          onRemoveFilter={handleRemoveFilter}
        />
      )}

      <ContractDataGrid
        selectionModel={selectionContract}
        onSelectionModelChange={handleSelectionContract}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionContract}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionContract?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
