/* eslint-disable @typescript-eslint/naming-convention */
import { Grid } from '@mui/material';
import { Field } from 'formik';
import * as Yup from 'yup';
import { DataDialog, DataPicker, InputNumber } from '../../../shared/components';
import { useDelivery, useDialog } from '../../../shared/hooks';
import { IDeliveryDTO } from '../../../shared/types';
import { schemaValidation, toNumber } from '../../../shared/utils';
import { CustomerInputSelectBase } from '../../customer/components';
import { EmployeeInputSelectBase } from '../../employee/components';

// Define validation fields form
const schemaPayloadDelivery = Yup.object({
  date: schemaValidation.dateTime.date(true),
  customer_name: schemaValidation.people.name,
  driver_name: schemaValidation.people.name,
  quantity_deliveries: schemaValidation.number.integer.min(1, 'Informe uma quantidade'),
});

// Create modal data for Delivery
export const DeliveryModal: React.FC = () => {
  // Use context Delivery
  const {
    dataDelivery,
    defaultDataDelivery,
    handleDataDelivery,
    createDelivery,
    updateByIdDelivery,
    deleteByIdDelivery,
  } = useDelivery();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Reset data and close modal
  const handleClose = () => handleDataDelivery();

  // Save actions
  const handleSave = (payload: any) => {
    const normalize = { ...payload };
    normalize.quantity_deliveries = toNumber(payload.quantity_deliveries);

    const id = dataDelivery?.id || 0;
    const data = normalize as IDeliveryDTO;
    if (id > 0) {
      updateByIdDelivery(id, data);
      return;
    }
    createDelivery(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, customer_name } = payload;
    dialogConfirmDanger(
      'Excluir entrega?',
      `Deseja realmente excluir o serviço de entrega: ${id} - ${customer_name} `,
      () => deleteByIdDelivery(id),
    );
  };

  return (
    <DataDialog
      referenceId={dataDelivery?.id}
      referenceDisplayName="entrega"
      initialValues={dataDelivery || defaultDataDelivery}
      validationSchema={schemaPayloadDelivery}
      open={dataDelivery !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      <Grid container columnSpacing={2}>
        <Grid item xs={6} md={6}>
          <Field name="date" component={DataPicker} label="Data *" />
        </Grid>

        <Grid item xs={6} md={6}>
          <InputNumber label="Entregas realizadas" name="quantity_deliveries" />
        </Grid>

        <Grid item xs={12} md={12}>
          <CustomerInputSelectBase label="Cliente *" />
        </Grid>

        <Grid item xs={12} md={12}>
          <EmployeeInputSelectBase baseName="driver" label="Motorista *" />
        </Grid>
      </Grid>
    </DataDialog>
  );
};
