import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useMedicalLeave, useDialog, useDeviceIsMobile } from '../../../shared/hooks';
import {
  IMedicalLeaveFilter,
  IDataGridRowParams,
  IDataGridColumnsHidden,
} from '../../../shared/types';
import { MedicalLeaveDataGrid } from './MedicalLeaveDataGrid';
import { MedicalLeaveFilterModal } from './MedicalLeaveFilterModal';
import { MedicalLeaveModal } from './MedicalLeaveModal';

interface IMedicalLeaveMainProps {
  embedded?: boolean;
  initialFilter?: Partial<IMedicalLeaveFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {
  employee_id: false,
};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {
  employee_id: false,
  reason: false,
  end_leave: false,
};

export const MedicalLeaveMain: React.FC<IMedicalLeaveMainProps> = ({ embedded, initialFilter }) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState<boolean>(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsMedicalLeave,
    rowsMedicalLeave,
    defaultDataMedicalLeave,
    handleDataMedicalLeave,
    selectionMedicalLeave,
    handleSelectionMedicalLeave,
    filterMedicalLeave,
    hideFilterMedicalLeave,
    handleFilterMedicalLeave,
    getByIdMedicalLeave,
    deleteIdsMedicalLeave,
  } = useMedicalLeave();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterMedicalLeave(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsMedicalLeave]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterMedicalLeave({});
      return;
    }
    handleFilterMedicalLeave({ ...filterMedicalLeave, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataMedicalLeave(defaultDataMedicalLeave);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdMedicalLeave(+id);
  };

  const handleDeleteData = () => {
    if (!selectionMedicalLeave || selectionMedicalLeave.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsMedicalLeave(selectionMedicalLeave as number[]);
      },
    );
  };

  return (
    <>
      <MedicalLeaveFilterModal />

      {!embedded && (
        <>
          <MedicalLeaveModal />
          <DataGridFilters
            columns={columnsMedicalLeave}
            filters={filterMedicalLeave}
            hideFilters={hideFilterMedicalLeave}
            onRemoveFilter={handleRemoveFilter}
          />
        </>
      )}

      <MedicalLeaveDataGrid
        selectionModel={selectionMedicalLeave}
        onSelectionModelChange={handleSelectionMedicalLeave}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionMedicalLeave}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionMedicalLeave?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
