/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useBusinessType } from '../../../shared/hooks';

type IBusinessTypeDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const BusinessTypeDataGrid: React.FC<IBusinessTypeDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsBusinessType,
    rowsBusinessType,
    rowCountBusinessType,
    pageBusinessType,
    handlePageBusinessType,
    pageSizeBusinessType,
    handlePageSizeBusinessType,
    sortBusinessType,
    filterBusinessType,
    handleFilterBusinessType,
    handleSortBusinessType,
    getAllBusinessType,
  } = useBusinessType();

  useEffect(() => handleFilterBusinessType({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllBusinessType();
  }, [canSearch, sortBusinessType, filterBusinessType, pageBusinessType, pageSizeBusinessType]);

  return (
    <DataGrid
      rows={rowsBusinessType}
      rowCount={rowCountBusinessType}
      columns={columnsBusinessType}
      onSortModelChange={handleSortBusinessType}
      sortModel={sortBusinessType}
      pageSize={pageSizeBusinessType}
      onPageSizeChange={handlePageSizeBusinessType}
      page={pageBusinessType}
      onPageChange={handlePageBusinessType}
      {...rest}
    />
  );
};
