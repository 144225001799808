import { ISchemaApiError } from '../../../types';

// Set schema for error request in recover password
export const ChangeLogSchemaApiError: ISchemaApiError[] = [
  {
    statusCode: 400,
    errors: [
      {
        error: 'Invalid period',
        message: 'Informe um período válido',
      },
    ],
  },
];
