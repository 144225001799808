import { useTheme } from '@mui/material';
import { ContentPasteSearch } from '@mui/icons-material';
import { P } from '../display-data/P';
import { Div } from '../display-data/Div';
import { H1 } from '../display-data/H1';
import { useDeviceIsMobile } from '../../hooks/useDeviceIsMobile';

interface IEmptyDataProps {
  title?: string;
  subtitle?: string;
  message?: string;
}

export const EmptyData: React.FC<IEmptyDataProps> = ({ title, subtitle, message }) => {
  const theme = useTheme();
  const deviceIsMobile = useDeviceIsMobile();
  return (
    <Div
      isFlexColumn
      sx={{ my: theme.spacing(6), px: theme.spacing(2), color: theme.palette.text.secondary }}
    >
      <ContentPasteSearch
        sx={{
          width: deviceIsMobile ? '130px' : '180px',
          height: deviceIsMobile ? '130px' : '180px',
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.contrastText,
          p: theme.spacing(4),
          borderRadius: theme.spacing(15),
        }}
      />

      <H1 variant="h4" sx={{ mt: theme.spacing(3) }}>
        {title || 'Ops!'}
      </H1>

      <P align="center">{subtitle || 'Não há dados para exibir...'}</P>

      <P align="center" sx={{ mt: theme.spacing(2), maxWidth: 'sm' }}>
        {message ||
          'Se estiver realizando uma consulta, experimente ajustar os filtros. Se for o caso, é possível incluir novos registros!'}
      </P>
    </Div>
  );
};
