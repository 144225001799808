import * as Yup from 'yup';
import { Field } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { useTheme } from '@mui/material';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import { Input, Button } from '../inputs';
import { DataDialog } from '../data-dialog/DataDialog';
import { useAttachment, useDialog, useSnackbarDispatch } from '../../hooks';
import { IAttachmentDTO, IAttachmentResource } from '../../types';
import { schemaValidation } from '../../utils';
import { UploadSingleFile } from '../upload-single-file';

interface IAttachmentModalProps {
  resource: IAttachmentResource;
  resourceId: number;
  disableType?: boolean;
}

// Create modal data for Attachment
export const AttachmentModal: React.FC<IAttachmentModalProps> = ({
  resource,
  resourceId,
  disableType,
}) => {
  // State for edit mode
  const [isEditMode, setIsEditMode] = useState(false);

  // Use context Attachment
  const {
    dataAttachment,
    defaultDataAttachment,
    handleDataAttachment,
    createAttachment,
    updateByIdAttachment,
    deleteByIdAttachment,
  } = useAttachment();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Use Theme
  const theme = useTheme();

  // Use snack dispatch
  const { snackbarError } = useSnackbarDispatch();

  // Define validation fields form
  const schemaPayloadAttachment = useMemo(
    () =>
      Yup.object({
        type: schemaValidation.commons.string(!disableType),
        description: schemaValidation.commons.string(disableType),
      }),
    [],
  );

  // Reset data and close modal
  const handleClose = () => handleDataAttachment();

  // Send request
  const handleSave = (payload: IAttachmentDTO) => {
    const id = dataAttachment?.id || 0;

    if (id > 0) {
      updateByIdAttachment(resource, resourceId, id, payload);
      return;
    }

    createAttachment(resource, resourceId, payload);
  };

  // Save actions
  const handleValidatePayload = (payload: any) => {
    const { type, description } = payload;
    const data = { type, description };

    if (isEditMode) {
      handleSave(data);
    } else {
      // Check file
      const { file } = payload;
      if (!file) {
        snackbarError('Selecione um arquivo');
        return;
      }

      // Convert file to base64
      const reader = new FileReader();
      reader.readAsDataURL(file);

      // Error base 64
      reader.onerror = (error) => {
        console.log(error);
        snackbarError(`Erro ao carregar o arquivo => ${error}`);
      };

      // On convert to base 64
      reader.onload = (loaded) => {
        // Process base64
        const base64: any = loaded.target?.result;

        if (!base64) {
          snackbarError('Erro ao carregar o arquivo');
          return;
        }

        // Append data with file info
        Object.assign(data, {
          file_base64: base64.split(';base64,')[1],
          file_name: payload.file.name,
        });

        handleSave(data);
      };
    }
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, type, description } = payload;

    const fileDescription = type && type !== '' ? type : description;

    dialogConfirmDanger(
      'Excluir anexo?',
      `Deseja realmente excluir o anexo: ${fileDescription}`,
      () => deleteByIdAttachment(resource, resourceId, id),
    );
  };

  // Download file
  const handleDownload = () => {
    if (!dataAttachment || !dataAttachment?.file) return;

    const { file, description, type } = dataAttachment;

    const fileExt = file.substring(file.lastIndexOf('/') + 1).split('.')[1];

    let fileName = '';

    if (description && description !== '') fileName = description;

    if (type && type !== '') {
      if (fileName !== '') fileName += ' - ';
      fileName += type;
    }

    fileName += `.${fileExt}`;

    Axios.get(dataAttachment?.file, {
      responseType: 'blob',
    }).then((res) => {
      fileDownload(res.data, fileName);
    });
  };

  // Check if is edit mode
  useEffect(() => {
    const hasAttachment = !!(dataAttachment && dataAttachment.id > 0);
    return setIsEditMode(hasAttachment);
  }, [dataAttachment]);

  return (
    <DataDialog
      referenceId={dataAttachment?.id}
      referenceDisplayName="anexo"
      initialValues={dataAttachment || defaultDataAttachment}
      validationSchema={schemaPayloadAttachment}
      open={dataAttachment !== undefined}
      close={handleClose}
      onSave={handleValidatePayload}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      {isEditMode ? (
        <input type="hidden" name="file" />
      ) : (
        <Field name="file" component={UploadSingleFile} />
      )}

      {!disableType && (
        <Input
          label="Tipo *"
          name="type"
          placeholder="Ex.: CNH, RG, Comprovante de residência..."
        />
      )}

      <Input label={`Descrição ${disableType ? '*' : ''}`} name="description" />

      {isEditMode && (
        <Button
          type="button"
          label="Download do anexo"
          startIcon={<DownloadIcon />}
          variant="text"
          sx={{ mt: theme.spacing(1) }}
          onClick={() => handleDownload()}
        />
      )}
    </DataDialog>
  );
};
