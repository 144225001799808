import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, PeopleAddressService } from '../services';
import { IPeopleAddressData, IPeopleAddressDTO, IPeopleAddressList, IPeopleType } from '../types';
import { getPeopleResource, toObjectWithoutNull } from '../utils';

// Define interface for context
interface IPeopleAddressContext {
  // Data
  rowsPeopleAddress: IPeopleAddressList[];
  defaultDataPeopleAddress: IPeopleAddressData;
  dataPeopleAddress: IPeopleAddressData | undefined;
  handleDataPeopleAddress: (data?: IPeopleAddressData) => void;
  HandleLoadStoragePeopleAddress: (data: IPeopleAddressList[]) => void;
  // Api
  getAllPeopleAddress: (peopleType: IPeopleType, peopleId: number) => void;
  createPeopleAddress: (
    peopleType: IPeopleType,
    peopleId: number,
    payload: IPeopleAddressDTO,
  ) => void;
  updateByIdPeopleAddress: (
    peopleType: IPeopleType,
    peopleId: number,
    id: number,
    payload: IPeopleAddressDTO,
  ) => void;
  deleteByIdPeopleAddress: (peopleType: IPeopleType, peopleId: number, id: number) => void;
}

// initial values for data
const defaultDataPeopleAddress: IPeopleAddressData = {
  id: 0,
  tag: 'COMERCIAL',
  postal_code: '',
  street_name: '',
  street_number: '',
  complement: '',
  neighborhood: '',
  city: '',
  state: '',
  is_default: true,
};

// Create context
export const PeopleAddressContext = createContext<IPeopleAddressContext>(
  {} as IPeopleAddressContext,
);

// Create context provider
export const PeopleAddressContextProvider: React.FC = ({ children }) => {
  // Rows state
  const [rowsPeopleAddress, setRowsPeopleAddress] = useState<IPeopleAddressList[]>([]);

  // Data state
  const [dataPeopleAddress, setDataPeopleAddress] = useState<IPeopleAddressData | undefined>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataPeopleAddress = useCallback(
    (data?: IPeopleAddressData) => {
      if (!data) return setDataPeopleAddress(data);
      const dataWithoutNull = toObjectWithoutNull<IPeopleAddressData>(
        data,
        defaultDataPeopleAddress,
      );
      return setDataPeopleAddress(dataWithoutNull);
    },
    [dataPeopleAddress],
  );

  // Clear data rows
  const HandleClearStoragePeopleAddress = useCallback(() => {
    setRowsPeopleAddress([]);
  }, [setRowsPeopleAddress]);

  // Load data from request
  const HandleLoadStoragePeopleAddress = useCallback(
    (response: IPeopleAddressList[]) => setRowsPeopleAddress(response),
    [setRowsPeopleAddress],
  );

  // API - Get all records
  const getAllPeopleAddress = useCallback(
    (peopleType: IPeopleType, peopleId: number) => {
      if (peopleId === 0) {
        HandleClearStoragePeopleAddress();
        return;
      }
      // Get people resource
      const peopleResource = getPeopleResource(peopleType);
      // Get data
      PeopleAddressService.getAll(headers, peopleResource, peopleId).then((response) => {
        if (response instanceof ApiError) {
          HandleClearStoragePeopleAddress();
          snackbarError(response.message);
          return;
        }
        HandleLoadStoragePeopleAddress(response);
      });
    },
    [headers, dataPeopleAddress],
  );

  // API - Create record
  const createPeopleAddress = useCallback(
    (peopleType: IPeopleType, peopleId: number, payload: IPeopleAddressDTO) => {
      // Get people resource
      const peopleResource = getPeopleResource(peopleType);
      // Get details
      PeopleAddressService.create(headers, peopleResource, peopleId, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataPeopleAddress();
        snackbarSuccess('Registro adicionado!');
        getAllPeopleAddress(peopleType, peopleId);
      });
    },
    [getAllPeopleAddress],
  );

  // API - Update record by id
  const updateByIdPeopleAddress = useCallback(
    (peopleType: IPeopleType, peopleId: number, id: number, payload: IPeopleAddressDTO) => {
      // Get people resource
      const peopleResource = getPeopleResource(peopleType);
      // Get details
      PeopleAddressService.updateById(headers, peopleResource, peopleId, id, payload).then(
        (response) => {
          if (response instanceof ApiError) {
            snackbarError(response.message);
            return;
          }
          handleDataPeopleAddress();
          getAllPeopleAddress(peopleType, peopleId);
          snackbarSuccess('Registro atualizado!');
        },
      );
    },
    [getAllPeopleAddress],
  );

  // API - Delete record by id
  const deleteByIdPeopleAddress = useCallback(
    (peopleType: IPeopleType, peopleId: number, id: number) => {
      // Get people resource
      const peopleResource = getPeopleResource(peopleType);
      // Delete record
      PeopleAddressService.deleteById(headers, peopleResource, peopleId, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataPeopleAddress();
        getAllPeopleAddress(peopleType, peopleId);
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllPeopleAddress],
  );

  return (
    <PeopleAddressContext.Provider
      value={{
        // Data
        rowsPeopleAddress,
        dataPeopleAddress,
        defaultDataPeopleAddress,
        handleDataPeopleAddress,
        HandleLoadStoragePeopleAddress,
        // Api
        getAllPeopleAddress,
        createPeopleAddress,
        updateByIdPeopleAddress,
        deleteByIdPeopleAddress,
      }}
    >
      {children}
    </PeopleAddressContext.Provider>
  );
};
