/* eslint-disable @typescript-eslint/naming-convention */
import { Today } from '@mui/icons-material';
import { Alert, DialogContent, Grid, useTheme } from '@mui/material';
import { Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Input,
  DataDialog,
  InputCurrency,
  DataPicker,
  SelectPeopleType,
  SelectFinancialType,
  Checkbox,
  Form,
} from '../../../shared/components';
import { useFinancial, useDialog, useDeviceIsMobile } from '../../../shared/hooks';
import { IFinancialDTO, IFinancialType, IPeopleType } from '../../../shared/types';
import { schemaValidation, toCapitalize, toDateApi, toNumberOrZero } from '../../../shared/utils';
import { CustomerInputSelectBase } from '../../customer/components';
import { EmployeeInputSelectBase } from '../../employee/components';
import { FinancialCategoryInputSelect } from '../../financial-category/components';
import { SupplierInputSelectBase } from '../../supplier/components';

// Get form id
const formId = 'data-dialog-form-financial';

// Define validation fields form
const schemaPayloadFinancial = Yup.object({
  issue_date: schemaValidation.dateTime.date(true),
  due_date: schemaValidation.dateTime
    .date(true)
    .min(Yup.ref('issue_date'), 'Data de vencimento não pode ser inferior a de emissão'),
  category_name: schemaValidation.people.name,
  people_name: schemaValidation.people.name,
  description: schemaValidation.commons.string(true),
  total: schemaValidation.number.float.test('is-empty', 'Informe um valor', (value) => {
    const number = Number(String(value).replace('.', '').replace(',', '.').trim());
    if (Number.isNaN(number)) return false;
    return number > 0;
  }),
  its_paid: Yup.boolean(),
  payment_date: schemaValidation.dateTime.date(false).when('its_paid', {
    is: true,
    then: schemaValidation.dateTime.date(true),
  }),
});

// Create modal data for Financial
export const FinancialModal: React.FC = () => {
  // State paid
  const [itsPaid, setItsPaid] = useState<boolean>(false);

  // state paid situation
  const [paidState, setPaidState] = useState<string | null>(null);

  // State people type
  const [peopleType, setPeopleType] = useState<IPeopleType>('CLIENTE');

  // State financial type
  const [financialType, setFinancialType] = useState<IFinancialType>('C');

  // Use context Financial
  const {
    dataFinancial,
    defaultDataFinancial,
    handleDataFinancial,
    createFinancial,
    updateByIdFinancial,
    deleteByIdFinancial,
  } = useFinancial();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Use theme
  const theme = useTheme();

  // Use is mobile
  const deviceIsMobile = useDeviceIsMobile();

  // On change people type
  const handleChangePeopleType = (type: IPeopleType) => setPeopleType(type);

  // On change financial type
  const handleChangeFinancialType = (type: IFinancialType) => setFinancialType(type);

  // On change financial type
  const handleChangeItsPaid = (paid: boolean) => setItsPaid(paid);

  // Load people type
  useEffect(() => {
    if (dataFinancial === undefined) return;
    const { people_type, type, its_paid, id, payment_date, due_date } = dataFinancial;

    handleChangePeopleType(people_type);
    handleChangeFinancialType(type);
    handleChangeItsPaid(its_paid);

    if (id > 0) {
      if (payment_date) {
        setPaidState(null);
        return;
      }

      const today = new Date().setHours(0, 0, 0, 0);
      const due = (due_date as Date).setHours(0, 0, 0, 0);

      if (due === today) {
        setPaidState('TODAY');
      } else if (due < today) {
        setPaidState('LATE');
      } else setPaidState(null);
    } else {
      setPaidState(null);
    }
  }, [dataFinancial]);

  // Reset data and close modal
  const handleClose = () => handleDataFinancial();

  // Save actions
  const handleSave = (payload: any) => {
    const normalize = { ...payload };
    normalize.issue_date = toDateApi(payload.issue_date);
    normalize.due_date = toDateApi(payload.due_date);
    if (normalize.its_paid) {
      normalize.payment_date = toDateApi(payload.payment_date);
      normalize.payment_value = toNumberOrZero(payload.payment_value);
    } else {
      normalize.payment_date = null;
      normalize.payment_value = null;
    }
    normalize.total = toNumberOrZero(payload.total);

    const id = dataFinancial?.id || 0;
    const data = normalize as IFinancialDTO;
    if (id > 0) {
      updateByIdFinancial(id, data);
      return;
    }
    createFinancial(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, description } = payload;
    dialogConfirmDanger(
      'Excluir título?',
      `Deseja realmente excluir o título financeiro: ${id} - ${description} `,
      () => deleteByIdFinancial(id),
    );
  };

  return (
    <DataDialog
      referenceId={dataFinancial?.id}
      referenceDisplayName="título financeiro"
      initialValues={dataFinancial || defaultDataFinancial}
      validationSchema={schemaPayloadFinancial}
      open={dataFinancial !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
      formId={formId}
      withTabs
    >
      <DialogContent dividers>
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={schemaPayloadFinancial}
          initialValues={dataFinancial || defaultDataFinancial}
          enableReinitialize
          onSubmit={(values) => {
            handleSave(values);
          }}
        >
          {({ handleSubmit, handleReset, setFieldValue, getFieldProps }) => (
            <Form
              id={formId}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
              onReset={handleReset}
            >
              <input type="hidden" name="id" />

              {paidState === 'TODAY' && (
                <Alert severity="warning" icon={<Today fontSize="inherit" />}>
                  Este título vence hoje
                </Alert>
              )}

              {paidState === 'LATE' && (
                <Alert severity="error" icon={<Today fontSize="inherit" />}>
                  Este título está atrasado
                </Alert>
              )}

              <Grid container columnSpacing={2}>
                <Grid item xs={12} md={8}>
                  <FinancialCategoryInputSelect label="Categoria *" />
                </Grid>

                <Grid item xs={4} md={4}>
                  <Field
                    name="type"
                    component={SelectFinancialType}
                    value={financialType}
                    onSelect={handleChangeFinancialType}
                  />
                </Grid>

                <Grid item xs={8} md={4}>
                  <Field
                    name="people_type"
                    component={SelectPeopleType}
                    label="Pessoa"
                    value={peopleType}
                    onSelect={handleChangePeopleType}
                  />
                </Grid>

                <Grid item xs={12} md={8}>
                  {peopleType === 'CLIENTE' && (
                    <CustomerInputSelectBase
                      baseName="people"
                      label={`${financialType === 'C' ? 'Pagador' : 'Beneficiário'} (${toCapitalize(
                        peopleType,
                      )}) *`}
                    />
                  )}
                  {peopleType === 'COLABORADOR' && (
                    <EmployeeInputSelectBase
                      baseName="people"
                      label={`${financialType === 'C' ? 'Pagador' : 'Beneficiário'} (${toCapitalize(
                        peopleType,
                      )}) *`}
                    />
                  )}
                  {peopleType === 'FORNECEDOR' && (
                    <SupplierInputSelectBase
                      baseName="people"
                      label={`${financialType === 'C' ? 'Pagador' : 'Beneficiário'} (${toCapitalize(
                        peopleType,
                      )}) *`}
                    />
                  )}
                </Grid>

                <Grid item xs={12} md={12}>
                  <Input
                    label="Descrição *"
                    name="description"
                    multiline
                    minRows={2}
                    placeholder="Descrição do título. Ex.: Pagamento do aluguel..."
                  />
                </Grid>

                <Grid item xs={6} md={4}>
                  <Field name="issue_date" component={DataPicker} label="Emissão *" />
                </Grid>

                <Grid item xs={6} md={4}>
                  <Field name="due_date" component={DataPicker} label="Vencimento *" />
                </Grid>

                <Grid item xs={12} md={4}>
                  <InputCurrency
                    label={`Total a ${financialType === 'C' ? 'receber *' : 'pagar *'}`}
                    name="total"
                    inputProps={{
                      style: {
                        fontWeight: 'bold',
                        textAlign: 'right',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={4} sx={{ pt: deviceIsMobile ? 0 : theme.spacing(3) }}>
                  <Checkbox
                    label="Compensado"
                    name="its_paid"
                    onChecked={(checked) => {
                      handleChangeItsPaid(checked);
                      if (checked) {
                        const paymentDate = getFieldProps('payment_date').value;
                        if (!paymentDate || paymentDate === '')
                          setFieldValue('payment_date', new Date());
                        const paymentValue = getFieldProps('payment_value').value;
                        if (!paymentValue || toNumberOrZero(paymentValue) === 0)
                          setFieldValue('payment_value', getFieldProps('total').value);
                      }
                    }}
                  />
                </Grid>

                {itsPaid && (
                  <>
                    <Grid item xs={6} md={4}>
                      <Field name="payment_date" component={DataPicker} label="Data do pgto *" />
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <InputCurrency
                        label={`Total ${financialType === 'C' ? 'recebido' : 'pago'}`}
                        name="payment_value"
                        inputProps={{
                          style: {
                            fontWeight: 'bold',
                            textAlign: 'right',
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </DataDialog>
  );
};
