/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, useCallback, useState } from 'react';
import { History } from '@mui/icons-material';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, ChangeLogService } from '../services';
import {
  IChangeLogFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IChangeLogList,
} from '../types';
import { Div } from '../components/display-data';
import { formatterDateTimeBR, getFilterParams, toDateApi, toDateTime } from '../utils';

// Define interface for context
interface IChangeLogContext {
  // Data
  columnsChangeLog: IDataGridColumns;
  rowsChangeLog: IChangeLogList[];
  // Pagination
  rowCountChangeLog: number;
  pageSizeChangeLog: number;
  pageChangeLog: number;
  handlePageSizeChangeLog: (size: number) => void;
  handlePageChangeLog: (page: number) => void;
  // Filter
  defaultFilterChangeLog: IChangeLogFilter;
  filterChangeLog: IChangeLogFilter;
  handleFilterChangeLog: (filter: IChangeLogFilter) => void;
  // Api
  getAllChangeLog: () => void;
}

// Define columns schema
const columnsSchemaChangeLog: IDataGridColumns = [
  {
    field: 'change_log',
    headerName: 'Alterações',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { user_nickname, change_log, changed_at } = params.row;
      const changeAt = toDateTime(changed_at);
      return (
        <Div sx={{ m: 2 }}>
          <Div component="div" whiteSpace="normal">
            <strong>{change_log}</strong>
          </Div>
          <Div component="div" whiteSpace="normal" sx={{ mt: 2 }} isFlexRow alignItems="center">
            <History sx={{ mr: 1 }} />
            {user_nickname} - {formatterDateTimeBR(changeAt)}
          </Div>
        </Div>
      );
    },
  },
];

// Initial values for filter
const defaultFilterChangeLog: IChangeLogFilter = {
  start_date: new Date(),
  end_date: new Date(),
  user_id: 0,
  user_name: '',
  change_log: '',
};

// Create context
export const ChangeLogContext = createContext<IChangeLogContext>({} as IChangeLogContext);

// Create context provider
export const ChangeLogContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsChangeLog] = useState<IDataGridColumns>(columnsSchemaChangeLog);

  // Rows state
  const [rowsChangeLog, setRowsChangeLog] = useState<IChangeLogList[]>([]);

  // Filter state
  const [filterChangeLog, setFilterChangeLog] = useState<IChangeLogFilter>(defaultFilterChangeLog);

  // Page state
  const [pageChangeLog, setPageChangeLog] = useState(0);

  // Page size state
  const [pageSizeChangeLog, setPageSizeChangeLog] = useState(10);

  // Record count state
  const [rowCountChangeLog, setRowCountChangeLog] = useState(0);

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError } = useSnackbarDispatch();

  // Change page status
  const handlePageChangeLog = useCallback(
    (page: number) => setPageChangeLog(page),
    [pageChangeLog],
  );

  // Change page size status
  const handlePageSizeChangeLog = useCallback(
    (size: number) => {
      setPageSizeChangeLog(size);
      setPageChangeLog(0);
    },
    [pageSizeChangeLog],
  );

  // Change filters status
  const handleFilterChangeLog = useCallback(
    (filter: IChangeLogFilter) => {
      setFilterChangeLog(filter);
      setPageChangeLog(0);
    },
    [filterChangeLog],
  );

  // Clear data rows
  const clearStorageChangeLog = useCallback(() => {
    setRowsChangeLog([]);
    setRowCountChangeLog(0);
  }, [setRowsChangeLog]);

  // Load data from request
  const loadStorageChangeLog = useCallback(
    (response: IApiResponseWithPagination<IChangeLogList>) => {
      setRowsChangeLog(response.data);
      setRowCountChangeLog(response.records);
    },
    [setRowsChangeLog],
  );

  // API - Get all records
  const getAllChangeLog = useCallback(() => {
    // Add filters in params
    const params: any = getFilterParams(filterChangeLog);
    params.sort = 'changed_at.desc';
    params.start_date = toDateApi(params.start_date);
    params.end_date = toDateApi(params.end_date);
    // Add pagination in params
    params.limit = pageSizeChangeLog;
    params.offset = pageChangeLog * pageSizeChangeLog;
    // Get data
    ChangeLogService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        clearStorageChangeLog();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageChangeLog(0);
        return;
      }
      loadStorageChangeLog(response);
    });
  }, [headers, filterChangeLog, pageChangeLog, pageSizeChangeLog]);

  return (
    <ChangeLogContext.Provider
      value={{
        // Data
        columnsChangeLog,
        rowsChangeLog,
        // Pagination
        rowCountChangeLog,
        pageSizeChangeLog,
        pageChangeLog,
        handlePageSizeChangeLog,
        handlePageChangeLog,
        // Filter
        defaultFilterChangeLog,
        filterChangeLog,
        handleFilterChangeLog,
        // Api
        getAllChangeLog,
      }}
    >
      {children}
    </ChangeLogContext.Provider>
  );
};
