import { Input, FilterDialog, InputMask } from '../../../shared/components';
import { useCustomer } from '../../../shared/hooks';

// Create modal filter for Customer
export const CustomerFilterModal: React.FC = () => {
  // Use context Customer
  const {
    isOpenFilterModalCustomer,
    toggleIsOpenFilterModalCustomer,
    handleFilterCustomer,
    filterCustomer,
    defaultFilterCustomer,
  } = useCustomer();

  return (
    <FilterDialog
      isOpen={isOpenFilterModalCustomer}
      onClose={toggleIsOpenFilterModalCustomer}
      setFilter={handleFilterCustomer}
      filter={filterCustomer}
      initialValues={defaultFilterCustomer}
    >
      <Input label="Código" name="id" type="number" placeholder="Código do Cliente" />
      <Input autoFocus label="Nome" name="name" />
      <InputMask label="CNPJ" name="cnpj" mask="cnpj" maxLength={18} inputMode="numeric" />
      <InputMask label="CPF" name="cpf" mask="cpf" maxLength={14} inputMode="numeric" />
    </FilterDialog>
  );
};
