/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useEmployee } from '../../../shared/hooks';

type IEmployeeDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const EmployeeDataGrid: React.FC<IEmployeeDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsEmployee,
    rowsEmployee,
    rowCountEmployee,
    pageEmployee,
    handlePageEmployee,
    pageSizeEmployee,
    handlePageSizeEmployee,
    sortEmployee,
    filterEmployee,
    handleFilterEmployee,
    handleSortEmployee,
    getAllEmployee,
  } = useEmployee();

  useEffect(() => handleFilterEmployee({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllEmployee();
  }, [canSearch, sortEmployee, filterEmployee, pageEmployee, pageSizeEmployee]);

  return (
    <DataGrid
      rows={rowsEmployee}
      rowCount={rowCountEmployee}
      columns={columnsEmployee}
      onSortModelChange={handleSortEmployee}
      sortModel={sortEmployee}
      pageSize={pageSizeEmployee}
      onPageSizeChange={handlePageSizeEmployee}
      page={pageEmployee}
      onPageChange={handlePageEmployee}
      {...rest}
    />
  );
};
