/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, InsurancePlanService } from '../services';
import {
  IInsurancePlanData,
  IInsurancePlanFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  IInsurancePlanDTO,
  IDataGridSelection,
  IInsurancePlanList,
} from '../types';
import { getFilterParams, toObjectWithoutNull, formatterDateBR, toDate } from '../utils';
import { getSortParams } from '../utils/getSortParams';
import { InfoStatusRecord, DisplayColumWithIcon } from '../components/feedback';

// Define interface for context
interface IInsurancePlanContext {
  // Data
  columnsInsurancePlan: IDataGridColumns;
  rowsInsurancePlan: IInsurancePlanList[];
  defaultDataInsurancePlan: IInsurancePlanData;
  dataInsurancePlan: IInsurancePlanData | undefined;
  handleDataInsurancePlan: (data?: IInsurancePlanData) => void;
  handleClearStorageInsurancePlan: () => void;
  // Pagination
  rowCountInsurancePlan: number;
  pageSizeInsurancePlan: number;
  pageInsurancePlan: number;
  handlePageSizeInsurancePlan: (size: number) => void;
  handlePageInsurancePlan: (page: number) => void;
  // Sort
  sortInsurancePlan: IDataGridSortOptions;
  handleSortInsurancePlan: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionInsurancePlan: IDataGridSelection;
  handleSelectionInsurancePlan: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterInsurancePlan: IInsurancePlanFilter;
  hideFilterInsurancePlan: string[];
  filterInsurancePlan: IInsurancePlanFilter;
  isOpenFilterModalInsurancePlan: boolean;
  toggleIsOpenFilterModalInsurancePlan: () => void;
  handleFilterInsurancePlan: (filter: IInsurancePlanFilter) => void;
  // Api
  getAllInsurancePlan: () => void;
  getByIdInsurancePlan: (id: number) => void;
  createInsurancePlan: (payload: IInsurancePlanDTO) => void;
  updateByIdInsurancePlan: (id: number, payload: IInsurancePlanDTO) => void;
  deleteByIdInsurancePlan: (id: number) => void;
  deleteIdsInsurancePlan: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaInsurancePlan: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código do seguro',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'type',
    headerName: 'Tipo',
    hideable: false,
  },
  {
    field: 'coverage_id',
    headerName: 'Assegurado',
    hideable: false,
  },
  {
    field: 'coverage_detail',
    headerName: 'Assegurado',
    description: 'Dados do assegurado',
    flex: 2,
    renderCell: (params) => {
      const { type, coverage_detail } = params.row;
      return (
        <DisplayColumWithIcon
          icon={type === 'AUTO' ? 'directions_car' : 'person'}
          cell={coverage_detail}
        />
      );
    },
  },
  {
    field: 'insurance_name',
    headerName: 'Seguradora',
    description: 'Nome da seguradora',
    flex: 1,
  },
  {
    field: 'broker_name',
    headerName: 'Corretora',
    description: 'Nome da corretora',
    flex: 1,
  },
  {
    field: 'end_coverage',
    headerName: 'Término',
    description: 'Fim do cobertura',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => formatterDateBR(params.row.end_coverage),
  },
  {
    field: 'is_active',
    headerName: 'Status',
    description: 'Indica se o registro esta ativo ou não',
    renderCell: (params) => <InfoStatusRecord status={params.row.is_active} />,
    headerAlign: 'center',
    align: 'center',
  },
];

// Initial values for filter
const defaultFilterInsurancePlan: IInsurancePlanFilter = {
  coverage_id: 0,
  coverage_detail: '',
  type: '',
  insurance_name: '',
  broker_name: '',
};

// Omit filter on list
const defaultHideFilterInsurancePlan = ['coverage_detail'];

// initial values for data
const defaultDataInsurancePlan: IInsurancePlanData = {
  id: 0,
  type: 'AUTO',
  coverage_id: 0,
  coverage_detail: '',
  insurance_name: '',
  insurance_phone: '',
  broker_name: '',
  broker_phone: '',
  total: 0,
  start_coverage: null,
  end_coverage: null,
  is_active: true,
};

// Create context
export const InsurancePlanContext = createContext<IInsurancePlanContext>(
  {} as IInsurancePlanContext,
);

// Create context provider
export const InsurancePlanContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsInsurancePlan] = useState<IDataGridColumns>(columnsSchemaInsurancePlan);

  // Rows state
  const [rowsInsurancePlan, setRowsInsurancePlan] = useState<IInsurancePlanList[]>([]);

  // Data state
  const [dataInsurancePlan, setDataInsurancePlan] = useState<IInsurancePlanData | undefined>();

  // Sort state
  const [sortInsurancePlan, setSortInsurancePlan] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterInsurancePlan, setFilterInsurancePlan] = useState<IInsurancePlanFilter>(
    defaultFilterInsurancePlan,
  );

  // Hide Filter state
  const [hideFilterInsurancePlan] = useState(defaultHideFilterInsurancePlan);

  // Modal filters
  const [isOpenFilterModalInsurancePlan, setIsOpenInsurancePlanFilterModal] = useState(false);

  // Page state
  const [pageInsurancePlan, setPageInsurancePlan] = useState(0);

  // Page size state
  const [pageSizeInsurancePlan, setPageSizeInsurancePlan] = useState(10);

  // Record count state
  const [rowCountInsurancePlan, setRowCountInsurancePlan] = useState(0);

  // Selection state
  const [selectionInsurancePlan, setSelectionInsurancePlan] = useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataInsurancePlan = useCallback(
    (data?: IInsurancePlanData) => {
      if (!data) return setDataInsurancePlan(data);
      const dataWithoutNull = toObjectWithoutNull<IInsurancePlanData>(
        data,
        defaultDataInsurancePlan,
      );
      dataWithoutNull.start_coverage = toDate(dataWithoutNull.start_coverage);
      dataWithoutNull.end_coverage = toDate(dataWithoutNull.end_coverage);
      return setDataInsurancePlan(dataWithoutNull);
    },
    [dataInsurancePlan],
  );

  // Change page status
  const handlePageInsurancePlan = useCallback(
    (page: number) => setPageInsurancePlan(page),
    [pageInsurancePlan],
  );

  // Change page size status
  const handlePageSizeInsurancePlan = useCallback(
    (size: number) => {
      setPageSizeInsurancePlan(size);
      setPageInsurancePlan(0);
    },
    [pageSizeInsurancePlan],
  );

  // Change sort status
  const handleSortInsurancePlan = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortInsurancePlan(sort);
      setPageInsurancePlan(0);
    },
    [sortInsurancePlan],
  );

  // Change filters status
  const handleFilterInsurancePlan = useCallback(
    (filter: IInsurancePlanFilter) => {
      setFilterInsurancePlan(filter);
      setPageInsurancePlan(0);
    },
    [filterInsurancePlan],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalInsurancePlan = useCallback(
    () => setIsOpenInsurancePlanFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionInsurancePlan = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionInsurancePlan(selection);
    },
    [selectionInsurancePlan],
  );

  // Clear data rows
  const handleClearStorageInsurancePlan = useCallback(() => {
    setRowsInsurancePlan([]);
    setRowCountInsurancePlan(0);
  }, [setRowsInsurancePlan]);

  // Load data from request
  const handleLoadStorageInsurancePlan = useCallback(
    (response: IApiResponseWithPagination<IInsurancePlanList>) => {
      setRowsInsurancePlan(response.data);
      setRowCountInsurancePlan(response.records);
    },
    [setRowsInsurancePlan],
  );

  // API - Get all records
  const getAllInsurancePlan = useCallback(() => {
    // Reset selection
    handleSelectionInsurancePlan([]);
    // Add filters in params
    const params: any = getFilterParams(filterInsurancePlan);
    // Add sort in params
    const sort = getSortParams(sortInsurancePlan);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeInsurancePlan;
    params.offset = pageInsurancePlan * pageSizeInsurancePlan;
    // Get data
    InsurancePlanService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        handleClearStorageInsurancePlan();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageInsurancePlan(0);
        return;
      }
      handleLoadStorageInsurancePlan(response);
    });
  }, [
    headers,
    filterInsurancePlan,
    sortInsurancePlan,
    pageInsurancePlan,
    pageSizeInsurancePlan,
    dataInsurancePlan,
  ]);

  // API - Get record by id
  const getByIdInsurancePlan = useCallback(
    (id: number) => {
      // Get details
      InsurancePlanService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataInsurancePlan(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createInsurancePlan = useCallback(
    (payload: IInsurancePlanDTO) => {
      // Get details
      InsurancePlanService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataInsurancePlan();
        snackbarSuccess('Registro adicionado!');
        if (pageInsurancePlan > 0) {
          handlePageInsurancePlan(0);
          return;
        }
        getAllInsurancePlan();
      });
    },
    [getAllInsurancePlan],
  );

  // API - Update record by id
  const updateByIdInsurancePlan = useCallback(
    (id: number, payload: IInsurancePlanDTO) => {
      // Get details
      InsurancePlanService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataInsurancePlan();
        getAllInsurancePlan();
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllInsurancePlan],
  );

  // API - Delete record by id
  const deleteByIdInsurancePlan = useCallback(
    (id: number) => {
      InsurancePlanService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataInsurancePlan();
        getAllInsurancePlan();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllInsurancePlan],
  );

  // API - Delete record by ids
  const deleteIdsInsurancePlan = useCallback(
    (ids: number[]) => {
      InsurancePlanService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllInsurancePlan();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllInsurancePlan],
  );

  return (
    <InsurancePlanContext.Provider
      value={{
        // Data
        columnsInsurancePlan,
        rowsInsurancePlan,
        dataInsurancePlan,
        defaultDataInsurancePlan,
        hideFilterInsurancePlan,
        handleDataInsurancePlan,
        handleClearStorageInsurancePlan,
        // Pagination
        rowCountInsurancePlan,
        pageSizeInsurancePlan,
        pageInsurancePlan,
        handlePageSizeInsurancePlan,
        handlePageInsurancePlan,
        // Sort
        sortInsurancePlan,
        handleSortInsurancePlan,
        // Selection
        selectionInsurancePlan,
        handleSelectionInsurancePlan,
        // Filter
        defaultFilterInsurancePlan,
        filterInsurancePlan,
        isOpenFilterModalInsurancePlan,
        toggleIsOpenFilterModalInsurancePlan,
        handleFilterInsurancePlan,
        // Api
        getAllInsurancePlan,
        getByIdInsurancePlan,
        createInsurancePlan,
        updateByIdInsurancePlan,
        deleteByIdInsurancePlan,
        deleteIdsInsurancePlan,
      }}
    >
      {children}
    </InsurancePlanContext.Provider>
  );
};
