/* eslint-disable react/jsx-props-no-spreading */

import { Tab, Tabs, ITabsProps } from '../../../shared/components';

interface ICustomerModalTabs extends ITabsProps {
  isEditMode?: boolean;
}

export const CustomerModalTabs: React.FC<ICustomerModalTabs> = ({ isEditMode, ...rest }) => {
  return (
    <Tabs {...rest}>
      <Tab label="Geral" />
      <Tab label="Contatos" disabled={!isEditMode} />
      <Tab label="Endereços" disabled={!isEditMode} />
      <Tab label="Usuário" disabled={!isEditMode} />
    </Tabs>
  );
};
