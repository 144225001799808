import { Grid } from '@mui/material';
import { Field } from 'formik';
import * as Yup from 'yup';
import {
  Input,
  DataDialog,
  DataTimePicker,
  InputNumber,
  InputCurrency,
  SelectTravelType,
} from '../../../shared/components';
import { useTravel, useDialog } from '../../../shared/hooks';
import { ITravelDTO } from '../../../shared/types';
import { schemaValidation, toNumber, toNumberOrZero, toDateTimeApi } from '../../../shared/utils';
import { CustomerInputSelectBase } from '../../customer/components';
import { EmployeeInputSelectBase } from '../../employee/components';
import { VehicleInputSelectBase } from '../../vehicle/components/VehicleInputSelectBase';

// Define validation fields form
const schemaPayloadTravel = Yup.object({
  started_at: schemaValidation.dateTime.timestamp(true),
  finished_at: schemaValidation.dateTime
    .timestamp(true)
    .min(Yup.ref('started_at'), 'Data final não pode ser inferior a inicial'),
  customer_name: schemaValidation.people.name,
  driver_name: schemaValidation.people.name,
  vehicle_detail: schemaValidation.people.name,
  destination: schemaValidation.commons.string(true),
  total: schemaValidation.number.float,
});

// Create modal data for Travel
export const TravelModal: React.FC = () => {
  // Use context Travel
  const {
    dataTravel,
    defaultDataTravel,
    handleDataTravel,
    createTravel,
    updateByIdTravel,
    deleteByIdTravel,
  } = useTravel();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Reset data and close modal
  const handleClose = () => handleDataTravel();

  // Save actions
  const handleSave = (payload: any) => {
    const normalize = { ...payload };
    normalize.started_at = toDateTimeApi(payload.started_at);
    normalize.finished_at = toDateTimeApi(payload.finished_at);
    normalize.start_km = toNumber(payload.start_km);
    normalize.end_km = toNumber(payload.end_km);
    normalize.total_km = toNumber(payload.total_km);
    normalize.price_km = toNumberOrZero(payload.price_km);
    normalize.toll_price = toNumberOrZero(payload.toll_price);
    normalize.service_price = toNumberOrZero(payload.service_price);
    normalize.total = toNumberOrZero(payload.total);

    const id = dataTravel?.id || 0;
    const data = normalize as ITravelDTO;
    if (id > 0) {
      updateByIdTravel(id, data);
      return;
    }
    createTravel(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, destination } = payload;
    dialogConfirmDanger(
      'Excluir Viagem?',
      `Deseja realmente excluir o Viagem: ${id} - ${destination} `,
      () => deleteByIdTravel(id),
    );
  };

  return (
    <DataDialog
      referenceId={dataTravel?.id}
      referenceDisplayName="viagem"
      initialValues={dataTravel || defaultDataTravel}
      validationSchema={schemaPayloadTravel}
      open={dataTravel !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={12}>
          <Field name="type" component={SelectTravelType} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Field name="started_at" component={DataTimePicker} label="Saída *" />
        </Grid>

        <Grid item xs={12} md={6}>
          <Field name="finished_at" component={DataTimePicker} label="Retorno *" />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input label="Origem" name="origin" />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input label="Destino *" name="destination" />
        </Grid>

        <Grid item xs={12} md={12}>
          <CustomerInputSelectBase label="Cliente *" />
        </Grid>

        <Grid item xs={12} md={12}>
          <EmployeeInputSelectBase baseName="driver" label="Motorista *" />
        </Grid>

        <Grid item xs={12} md={12}>
          <VehicleInputSelectBase refId="vehicle_id" refName="vehicle_detail" label="Veículo *" />
        </Grid>

        <Grid item xs={6} md={3}>
          <InputNumber label="KM Inicial" name="start_km" />
        </Grid>

        <Grid item xs={6} md={3}>
          <InputNumber label="KM Final" name="end_km" />
        </Grid>

        <Grid item xs={6} md={3}>
          <InputNumber label="KM Total" name="total_km" />
        </Grid>

        <Grid item xs={6} md={3}>
          <InputCurrency label="Preço / KM" name="price_km" />
        </Grid>

        <Grid item xs={6} md={4}>
          <InputCurrency label="Pedágios" name="toll_price" />
        </Grid>

        <Grid item xs={6} md={4}>
          <InputCurrency label="Serviços" name="service_price" />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputCurrency
            label="Total"
            name="total"
            inputProps={{ style: { fontWeight: 'bold', textAlign: 'right' } }}
          />
        </Grid>
      </Grid>
    </DataDialog>
  );
};
