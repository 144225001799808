/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Collapse,
  Divider,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDeviceIsMobile, useMenu } from '../../hooks';
import { ISidebarMenuItemData } from '../../contexts';

// Define props
interface ISidebarMenuItemProps extends ISidebarMenuItemData {
  subheader?: string;
  items?: ISidebarMenuItemData[];
}

// Create item for list sidebar component
export const SidebarMenuItem: React.FC<ISidebarMenuItemProps> = (props) => {
  // Destructure data
  const { label, icon, items, to, onClick, subheader, level } = props;

  // Check if has items
  const hasItems = items && items.length;

  // Check typeof icon
  const iconIsString = typeof icon === 'string';

  // Use hook is mobile
  const deviceIsMobile = useDeviceIsMobile();

  // Use hook menu
  const { isExpandedSidebarMenu, isOpenSidebarMenu, closeSidebarMenu, openSidebarMenu } = useMenu();

  // Use hook navigate for redirect user
  const navigate = useNavigate();

  // Define key name for state level isOpen
  const keyIsOpenLevelLocalStorage = useMemo(() => `menu_level_${level || 0}`, [level]);

  // Get state level is open
  const isOpenLevelLocalStorage = useCallback(() => {
    if (!isOpenSidebarMenu && !deviceIsMobile && !isExpandedSidebarMenu) {
      return false;
    }
    return localStorage.getItem(keyIsOpenLevelLocalStorage) === label;
  }, [keyIsOpenLevelLocalStorage, label]);

  // Set state level is open
  const setIsOpenLevelLocalStorage = useCallback(
    (open: boolean) => {
      if (open) {
        localStorage.setItem(keyIsOpenLevelLocalStorage, label);
        return;
      }
      localStorage.removeItem(keyIsOpenLevelLocalStorage);
    },
    [keyIsOpenLevelLocalStorage, label],
  );

  // Use state
  const [isOpen, setIsOpen] = useState(isOpenLevelLocalStorage());

  // toggle state isOpen
  const toggleIsOpen = () =>
    setIsOpen((prevState) => {
      setIsOpenLevelLocalStorage(!prevState);
      return !prevState;
    });

  // Event click
  const handleClick = () => {
    if (to) {
      setIsOpenLevelLocalStorage(false);
      navigate(to);
    }
    if (hasItems) {
      toggleIsOpen();
      if (!isExpandedSidebarMenu && !isOpenSidebarMenu && !deviceIsMobile) openSidebarMenu();
    }
    if (!hasItems && deviceIsMobile) {
      closeSidebarMenu();
    }
    onClick?.();
  };

  // If contains to, validate path selected
  let match = null;
  if (to) {
    const resolvedPath = useResolvedPath(to);
    match = useMatch({ path: resolvedPath.pathname, end: false });
  }

  // Auto close on hide main menu
  useEffect(() => {
    if (isOpen && !isOpenSidebarMenu && !isExpandedSidebarMenu) {
      setIsOpen(false);
    }
  }, [isOpenSidebarMenu]);

  return (
    <>
      <ListItemButton
        selected={!!match}
        onClick={handleClick}
        sx={{
          height: '48px',
          borderRadius: '4px',
          pl: level! + 4,
          mb: !level ? 1 : 0,
        }}
      >
        {icon &&
          (iconIsString ? (
            <ListItemIcon>
              <Icon>{icon}</Icon>
            </ListItemIcon>
          ) : (
            <ListItemIcon>{icon}</ListItemIcon>
          ))}

        <ListItemText
          sx={{ ml: !isOpenSidebarMenu ? 0 : -3 }}
          primary={isOpenSidebarMenu ? label : ''}
          secondary={
            !isOpen &&
            (subheader ||
              items
                ?.map((item) => item.label)
                .join(', ')
                .substring(0, 29))
          }
        />
        {hasItems && (isOpen ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {hasItems && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {items.map((item, index) => (
              <SidebarMenuItem key={index} {...item} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};
