/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useSupplier } from '../../../shared/hooks';

type ISupplierDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const SupplierDataGrid: React.FC<ISupplierDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsSupplier,
    rowsSupplier,
    rowCountSupplier,
    pageSupplier,
    handlePageSupplier,
    pageSizeSupplier,
    handlePageSizeSupplier,
    sortSupplier,
    filterSupplier,
    handleFilterSupplier,
    handleSortSupplier,
    getAllSupplier,
  } = useSupplier();

  useEffect(() => handleFilterSupplier({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllSupplier();
  }, [canSearch, sortSupplier, filterSupplier, pageSupplier, pageSizeSupplier]);

  return (
    <DataGrid
      rows={rowsSupplier}
      rowCount={rowCountSupplier}
      columns={columnsSupplier}
      onSortModelChange={handleSortSupplier}
      sortModel={sortSupplier}
      pageSize={pageSizeSupplier}
      onPageSizeChange={handlePageSizeSupplier}
      page={pageSupplier}
      onPageChange={handlePageSupplier}
      {...rest}
    />
  );
};
