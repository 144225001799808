import { Divider, useTheme } from '@mui/material';
import { Div, H1, P, FilterActionButton } from '../components';
import { useDeviceIsMobile } from '../hooks';

// Define interface
interface IPageTitleProps {
  title?: string;
  subtitle?: string;
  filterAction?: () => void;
}

// Create component page title
export const PageTitle: React.FC<IPageTitleProps> = ({
  title,
  subtitle,
  filterAction,
  children,
}) => {
  const theme = useTheme();
  const deviceIsMobile = useDeviceIsMobile();

  return (
    <Div component="header">
      <Div sx={{ py: theme.spacing(2) }} isFlexRow>
        {title && (
          <Div>
            <H1 variant={deviceIsMobile ? 'h5' : 'h4'}>{title}</H1>
            <P ellipsisOnMobile="260px">{subtitle}</P>
          </Div>
        )}
        {filterAction && (
          <>
            <Div fillInFlex />
            <FilterActionButton onClick={filterAction} />
          </>
        )}
        {children}
      </Div>
      <Divider sx={{ mb: theme.spacing(2) }} />
    </Div>
  );
};
