import { useInsurancePlan } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { InsurancePlanMain } from './components/InsurancePlanMain';

export const InsurancePlanPage: React.FC = () => {
  const { toggleIsOpenFilterModalInsurancePlan } = useInsurancePlan();

  return (
    <PageContainer>
      <PageTitle
        title="Seguros"
        subtitle="Gerencie os planos de seguro"
        filterAction={toggleIsOpenFilterModalInsurancePlan}
      />

      <PageMain>
        <InsurancePlanMain />
      </PageMain>
    </PageContainer>
  );
};
