import {
  IApiResponseWithPagination,
  IUserData,
  IUserList,
  IUserDTO,
  IUserRoleList,
  IUserStatusDTO,
  IUserChangePasswordDTO,
  IUserRolePermissionList,
  IUserChangeTwoStepAuthDTO,
  IUserPinList,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { UserPerfilSchemaApiError, UserSchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<IUserList> | ApiError> => {
  try {
    const response = await Api().get('/users', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, UserSchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<IUserData | ApiError> => {
  try {
    const response = await Api().get(`/users/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, UserSchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: IUserDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/users', payload, { headers });
    return response.data.business_type_id;
  } catch (error: any) {
    return makeApiError(error, UserSchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: IUserDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/users/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, UserSchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/users/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, UserSchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/users/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, UserSchemaApiError);
  }
};

// toggle user status
const updateStatusById = async (
  headers: any,
  id: number,
  status: IUserStatusDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().patch(`/users/${id}/status/${status}`, {}, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, UserSchemaApiError);
  }
};

// Update password (Active user)
const updatePasswordCurrentUser = async (
  headers: any,
  payload: IUserChangePasswordDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().patch(`/users/current/password`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, UserPerfilSchemaApiError);
  }
};

// Update two step authentication (Active user)
const updateTwoStepAuthCurrentUser = async (
  headers: any,
  status: IUserChangeTwoStepAuthDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().patch(`/users/current/two-step-auth/${status}`, {}, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, UserPerfilSchemaApiError);
  }
};

// Get all PIN (Active user)
const getAllPins = async (headers: any): Promise<IUserPinList[] | ApiError> => {
  try {
    const response = await Api().get('/users/current/pin', {
      headers,
      params: { sort: 'last_access.desc' },
    });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, UserSchemaApiError);
  }
};

// delete pin (Active user)
const deletePin = async (headers: any, pin: string): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/users/current/pin/${pin}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, UserSchemaApiError);
  }
};

// Get all roles
const getAllRoles = async (headers: any): Promise<IUserRoleList[] | ApiError> => {
  try {
    const response = await Api().get('/system/roles', { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, UserSchemaApiError);
  }
};

// Get all roles
const getPermissionsByRoleId = async (
  headers: any,
  id: number,
): Promise<IUserRolePermissionList[] | ApiError> => {
  try {
    const response = await Api().get(`/system/roles/${id}/permissions`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, UserSchemaApiError);
  }
};

export const UserService = {
  getAll,
  getById,
  create,
  updateById,
  updateStatusById,
  deleteById,
  deleteIds,
  getAllPins,
  getAllRoles,
  getPermissionsByRoleId,
  updatePasswordCurrentUser,
  updateTwoStepAuthCurrentUser,
  deletePin,
};
