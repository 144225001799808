import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, PeoplePaymentAccountService } from '../services';
import {
  IPeoplePaymentAccountData,
  IPeoplePaymentAccountDTO,
  IPeoplePaymentAccountList,
  IPeopleType,
} from '../types';
import { getPeopleResource, toObjectWithoutNull } from '../utils';

// Define interface for context
interface IPeoplePaymentAccountContext {
  // Data
  rowsPeoplePaymentAccount: IPeoplePaymentAccountList[];
  defaultDataPeoplePaymentAccount: IPeoplePaymentAccountData;
  dataPeoplePaymentAccount: IPeoplePaymentAccountData | undefined;
  handleDataPeoplePaymentAccount: (data?: IPeoplePaymentAccountData) => void;
  HandleLoadStoragePeoplePaymentAccount: (data: IPeoplePaymentAccountList[]) => void;
  // Api
  getAllPeoplePaymentAccount: (peopleType: IPeopleType, peopleId: number) => void;
  createPeoplePaymentAccount: (
    peopleType: IPeopleType,
    peopleId: number,
    payload: IPeoplePaymentAccountDTO,
  ) => void;
  updateByIdPeoplePaymentAccount: (
    peopleType: IPeopleType,
    peopleId: number,
    id: number,
    payload: IPeoplePaymentAccountDTO,
  ) => void;
  deleteByIdPeoplePaymentAccount: (peopleType: IPeopleType, peopleId: number, id: number) => void;
}

// initial values for data
const defaultDataPeoplePaymentAccount: IPeoplePaymentAccountData = {
  id: 0,
  type: '',
  account: '',
  is_default: true,
};

// Create context
export const PeoplePaymentAccountContext = createContext<IPeoplePaymentAccountContext>(
  {} as IPeoplePaymentAccountContext,
);

// Create context provider
export const PeoplePaymentAccountContextProvider: React.FC = ({ children }) => {
  // Rows state
  const [rowsPeoplePaymentAccount, setRowsPeoplePaymentAccount] = useState<
    IPeoplePaymentAccountList[]
  >([]);

  // Data state
  const [dataPeoplePaymentAccount, setDataPeoplePaymentAccount] = useState<
    IPeoplePaymentAccountData | undefined
  >();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataPeoplePaymentAccount = useCallback(
    (data?: IPeoplePaymentAccountData) => {
      if (!data) return setDataPeoplePaymentAccount(data);
      const dataWithoutNull = toObjectWithoutNull<IPeoplePaymentAccountData>(
        data,
        defaultDataPeoplePaymentAccount,
      );
      return setDataPeoplePaymentAccount(dataWithoutNull);
    },
    [dataPeoplePaymentAccount],
  );

  // Clear data rows
  const HandleClearStoragePeoplePaymentAccount = useCallback(() => {
    setRowsPeoplePaymentAccount([]);
  }, [setRowsPeoplePaymentAccount]);

  // Load data from request
  const HandleLoadStoragePeoplePaymentAccount = useCallback(
    (response: IPeoplePaymentAccountList[]) => {
      setRowsPeoplePaymentAccount(response);
    },
    [setRowsPeoplePaymentAccount],
  );

  // API - Get all records
  const getAllPeoplePaymentAccount = useCallback(
    (peopleType: IPeopleType, peopleId: number) => {
      if (peopleId === 0) {
        HandleClearStoragePeoplePaymentAccount();
        return;
      }
      // Get people resource
      const peopleResource = getPeopleResource(peopleType);
      // Get data
      PeoplePaymentAccountService.getAll(headers, peopleResource, peopleId).then((response) => {
        if (response instanceof ApiError) {
          HandleClearStoragePeoplePaymentAccount();
          snackbarError(response.message);
          return;
        }
        HandleLoadStoragePeoplePaymentAccount(response);
      });
    },
    [headers, dataPeoplePaymentAccount],
  );

  // API - Create record
  const createPeoplePaymentAccount = useCallback(
    (peopleType: IPeopleType, peopleId: number, payload: IPeoplePaymentAccountDTO) => {
      // Get people resource
      const peopleResource = getPeopleResource(peopleType);
      // Get details
      PeoplePaymentAccountService.create(headers, peopleResource, peopleId, payload).then(
        (response) => {
          if (response instanceof ApiError) {
            snackbarError(response.message);
            return;
          }
          handleDataPeoplePaymentAccount();
          snackbarSuccess('Registro adicionado!');
          getAllPeoplePaymentAccount(peopleType, peopleId);
        },
      );
    },
    [getAllPeoplePaymentAccount],
  );

  // API - Update record by id
  const updateByIdPeoplePaymentAccount = useCallback(
    (peopleType: IPeopleType, peopleId: number, id: number, payload: IPeoplePaymentAccountDTO) => {
      // Get people resource
      const peopleResource = getPeopleResource(peopleType);
      // Get details
      PeoplePaymentAccountService.updateById(headers, peopleResource, peopleId, id, payload).then(
        (response) => {
          if (response instanceof ApiError) {
            snackbarError(response.message);
            return;
          }
          handleDataPeoplePaymentAccount();
          getAllPeoplePaymentAccount(peopleType, peopleId);
          snackbarSuccess('Registro atualizado!');
        },
      );
    },
    [getAllPeoplePaymentAccount],
  );

  // API - Delete record by id
  const deleteByIdPeoplePaymentAccount = useCallback(
    (peopleType: IPeopleType, peopleId: number, id: number) => {
      // Get people resource
      const peopleResource = getPeopleResource(peopleType);
      // Delete record
      PeoplePaymentAccountService.deleteById(headers, peopleResource, peopleId, id).then(
        (response) => {
          if (response instanceof ApiError) {
            snackbarError(response.message);
            return;
          }
          handleDataPeoplePaymentAccount();
          getAllPeoplePaymentAccount(peopleType, peopleId);
          snackbarSuccess('Registro excluído!');
        },
      );
    },
    [getAllPeoplePaymentAccount],
  );

  return (
    <PeoplePaymentAccountContext.Provider
      value={{
        // Data
        rowsPeoplePaymentAccount,
        dataPeoplePaymentAccount,
        defaultDataPeoplePaymentAccount,
        handleDataPeoplePaymentAccount,
        HandleLoadStoragePeoplePaymentAccount,
        // Api
        getAllPeoplePaymentAccount,
        createPeoplePaymentAccount,
        updateByIdPeoplePaymentAccount,
        deleteByIdPeoplePaymentAccount,
      }}
    >
      {children}
    </PeoplePaymentAccountContext.Provider>
  );
};
