/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

type ISelectContactTypeProps = FieldProps;

const options: ISelectOptions[] = [
  { label: 'Telefone', value: 'TELEFONE' },
  { label: 'Celular', value: 'CELULAR' },
  { label: 'E-mail', value: 'EMAIL' },
  { label: 'WhatsApp', value: 'WHATSAPP' },
  { label: 'Telegram', value: 'TELEGRAM' },
  { label: 'Instagram', value: 'INSTAGRAM' },
  { label: 'Facebook', value: 'FACEBOOK' },
  { label: 'Site', value: 'SITE' },
  { label: 'Outros', value: 'OUTROS' },
];

export const SelectContactType: React.FC<ISelectContactTypeProps> = ({ ...rest }) => {
  return <Select label="Tipo" defaultOption="TELEFONE" dataOptions={options} {...rest} />;
};
