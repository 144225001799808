import { getAppVersionTag, getAppVersion, getApiBaseURL, getAppENV } from '../utils';

export const ENV = {
  LOCAL_STORAGE: {
    TOKEN: 'token',
    PIN: 'pin',
    REMEMBER_USER: 'remember_user',
    THEME: 'theme',
    RETRY_REQUEST: 'retry',
    BASE_URL: 'baseURL',
    ENV: 'environment',
  },
  SNACKBAR: {
    TIME_AUTOCLOSE: 3000,
    MAX_SNACK: 3,
  },
  API: {
    BASE_URL: getApiBaseURL(),
    REQUEST_TIMEOUT: 10000,
    INTERVAL_RETRY: 1000,
  },
  APP: {
    SHORT_NAME: 'Logmoto',
    URL_BASE: '',
    VERSION: getAppVersion(),
    TAG: getAppVersionTag(),
    ENV: getAppENV(),
  },
};
