/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

type ISelectTimezoneProps = FieldProps;

const options: ISelectOptions[] = [
  { label: '(UTC -5) Acre', value: 1 },
  { label: '(UTC -4) Amazonas', value: 2 },
  { label: '(UTC -3) Brasília', value: 3 },
  { label: '(UTC -2) Fernando de Noronha', value: 4 },
];

export const SelectTimezone: React.FC<ISelectTimezoneProps> = ({ ...rest }) => {
  return <Select label="Fuso horário" defaultOption={3} dataOptions={options} {...rest} />;
};
