import {
  IApiResponseWithPagination,
  IFinancialCategoryData,
  IFinancialCategoryList,
  IFinancialCategoryDTO,
} from '../../../types';
import { Api, ApiError, makeApiError } from '../config';
import { FinancialCategorySchemaApiError } from '../schemas';

// Get all records
const getAll = async (
  headers: any,
  params: any,
): Promise<IApiResponseWithPagination<IFinancialCategoryList> | ApiError> => {
  try {
    const response = await Api().get('/financial-categories', { headers, params });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, FinancialCategorySchemaApiError);
  }
};

// Detail record by id
const getById = async (headers: any, id: number): Promise<IFinancialCategoryData | ApiError> => {
  try {
    const response = await Api().get(`/financial-categories/${id}`, { headers });
    return response.data;
  } catch (error: any) {
    return makeApiError(error, FinancialCategorySchemaApiError);
  }
};

// Create record
const create = async (headers: any, payload: IFinancialCategoryDTO): Promise<number | ApiError> => {
  try {
    const response = await Api().post('/financial-categories', payload, { headers });
    return response.data.financial_category_id;
  } catch (error: any) {
    return makeApiError(error, FinancialCategorySchemaApiError);
  }
};

// Update record by id
const updateById = async (
  headers: any,
  id: number,
  payload: IFinancialCategoryDTO,
): Promise<boolean | ApiError> => {
  try {
    await Api().put(`/financial-categories/${id}`, payload, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, FinancialCategorySchemaApiError);
  }
};

// delete record by id
const deleteById = async (headers: any, id: number): Promise<boolean | ApiError> => {
  try {
    await Api().delete(`/financial-categories/${id}`, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, FinancialCategorySchemaApiError);
  }
};

// delete records
const deleteIds = async (headers: any, ids: number[]): Promise<boolean | ApiError> => {
  try {
    await Api().patch('/financial-categories/status/DELETE', ids, { headers });
    return true;
  } catch (error: any) {
    return makeApiError(error, FinancialCategorySchemaApiError);
  }
};

export const FinancialCategoryService = {
  getAll,
  getById,
  create,
  updateById,
  deleteById,
  deleteIds,
};
