import { ISchemaApiError } from '../../../types';

// Set schema for error request in recover password
export const CustomerSchemaApiError: ISchemaApiError[] = [
  {
    statusCode: 400,
    errors: [
      {
        error: 'Document already registered for the',
        message: 'Documento já cadastrado',
        isPartial: true,
      },
      {
        error: 'CPF and CNPJ is empty',
        message: 'Informe um documento (CPF ou CNPJ)',
      },
      {
        error: 'Invalid content: CPF',
        message: 'CPF inválido',
      },
      {
        error: 'Invalid content: CNPJ',
        message: 'CNPJ inválido',
      },
    ],
  },
];
