/* eslint-disable react/jsx-props-no-spreading */
import { useField, useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { Input, IInputProps } from './Input';
import {
  pinMask,
  cnpjMask,
  cpfMask,
  rgMask,
  phoneMask,
  postalCodeMask,
  currencyMask,
  quantityMask,
  formatterCurrency,
  formatterQuantity,
  vehiclePlateMask,
} from '../../utils';

// Extend props Input
export interface IInputMaskProps extends IInputProps {
  mask?: 'pin' | 'cnpj' | 'cpf' | 'rg' | 'phone' | 'postalCode' | 'currency' | 'quantity' | 'plate';
  decimalsForQuantity?: number;
}

// Create component TextField Custom
export const InputMask: React.FC<IInputMaskProps> = ({
  name,
  mask,
  decimalsForQuantity = 0,
  ...rest
}) => {
  // Use hook formik
  const [field, meta, helpers] = useField(name);

  // Use hook formik context
  const { setFieldValue } = useFormikContext();

  // Apply mask
  const handleApplyMask = useCallback((value: any): any => {
    const data = String(value);
    if (mask === 'pin') return pinMask(data);
    if (mask === 'cnpj') return cnpjMask(data);
    if (mask === 'cpf') return cpfMask(data);
    if (mask === 'rg') return rgMask(data);
    if (mask === 'phone') return phoneMask(data);
    if (mask === 'postalCode') return postalCodeMask(data);
    if (mask === 'currency') return currencyMask(data);
    if (mask === 'quantity') return quantityMask(data, decimalsForQuantity);
    if (mask === 'plate') return vehiclePlateMask(data);
    return value;
  }, []);

  // Apply initial values
  const handleApplyInitialFormat = useCallback((value: any): any => {
    const data = String(value);
    if (mask === 'currency') return formatterCurrency(data);
    if (mask === 'quantity') return formatterQuantity(data, decimalsForQuantity);
    return value;
  }, []);

  // On change value
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldValue(name, handleApplyMask(event.currentTarget.value));
  };

  // Set default props
  const props: Partial<IInputMaskProps> = {
    ...field,
    margin: 'normal',
    id: name,
    fullWidth: true,
    autoComplete: 'off',
    onChange: handleChange,
    ...rest,
  };

  // Apply initial mask
  useEffect(() => {
    if (!meta.touched) {
      helpers.setValue(handleApplyInitialFormat(props.value));
      helpers.setTouched(true);
    }
  }, []);

  // Append props with erros and helpers
  if (meta && meta.touched && meta.error) {
    props.error = true;
    props.helperText = meta.error;
  }

  return <Input name={name} {...props} />;
};
