/* eslint-disable react/jsx-props-no-spreading */
import { Icon, IconProps, useTheme } from '@mui/material';

interface IUploadSingleFileIconProps extends Omit<IconProps, 'color'> {
  icon: string;
  color?: any;
}

export const UploadSingleFileIcon: React.FC<IUploadSingleFileIconProps> = ({
  icon,
  color,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Icon
      style={{
        fontSize: '48px',
        color: color || 'inherit',
        marginRight: theme.spacing(1),
      }}
      {...rest}
    >
      {icon}
    </Icon>
  );
};
