import * as Yup from 'yup';
import { Checkbox, Input } from '../inputs';
import { DataDialog } from '../data-dialog/DataDialog';
import { usePeoplePaymentAccount, useDialog } from '../../hooks';
import { IPeoplePaymentAccountDTO, IPeopleType } from '../../types';
import { schemaValidation } from '../../utils';

// Define validation fields form
const schemaPayloadPeoplePaymentAccount = Yup.object({
  type: schemaValidation.commons.string(true),
  account: schemaValidation.commons.string(true),
  is_default: Yup.boolean(),
});

interface IPeopleContractModalProps {
  peopleType?: IPeopleType;
  peopleId: number;
}

// Create modal data for PeoplePaymentAccount
export const PeoplePaymentAccountModal: React.FC<IPeopleContractModalProps> = ({
  peopleType = 'COLABORADOR',
  peopleId,
}) => {
  // Use context PeoplePaymentAccount
  const {
    dataPeoplePaymentAccount,
    defaultDataPeoplePaymentAccount,
    handleDataPeoplePaymentAccount,
    createPeoplePaymentAccount,
    updateByIdPeoplePaymentAccount,
    deleteByIdPeoplePaymentAccount,
  } = usePeoplePaymentAccount();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Reset data and close modal
  const handleClose = () => handleDataPeoplePaymentAccount();

  // Save actions
  const handleSave = (payload: object) => {
    const id = dataPeoplePaymentAccount?.id || 0;
    const data = payload as IPeoplePaymentAccountDTO;
    if (id > 0) {
      updateByIdPeoplePaymentAccount(peopleType, peopleId, id, data);
      return;
    }
    createPeoplePaymentAccount(peopleType, peopleId, data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, account } = payload;
    dialogConfirmDanger(
      'Excluir conta?',
      `Deseja realmente excluir a conta de pagamento: ${account}`,
      () => deleteByIdPeoplePaymentAccount(peopleType, peopleId, id),
    );
  };

  return (
    <DataDialog
      referenceId={dataPeoplePaymentAccount?.id}
      referenceDisplayName="conta"
      initialValues={dataPeoplePaymentAccount || defaultDataPeoplePaymentAccount}
      validationSchema={schemaPayloadPeoplePaymentAccount}
      open={dataPeoplePaymentAccount !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <input type="hidden" name="id" />
      <Input label="Tipo *" name="type" placeholder="Ex.: Poupança, Corrente, Pix..." />
      <Input label="Conta *" name="account" placeholder="Ex.: Ag.: 0000 C.: 00000-0" />
      <Checkbox name="is_default" label="Marcar como conta padrão" />
    </DataDialog>
  );
};
