import { createContext, useCallback, useState } from 'react';
import { AccessTimeOutlined, TaskAlt } from '@mui/icons-material';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, FinancialService } from '../services';
import {
  IFinancialData,
  IFinancialFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  IFinancialDTO,
  IDataGridSelection,
  IFinancialList,
} from '../types';
import { InfoStatusRecord } from '../components/feedback/InfoStatusRecord';
import { formatterCurrency, formatterDateBR, getFilterParams, toDate, toDateApi } from '../utils';
import { getSortParams } from '../utils/getSortParams';

// Define interface for context
interface IFinancialContext {
  // Data
  columnsFinancial: IDataGridColumns;
  rowsFinancial: IFinancialList[];
  defaultDataFinancial: IFinancialData;
  dataFinancial: IFinancialData | undefined;
  handleDataFinancial: (data?: IFinancialData) => void;
  // Pagination
  rowCountFinancial: number;
  pageSizeFinancial: number;
  pageFinancial: number;
  handlePageSizeFinancial: (size: number) => void;
  handlePageFinancial: (page: number) => void;
  // Sort
  sortFinancial: IDataGridSortOptions;
  handleSortFinancial: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionFinancial: IDataGridSelection;
  handleSelectionFinancial: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterFinancial: IFinancialFilter;
  hideFilterFinancial: string[];
  filterFinancial: IFinancialFilter;
  isOpenFilterModalFinancial: boolean;
  toggleIsOpenFilterModalFinancial: () => void;
  handleFilterFinancial: (filter: IFinancialFilter) => void;
  // Api
  getAllFinancial: () => void;
  getByIdFinancial: (id: number) => void;
  createFinancial: (payload: IFinancialDTO) => void;
  updateByIdFinancial: (id: number, payload: IFinancialDTO) => void;
  deleteByIdFinancial: (id: number) => void;
  deleteIdsFinancial: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaFinancial: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código do título financeiro',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'category_name',
    headerName: 'Categoria',
    description: 'Nome da categoria financeira',
    flex: 1,
  },
  {
    field: 'people_name',
    headerName: 'Nome',
    description: 'Nome do pagador / beneficiário',
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Descrição',
    description: 'Descrição do pagamento',
    flex: 1,
  },
  {
    field: 'due_date',
    headerName: 'Vencimento',
    description: 'Data de vencimento',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => formatterDateBR(params.row.due_date),
  },
  {
    field: 'total',
    headerName: 'Total',
    description: 'Valor total do título',
    headerAlign: 'right',
    align: 'right',
    valueFormatter: ({ value }) => formatterCurrency(value, true),
  },
  {
    field: 'its_paid',
    headerName: 'Pago',
    description: 'Indica se o título foi compensado',
    renderCell: (params) => (
      <InfoStatusRecord
        status={params.row.its_paid}
        rederOnTrue={<TaskAlt color="success" />}
        rederOnFalse={<AccessTimeOutlined color="disabled" />}
      />
    ),
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'start_due_date',
    headerName: 'Início',
    hideable: false,
  },
  {
    field: 'end_due_date',
    headerName: 'Fim',
    hideable: false,
  },
  {
    field: 'type',
    headerName: 'Tipo',
    hideable: false,
  },
  {
    field: 'category_id',
    headerName: 'Categoria',
    hideable: false,
  },
  {
    field: 'people_id',
    headerName: 'Pessoa',
    hideable: false,
  },
];

// Omit filter on list
const defaultHideFilterFinancial = ['people_type', 'people_name', 'category_name'];

// Initial values for filter
const defaultFilterFinancial: IFinancialFilter = {
  people_id: 0,
  people_name: '',
  people_type: 'CLIENTE',
  category_id: 0,
  category_name: '',
  type: '',
  description: '',
  start_due_date: null,
  end_due_date: null,
};

// initial values for data
const defaultDataFinancial: IFinancialData = {
  id: 0,
  category_id: 0,
  category_name: '',
  people_id: 0,
  people_name: '',
  people_type: 'CLIENTE',
  description: '',
  type: 'C',
  issue_date: new Date(),
  due_date: new Date(),
  total: 0,
  payment_value: 0,
  payment_date: null,
  its_paid: false,
};

// Create context
export const FinancialContext = createContext<IFinancialContext>({} as IFinancialContext);

// Create context provider
export const FinancialContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsFinancial] = useState<IDataGridColumns>(columnsSchemaFinancial);

  // Rows state
  const [rowsFinancial, setRowsFinancial] = useState<IFinancialList[]>([]);

  // Data state
  const [dataFinancial, setDataFinancial] = useState<IFinancialData | undefined>();

  // Sort state
  const [sortFinancial, setSortFinancial] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterFinancial, setFilterFinancial] = useState<IFinancialFilter>(defaultFilterFinancial);

  // Hide Filter state
  const [hideFilterFinancial] = useState(defaultHideFilterFinancial);

  // Modal filters
  const [isOpenFilterModalFinancial, setIsOpenFinancialFilterModal] = useState(false);

  // Page state
  const [pageFinancial, setPageFinancial] = useState(0);

  // Page size state
  const [pageSizeFinancial, setPageSizeFinancial] = useState(10);

  // Record count state
  const [rowCountFinancial, setRowCountFinancial] = useState(0);

  // Selection state
  const [selectionFinancial, setSelectionFinancial] = useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataFinancial = useCallback(
    (data?: IFinancialData) => {
      if (!data) return setDataFinancial(data);
      const normalizeData = data;
      if (data.issue_date) normalizeData.issue_date = toDate(data.issue_date);
      if (data.due_date) normalizeData.due_date = toDate(data.due_date);
      if (data.payment_date) normalizeData.payment_date = toDate(data.payment_date);
      normalizeData.its_paid = !!data.payment_date;
      return setDataFinancial(normalizeData);
    },
    [dataFinancial],
  );
  // Change page status
  const handlePageFinancial = useCallback(
    (page: number) => setPageFinancial(page),
    [pageFinancial],
  );

  // Change page size status
  const handlePageSizeFinancial = useCallback(
    (size: number) => {
      setPageSizeFinancial(size);
      setPageFinancial(0);
    },
    [pageSizeFinancial],
  );

  // Change sort status
  const handleSortFinancial = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortFinancial(sort);
      setPageFinancial(0);
    },
    [sortFinancial],
  );

  // Change filters status
  const handleFilterFinancial = useCallback(
    (filter: IFinancialFilter) => {
      setFilterFinancial(filter);
      setPageFinancial(0);
    },
    [filterFinancial],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalFinancial = useCallback(
    () => setIsOpenFinancialFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionFinancial = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionFinancial(selection);
    },
    [selectionFinancial],
  );

  // Clear data rows
  const clearStorageFinancial = useCallback(() => {
    setRowsFinancial([]);
    setRowCountFinancial(0);
  }, [setRowsFinancial]);

  // Load data from request
  const loadStorageFinancial = useCallback(
    (response: IApiResponseWithPagination<IFinancialList>) => {
      setRowsFinancial(response.data);
      setRowCountFinancial(response.records);
    },
    [setRowsFinancial],
  );

  // API - Get all records
  const getAllFinancial = useCallback(() => {
    // Reset selection
    handleSelectionFinancial([]);
    // Add filters in params
    const params: any = getFilterParams(filterFinancial);
    // Normalize date
    if (params.start_due_date) params.start_due_date = toDateApi(params.start_due_date);
    if (params.end_due_date) params.end_due_date = toDateApi(params.end_due_date);
    // Add sort in params
    const sort = getSortParams(sortFinancial);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeFinancial;
    params.offset = pageFinancial * pageSizeFinancial;
    // Get data
    FinancialService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        clearStorageFinancial();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageFinancial(0);
        return;
      }
      loadStorageFinancial(response);
    });
  }, [headers, filterFinancial, sortFinancial, pageFinancial, pageSizeFinancial, dataFinancial]);

  // API - Get record by id
  const getByIdFinancial = useCallback(
    (id: number) => {
      // Get details
      FinancialService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataFinancial(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createFinancial = useCallback(
    (payload: IFinancialDTO) => {
      // Get details
      FinancialService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataFinancial();
        snackbarSuccess('Registro adicionado!');
        if (pageFinancial > 0) {
          handlePageFinancial(0);
          return;
        }
        getAllFinancial();
      });
    },
    [getAllFinancial],
  );

  // API - Update record by id
  const updateByIdFinancial = useCallback(
    (id: number, payload: IFinancialDTO) => {
      // Get details
      FinancialService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataFinancial();
        getAllFinancial();
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllFinancial],
  );

  // API - Delete record by id
  const deleteByIdFinancial = useCallback(
    (id: number) => {
      FinancialService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataFinancial();
        getAllFinancial();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllFinancial],
  );

  // API - Delete record by ids
  const deleteIdsFinancial = useCallback(
    (ids: number[]) => {
      FinancialService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllFinancial();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllFinancial],
  );

  return (
    <FinancialContext.Provider
      value={{
        // Data
        columnsFinancial,
        rowsFinancial,
        dataFinancial,
        defaultDataFinancial,
        handleDataFinancial,
        // Pagination
        rowCountFinancial,
        pageSizeFinancial,
        pageFinancial,
        handlePageSizeFinancial,
        handlePageFinancial,
        // Sort
        sortFinancial,
        handleSortFinancial,
        // Selection
        selectionFinancial,
        handleSelectionFinancial,
        // Filter
        defaultFilterFinancial,
        hideFilterFinancial,
        filterFinancial,
        isOpenFilterModalFinancial,
        toggleIsOpenFilterModalFinancial,
        handleFilterFinancial,
        // Api
        getAllFinancial,
        getByIdFinancial,
        createFinancial,
        updateByIdFinancial,
        deleteByIdFinancial,
        deleteIdsFinancial,
      }}
    >
      {children}
    </FinancialContext.Provider>
  );
};
