/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

type ISelectEmployeeCategoryProps = FieldProps;

const options: ISelectOptions[] = [
  { label: 'Geral', value: 'GERAL' },
  { label: 'Usuário', value: 'USUARIO' },
  { label: 'Motorista', value: 'MOTORISTA' },
  { label: 'Prestador', value: 'PRESTADOR' },
];

export const SelectEmployeeCategory: React.FC<ISelectEmployeeCategoryProps> = ({ ...rest }) => {
  return <Select label="Categoria" defaultOption="GERAL" dataOptions={options} {...rest} />;
};
