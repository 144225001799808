import { DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Attachment,
  DataDialog,
  PeopleAddress,
  PeopleContact,
  PeoplePaymentAccount,
  TabPanel,
} from '../../../shared/components';
import { useEmployee, useDialog } from '../../../shared/hooks';
import { IEmployeeDTO } from '../../../shared/types';
import { EmployeeModalTabs } from './EmployeeModalTabs';
import { EmployeeModalDataTab } from './EmployeeModalDataTab';
import { PeopleUser } from '../../../shared/components/people-user/PeopleUser';
import { toNumberOrZero } from '../../../shared/utils';
import { PeopleVehicle } from '../../../shared/components/people-vehicle/PeopleVehicle';
import { PeopleMedicalLeave } from '../../../shared/components/people-medical-leave/PeopleMedicalLeave';
import { PeopleInsurancePlan } from '../../../shared/components/people-insurance-plan/PeopleInsurancePlan';

// Get form id
const formData = 'data-dialog-form-Employee';

// Create modal data for Employee
export const EmployeeModal: React.FC = () => {
  // State for tab controls
  const [tabIndex, setTabIndex] = useState(0);

  // State for edit mode
  const [isEditMode, setIsEditMode] = useState(false);

  // Use context Employee
  const {
    dataEmployee,
    handleDataEmployee,
    getAllEmployee,
    createEmployee,
    updateByIdEmployee,
    deleteByIdEmployee,
  } = useEmployee();

  // Check if is edit mode
  useEffect(() => {
    const hasEmployee = !!(dataEmployee && dataEmployee.id > 0);
    return setIsEditMode(hasEmployee);
  }, [dataEmployee]);

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Change tab index
  const handleChangeTabIndex = (event: React.SyntheticEvent, index: number) => setTabIndex(index);

  // Reset data and close modal
  const handleClose = () => {
    getAllEmployee();
    handleDataEmployee();
    if (tabIndex > 0) setTabIndex(0);
  };

  // Save actions
  const handleSave = (payload: any) => {
    const id = dataEmployee?.id || 0;

    // Normalize payload data
    const normalizePayload = {
      ...payload,
      payment_day: toNumberOrZero(payload.payment_day),
      payment_value: toNumberOrZero(payload.payment_value),
      food_voucher: toNumberOrZero(payload.food_voucher),
    };

    const data = normalizePayload as IEmployeeDTO;
    if (payload.person_type === 'JURIDICA') {
      data.cpf = null;
      data.rg = null;
    } else {
      data.cnpj = null;
      data.ie = null;
    }
    data.employee_type = data.type;
    if (id > 0) {
      updateByIdEmployee(id, data);
      return;
    }
    createEmployee(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, name } = payload;
    dialogConfirmDanger(
      'Excluir colaborador?',
      `Deseja realmente excluir o colaborador: ${id} - ${name} `,
      () => deleteByIdEmployee(id),
    );
  };

  return (
    <DataDialog
      referenceId={dataEmployee?.id}
      referenceDisplayName="colaborador"
      initialValues={dataEmployee}
      open={dataEmployee !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
      formId={formData}
      withTabs
    >
      <EmployeeModalTabs value={tabIndex} onChange={handleChangeTabIndex} isEditMode={isEditMode} />
      <DialogContent
        dividers
        sx={{
          backgroundColor: (theme) => {
            if (tabIndex === 0) return '';
            return theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900];
          },
        }}
      >
        <TabPanel index={0} tabActive={tabIndex} forceRender>
          <EmployeeModalDataTab formId={formData} onSave={handleSave} isEditMode={isEditMode} />
        </TabPanel>

        <TabPanel index={1} tabActive={tabIndex}>
          <PeopleContact peopleType="COLABORADOR" peopleId={dataEmployee?.id || 0} />
        </TabPanel>

        <TabPanel index={2} tabActive={tabIndex}>
          <PeopleAddress peopleType="COLABORADOR" peopleId={dataEmployee?.id || 0} />
        </TabPanel>

        <TabPanel index={3} tabActive={tabIndex}>
          <PeoplePaymentAccount peopleId={dataEmployee?.id || 0} />
        </TabPanel>

        <TabPanel index={4} tabActive={tabIndex}>
          <Attachment resource="employees" resourceId={dataEmployee?.id || 0} />
        </TabPanel>

        <TabPanel index={5} tabActive={tabIndex}>
          <PeopleVehicle peopleId={dataEmployee?.id || 0} peopleType="COLABORADOR" />
        </TabPanel>

        <TabPanel index={6} tabActive={tabIndex}>
          <PeopleInsurancePlan peopleId={dataEmployee?.id || 0} peopleType="COLABORADOR" />
        </TabPanel>

        <TabPanel index={7} tabActive={tabIndex}>
          <PeopleMedicalLeave peopleId={dataEmployee?.id || 0} peopleType="COLABORADOR" />
        </TabPanel>

        <TabPanel index={8} tabActive={tabIndex}>
          <PeopleUser peopleId={dataEmployee?.id || 0} peopleType="COLABORADOR" />
        </TabPanel>
      </DialogContent>
    </DataDialog>
  );
};
