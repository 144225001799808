/* eslint-disable react/jsx-props-no-spreading */
import { Icon, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Define interface
interface IDropDownMenuItem {
  to?: string;
  icon: string;
  iconSize?: 'large' | 'medium' | 'small' | undefined;
  label?: string;
  onClick?: () => void;
}

// Crete item list for drop down
export const DropDownMenuItem: React.FC<IDropDownMenuItem> = ({
  label,
  to,
  onClick,
  icon,
  iconSize = 'small',
  children,
}) => {
  // Use hook navigate for redirect user
  const navigate = useNavigate();

  // Event click
  const handleClick = () => {
    if (to) {
      navigate(to);
    }
    onClick?.();
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <Icon fontSize={iconSize} sx={{ ml: iconSize === 'large' ? -1 : 0 }}>
          {icon}
        </Icon>
      </ListItemIcon>
      {label ? <ListItemText primary={label} /> : children}
    </MenuItem>
  );
};
