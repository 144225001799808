/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

type ISelectAddressTypeProps = FieldProps;

const options: ISelectOptions[] = [
  { label: 'Comercial', value: 'COMERCIAL' },
  { label: 'Residencial', value: 'RESIDENCIAL' },
  { label: 'Cobrança', value: 'COBRANCA' },
  { label: 'Correspondência', value: 'CORRESPONDENCIA' },
  { label: 'Outro', value: 'OUTROS' },
];

export const SelectAddressType: React.FC<ISelectAddressTypeProps> = ({ ...rest }) => {
  return <Select label="Tipo" defaultOption="COMERCIAL" dataOptions={options} {...rest} />;
};
