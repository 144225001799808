/* eslint-disable react/jsx-props-no-spreading */
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { InputSelect, SelectDialog, IInputProps } from '../../../shared/components';
import { useCustomer } from '../../../shared/hooks';
import { IDataGridColumnsHidden, IDataGridRowParams } from '../../../shared/types';
import { CustomerDataGrid } from './CustomerDataGrid';
import { CustomerFilterModal } from './CustomerFilterModal';

export interface ICustomerInputSelectBaseProps extends Omit<IInputProps, 'name' | 'onSelect'> {
  label?: string;
  title?: string;
  baseName?: string;
  enableAdd?: boolean;
}

const defaultBaseName = 'customer';

const defaultTitle = 'Cliente';

const hiddenColumnsCustomer: IDataGridColumnsHidden = {
  nickname: false,
  business_type: false,
  contract: false,
  phone: false,
  neighborhood: false,
  state: false,
  is_active: false,
};

export const CustomerInputSelectBase: React.FC<ICustomerInputSelectBaseProps> = ({
  label = defaultTitle,
  title = defaultTitle,
  baseName = defaultBaseName,
  disabled,
  enableAdd = false,
  ...rest
}) => {
  // Define name
  const [fieldId] = useState(`${baseName}_id`);
  const [fieldName] = useState(`${baseName}_name`);

  // State for control opened search dialog
  const [isOpen, setIsOpen] = useState(false);

  // Use hook formik context
  const { setFieldValue } = useFormikContext();

  // Use context
  const {
    toggleIsOpenFilterModalCustomer,
    handleFilterCustomer,
    defaultDataCustomer,
    handleDataCustomer,
  } = useCustomer();

  // Open search dialog
  const handleOpen = () => {
    if (disabled) return;
    setIsOpen(true);
  };

  // Close search modal
  const handleClose = () => setIsOpen(false);

  // On click in add button
  const handleAdd = () => handleDataCustomer(defaultDataCustomer);

  // On click in filter button
  const handleResetFilter = () => handleFilterCustomer({});

  // Apply filters
  const handleApplyFilterName = (filter: any) => handleFilterCustomer({ name: filter });

  // On select record
  const handleSelect = (data: IDataGridRowParams) => {
    setFieldValue(fieldId, data.row.id);
    setFieldValue(fieldName, data.row.name);
    handleClose();
  };

  return (
    <>
      <CustomerFilterModal />

      <SelectDialog
        title={title}
        open={isOpen}
        onClose={handleClose}
        applyFilter={handleApplyFilterName}
        showFilter={toggleIsOpenFilterModalCustomer}
        resetFilter={handleResetFilter}
        add={enableAdd ? handleAdd : undefined}
      >
        <CustomerDataGrid
          simpleMode
          onRowClick={handleSelect}
          columnVisibilityModel={hiddenColumnsCustomer}
        />
      </SelectDialog>

      <InputSelect
        label={label}
        fieldId={fieldId}
        fieldName={fieldName}
        onClick={handleOpen}
        disabled={disabled}
        {...rest}
      />
    </>
  );
};
