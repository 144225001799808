import { createContext, useCallback, useState } from 'react';
import { useSnackbarDispatch } from '../hooks/useSnackbarDispatch';
import { useSetHeaders } from '../hooks/useSetHeaders';
import { ApiError, DeliveryService } from '../services';
import {
  IDeliveryData,
  IDeliveryFilter,
  IApiResponseWithPagination,
  IDataGridColumns,
  IDataGridSortOptions,
  IDeliveryDTO,
  IDataGridSelection,
  IDeliveryList,
} from '../types';
import { formatterDateBR, getFilterParams, toDate, toDateApi, toObjectWithoutNull } from '../utils';
import { getSortParams } from '../utils/getSortParams';

// Define interface for context
interface IDeliveryContext {
  // Data
  columnsDelivery: IDataGridColumns;
  rowsDelivery: IDeliveryList[];
  defaultDataDelivery: IDeliveryData;
  dataDelivery: IDeliveryData | undefined;
  handleDataDelivery: (data?: IDeliveryData) => void;
  // Pagination
  rowCountDelivery: number;
  pageSizeDelivery: number;
  pageDelivery: number;
  handlePageSizeDelivery: (size: number) => void;
  handlePageDelivery: (page: number) => void;
  // Sort
  sortDelivery: IDataGridSortOptions;
  handleSortDelivery: (sort: IDataGridSortOptions) => void;
  // Selection
  selectionDelivery: IDataGridSelection;
  handleSelectionDelivery: (selection: IDataGridSelection) => void;
  // Filter
  defaultFilterDelivery: IDeliveryFilter;
  hideFilterDelivery: string[];
  filterDelivery: IDeliveryFilter;
  isOpenFilterModalDelivery: boolean;
  toggleIsOpenFilterModalDelivery: () => void;
  handleFilterDelivery: (filter: IDeliveryFilter) => void;
  // Api
  getAllDelivery: () => void;
  getByIdDelivery: (id: number) => void;
  createDelivery: (payload: IDeliveryDTO) => void;
  updateByIdDelivery: (id: number, payload: IDeliveryDTO) => void;
  deleteByIdDelivery: (id: number) => void;
  deleteIdsDelivery: (ids: number[]) => void;
}

// Define columns schema
const columnsSchemaDelivery: IDataGridColumns = [
  {
    field: 'id',
    headerName: 'Código',
    description: 'Código do delivery',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
  {
    field: 'date',
    headerName: 'Data',
    description: 'Data do delivery',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => formatterDateBR(params.row.date),
  },
  {
    field: 'start_date',
    headerName: 'Início',
    hideable: false,
  },
  {
    field: 'end_date',
    headerName: 'fim',
    hideable: false,
  },
  {
    field: 'customer_id',
    headerName: 'Cliente',
    hideable: false,
  },
  {
    field: 'customer_name',
    headerName: 'Cliente',
    description: 'Tomador da viagem',
    flex: 1,
  },
  {
    field: 'driver_id',
    headerName: 'Motorista',
    hideable: false,
  },
  {
    field: 'driver_name',
    headerName: 'Motorista',
    description: 'Motorista que realizou o serviço',
    flex: 1,
  },
  {
    field: 'quantity_deliveries',
    headerName: 'Entregas',
    description: 'Total de entregas realizadas',
    headerAlign: 'center',
    align: 'center',
  },
];

// Initial values for filter
const defaultFilterDelivery: IDeliveryFilter = {
  start_date: null,
  end_date: null,
  customer_id: 0,
  customer_name: '',
  driver_id: 0,
  driver_name: '',
};

// Omit filter on list
const defaultHideFilterDelivery = ['customer_name', 'driver_name'];

// initial values for data
const defaultDataDelivery: IDeliveryData = {
  id: 0,
  date: new Date(),
  customer_id: 0,
  customer_name: '',
  driver_id: 0,
  driver_name: '',
  quantity_deliveries: 0,
};

// Create context
export const DeliveryContext = createContext<IDeliveryContext>({} as IDeliveryContext);

// Create context provider
export const DeliveryContextProvider: React.FC = ({ children }) => {
  // Columns state
  const [columnsDelivery] = useState<IDataGridColumns>(columnsSchemaDelivery);

  // Rows state
  const [rowsDelivery, setRowsDelivery] = useState<IDeliveryList[]>([]);

  // Data state
  const [dataDelivery, setDataDelivery] = useState<IDeliveryData | undefined>();

  // Sort state
  const [sortDelivery, setSortDelivery] = useState<IDataGridSortOptions>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  // Filter state
  const [filterDelivery, setFilterDelivery] = useState<IDeliveryFilter>(defaultFilterDelivery);

  // Hide Filter state
  const [hideFilterDelivery] = useState(defaultHideFilterDelivery);

  // Modal filters
  const [isOpenFilterModalDelivery, setIsOpenDeliveryFilterModal] = useState(false);

  // Page state
  const [pageDelivery, setPageDelivery] = useState(0);

  // Page size state
  const [pageSizeDelivery, setPageSizeDelivery] = useState(10);

  // Record count state
  const [rowCountDelivery, setRowCountDelivery] = useState(0);

  // Selection state
  const [selectionDelivery, setSelectionDelivery] = useState<IDataGridSelection>();

  // Get custom headers (auth)
  const headers = useSetHeaders();

  // Use snacks dispatch
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set data
  const handleDataDelivery = useCallback(
    (data?: IDeliveryData) => {
      if (!data) return setDataDelivery(data);
      const dataWithoutNull = toObjectWithoutNull<IDeliveryData>(data, defaultDataDelivery);
      dataWithoutNull.date = toDate(dataWithoutNull.date);
      return setDataDelivery(dataWithoutNull);
    },
    [dataDelivery],
  );

  // Change page status
  const handlePageDelivery = useCallback((page: number) => setPageDelivery(page), [pageDelivery]);

  // Change page size status
  const handlePageSizeDelivery = useCallback(
    (size: number) => {
      setPageSizeDelivery(size);
      setPageDelivery(0);
    },
    [pageSizeDelivery],
  );

  // Change sort status
  const handleSortDelivery = useCallback(
    (sort: IDataGridSortOptions) => {
      setSortDelivery(sort);
      setPageDelivery(0);
    },
    [sortDelivery],
  );

  // Change filters status
  const handleFilterDelivery = useCallback(
    (filter: IDeliveryFilter) => {
      setFilterDelivery(filter);
      setPageDelivery(0);
    },
    [filterDelivery],
  );

  // Open/Close modal filters
  const toggleIsOpenFilterModalDelivery = useCallback(
    () => setIsOpenDeliveryFilterModal((prevState) => !prevState),
    [],
  );

  // Change selection status
  const handleSelectionDelivery = useCallback(
    (selection: IDataGridSelection) => {
      setSelectionDelivery(selection);
    },
    [selectionDelivery],
  );

  // Clear data rows
  const clearStorageDelivery = useCallback(() => {
    setRowsDelivery([]);
    setRowCountDelivery(0);
  }, [setRowsDelivery]);

  // Load data from request
  const loadStorageDelivery = useCallback(
    (response: IApiResponseWithPagination<IDeliveryList>) => {
      setRowsDelivery(response.data);
      setRowCountDelivery(response.records);
    },
    [setRowsDelivery],
  );

  // API - Get all records
  const getAllDelivery = useCallback(() => {
    // Reset selection
    handleSelectionDelivery([]);
    // Add filters in params
    const params: any = getFilterParams(filterDelivery);
    // Normalize date
    if (params.start_date) params.start_date = toDateApi(params.start_date);
    if (params.end_date) params.end_date = toDateApi(params.end_date);
    // Add sort in params
    const sort = getSortParams(sortDelivery);
    if (sort !== '') params.sort = sort;
    // Add pagination in params
    params.limit = pageSizeDelivery;
    params.offset = pageDelivery * pageSizeDelivery;
    // Get data
    DeliveryService.getAll(headers, params).then((response) => {
      if (response instanceof ApiError) {
        clearStorageDelivery();
        snackbarError(response.message);
        return;
      }
      if (response.data.length === 0 && response.records > 0 && params.offset > 0) {
        handlePageDelivery(0);
        return;
      }
      loadStorageDelivery(response);
    });
  }, [headers, filterDelivery, sortDelivery, pageDelivery, pageSizeDelivery, dataDelivery]);

  // API - Get record by id
  const getByIdDelivery = useCallback(
    (id: number) => {
      // Get details
      DeliveryService.getById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataDelivery(response);
      });
    },
    [headers],
  );

  // API - Create record
  const createDelivery = useCallback(
    (payload: IDeliveryDTO) => {
      // Get details
      DeliveryService.create(headers, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataDelivery();
        snackbarSuccess('Registro adicionado!');
        if (pageDelivery > 0) {
          handlePageDelivery(0);
          return;
        }
        getAllDelivery();
      });
    },
    [getAllDelivery],
  );

  // API - Update record by id
  const updateByIdDelivery = useCallback(
    (id: number, payload: IDeliveryDTO) => {
      // Get details
      DeliveryService.updateById(headers, id, payload).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataDelivery();
        getAllDelivery();
        snackbarSuccess('Registro atualizado!');
      });
    },
    [getAllDelivery],
  );

  // API - Delete record by id
  const deleteByIdDelivery = useCallback(
    (id: number) => {
      DeliveryService.deleteById(headers, id).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        handleDataDelivery();
        getAllDelivery();
        snackbarSuccess('Registro excluído!');
      });
    },
    [getAllDelivery],
  );

  // API - Delete record by ids
  const deleteIdsDelivery = useCallback(
    (ids: number[]) => {
      DeliveryService.deleteIds(headers, ids).then((response) => {
        if (response instanceof ApiError) {
          snackbarError(response.message);
          return;
        }
        getAllDelivery();
        snackbarSuccess(`${ids.length} registro(s) excluído(s)!`);
      });
    },
    [getAllDelivery],
  );

  return (
    <DeliveryContext.Provider
      value={{
        // Data
        columnsDelivery,
        rowsDelivery,
        dataDelivery,
        defaultDataDelivery,
        handleDataDelivery,
        // Pagination
        rowCountDelivery,
        pageSizeDelivery,
        pageDelivery,
        handlePageSizeDelivery,
        handlePageDelivery,
        // Sort
        sortDelivery,
        handleSortDelivery,
        // Selection
        selectionDelivery,
        handleSelectionDelivery,
        // Filter
        defaultFilterDelivery,
        hideFilterDelivery,
        filterDelivery,
        isOpenFilterModalDelivery,
        toggleIsOpenFilterModalDelivery,
        handleFilterDelivery,
        // Api
        getAllDelivery,
        getByIdDelivery,
        createDelivery,
        updateByIdDelivery,
        deleteByIdDelivery,
        deleteIdsDelivery,
      }}
    >
      {children}
    </DeliveryContext.Provider>
  );
};
