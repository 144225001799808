import { useCallback, useEffect, useState } from 'react';
import { DataGridFilters, DataGridSelection, FloatingButton } from '../../../shared/components';
import { useEmployee, useDeviceIsMobile, useDialog } from '../../../shared/hooks';
import { IEmployeeFilter, IDataGridColumnsHidden, IDataGridRowParams } from '../../../shared/types';
import { EmployeeDataGrid } from './EmployeeDataGrid';
import { EmployeeFilterModal } from './EmployeeFilterModal';
import { EmployeeModal } from './EmployeeModal';

interface IEmployeeMainProps {
  embedded?: boolean;
  initialFilter?: Partial<IEmployeeFilter>;
}

const defaultColumnsVisibilityModel: IDataGridColumnsHidden = {
  nickname: false,
  payment_type: false,
};

const defaultColumnsOnMobileVisibilityModel: IDataGridColumnsHidden = {
  nickname: false,
  category: false,
  type: false,
  payment_type: false,
  phone: false,
  contact: false,
  document: false,
};

export const EmployeeMain: React.FC<IEmployeeMainProps> = ({ embedded, initialFilter }) => {
  const [checkboxSelectionMode, setCheckboxSelectionMode] = useState(false);

  const [hasChangedVisibilityModel, setHasChangedVisibilityModel] = useState(false);

  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<IDataGridColumnsHidden>(
    defaultColumnsVisibilityModel,
  );

  const {
    columnsEmployee,
    rowsEmployee,
    defaultDataEmployee,
    handleDataEmployee,
    selectionEmployee,
    handleSelectionEmployee,
    filterEmployee,
    handleFilterEmployee,
    getByIdEmployee,
    deleteIdsEmployee,
  } = useEmployee();

  const { dialogConfirmDanger } = useDialog();

  const deviceIsMobile = useDeviceIsMobile();

  useEffect(() => {
    if (initialFilter) handleFilterEmployee(initialFilter);
  }, []);

  useEffect(() => setCheckboxSelectionMode(false), [rowsEmployee]);

  useEffect(() => {
    if (hasChangedVisibilityModel) return;
    const columns = deviceIsMobile
      ? defaultColumnsOnMobileVisibilityModel
      : defaultColumnsVisibilityModel;
    setColumnsVisibilityModel(columns);
  }, [hasChangedVisibilityModel, deviceIsMobile]);

  const handleColumnsVisibilityModelChange = useCallback(
    (visibility: IDataGridColumnsHidden) => {
      if (!hasChangedVisibilityModel) setHasChangedVisibilityModel(true);
      setColumnsVisibilityModel(visibility);
    },
    [hasChangedVisibilityModel, setColumnsVisibilityModel],
  );

  const handleRemoveFilter = (filter: string) => {
    if (filter === '*') {
      handleFilterEmployee({});
      return;
    }
    handleFilterEmployee({ ...filterEmployee, [filter]: '' });
  };

  const handleToggleCheckboxSelectionMode = (state?: boolean) =>
    setCheckboxSelectionMode((prevState) => state || !prevState);

  const handleAddData = () => handleDataEmployee(defaultDataEmployee);

  const handleEditData = (row: IDataGridRowParams) => {
    const { id } = row;
    getByIdEmployee(+id);
  };

  const handleDeleteData = () => {
    if (!selectionEmployee || selectionEmployee.length === 0) return;
    dialogConfirmDanger(
      'Excluir selecionados',
      'Deseja realmente excluir os registros selecionados?',
      () => {
        deleteIdsEmployee(selectionEmployee as number[]);
      },
    );
  };

  return (
    <>
      <EmployeeFilterModal />
      <EmployeeModal />

      {!embedded && (
        <DataGridFilters
          columns={columnsEmployee}
          filters={filterEmployee}
          onRemoveFilter={handleRemoveFilter}
        />
      )}

      <EmployeeDataGrid
        selectionModel={selectionEmployee}
        onSelectionModelChange={handleSelectionEmployee}
        checkboxSelection={checkboxSelectionMode}
        onCheckboxSelectionModeChange={handleToggleCheckboxSelectionMode}
        onRowClick={handleEditData}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={handleColumnsVisibilityModelChange}
      />

      {!embedded && (
        <FloatingButton
          label="Adicionar"
          tooltip="Adicionar registro"
          icon="add"
          onClick={handleAddData}
          hidden={checkboxSelectionMode}
        />
      )}

      <DataGridSelection
        selectionMode={checkboxSelectionMode}
        rowSelection={selectionEmployee}
        onSelectionModeChange={handleToggleCheckboxSelectionMode}
        singleAction
      >
        <FloatingButton
          color="error"
          variant="extended"
          label="Excluir selecionados"
          icon="delete"
          onClick={handleDeleteData}
        >
          {`Excluir ${selectionEmployee?.length} registro(s)`}
        </FloatingButton>
      </DataGridSelection>
    </>
  );
};
