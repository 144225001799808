import { DialogActions, DialogContent, DialogContentText, InputAdornment } from '@mui/material';
import { Refresh, Email } from '@mui/icons-material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { DialogActionButton, Dialog, Form, Input } from '../../../shared/components';
import { AuthService, ApiError } from '../../../shared/services';
import { useDialog, useSnackbarDispatch } from '../../../shared/hooks';
import { IAuthRecoverPasswordPayload } from '../../../shared/types';
import { useRecoverPasswordModalStore } from '../../../shared/store';

// Modal for recover password
export const LoginRecoverPasswordModal: React.FC = () => {
  // Use store hook
  const { isOpen, close } = useRecoverPasswordModalStore();

  // Use hook dialog
  const { dialogConfirmGrant } = useDialog();

  // Use hook snackbar
  const { snackbarError, snackbarSuccess } = useSnackbarDispatch();

  // Set validation schema
  const schemaRecoverPasswordPayload = Yup.object({
    username: Yup.string().email('Informe um e-mail válido').required('O e-mail é obrigatório'),
  });

  // Set initial data
  const defaultRecoverPasswordPayload: IAuthRecoverPasswordPayload = { username: '' };

  // Send request
  const handleRecoverPassword = ({ username }: IAuthRecoverPasswordPayload) => {
    dialogConfirmGrant(
      'Confirme o e-mail',
      `Por favor, confirme se o e-mail digitado está correto: ${username}`,
      () => {
        AuthService.recoverPassword(username).then((result) => {
          if (result instanceof ApiError) {
            snackbarError(result.message);
          } else {
            snackbarSuccess('Enviamos um e-mail contendo o procedimento para redefinir sua senha');
            close();
          }
        });
      },
    );
  };

  return (
    <Dialog title="Esqueceu sua senha?" open={isOpen} close={close}>
      <DialogContent dividers>
        <DialogContentText>
          Informe seu e-mail, se ele existir em nossa base de dados, as instruções para recuperação
          de senha serão enviadas. Lembre-se de verificar sua lixeira ou caixa de Spam caso não
          tiver recebido.
        </DialogContentText>

        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={defaultRecoverPasswordPayload}
          validationSchema={schemaRecoverPasswordPayload}
          onSubmit={(values) => {
            handleRecoverPassword(values);
          }}
        >
          {({ handleSubmit }) => (
            <Form id="form-recover-password" onSubmit={handleSubmit}>
              <Input
                autoFocus
                label="E-mail"
                name="username"
                type="email"
                placeholder="Digite o e-mail cadastrado"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>

      <DialogActions>
        <DialogActionButton
          label="Recuperar senha"
          type="submit"
          primaryAction
          form="form-recover-password"
          startIcon={<Refresh />}
        />
      </DialogActions>
    </Dialog>
  );
};
