import { useVehicleUsage } from '../../shared/hooks';
import { PageContainer, PageTitle, PageMain } from '../../shared/layouts';
import { VehicleUsageMain } from './components/VehicleUsageMain';

export const VehicleUsagePage: React.FC = () => {
  const { toggleIsOpenFilterModalVehicleUsage } = useVehicleUsage();

  return (
    <PageContainer>
      <PageTitle
        title="Uso de veículo"
        subtitle="Registro de uso de veículo"
        filterAction={toggleIsOpenFilterModalVehicleUsage}
      />

      <PageMain>
        <VehicleUsageMain />
      </PageMain>
    </PageContainer>
  );
};
