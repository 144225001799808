/* eslint-disable react/jsx-props-no-spreading */
import {
  Dialog as MuiDialog,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDeviceIsMobile } from '../../hooks/useDeviceIsMobile';
import { StyleClassType } from '../../types';

// Define interface of props
export interface IDialogProps extends DialogProps {
  title: string;
  open: boolean;
  classType?: StyleClassType;
  dividers?: boolean;
  close: () => void;
}

// Create component custom dialog
export const Dialog: React.FC<IDialogProps> = ({
  title,
  classType = 'default',
  open,
  dividers,
  close,
  children,
  ...rest
}) => {
  // use hook for get device type
  const isMobile = useDeviceIsMobile();

  // use hook for get theme params
  const theme = useTheme();

  // Define custom color for type class
  const colors = {
    font: classType === 'default' ? theme.palette.text.secondary : '#fff',
    background: classType === 'default' ? null : `${classType}.main`,
    icon: classType === 'default' ? theme.palette.grey[500] : '#fff',
  };

  return (
    <MuiDialog
      open={open}
      fullWidth
      fullScreen={isMobile}
      disableEscapeKeyDown
      scroll="paper"
      {...rest}
    >
      <DialogTitle
        sx={{
          bgcolor: colors.background,
          color: colors.font,
        }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: 'absolute',
            right: theme.spacing(2),
            top: '12px',
            color: colors.icon,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {dividers && <Divider />}
      {children}
    </MuiDialog>
  );
};
