/* eslint-disable react/jsx-props-no-spreading */
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { InputSelect, SelectDialog, IInputProps } from '../../../shared/components';
import { useContract } from '../../../shared/hooks';
import { IDataGridColumnsHidden, IDataGridRowParams } from '../../../shared/types';
import { ContractDataGrid } from './ContractDataGrid';
import { ContractFilterModal } from './ContractFilterModal';
import { ContractModal } from './ContractModal';

interface IContractInputSelectProps extends Omit<IInputProps, 'name' | 'onSelect'> {
  label?: string;
  title?: string;
  baseName?: string;
  onSelect?: (contract: number) => void;
}

const defaultBaseName = 'contract';

const defaultTitle = 'Contrato';

const hiddenColumnsContract: IDataGridColumnsHidden = {
  is_active: false,
};

export const ContractInputSelect: React.FC<IContractInputSelectProps> = ({
  label = defaultTitle,
  title = defaultTitle,
  baseName = defaultBaseName,
  onSelect,
  disabled,
  ...rest
}) => {
  // Define name
  const [fieldId] = useState(`${baseName}_id`);
  const [fieldName] = useState(`${baseName}_name`);

  // State for control opened search dialog
  const [isOpen, setIsOpen] = useState(false);

  // Use hook formik context
  const { setFieldValue } = useFormikContext();

  // Use context
  const {
    toggleIsOpenFilterModalContract,
    handleFilterContract,
    defaultDataContract,
    handleDataContract,
  } = useContract();

  // Open search dialog
  const handleOpen = () => {
    if (disabled) return;
    setIsOpen(true);
  };

  // Close search modal
  const handleClose = () => setIsOpen(false);

  // On click in add button
  const handleAdd = () => handleDataContract(defaultDataContract);

  // On click in filter button
  const handleResetFilter = () => handleFilterContract({});

  // Apply filters
  const handleApplyFilterName = (filter: any) => handleFilterContract({ name: filter });

  // On select record
  const handleSelect = (data: IDataGridRowParams) => {
    setFieldValue(fieldId, data.row.id);
    setFieldValue(fieldName, data.row.name);
    if (onSelect) onSelect(data.row.id);
    handleClose();
  };

  return (
    <>
      <ContractModal />
      <ContractFilterModal />

      <SelectDialog
        title={title}
        open={isOpen}
        onClose={handleClose}
        applyFilter={handleApplyFilterName}
        showFilter={toggleIsOpenFilterModalContract}
        resetFilter={handleResetFilter}
        add={handleAdd}
      >
        <ContractDataGrid
          simpleMode
          onRowClick={handleSelect}
          columnVisibilityModel={hiddenColumnsContract}
        />
      </SelectDialog>

      <InputSelect
        label={label}
        fieldId={fieldId}
        fieldName={fieldName}
        onClick={handleOpen}
        disabled={disabled}
        disabledClear
        {...rest}
      />
    </>
  );
};
