import { Icon, useTheme } from '@mui/material';

interface IDisplayColumWithIconProps {
  icon: string;
  cell: any;
  color?: any;
}

export const DisplayColumWithIcon: React.FC<IDisplayColumWithIconProps> = ({
  icon,
  cell,
  color,
}) => {
  const theme = useTheme();

  return (
    <>
      <Icon style={{ marginRight: theme.spacing(1), color: color || theme.palette.text.secondary }}>
        {icon}
      </Icon>
      {cell}
    </>
  );
};
