/* eslint-disable @typescript-eslint/naming-convention */
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { DialogContent, Grid } from '@mui/material';
import { Checkbox, Form, SelectAddressType } from '../inputs';
import { DataDialog } from '../data-dialog/DataDialog';
import { usePeopleAddress, useDialog } from '../../hooks';
import { IPeopleAddressDTO, IPeopleType } from '../../types';
import { schemaValidation } from '../../utils';
import { InputGroupAddress } from '../address';

// Define validation fields form
const schemaPayloadPeopleAddress = Yup.object({
  tag: schemaValidation.commons.string(false),
  postal_code: schemaValidation.address.postalCode,
  street_name: schemaValidation.address.streetName,
  street_number: schemaValidation.address.streetNumber,
  complement: schemaValidation.address.complement,
  neighborhood: schemaValidation.address.neighborhood,
  city: schemaValidation.address.city,
  state: schemaValidation.address.state,
  reference: schemaValidation.address.reference,
});

interface IPeopleContractModalProps {
  peopleType: IPeopleType;
  peopleId: number;
}

// Create modal data for PeopleAddress
export const PeopleAddressModal: React.FC<IPeopleContractModalProps> = ({
  peopleType,
  peopleId,
}) => {
  // Use context PeopleAddress
  const {
    dataPeopleAddress,
    defaultDataPeopleAddress,
    handleDataPeopleAddress,
    createPeopleAddress,
    updateByIdPeopleAddress,
    deleteByIdPeopleAddress,
  } = usePeopleAddress();

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Reset data and close modal
  const handleClose = () => handleDataPeopleAddress();

  // Save actions
  const handleSave = (payload: object) => {
    const id = dataPeopleAddress?.id || 0;
    const data = payload as IPeopleAddressDTO;
    if (id > 0) {
      updateByIdPeopleAddress(peopleType, peopleId, id, data);
      return;
    }
    createPeopleAddress(peopleType, peopleId, data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, street_name } = payload;
    dialogConfirmDanger(
      'Excluir endereço?',
      `Deseja realmente excluir o endereço: ${street_name}`,
      () => deleteByIdPeopleAddress(peopleType, peopleId, id),
    );
  };

  // Define form id
  const formData = 'data-dialog-form-people-address';

  return (
    <DataDialog
      referenceId={dataPeopleAddress?.id}
      referenceDisplayName="endereço"
      initialValues={dataPeopleAddress || defaultDataPeopleAddress}
      validationSchema={schemaPayloadPeopleAddress}
      open={dataPeopleAddress !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
      formId={formData}
      withTabs
    >
      <DialogContent dividers>
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={schemaPayloadPeopleAddress}
          initialValues={dataPeopleAddress || defaultDataPeopleAddress}
          enableReinitialize
          onSubmit={(values) => {
            handleSave(values);
          }}
        >
          {({ handleSubmit, handleReset, setFieldValue }) => (
            <Form
              id={formData}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
              onReset={handleReset}
            >
              <Grid container columnSpacing={2}>
                <input type="hidden" name="id" />
                <Grid item xs={12} md={12}>
                  <Field name="tag" component={SelectAddressType} />
                </Grid>
                <InputGroupAddress
                  setFieldValue={setFieldValue}
                  gridItemsProps={{
                    postalCode: { md: 4, xl: 4 },
                    streetName: { md: 8, xl: 8 },
                    city: { md: 9, xl: 9 },
                    state: { md: 3, xl: 3 },
                  }}
                />
                <Grid item xs={12} md={12}>
                  <Checkbox name="is_default" label="Marcar como endereço padrão" />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </DataDialog>
  );
};
