import * as Yup from 'yup';
import { useValidationsBR } from 'validations-br';

export const schemaValidation = {
  commons: {
    name: Yup.string()
      .trim()
      .required('O campo é obrigatório')
      .max(60, 'Não pode conter mais de 60 caracteres'),
    description: Yup.string().trim().max(255, 'Não pode conter mais de 255 caracteres'),
    string: (required?: boolean) => {
      const schema = Yup.string().trim();
      if (required) return schema.required('O campo é obrigatório');
      return schema;
    },
  },

  people: {
    name: Yup.string()
      .trim()
      .required('O campo é obrigatório')
      .max(60, 'Não pode conter mais de 60 caracteres'),
    corporate_name: Yup.string()
      .trim()
      .required('O campo é obrigatória')
      .max(255, 'Não pode conter mais de 255 caracteres'),
  },

  vehicles: {
    plate: (required?: boolean) => {
      const schema = Yup.string().trim().length(8, 'A placa deve conter 7 caracteres (XXX-XXXX)');
      if (required)
        return schema
          .required('A placa é obrigatória')
          .matches(/^[A-Z]{3}-?[0-9][0-9A-Z][0-9]{2}/, 'A placa não é válida');
      return schema;
    },
  },

  documents: {
    cnpj: Yup.string()
      .required('O CNPJ é obrigatório')
      .length(18, 'O CNPJ deve conter 14 dígitos (XX.XXX.XXX/XXXX-XX)')
      .test('is-cnpj', 'O CNPJ não é válido', (value) => useValidationsBR('cnpj', value || '')),
    cpf: Yup.string()
      .required('O CPF é obrigatório')
      .length(14, 'O CPF deve conter 11 dígitos (XXX.XXX.XXX-XX)')
      .test('is-cpf', 'O CPF não é válido', (value) => useValidationsBR('cpf', value || '')),
  },

  contact: {
    name: (required?: boolean) => {
      const schema = Yup.string().trim().max(60, 'Não pode conter mais de 60 caracteres');
      if (required) return schema.required('O responsável é obrigatório');
      return schema;
    },
    email: (required?: boolean) => {
      const schema = Yup.string().email('informe um e-mail válido');
      if (required) return schema.required('A e-mail é obrigatório');
      return schema;
    },
    phone: (required?: boolean) => {
      const schema = Yup.string().test('is-phone', 'Informe um telefone válido', (value) => {
        const phoneNumber = value || '';
        if (phoneNumber === '' && !required) return true;
        return useValidationsBR('phone', phoneNumber);
      });
      if (required) return schema.required('O telefone é obrigatório');
      return schema.nullable();
    },
  },

  address: {
    postalCode: Yup.string()
      .trim()
      .required('O CEP é obrigatório')
      .length(9, 'O CEP deve conter 8 dígitos (XXXXX-XXX)')
      .test('is-cep', 'Informe um CEP válido', (value) => useValidationsBR('cep', value || '')),
    streetName: Yup.string()
      .trim()
      .required('O logradouro é obrigatório')
      .max(500, 'O logradouro não pode conter mais de 500 caracteres'),
    streetNumber: Yup.string()
      .trim()
      .required('O número do endereço é obrigatório')
      .max(40, 'O número não pode conter mais de 40 caracteres'),
    complement: Yup.string().trim().nullable(),
    neighborhood: Yup.string().trim().required('O bairro é obrigatório'),
    city: Yup.string().trim().required('A cidade é obrigatória'),
    state: Yup.string()
      .trim()
      .required('O estado é obrigatório')
      .length(2, 'O estado deve conter apenas 2 caracteres'),
    reference: Yup.string().nullable(),
  },

  dateTime: {
    date: (required?: boolean) => {
      const schema = Yup.date().typeError('Informe uma data válida. (dd/mm/yyyy)');
      if (!required) return schema.nullable();
      return schema;
    },
    timestamp: (required?: boolean) => {
      const schema = Yup.date().typeError('Informe uma data e hora válida. (dd/mm/yyyy hh:mm)');
      if (!required) return schema.nullable();
      return schema;
    },
    timeZone: Yup.number()
      .required('Informe o fuso horário')
      .min(1, 'Informe um fuso horário válido')
      .max(4, 'Informe um fuso horário válido'),
  },

  status: {
    active: Yup.bool().default(true),
  },

  number: {
    float: Yup.string()
      .trim()
      .default('0')
      .required('Campo obrigatório')
      .test(
        'is-float',
        'Informe um valor válido',
        (value) => !Number.isNaN(Number(String(value).replace('.', '').replace(',', '.').trim())),
      ),
    integer: Yup.string()
      .trim()
      .default('0')
      .required('Campo obrigatório')
      .test('is-integer', 'Informe um valor válido', (value) => {
        const number = Number(String(value).replace('.', '').replace(',', '.').trim());
        if (Number.isNaN(number)) return false;
        return number % 1 === 0;
      }),
  },
};
