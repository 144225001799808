/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useFinancial } from '../../../shared/hooks';

type IFinancialDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const FinancialDataGrid: React.FC<IFinancialDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsFinancial,
    rowsFinancial,
    rowCountFinancial,
    pageFinancial,
    handlePageFinancial,
    pageSizeFinancial,
    handlePageSizeFinancial,
    sortFinancial,
    filterFinancial,
    handleFilterFinancial,
    handleSortFinancial,
    getAllFinancial,
  } = useFinancial();

  useEffect(() => handleFilterFinancial({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllFinancial();
  }, [canSearch, sortFinancial, filterFinancial, pageFinancial, pageSizeFinancial]);

  return (
    <DataGrid
      rows={rowsFinancial}
      rowCount={rowCountFinancial}
      columns={columnsFinancial}
      onSortModelChange={handleSortFinancial}
      sortModel={sortFinancial}
      pageSize={pageSizeFinancial}
      onPageSizeChange={handlePageSizeFinancial}
      page={pageFinancial}
      onPageChange={handlePageFinancial}
      {...rest}
    />
  );
};
