import { Input, FilterDialog } from '../../../shared/components';
import { useContract } from '../../../shared/hooks';

// Create modal filter for contracts
export const ContractFilterModal: React.FC = () => {
  // Use context contracts
  const {
    isOpenFilterModalContract,
    toggleIsOpenFilterModalContract,
    handleFilterContract,
    filterContract,
    defaultFilterContract,
  } = useContract();

  return (
    <FilterDialog
      isOpen={isOpenFilterModalContract}
      onClose={toggleIsOpenFilterModalContract}
      setFilter={handleFilterContract}
      filter={filterContract}
      initialValues={defaultFilterContract}
    >
      <Input autoFocus label="Código" name="id" type="number" placeholder="Código do contrato" />
      <Input autoFocus label="Nome" name="name" type="text" placeholder="Nome do contrato" />
    </FilterDialog>
  );
};
