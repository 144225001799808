/* eslint-disable react/jsx-props-no-spreading */
import { FieldProps } from 'formik';
import { ISelectOptions, Select } from './Select';

type ISelectVehicleSupplyFuelTypeProps = FieldProps;

const options: ISelectOptions[] = [
  { label: 'Gasolina', value: 'GASOLINA' },
  { label: 'Etanol', value: 'ETANOL' },
  { label: 'Diesel', value: 'DIESEL' },
  { label: 'GNV', value: 'GNV' },
];

export const SelectVehicleSupplyFuelType: React.FC<ISelectVehicleSupplyFuelTypeProps> = ({
  ...rest
}) => {
  return <Select label="Combustível" defaultOption="GASOLINA" dataOptions={options} {...rest} />;
};
