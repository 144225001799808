/* eslint-disable react/jsx-props-no-spreading */
import { Tooltip, TooltipProps } from '@mui/material';

interface ITooltipWrapperProps extends Omit<TooltipProps, 'title'> {
  title?: string;
}

// Create component tooltip wrapper
export const TooltipWrapper: React.FC<ITooltipWrapperProps> = ({ title, children, ...rest }) => {
  return title ? (
    <Tooltip title={title} arrow {...rest}>
      {children}
    </Tooltip>
  ) : (
    <span>{children}</span>
  );
};
