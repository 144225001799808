import { DialogContent, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataDialog, PeopleAddress, PeopleContact, TabPanel } from '../../../shared/components';
import { useCustomer, useDialog } from '../../../shared/hooks';
import { ICustomerDTO } from '../../../shared/types';
import { CustomerModalTabs } from './CustomerModalTabs';
import { CustomerModalDataTab } from './CustomerModalDataTab';
import { PeopleUser } from '../../../shared/components/people-user/PeopleUser';

// Get form id
const formData = 'data-dialog-form-customer';

// Create modal data for Customer
export const CustomerModal: React.FC = () => {
  // State for tab controls
  const [tabIndex, setTabIndex] = useState(0);

  // State for edit mode
  const [isEditMode, setIsEditMode] = useState(false);

  // Use context Customer
  const {
    dataCustomer,
    handleDataCustomer,
    getAllCustomer,
    createCustomer,
    updateByIdCustomer,
    deleteByIdCustomer,
  } = useCustomer();

  // Check if is edit mode
  useEffect(() => {
    const hasCustomer = !!(dataCustomer && dataCustomer.id > 0);
    return setIsEditMode(hasCustomer);
  }, [dataCustomer]);

  // Use hook dialog
  const { dialogConfirmDanger } = useDialog();

  // Use theme
  const theme = useTheme();

  // Change tab index
  const handleChangeTabIndex = (event: React.SyntheticEvent, index: number) => setTabIndex(index);

  // Reset data and close modal
  const handleClose = () => {
    getAllCustomer();
    handleDataCustomer();
    if (tabIndex > 0) setTabIndex(0);
  };

  // Save actions
  const handleSave = (payload: any) => {
    const id = dataCustomer?.id || 0;
    const data = payload as ICustomerDTO;
    if (payload.person_type === 'JURIDICA') {
      data.cpf = null;
      data.rg = null;
    } else {
      data.cnpj = null;
      data.ie = null;
    }
    if (id > 0) {
      updateByIdCustomer(id, data);
      return;
    }
    createCustomer(data);
  };

  // Delete action
  const handleDelete = (payload: any) => {
    const { id, name } = payload;
    dialogConfirmDanger(
      'Excluir Cliente?',
      `Deseja realmente excluir o Cliente: ${id} - ${name} `,
      () => deleteByIdCustomer(id),
    );
  };

  return (
    <DataDialog
      referenceId={dataCustomer?.id}
      referenceDisplayName="cliente"
      initialValues={dataCustomer}
      open={dataCustomer !== undefined}
      close={handleClose}
      onSave={handleSave}
      onDelete={handleDelete}
      formId={formData}
      withTabs
    >
      <CustomerModalTabs value={tabIndex} onChange={handleChangeTabIndex} isEditMode={isEditMode} />
      <DialogContent
        dividers
        sx={{
          backgroundColor: () => {
            if (tabIndex === 0) return '';
            return theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900];
          },
        }}
      >
        <TabPanel index={0} tabActive={tabIndex} forceRender>
          <CustomerModalDataTab formId={formData} onSave={handleSave} isEditMode={isEditMode} />
        </TabPanel>

        <TabPanel index={1} tabActive={tabIndex}>
          <PeopleContact peopleType="CLIENTE" peopleId={dataCustomer?.id || 0} />
        </TabPanel>

        <TabPanel index={2} tabActive={tabIndex}>
          <PeopleAddress peopleType="CLIENTE" peopleId={dataCustomer?.id || 0} />
        </TabPanel>

        <TabPanel index={3} tabActive={tabIndex}>
          <PeopleUser peopleId={dataCustomer?.id || 0} peopleType="CLIENTE" />
        </TabPanel>
      </DialogContent>
    </DataDialog>
  );
};
