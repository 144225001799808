import { createContext, useEffect, useState } from 'react';

// Define type for routes
interface IRouteOptionsData {
  label: string;
  icon?: string | React.ReactNode;
  to: string;
}

// Define interface for sidebar list
interface ISidebarMenuOptionsData {
  subheader?: string;
  items: Array<ISidebarMenuGroupData | ISidebarMenuItemData>;
}

// Define interface for sidebar group
export interface ISidebarMenuGroupData {
  label: string;
  subheader?: string;
  icon?: string | React.ReactNode;
  items: ISidebarMenuItemData[];
  level?: number;
}

// Define interface for sidebar item
export interface ISidebarMenuItemData {
  label: string;
  icon?: string | React.ReactNode;
  to?: string;
  onClick?: () => void;
  level?: number;
}

// Create data for sidebar
const sidebarOptions: ISidebarMenuOptionsData[] = [
  {
    items: [
      {
        label: 'Página inicial',
        icon: 'home',
        to: '/pagina-inicial',
      },
      {
        label: 'Cadastros',
        icon: 'app_registration',
        items: [
          {
            label: 'Ramo de atividades',
            to: '/ramos-de-atividade',
            icon: 'cases',
            level: 2,
          },
          {
            label: 'Contratos',
            to: '/contratos',
            icon: 'description',
            level: 2,
          },
          {
            label: 'Clientes',
            to: '/clientes',
            icon: 'face',
            level: 2,
          },
          {
            label: 'Fornecedores',
            to: '/fornecedores',
            icon: 'inventory',
            level: 2,
          },
          {
            label: 'Colaboradores',
            to: '/colaboradores',
            icon: 'home',
            level: 2,
          },
          {
            label: 'Atestados',
            to: '/atestados',
            icon: 'emergency',
            level: 2,
          },
          {
            label: 'Veículos',
            to: '/veiculos',
            icon: 'directions_car',
            level: 2,
          },
          {
            label: 'Seguros',
            to: '/seguros',
            icon: 'support',
            level: 2,
          },
        ],
      },
    ],
  },
  {
    subheader: 'Manutenção',
    items: [
      {
        label: 'Veículos',
        icon: 'directions_car',
        items: [
          {
            label: 'Registro de uso',
            to: '/uso-veiculos',
            icon: 'key',
            level: 2,
          },
          {
            label: 'Manutenção',
            to: '/manutencoes',
            icon: 'build',
            level: 2,
          },
          {
            label: 'Abastecimentos',
            to: '/abastecimentos',
            icon: 'local_gas_station',
            level: 2,
          },
        ],
      },
      {
        label: 'Lançamentos',
        icon: 'location_on',
        items: [
          {
            label: 'Viagens',
            to: '/viagens',
            icon: 'airport_shuttle',
            level: 2,
          },
          {
            label: 'Entregas',
            to: '/entregas',
            icon: 'moped',
            level: 2,
          },
        ],
      },
      {
        label: 'Financeiro',
        icon: 'account_balance',
        items: [
          {
            label: 'Categorias',
            to: '/financeiro/categorias',
            icon: 'category',
            level: 2,
          },
          {
            label: 'Títulos',
            to: '/financeiro/lancamentos',
            icon: 'payments',
            level: 2,
          },
        ],
      },
    ],
  },
  {
    subheader: 'Sistema',
    items: [
      {
        label: 'Sistema',
        icon: 'settings',
        items: [
          {
            label: 'Dados da empresa',
            to: '/empresa',
            icon: 'business',
            level: 2,
          },
          {
            label: 'Usuários',
            to: '/usuarios',
            icon: 'account_circle',
            level: 2,
          },
          {
            label: 'Logs',
            to: '/logs',
            icon: 'history',
            level: 2,
          },
        ],
      },
    ],
  },
];

// Define interface for menu context
interface IMenuContext {
  // Sidebar menu state
  isOpenSidebarMenu: boolean;
  openSidebarMenu: () => void;
  closeSidebarMenu: () => void;
  // Sidebar menu type
  isExpandedSidebarMenu: boolean;
  toggleIsExpandedSidebarMenu: (value?: boolean) => void;
  // Sidebar options
  optionsSidebarMenu: ISidebarMenuOptionsData[];
  optionsRoute: IRouteOptionsData[];
  // Account dropdown menu
  isOpenAccountDropDownMenu: boolean;
  anchorElementAccountDropDownMenu: null | HTMLElement;
  closeAccountDropDownMenu: () => void;
  openAccountDropDownMenu: (event: React.MouseEvent<HTMLElement>) => void;
}

// Create context
export const MenuContext = createContext({} as IMenuContext);

// Create menu provider
export const MenuContextProvider: React.FC = ({ children }) => {
  // Use state
  const [isOpenSidebarMenu, setIsOpenSidebarMenu] = useState(false);
  const [isExpandedSidebarMenu, setIsExpandedSidebarMenu] = useState(false);
  const [isOpenAccountDropDownMenu, setIsOpenAccountDropDownMenu] = useState(false);
  const [anchorElementAccountDropDownMenu, setAnchorElementAccountDropDownMenu] =
    useState<null | HTMLElement>(null);
  const [optionsSidebarMenu] = useState<ISidebarMenuOptionsData[]>(sidebarOptions);
  const [optionsRoute, setOptionsRoute] = useState<IRouteOptionsData[]>([]);

  // Set is open sidebar
  const openSidebarMenu = () => setIsOpenSidebarMenu(true);

  // Set is close
  const closeSidebarMenu = () => setIsOpenSidebarMenu(!!isExpandedSidebarMenu);

  // Toggle sidebar expanded
  const toggleIsExpandedSidebarMenu = (value?: boolean) =>
    setIsExpandedSidebarMenu(value !== undefined ? value : !isExpandedSidebarMenu);

  // On change expanded state
  useEffect(() => {
    // Force close sidebar
    if (!isExpandedSidebarMenu) setIsOpenSidebarMenu(false);
  }, [isExpandedSidebarMenu]);

  // Open account dropdown menu
  const openAccountDropDownMenu = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpenAccountDropDownMenu(true);
    setAnchorElementAccountDropDownMenu(event.currentTarget);
  };

  // Close account dropdown menu
  const closeAccountDropDownMenu = () => {
    setIsOpenAccountDropDownMenu(false);
    setAnchorElementAccountDropDownMenu(null);
  };

  // Generate link routes
  useEffect(() => {
    const onlyWithLinks: IRouteOptionsData[] = [];
    const getRoute = (routes: any[]): void => {
      routes.map((item) => (item.to ? onlyWithLinks.push({ ...item }) : getRoute(item.items)));
    };
    getRoute(sidebarOptions);
    setOptionsRoute(onlyWithLinks);
  }, [sidebarOptions]);

  return (
    <MenuContext.Provider
      value={{
        // Sidebar control state
        isOpenSidebarMenu,
        openSidebarMenu,
        closeSidebarMenu,
        // Expanded sidebar state
        isExpandedSidebarMenu,
        toggleIsExpandedSidebarMenu,
        // Options data route
        optionsSidebarMenu,
        optionsRoute,
        // Account dropdown menu
        isOpenAccountDropDownMenu,
        anchorElementAccountDropDownMenu,
        openAccountDropDownMenu,
        closeAccountDropDownMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
