/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { DataGrid, IDataGridProps } from '../../../shared/components';
import { useAuth, useInsurancePlan } from '../../../shared/hooks';

type IInsurancePlanDataGridProps = Omit<IDataGridProps, 'rows' | 'columns'>;

export const InsurancePlanDataGrid: React.FC<IInsurancePlanDataGridProps> = ({ ...rest }) => {
  const [canSearch, setCanSearch] = useState(false);

  const { user } = useAuth();

  const {
    columnsInsurancePlan,
    rowsInsurancePlan,
    rowCountInsurancePlan,
    pageInsurancePlan,
    handlePageInsurancePlan,
    pageSizeInsurancePlan,
    handlePageSizeInsurancePlan,
    sortInsurancePlan,
    filterInsurancePlan,
    handleFilterInsurancePlan,
    handleSortInsurancePlan,
    getAllInsurancePlan,
  } = useInsurancePlan();

  useEffect(() => handleFilterInsurancePlan({}), []);

  useEffect(() => {
    if (!user.token || canSearch) return;
    setCanSearch(true);
  }, [canSearch, user]);

  useEffect(() => {
    if (!canSearch) return;
    getAllInsurancePlan();
  }, [canSearch, sortInsurancePlan, filterInsurancePlan, pageInsurancePlan, pageSizeInsurancePlan]);

  return (
    <DataGrid
      rows={rowsInsurancePlan}
      rowCount={rowCountInsurancePlan}
      columns={columnsInsurancePlan}
      onSortModelChange={handleSortInsurancePlan}
      sortModel={sortInsurancePlan}
      pageSize={pageSizeInsurancePlan}
      onPageSizeChange={handlePageSizeInsurancePlan}
      page={pageInsurancePlan}
      onPageChange={handlePageInsurancePlan}
      {...rest}
    />
  );
};
